import { getContext } from '@fiverr-private/fiverr_context';
import { metric } from '../metric';
import { maxios } from '../maxios';

/**
 * Fetches the seller's online status.
 * @param {object} pathfinderWrapper the context's pathfinder wrapper.
 * @param {object} logger the context's logger.
 * @returns {Promise<boolean>} whether or not the user is online.
 */
const fetchIsOnline = async ({ pathfinderWrapper, logger }) => {
    const { pathParameters } = getContext();
    const { username } = pathParameters;
    const url = pathfinderWrapper.pathfinder('is_online', { username });

    try {
        const { data } = await maxios.get(url, { routeKey: 'is_online' });
        const { is_online: isOnline = false } = data;

        return isOnline;
    } catch (error) {
        logger.warn(error, {
            description: 'Failed to fetch seller online status',
            username,
        });
        metric.count('warnings.general.gig_page.fetch_is_online.failed');
        return false;
    }
};

export default fetchIsOnline;
