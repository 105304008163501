import { CHECKOUT_PLANS } from '../../utils/checkoutPlan/constants';
import { updateExtraFast } from '../packages';
import { getSelectedPackageId } from '../../reducers/packages';

/**
/**
 * @constant {string}
 * @desc The select checkout plan action type for the checkoutPlan reducer
 * **/
export const SELECT_CHECKOUT_PLAN = 'SELECT_CHECKOUT_PLAN';

/**
 * @constant {string}
 * @desc The set checkout drawer open action type for the checkoutPlan reducer
 * **/
export const SET_CHECKOUT_DRAWER_OPEN = 'SET_CHECKOUT_DRAWER_OPEN';

/**
 * Returns the dispatch object for the seller's select checkout plan action.
 * @param selectedCheckoutPlan a checkout plan
 * @returns {object} the dispatch object
 */
export const selectCheckoutPlan = ({ selectedCheckoutPlan }) => ({
    type: SELECT_CHECKOUT_PLAN,
    data: {
        selectedCheckoutPlan,
    },
});

export const selectMilestonesPlan = () => (dispatch, getState) => {
    const packageId = getSelectedPackageId(getState());
    dispatch(selectCheckoutPlan({ selectedCheckoutPlan: CHECKOUT_PLANS.MILESTONES_ORDER }));
    dispatch(updateExtraFast({ value: 0, packageId }));
};

/**
 * Returns the dispatch object for setting whether the checkout drawer is open or not
 * @param {boolean} checkoutDrawerOpen open or close the drawer
 * @returns {object} the dispatch object
 */
export const setCheckoutDrawerOpen = (checkoutDrawerOpen) => ({
    type: SET_CHECKOUT_DRAWER_OPEN,
    data: {
        checkoutDrawerOpen,
    },
});
