import React from 'react';
import { bool } from 'prop-types';
import { translate } from '@fiverr-private/i18n-react';
import { BillIcon, CommentIcon } from '@fiverr-private/icons';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { useGigPageContext } from "@fiverr-private/gig_page_context";
import CustomOrder from '../../../shared/CustomOrder';
import ContactSellerBtn from '../../ContactSellerBtn';
import { ConsultationButton } from '../../ConsultationButton/ConsultationButton';
import { ContactSellerOption } from '../../ContactSellerOption/ContactSellerOption';
import {useExperiments} from "../../../../hooks/useExperiments";

const MP_SOURCE_COMPONENT = 'Gig page contact seller options button';
const BQ_SOURCE_NAME = 'ask_a_question';
const CUSTOM_ORDER_BQ_SOURCE_NAME = 'contact_seller';

const PopoverContent = ({ customOrderEligible }) => {
    const { seller } = useGigPageContext();
    const { inAIBriefTest } = useExperiments();

    const { isAgency } = seller;
    const headerTitle = isAgency ?
        `gig_page_perseus.contact_seller_options.popover.title_agency` :
        `gig_page_perseus.contact_seller_options.popover.title`;

    const triggerCopy = translate(`gig_page_perseus.contact_seller_options.popover.${inAIBriefTest ? 'start_a_conversation' : 'contact_seller'}`);
    const shouldShowRequestAQuoteOption = !inAIBriefTest && customOrderEligible;

    return (
        <Container>
            <Text fontWeight="bold" padding="4" marginBottom="2" textAlign="start">
                {translate(headerTitle)}
            </Text>
            <Stack gap="2" direction="column">
                {shouldShowRequestAQuoteOption && (
                    <CustomOrder
                        bqSourceName={CUSTOM_ORDER_BQ_SOURCE_NAME}
                        triggerId="Contact Seller"
                        triggerCopy={translate('gig_page_perseus.contact_seller_options.popover.get_a_quote')}
                        triggerPlacement="Contact me dropdown"
                    >
                        <ContactSellerOption
                            title={translate('gig_page_perseus.contact_seller_options.popover.get_a_quote')}
                            IconComponent={<BillIcon/>}
                        />
                    </CustomOrder>
                )}
                <ContactSellerBtn
                    asContactSellerOption
                    mixpanelSourceComponent={MP_SOURCE_COMPONENT}
                    bqSourceName={BQ_SOURCE_NAME}
                    triggerId={"Contact Seller"}
                    triggerCopy={triggerCopy}
                    triggerPlacement="Contact me dropdown"
                >
                    <ContactSellerOption
                        title={triggerCopy}
                        IconComponent={<CommentIcon/>}
                    />
                </ContactSellerBtn>
                <ConsultationButton/>
            </Stack>
        </Container>
    );
};

PopoverContent.propTypes = {
    customOrderEligible: bool,
};

export default PopoverContent;
