import React, { useEffect } from 'react';
import { object, string } from 'prop-types';
import { Button } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { BQ_CLICKED_PROMOTE_YOUR_GIG, BQ_VIEWED_PROMOTE_YOUR_GIG } from '../../../utils/bigQueryEvents';

const PromoteYourGig = ({ username, customClassName, promotionStatus }, { biEvents, pathfinderWrapper, general }) => {
    const onPromoteClick = () => {
        biEvents.sendBigQueryEvent({ eventName: BQ_CLICKED_PROMOTE_YOUR_GIG, params: { promotionStatus } });
    };

    useEffect(() => {
        biEvents.sendBigQueryEvent({ eventName: BQ_VIEWED_PROMOTE_YOUR_GIG, params: { promotionStatus } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const btnColor = general.isPro ? Button.COLORS.BLACK : Button.COLORS.GREEN;

    return (
        <Button
            className={customClassName}
            onClick={onPromoteClick}
            href={pathfinderWrapper.pathfinder('user_promoted_gigs', { user_id: username })}
            target="_blank"
            color={btnColor}
            size={Button.SIZES.SMALL}
        >
            <I18n k="gig_page_perseus.seller_banner.promoted_button_text" />
        </Button>
    );
};

PromoteYourGig.propTypes = {
    username: string,
    customClassName: string,
    promotionStatus: string,
};

PromoteYourGig.contextTypes = {
    biEvents: object,
    pathfinderWrapper: object,
    general: object,
};

export default PromoteYourGig;
