import React from 'react';
import { isEqual } from 'lodash';
import { theme } from '@fiverr-private/theme';
import { CONSULTATION_DEFAULT_RESPONSE, ConsultationIndication } from '@fiverr-private/consultation';
import { useGigPageContext } from '@fiverr-private/gig_page_context';

export const ConsultationIndicationWrapper = () => {
  const { biEvents, consultationData } = useGigPageContext();

  const { sellerId } = biEvents.getBigQueryEnrichmentData();

  if (!consultationData || isEqual(consultationData, CONSULTATION_DEFAULT_RESPONSE)) {
    return null;
  }

  return (
    <ConsultationIndication
      wrapperProps={{
        justifyContent: { default: 'center', sm: 'flexStart' },
      }}
      iconColor={theme.colors.grey_1200}
      textColor="grey_1200"
      iconSize={16}
      sellerId={sellerId}
      biEnrichment={{ pageName: 'gig_page', elementName: 'side_bar_indication' }}
    />
  );
};
