import React from 'react';
import { connect } from 'react-redux';
import { object } from 'prop-types';
import { LazyComponent } from '@fiverr-private/orca';
import { eligibleForReturningBuyerSubscriptionModal } from '../../utils/recurring/returningBuyerModal';

const lazyImportReturningBuyerSubscriptionModal = () =>
    import(/* webpackChunkName: 'ReturningBuyerSubscriptionModal' */ './ReturningBuyerSubscriptionModal');

const ReturningBuyerSubscriptionModal = ({ packages }, { general, currentUser, logger }) => {
    const returningBuyerSubscriptionModal = eligibleForReturningBuyerSubscriptionModal({
        packages,
        currentUser,
        general,
    });

    const onError = (error) => {
        logger.error(error, {
            source: 'ReturningBuyerSubscriptionModal',
            description: 'Failed to lazy load ReturningBuyerSubscriptionModal',
        });
    };

    if (!returningBuyerSubscriptionModal) {
        return null;
    }

    return <LazyComponent lazyImport={lazyImportReturningBuyerSubscriptionModal} onError={onError} />;
};

ReturningBuyerSubscriptionModal.propTypes = {
    packages: object.isRequired,
};

ReturningBuyerSubscriptionModal.contextTypes = {
    general: object,
    currentUser: object,
    logger: object,
};

const mapStateToProps = ({ packages }) => ({ packages });

export { ReturningBuyerSubscriptionModal };
export default connect(mapStateToProps)(ReturningBuyerSubscriptionModal);
