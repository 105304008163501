export const UPDATE_CALCULATOR = 'UPDATE_CALCULATOR';
export const updateCalculator = ({ value, calculatorId, packageId }) => ({
    type: UPDATE_CALCULATOR,
    data: {
        packageId,
        calculatorId,
        value,
    },
});

export const UPDATE_EXTRA_FAST = 'UPDATE_EXTRA_FAST';
export const updateExtraFast = ({ value, packageId }) => ({
    type: UPDATE_EXTRA_FAST,
    data: {
        value,
        packageId,
    },
});

export const SELECT_PACKAGE = 'SELECT_PACKAGE';
export const selectPackage = ({ packageId }) => ({
    type: SELECT_PACKAGE,
    data: {
        packageId,
    },
});

export const UPDATE_FEATURE = 'UPDATE_FEATURE';
export const updateFeature = ({ featureId, amount }) => ({
    type: UPDATE_FEATURE,
    data: {
        featureId,
        amount,
    },
});

export const UPDATE_CUSTOM_EXTRA = 'UPDATE_CUSTOM_EXTRA';
export const updateCustomExtra = ({ customExtraId, amount }) => ({
    type: UPDATE_CUSTOM_EXTRA,
    data: {
        customExtraId,
        amount,
    },
});

export const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
export const updateQuantity = ({ quantity }) => ({
    type: UPDATE_QUANTITY,
    data: {
        quantity,
    },
});

export const SELECT_RECURRING_OPTION = 'SELECT_RECURRING_OPTION';
export const selectRecurringOption = ({ selectedRecurringOptionId }) => ({
    type: SELECT_RECURRING_OPTION,
    data: {
        selectedRecurringOptionId,
    },
});
