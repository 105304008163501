/**
 * Payment types. used also for drawer component
 * @type {{SINGLE_ORDER: string, RECURRING_ORDER: string, MILESTONES_ORDER: string}}
 * @note: Recurring Order is a Subscription Order
 */
export const CHECKOUT_PLANS = {
    SINGLE_ORDER: 'single_order',
    RECURRING_ORDER: 'recurring_order',
    MILESTONES_ORDER: 'milestones_order',
};
