import React from 'react';
import { number, string, bool, func } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Checkbox, Tooltip, Icon } from '@fiverr-private/fit';
import { s_question_circle } from '@fiverr-private/icons';
import { formatDaysKey } from '../../../../utils/packagesUtils';
import { EXTRA_FAST_TYPE } from '../../../../utils/packagesUtils/constants';

import './style.scss';

const PackageExtraCheckbox = ({ checked, label, type, tooltipContent, onChange, currentDuration }) => {
    const isExtraFast = type === EXTRA_FAST_TYPE;

    return (
        <Checkbox className="package-extra-checkbox" checked={checked} onChange={onChange}>
            <div>
                <div>
                    <span className="checkbox-label">{label}</span>
                    {tooltipContent && (
                        <Tooltip
                            className="checkbox-tooltip"
                            boxContentClassName="tooltip-box-content"
                            content={tooltipContent}
                            appendToBody={false}
                        >
                            <Icon size={12}>{s_question_circle}</Icon>
                        </Tooltip>
                    )}
                </div>
                {!isExtraFast && currentDuration > 0 && (
                    <div className="current-duration">
                        (+{currentDuration} <I18n k={formatDaysKey(currentDuration)} />)
                    </div>
                )}
            </div>
        </Checkbox>
    );
};

PackageExtraCheckbox.propTypes = {
    checked: bool,
    label: string,
    type: string,
    tooltipContent: string,
    onChange: func,
    currentDuration: number,
};

export { PackageExtraCheckbox };
