import { PreparedLocalizedEntity } from '../sellerTranslationTypes';
import { MappedCustomExtra } from './mapToLocalizedPackageList';

export const mapLocalizedCustomExtras = (customExtras: PreparedLocalizedEntity[] = []): MappedCustomExtra[] =>
    customExtras
        .filter(({ localizedTitle, localizedDescription }) => localizedTitle && localizedDescription)
        .map(({ localizedTitle, localizedDescription }) => ({
            label: localizedTitle,
            buyerTooltip: localizedDescription,
        }));
