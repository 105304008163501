const CHOICE_ALGO_A = 'ALGO_A';

const isChoiceEligible = (choiceEligibilities) => {
    if (!choiceEligibilities) {
        return false;
    }
    const formattedChoiceEligibilities = choiceEligibilities.map((choice) => choice.type);

    return formattedChoiceEligibilities.includes(CHOICE_ALGO_A);
};

export const isFiverrChoice = ({ queryParameters, choiceEligibilities }) => {
    // Logic is complex and shared between 3 sources.
    // Main Caveat is that the user should only see one Fiverr Choice Gig per session, and it should stay
    // consistent across the site.
    if (!queryParameters.fiverr_choice) {
        return false;
    }

    return isChoiceEligible(choiceEligibilities);
};
