import React from 'react';
import { Rating } from '@fiverr-private/data_display';
import { translate } from '@fiverr-private/i18n-react';

export interface SellerRatingProps {
  rating: number;
  ratingsCount?: number;
  withFiveStars?: boolean;
  withWordReviews?: boolean;
  onReviewsClick?: () => void;
}

export function SellerRating({
  rating,
  ratingsCount,
  withFiveStars = false,
  withWordReviews = false,
  onReviewsClick,
}: SellerRatingProps) {
  return (
    <Rating
      variant={withFiveStars ? 'full' : 'compact'}
      score={rating}
      reviews={getReviewsValue(ratingsCount, withWordReviews)}
      onReviewsClick={onReviewsClick}
      minimumFractionDigits={1}
      maximumFractionDigits={1}
    />
  );
}

const getReviewsValue = (ratingsCount: number | undefined, withWordReviews: boolean): string | undefined => {
  if (!ratingsCount) return undefined;
  if (!withWordReviews) return `${ratingsCount}`;
  return getReviewsValueWithWord(ratingsCount);
};

const getReviewsValueWithWord = (count: number): string => {
  if (count === 1) {
    return translate('gig_seller_info.num_reviews.one', { params: { count } });
  }
  return translate('gig_seller_info.num_reviews.other', { params: { count } });
};
