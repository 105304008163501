import { NUMERIC_TYPE } from '../packagesUtils/constants';

/**
 *  Prepares pricing factors for package table display:
 *  First pricing factors are aggregated from all packages
 *  into a single array and then they are sorted by type
 */
export const preparePricingFactors = (packageList) => {
    const enrichedFeatures = enrichFeaturesRowData(packageList);
    return sortFeaturesByType(enrichedFeatures);
};

/**
 *  Constructs a flat array of enriched features from the
 *  combined features of all packages in the package list.
 *
 *  For example:
 *
 *  for packageList: [
 *      {features: [{id: 1}, {id: 2}]},
 *      {features: [{id: 3}, {id: 4}]}
 *  ]
 *
 *  returns [
 *      {id: 1, data: {}, ...},
 *      {id: 2, data: {}, ...},
 *      {id: 3, data: {}, ...},
 *      {id: 4, data: {}, ...}
 *  ]
 */
export const enrichFeaturesRowData = (packageList) => {
    const enrichedFeatures = [];

    packageList.forEach(({ features, id }) =>
        features.forEach((feature) => enrichFeatureRowData(enrichedFeatures, feature, id))
    );

    return enrichedFeatures;
};

/**
 * This method mutates the `flatFeatures` to include a `rowData` list for each Feature.
 * the `rowData` controls what will be displayed on each column in the PackagesTable -
 * either a checked/un-checked √ or a number
 * @param {[]} flatFeatures - mutated with list of column values for each Feature (`rowData`)
 * @param {object} feature
 * @param {number} packageId
 */
const enrichFeatureRowData = (flatFeatures, feature, packageId) => {
    const { id, name, value, included, type, serviceId } = feature;
    const columnList = flatFeatures.find((item) => item.id === id);
    const rowData = { id, name, value, included, type, packageId };

    columnList
        ? columnList.rowData.push(rowData)
        : flatFeatures.push({ id, name, type, serviceId, rowData: [rowData] });
};

/**
 *  Sorts features in a way that boolean features will always precede
 *  number features (features with a non negative numerable value)
 */
export const sortFeaturesByType = (features) =>
    features.sort((feature, otherFeature) => {
        const isFeatureNumberType = isNumberFeature(feature) ? 1 : 0;
        const isOtherFeatureNumberType = isNumberFeature(otherFeature) ? 1 : 0;

        return isFeatureNumberType - isOtherFeatureNumberType;
    });

export const isNumberFeature = ({ rowData }) => rowData && rowData.some(({ type }) => type === NUMERIC_TYPE);

export const shouldShowFeature = (included, displayNotIncluded) => included || displayNotIncluded;
