import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import OutOfOfficeBase from '@fiverr-private/out_of_office';
import SafePureComponent from '../shared/SafePureComponent';
import { isLoggedInUser } from '../../utils/isLoggedInUser';

import './style.scss';

interface OutOfOfficeProps {
  is_page_owner: boolean;
  username?: string;
  displayName?: string;
  vacation_status: 'active' | 'pending' | 'historic';
  end_date: number;
  start_date: number;
  is_on_vacation: boolean;
  allow_contact?: boolean;
  message_for_buyer?: string;
  user_id?: number;
}

const _OutOfOffice = () => {
  const { userId } = getContext();
  const {
    general,
    isPageOwner,
    seller: { username, vacation, displayName, isOnVacation },
  } = useGigPageContext();
  const { isStudio } = general;

  if (isStudio || !isOnVacation || !vacation) {
    return null;
  }

  const outOfOfficeProps: OutOfOfficeProps = {
    is_page_owner: isPageOwner,
    username,
    displayName,
    start_date: vacation.startsAt,
    end_date: vacation.endsAt,
    message_for_buyer: vacation.awayMessage,
    allow_contact: vacation.isContactAllowed,
    is_on_vacation: true,
    vacation_status: 'active',
  };

  if (isLoggedInUser()) {
    outOfOfficeProps.user_id = userId;
  }

  return <OutOfOfficeBase {...outOfOfficeProps} />;
};

export const OutOfOffice = SafePureComponent(_OutOfOffice);
