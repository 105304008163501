import { TOGGLE_UGC } from '../../actions/ugc';
import { getCurrentContent } from '../../utils/ugc/getCurrentContent';
import { getUgcTranslatedMilestones } from './utils';

const ugcReducer = ({ ugcMap = {}, showOriginal = true }) => {
    const initialState = {
        ugcMap,
        showOriginal,
    };

    return (state = initialState, action = {}) => {
        const { type, data } = action;
        switch (type) {
            case TOGGLE_UGC:
                return {
                    ...state,
                    showOriginal: !state.showOriginal,
                    ugcMap: {
                        ...state.ugcMap,
                        translated: data?.translatedUGC ? data.translatedUGC : state.ugcMap.translated,
                    },
                };
            default:
                return state;
        }
    };
};

// Selectors

/**
 * Get work process with the ugc translations
 * @param {object} state redux state
 * @param {object} workProcess work process object before ugc translation
 * @return {object} work process object with ugc translation
 */
export const getTranslatedMilestones = ({ ugc, workProcess }) => {
    const { workProcess: currentWorkProcessUGC = {} } = getCurrentContent({ ugc });

    return getUgcTranslatedMilestones({ workProcess, currentWorkProcessUGC });
};

// End of selectors

export default ugcReducer;
