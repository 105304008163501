import { PreparedLocalizedEntity, PreparedLocalizedPackage } from '../sellerTranslationTypes';
import { mapLocalizedCustomExtras } from './mapLocalizedCustomExtras';

export interface MappedCustomExtra {
    label: string;
    buyerTooltip: string;
}

interface extractPackagesContentProps {
    packageList?: PreparedLocalizedPackage[];
    customExtras?: PreparedLocalizedEntity[];
}

export const mapToLocalizedPackageList = ({ packageList = [], customExtras = [] }: extractPackagesContentProps) => {
    const packages = {};
    const localizedCustomExtras = mapLocalizedCustomExtras(customExtras);

    packageList.forEach(({ localizedTitle = {}, localizedDescription = {}, id }) => {
        if (!localizedTitle || !localizedDescription) {
            return;
        }

        packages[id] = {
            title: localizedTitle,
            description: localizedDescription,
            customExtras: localizedCustomExtras,
        };
    });

    return packages;
};
