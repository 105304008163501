import React from 'react';
import { array } from 'prop-types';
import { isEmpty } from 'lodash';
import Feature from '../Feature';

const FeatureList = ({ features = [] }) => {
    if (isEmpty(features)) {
        return null;
    }

    return (
        <ul className="features">
            {features.map(({ id, ...rest }) => (
                <Feature key={id} id={id} {...rest} />
            ))}
        </ul>
    );
};

FeatureList.propTypes = {
    features: array,
};

export default FeatureList;
