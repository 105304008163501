import React, { useRef, useState } from 'react';
import { noop } from 'lodash';
import { object, func } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';
import { metric } from '../../../../../../utils/metric';
import { MP_LIMITED_EDITION_APPLY } from '../../../../../../utils/mixPanelEvents';
import { onGuestClick } from '../../../../../../utils/contactSeller';

const LimitedEdition = ({ onClickButton = noop }, { limitedEdition, biEvents, pathfinderWrapper }) => {
    const modalRef = useRef(null);
    const [modalImported, setModalImported] = useState(false);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(limitedEdition.submitted);

    const { userId } = getContext();
    const isLoggedIn = !!userId;
    const ctaSuffix = submitted ? 'entry_submitted' : 'submit_your_entry';
    let closeModal = noop;
    let openModal = noop;

    const onClick = async (e) => {
        e.preventDefault(); // to prevent the form from being submitted

        onClickButton();

        biEvents.sendMixPanelEvent({ eventName: MP_LIMITED_EDITION_APPLY });
        metric.count(`limited_edition.apply.click.logged_in.${isLoggedIn}`);

        if (!isLoggedIn) {
            return onGuestClick({
                biEvents,
                pathfinderWrapper,
                modalOptions: { source: 'gig_page' }
            });
        }

        if (!modalImported) {
            setLoading(true);
            const modal = await import(
                /* webpackChunkName: 'LimitedEditionModal' */ '../../../../../LimitedEdition/Modal'
            );
            modalRef.current = modal.default;
            setLoading(false);
            setModalImported(true);
        } else {
            openModal();
        }
    };

    const setModalTriggers = ({ open, close }) => {
        openModal = open;
        closeModal = close;
    };

    const onSubmit = () => {
        setSubmitted(true);
        closeModal();
    };

    return (
        <>
            <Button color={Button.COLORS.BLACK} loading={loading} disabled={submitted} onClick={onClick}>
                <I18n k={`gig_page_perseus.limited_edition.cta.${ctaSuffix}`} />
            </Button>
            {modalImported && (
                <modalRef.current
                    trigger={setModalTriggers}
                    onSubmitSuccess={onSubmit}
                    limitedEdition={limitedEdition}
                />
            )}
        </>
    );
};

LimitedEdition.propTypes = {
    onClickButton: func,
};

LimitedEdition.contextTypes = {
    limitedEdition: object,
    biEvents: object,
};

export default LimitedEdition;
