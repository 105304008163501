/**
 * @constant {number}
 * @desc Max amount of extra.
 * **/
export const MAX_AMOUNT = 20;

/**
 * @constant {number}
 * @desc Min amount of extra.
 * **/
export const MIN_AMOUNT = 0;
