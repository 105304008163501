import { useState } from 'react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { useBusinessSignInSteps } from '@fiverr-private/user_session';
import { BUSINESS_TRIGGER_CTA } from '@fiverr-private/business_success_sign_modal';
import { FLOW_CONTEXT_TYPES } from '@fiverr-private/pro_migration/src/components/UserMigration/fib-migration-trigger';
import { AI_BRIEF_DRAWER, PAGE_NAME } from '../utils/events';
import { reportContactSellerClick } from '../utils/contactSeller';

const COMPONENT_NAME = 'ai_brief_button';
export interface UseAIBriefOptions {
  onContactSellerClick: () => void;
}
export const useAIBrief = ({ onContactSellerClick }: UseAIBriefOptions) => {
  const [shouldShowDrawer, setShouldShowDrawer] = useState(false);
  const [isDrawerLoading, setIsDrawerLoading] = useState(false);
  const { seller, general, biEvents } = useGigPageContext();
  const { sellerId, subCategoryId, categoryId } = general;
  const sellerUsername = seller?.username;
  const sellerDisplayName = seller?.displayName || sellerUsername;

  const handleContactSellerClick = () => {
    closeDrawer();
    reportContactSellerClick({
      biEvents,
      mixpanelSourceComponent: AI_BRIEF_DRAWER,
      bqSourceName: AI_BRIEF_DRAWER,
      metricKey: 'contact_seller_from_ai_brief.click',
    });
    onContactSellerClick();
  };

  const openDrawer = () => {
    setIsDrawerLoading(true);
    setShouldShowDrawer(true);
  };

  const { ActivationModalComponent, signInSteps } = useBusinessSignInSteps({
    id: `brief-drawer-${sellerId}`,
    onSuccess: openDrawer,
    signInModalParams: {
      businessTriggerCtaType: BUSINESS_TRIGGER_CTA.NO_RESULT_PAGE_BRIEF,
      biEnrichmentParams: { source: PAGE_NAME },
      source: PAGE_NAME,
    },
    activationModalParams: {
      biPageName: PAGE_NAME,
    },
    migrateModalParams: {
      openFibMigrationPayload: {
        source: PAGE_NAME,

        biEnrichment: {
          pageElementName: COMPONENT_NAME,
          trigger: 'click',
        },

        flowContext: {
          type: FLOW_CONTEXT_TYPES.no_result_page_brief,
          sellerName: sellerDisplayName,
        },
      },
    },
    shouldActivate: true,
    shouldMigrate: true,
  });

  const closeDrawer = () => {
    setShouldShowDrawer(false);
    setIsDrawerLoading(false);
  };

  return {
    props: {
      componentName: COMPONENT_NAME,
      source: PAGE_NAME,
      seller: {
        ...seller,
        id: Number(sellerId),
        userName: sellerUsername || '',
        displayName: sellerDisplayName || '',
      },
      shouldRefreshData: shouldShowDrawer,
      categoryId,
      subCategoryId,
      isOpen: shouldShowDrawer && !isDrawerLoading,
      onClose: closeDrawer,
      onLoadingChange: setIsDrawerLoading,
      onContactSellerClick: handleContactSellerClick,
    },
    openDrawerAiBriefDrawer: signInSteps,
    closeDrawer,
    isDrawerLoading,
    ActivationModalComponent,
  };
};
