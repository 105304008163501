import cookieManager from '../cookieManager';
import { isLoggedInUser } from '../../isLoggedInUser';
import Logger from '../../logger';
import localStorage from '../localStorage';
import { maxios } from '../../maxios';
import { PAGE_ID_DELIMITER, PAGE_ID_TEMPLATE, POST_RECENTLY_VIEWED_ROUTE_KEY } from './constants';

/**
 * Activates the logic for managing recently viewed pages
 *
 * @param {number} gigId - Id of the current gig
 * @param {PathfinderWrapper} pathfinderWrapper
 */
export const manageRecentlyViewed = (gigId, pathfinderWrapper) => {
    const recentlyViewedPages = localStorage.storageValue || [];
    const currentPageId = PAGE_ID_TEMPLATE + gigId;

    cookieManager.updateLastContentPages(currentPageId);

    if (!isLoggedInUser()) {
        cookieManager.updateLastViewedGig(gigId);
        updateBrowseHistory(currentPageId, recentlyViewedPages);
    } else if (recentlyViewedPages.length) {
        postRecentlyViewed(recentlyViewedPages, pathfinderWrapper);
    }
};

/**
 * Updates the browse history stored in the local storage to include the current page ID
 *
 * @param {string} currentPageId Id of the current page
 * @param {Array<string>} recentlyViewedPages Last visited pages from local storage
 */
const updateBrowseHistory = (currentPageId, recentlyViewedPages) => {
    // Bounce current page Id to top of the list in
    const notCurrentPage = (page) => !page.endsWith(currentPageId);

    recentlyViewedPages = recentlyViewedPages.filter(notCurrentPage);
    recentlyViewedPages.push(pageIdWithTimestamp(currentPageId));

    localStorage.storageValue = recentlyViewedPages;
};

/**
 * Sending the browsing history of the user to recommendations
 * and clears local storage & cookies related to it
 *
 * @param {Array<string>} recentlyViewedPages Last visited pages from local storage
 * @param {*} pathfinderWrapper
 */
const postRecentlyViewed = async (recentlyViewedPages, pathfinderWrapper) => {
    const url = pathfinderWrapper.pathfinder(POST_RECENTLY_VIEWED_ROUTE_KEY);

    try {
        await maxios.post(
            url,
            { page_ids: recentlyViewedPages.join(',') },
            { routeKey: POST_RECENTLY_VIEWED_ROUTE_KEY }
        );

        localStorage.removeStorage();
        cookieManager.removeLastViewedGig();
    } catch (error) {
        const logger = Logger.getInstance();
        logger.error(error, {
            page_ids: recentlyViewedPages.join(','),
        });
    }
};

/**
 * Attach a timestamp to a page ID
 *
 * @param {string} pageId
 */
export const pageIdWithTimestamp = (pageId) => `${Date.now()}${PAGE_ID_DELIMITER}${pageId}`;
