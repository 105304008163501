import React from 'react';
import { string, number, arrayOf, object, bool } from 'prop-types';
import classNames from 'classnames';
import { shouldRenderNotableClients } from '../../shared/NotableClients/helper';
import Stats from './Stats';
import NotableClientsAbout from './NotableClientsAbout';
import Description from './Description';

import './style.scss';

const StatsDesc = (props) => {
    const {
        description,
        recentDelivery,
        countryCode,
        memberSince,
        notableClients,
        sellerName,
        profilePhoto,
        responseTime,
        isPro,
    } = props;
    const showNotableClients = shouldRenderNotableClients(notableClients);
    const notableClientsProps = {
        notableClients,
        sellerName,
        profilePhoto,
    };

    return (
        <div className={classNames('stats-desc', { 'pro-seller-card': isPro })}>
            <Stats
                countryCode={countryCode}
                memberSince={memberSince}
                responseTime={responseTime}
                recentDelivery={recentDelivery}
            />
            {description && <Description userDescription={description} />}
            {showNotableClients && <NotableClientsAbout {...notableClientsProps} />}
        </div>
    );
};

StatsDesc.propTypes = {
    description: string,
    recentDelivery: number,
    countryCode: string,
    memberSince: number,
    notableClients: arrayOf(object),
    sellerName: string,
    profilePhoto: string,
    responseTime: number,
    isPro: bool,
};

export default StatsDesc;
