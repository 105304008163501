export const getCategoryUrl = ({ categorySlug, isPro, pathfinderWrapper, absolute = false }) => {
    const path = isPro ? 'short_pro_category' : 'short_category';

    return pathfinderWrapper.pathfinder(
        path,
        { id: categorySlug },
        { query: { source: 'gig_category_link' }, absolute }
    );
};

export const getSubCategoryUrl = ({ categorySlug, subCategorySlug, isPro, pathfinderWrapper, absolute = false }) => {
    const path = isPro ? 'short_pro_sub_category' : 'short_category_sub_category';

    return pathfinderWrapper.pathfinder(
        path,
        {
            category_id: categorySlug,
            id: subCategorySlug,
        },
        { query: { source: 'gig_sub_category_link' }, absolute }
    );
};

export const getNestedSubCategoryUrl = ({
    categorySlug,
    subCategorySlug,
    nestedSubCategorySlug,
    pathfinderWrapper,
    absolute = false,
}) =>
    pathfinderWrapper.pathfinder(
        'short_category_nested_sub_category',
        {
            category_id: categorySlug,
            id: subCategorySlug,
            nested_sub_category_id: nestedSubCategorySlug,
        },
        { query: { source: 'gig_nested_sub_category_link' }, absolute }
    );
