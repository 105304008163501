import { BQ_CLICKED_PACKAGES_TAB } from '../../../../utils/bigQueryEvents';
import { TAB_NAMES } from '../config';
import { MP_CLICKED_TAB } from '../../../../utils/mixPanelEvents';

export const reportTabClickEvents = (biEvents, tabId) => {
    reportTabClickBigQueryEvent(biEvents, tabId);
    reportTabClickMixpanelEvent(biEvents, tabId);
};

export const reportTabClickBigQueryEvent = (biEvents, tabId) => {
    biEvents.sendBigQueryEvent({
        eventName: BQ_CLICKED_PACKAGES_TAB,
        params: {
            page: {
                element: {
                    name: TAB_NAMES[tabId - 1],
                    position: tabId,
                },
            },
        },
    });
};

export const reportTabClickMixpanelEvent = (biEvents, tabId) => {
    const eventName = MP_CLICKED_TAB;
    const params = {
        'Tab Name': TAB_NAMES[tabId - 1],
    };

    biEvents.sendMixPanelEvent({
        eventName,
        params,
    });
};
