import { isEmpty } from 'lodash';
import { getCurrentContent } from '../getCurrentContent';
import { calculateVoPackageData } from '../../../reducers/packages/utils';

const mergeCustomExtras = ({ customExtras = [], customExtrasUGCs = [] }) =>
    customExtras.map((customExtra, index) => ({
        ...customExtra,
        ...(customExtrasUGCs[index] || {}),
    }));

export const getCustomExtrasFallback = ({ ugc, id }) => {
    const { showOriginal, ugcMap } = ugc;
    const { original, translated } = ugcMap;

    const ugcSource = showOriginal ? original : translated;

    const { packages: packageUGCS } = ugcSource;

    return packageUGCS[id]?.customExtras || [];
};

export const mergePackageUGCs = ({ packageList, ugc, isVoiceOverGig }) => {
    const { packages: packageUGCS } = getCurrentContent({ ugc });

    return packageList.map((pkg) => {
        const { id, customExtras } = pkg;
        const { description, customExtras: customExtrasUGCs, title } = packageUGCS[id];
        const customExtrasUgc = isEmpty(customExtrasUGCs) ? getCustomExtrasFallback({ ugc, id }) : customExtrasUGCs;
        const combinedCustomExtras = mergeCustomExtras({ customExtras, customExtrasUGCs: customExtrasUgc });

        return {
            ...pkg,
            title,
            description,
            customExtras: combinedCustomExtras,
            ...(isVoiceOverGig ? calculateVoPackageData(pkg) : {}),
        };
    });
};
