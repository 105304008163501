import React, { useEffect, useRef, useState } from 'react';
import { bool, object, oneOf, string } from 'prop-types';
import classNames from 'classnames';
import { Button, Icon } from '@fiverr-private/fit';
import { translate } from '@fiverr-private/i18n-react';
import { s_share } from '@fiverr-private/icons';
import { getContext } from '@fiverr-private/fiverr_context';
import { MP_SHARE_GIG } from '../../../utils/mixPanelEvents';

const SocialSharing = ({ customClass, isPageOwner, sellerName, title, btnFill }, { general, biEvents, logger }) => {
    const { gigId, gigTitle } = general;
    const { locale } = getContext();

    const modalRef = useRef(null);
    const [modalImported, setModalImported] = useState(false);

    useEffect(() => {
        if (modalImported) {
            openModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalImported]);

    const onSharingClick = async () => {
        if (!modalImported) {
            try {
                const modal = await import(
                    /* webpackChunkName: 'SharingLinkModal' */ '@fiverr-private/sharing_link/src'
                );
                modalRef.current = modal.default;
                setModalImported(true);
            } catch (error) {
                logger.error(error, {
                    description: 'failed to load sharing link modal',
                });
            }
        } else {
            openModal();
        }
    };

    const openModal = () => {
        let openShareEvent;

        const data = {
            detail: {
                source: isPageOwner ? 'gigs_show' : 'gigs_show_buyers',
                resource_type: 'gig',
                resource_id: gigId,
                is_resource_owner: isPageOwner,
                interpolation_variables: {
                    username: sellerName,
                    gig_title: gigTitle,
                },
            },
        };

        if (typeof window.CustomEvent === 'function') {
            openShareEvent = new CustomEvent('open-share-modal', data);
        } else {
            openShareEvent = document.createEvent('CustomEvent');
            openShareEvent.initCustomEvent('open-share-modal', false, false, data.detail);
        }
        window.dispatchEvent(openShareEvent);
        biEvents.sendMixPanelEvent({ eventName: MP_SHARE_GIG });
    };

    const btnClasses = classNames('btn-sharing-link', 'sharing-icon', customClass);
    const btnColor = general.isPro ? Button.COLORS.BLACK : Button.COLORS.GREEN;
    const btnAriaLabel = translate('gig_page_perseus.top_nav.share_gig.tooltip');

    const SharingLink = modalRef.current;

    return (
        <>
            <Button
                className={btnClasses}
                onClick={onSharingClick}
                size={Button.SIZES.SMALL}
                color={btnColor}
                fill={btnFill}
                aria-label={btnAriaLabel}
            >
                {title || <Icon className="share-icon">{s_share}</Icon>}
            </Button>
            {modalImported && <SharingLink locale={locale} />}
        </>
    );
};

SocialSharing.propTypes = {
    isPageOwner: bool,
    sellerName: string,
    title: string,
    customClass: string,
    btnFill: oneOf(Object.values(Button.FILLS)),
};

SocialSharing.contextTypes = {
    general: object,
    biEvents: object,
    logger: object,
};

export default SocialSharing;
