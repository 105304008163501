export const BQ_CLICKED_PACKAGE_SELECT = 'clicked_package_select';
export const BQ_CLICKED_CUSTOM_ORDER = 'gig_page.click_on_get_quote';
export const BQ_CLICKED_REPORT_GIG = 'gig_report.click';
export const BQ_GIG_DESCRIPTION_SEE_MORE = 'clicked_on_see_more_gig_description';
export const BQ_SELLER_DETAILS_SEE_MORE = 'clicked_on_see_more_seller_details';
export const BQ_FAQ_EXPANSION = 'clicked_on_fqa_expansion';
export const BQ_REVIEWS_TOGGLE = 'clicked_on_reviews_toggle';
export const BQ_CLICKED_CONTACT_SELLER = 'clicked_on_contact_seller';
export const BQ_CLICKED_PACKAGES_TAB = 'clicked_on_packages_tab';
export const BQ_CLICKED_REVIEWS_SNIPPET_MORE = 'clicked_on_show_more';
export const BQ_OPEN_COUPON_ARTICLE = 'clicked_on_learn_more_about_coupons';
export const BQ_SEEN_BOMBA_COUPON = 'learn_more_about_coupons_bomba_is_shown';
export const BQ_SEEN_PROMOTION_BANNER = 'promotion_banner_is_shown';
export const BQ_SEEN_PROMOTION_MODAL = 'promotion_popup_is_shown';
export const BQ_SELLER_BUYER_SPEAK_SAME_LANGUAGE = 'seller_and_buyer_speak_the_same_language';
export const BQ_CLICKED_GALLERY_VIDEO = 'video_play';
export const BQ_CLICKED_SELLER_LINK = 'clicked_on_seller_name_gig_page';
export const BQ_CLICKED_SELLER_NAME_ANCHOR = 'clicked_on_seller_icon_gig_page';
export const BQ_CLICKED_TOPNAV = 'clicked_on_top_bar_gig_page';
export const BQ_CLICKED_REVIEWS_RATINGS = 'clicked_on_ratings_stars_gig_page';
export const BQ_CLICKED_ON_CONTACT_SELLER_OPTIONS = 'clicked_on_contact_seller_options';
export const BQ_CLICKED_ON_STICKY_CHAT_OPTION = 'clicked_on_sticky_chat_option';
export const BQ_SIGNUP_CLICK = 'signup_click';
export const BQ_NOTABLE_CLIENTS_ABOVE_THE_FOLD_SHOW = 'notable_client_above_the_fold_component_is_shown';
export const BQ_NOTABLE_CLIENTS_BELOW_THE_FOLD_SHOW = 'notable_client_below_the_fold_component_is_shown';
export const BQ_NOTABLE_CLIENT_GIG_ELIGIBLE = 'viewed_gig_notable_clients_eligible';
export const BQ_CLICKED_ON_CONTINUE_IN_WORK_PROCESS_DRAWER = 'click_on_payment_method';
export const BQ_VOICE_OVER_AI_AUDITION_SUCCESS = 'ai_audition_success';
export const BQ_VOICE_OVER_AI_AUDITION_CLICKED_ON_PLAY = 'clicked_on_play_ai_audition';
export const BQ_VOICE_OVER_AI_AUDITION_ERROR_MESSAGE_IS_SHOWN = 'ai_audition_error_message_is_shown';
export const BQ_CLICK_ON_SUBSCRIBE_OPTION = 'clicked_on_subscription_options_in_drawer';
export const BQ_CLICKED_PROMOTE_YOUR_GIG = 'seller_self_view.promoting_banner.promote_gig_clicked';
export const BQ_VIEWED_PROMOTE_YOUR_GIG = 'seller_self_view.promoting_banner.shown';
export const BQ_RETURNING_BUYER_SUB_MODAL_SHOW = 'pop_up_subscription_gig_repeat_buyer_is_shown';
export const BQ_RETURNING_BUYER_SUB_MODAL_CLICK_CTA = 'clicked_on_subscription_gig_repeat_buyer_pop_up';
export const BQ_REQUEST_TO_ORDER_ED_TOOLTIP_IS_SHOWN = 'request_to_order_education_is_shown';
export const BQ_REQUEST_TO_ORDER_BUTTON_IS_SHOWN = 'request_to_order_button_is_shown';
export const BQ_REQUEST_TO_ORDER_BUTTON_CLICKED = 'clicked_on_request_to_order_button';
export const BQ_GIG_TITLE_IMPRESSION = 'gig_title_is_shown';
export const BQ_PRICING_FACTORS_VIEWED = 'pricing_factors_viewed';
export const BQ_CHECKOUT_DRAWER_VIEWED = 'checkout_drawer_viewed';
export const BQ_PAYMENT_DRAWER_IS_SHOWN = 'payment_drawer_is_shown';
export const BQ_CLICKED_ON_CONTINUE_TO_CHECKOUT = 'clicked_on_continue_to_checkout';
export const BQ_CHECKOUT_GIG_ELIGIBLE = 'gig_eligible_for_checkout_drawer_gig_page';
export const BQ_CLICKED_SHOW_MORE_EXTRAS = 'clicked_on_collapse_extras_checkout_drawer';
export const BQ_CLICKED_ON_GIG_REVIEWS_SECTION = 'clicked_on_gig_reviews_section';
export const BQ_CLICKED_ON_SELLER_REVIEWS_SECTION = 'clicked_on_seller_reviews_section';
export const BQ_LOCAL_CATALOG_MACHINE_TRANSLATION_PREVIEW_VIEWED =
    'gig_translation.machine_translation_preview_is_shown';
export const BQ_LOCAL_CATALOG_HUMAN_TRANSLATION_PREVIEW_VIEWED = 'gig_translation.human_translation_preview_is_shown';
export const BQ_LOCAL_CATALOG_GIG_TRANSLATION_LAYER_VIEWED = 'translation_layers_are_shown';
export const BQ_LOCAL_CATALOG_GIG_IN_ORIGINAL_LANGUAGE_CLICKED = 'clicked_on_show_page_in_original_language';
export const BQ_LOCAL_CATALOG_GIG_IN_LOCAL_LANGUAGE_CLICKED = 'clicked_on_translate_page_to_local_language';
export const BQ_PROMOTION_CROSSED_PRICE_IS_SHOWN = 'crossed_price_is_shown';

export const BQ_CLICKED_ON_PAYPAL_CREDIT_LEARN = 'clicked_on_paypal_credit_learn';
export const BQ_PAYPAL_CREDIT_LEARN_IS_SHOWN = 'paypal_credit_learn_is_shown';
export const BQ_VIEWED_CONTACT_ME_BUTTON = 'contact_me_button_shown';
