import { groupBy } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import { sortFeaturesByType } from '../pricingFactors';
import { flattenCalculators } from '../calculator';
import { groupById } from '../collection';
import { buildNestedSubCategoryNameKey, buildSubCategoryNameKey } from '../translation/categories';

/**
 * Sorts services by feature existence. i.e services with features
 * will always precede services without features.
 *
 * @param packageList
 * @param services
 */
export const sortServices = (packageList = [], services = []) => {
    const featureExistenceMap = buildFeatureExistenceMap(packageList);

    return services.sort(({ id }, { id: otherServiceId }) => {
        const serviceHasFeatures = featureExistenceMap[id] ? 1 : 0;
        const otherServiceHasFeatures = featureExistenceMap[otherServiceId] ? 1 : 0;

        return otherServiceHasFeatures - serviceHasFeatures;
    });
};

/**
 * Constructs a mapping between a service and weather
 * or not the service has at least one feature.
 * @param packageList
 */
const buildFeatureExistenceMap = (packageList) => {
    const featureExistenceMap = {};

    packageList.forEach(({ features }) =>
        features.forEach(({ serviceId }) => {
            featureExistenceMap[serviceId] = true;
        })
    );

    return featureExistenceMap;
};

export const enrichServices = (services, packageList) => {
    const sortedServices = sortServices(packageList, services);
    const calculators = flattenCalculators(packageList);
    const calculatorByService = groupBy(calculators, 'serviceId');

    return sortedServices.map((service) => {
        const calculators = calculatorByService[service.id] || [];
        return { ...service, calculators };
    });
};

export const groupFeaturesByService = (services, features) => {
    const groupedFeatures = {};
    const featuresByService = groupBy(features, 'serviceId');

    services.forEach(({ id }) => {
        const features = featuresByService[id] || [];
        groupedFeatures[id] = sortFeaturesByType(features);
    });

    return groupedFeatures;
};

/**
 * Formats studio for studio card
 *
 * @param studio
 */
export const formatStudio = (studio) => {
    const { info = {}, lead = {}, members = [], services = [] } = studio;

    return {
        ...formatInfo(info),
        lead: formatLead(lead, services),
        members: formatMembers(members, services),
    };
};

const formatInfo = ({ profilePhoto, reviewsCount, ...rest }) => ({
    profile_photo: profilePhoto,
    reviews_count: reviewsCount,
    ...rest,
});

const formatLead = (lead, services) => formatMember(lead, services, true);

const formatMembers = (members, services) => members.map((member) => formatMember(member, services, false));

const formatMember = ({ serviceIds, profilePhoto, isPro, ...rest }, services, isLead) => ({
    services: formatMemberServices(serviceIds, services),
    is_lead: isLead,
    is_pro: isPro,
    profile_photo: profilePhoto,
    ...rest,
});

const formatMemberServices = (serviceIds = [], services) => {
    const groupedServices = groupById(services);

    return serviceIds
        .filter((serviceId) => !!groupedServices[serviceId])
        .map((serviceId) => groupedServices[serviceId].name);
};

/**
 * Calculates service name according to it's category hierarchy
 */
export const calculateServiceName = (subCategoryId, nestedSubCategoryId) => {
    const subcategoryName = translate(buildSubCategoryNameKey(subCategoryId));

    if (nestedSubCategoryId) {
        const nestedSubcategoryName = translate(buildNestedSubCategoryNameKey(subCategoryId, nestedSubCategoryId));
        return `${subcategoryName}: ${nestedSubcategoryName}`;
    }

    return subcategoryName;
};
