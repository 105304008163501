import { PAGE_NAME } from '../../../utils/events';

export const gigReviewsSectionIsShownEvent = (gigId, sellerId, userId) => ({
    enrichment: {
        type: 'gig_reviews_section_is_shown',
        group: 'ratings',
        gig: { id: gigId },
        seller: { id: sellerId },
        buyer: { id: userId },
        page: {
            element: {
                name: PAGE_NAME,
            },
        },
    },
});

export const sellerReviewsSectionIsShownEvent = (gigId, sellerId, userId) => ({
    enrichment: {
        type: 'seller_reviews_section_is_shown',
        group: 'ratings',
        gig: { id: gigId },
        seller: { id: sellerId },
        buyer: { id: userId },
        page: {
            element: {
                name: PAGE_NAME,
            },
        },
    },
});

export const reviewsHeaderIsShownEvent = (gigId, sellerId, userId) => ({
    enrichment: {
        type: 'gig_page_reviews_header_is_shown',
        group: 'ratings',
        gig: { id: gigId },
        seller: { id: sellerId },
        buyer: { id: userId },
        page: {
            element: {
                name: PAGE_NAME,
            },
        },
    },
});
