import React, { Component } from 'react';
import { connect } from 'react-redux';
import { object, bool, string } from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { isTranslated } from '../../../../utils/ugc/isTranslated';
import { shouldShowSignUpForm } from '../../../../utils/shouldShowSignUpForm';
import { triggerSignUp } from '../../../../utils/signUp';
import { BQ_SELLER_DETAILS_SEE_MORE } from '../../../../utils/bigQueryEvents';
import TranslatedBadge from '../../../shared/TranslatedBadge';

import './style.scss';

const xss = require('xss');

export const replaceBreakTags = (string, replacer = '\r\n') => string.replace(/\s?(<br\s?\/?>)\s?/g, replacer);

class Description extends Component {
    constructor(props) {
        super(props);

        this.state = {
            descExpanded: false,
        };

        this.onReadMoreClick = this.onReadMoreClick.bind(this);
    }

    async onReadMoreClick() {
        const { descExpanded } = this.state;
        const { biEvents, pathfinderWrapper } = this.context;

        if (!descExpanded) {
            this.reportShowInfoEvent();

            if (shouldShowSignUpForm()) {
                triggerSignUp({
                    biEvents,
                    pathfinderWrapper,
                    options: { source: 'gig_page_description' }
                });
            }
        }

        this.setState({
            descExpanded: !descExpanded,
        });
    }

    reportShowInfoEvent() {
        const { biEvents } = this.context;

        biEvents.sendBigQueryEvent({ eventName: BQ_SELLER_DETAILS_SEE_MORE });
    }

    render() {
        const { descExpanded } = this.state;
        const { showTranslatedBadge, userDescription } = this.props;
        const { isProRebrandingLayout } = this.context;
        let desc = '';

        try {
            desc = xss(replaceBreakTags(userDescription), {
                whiteList: [],
                stripIgnoreTag: true,
                stripIgnoreTagBody: ['script', 'a'],
            });
        } catch (error) {
            // fail silently
        }

        if (!desc.length) {
            return null;
        }

        const showReadMore = desc.length > 120;
        const descClassName = classNames('seller-desc', {
            expanded: descExpanded || !showReadMore,
        });
        const buttonClass = classNames('read-more', { 'new-pro-layout': isProRebrandingLayout });

        return (
            <article className={descClassName}>
                <div className="inner">{desc}</div>
                {showReadMore && (
                    <button className={buttonClass} onClick={this.onReadMoreClick}>
                        <I18n k={`gig_page_perseus.seller_card.read_more_text.${descExpanded ? 'less' : 'more'}`} />
                    </button>
                )}
                {showTranslatedBadge && <TranslatedBadge />}
            </article>
        );
    }
}

Description.propTypes = {
    userDescription: string,
    showTranslatedBadge: bool,
};

Description.contextTypes = {
    biEvents: object,
    pathfinderWrapper: object,
    isProRebrandingLayout: bool,
};

const mapStateToProps = ({ ugc }) => ({
    showTranslatedBadge: isTranslated(ugc),
});

const ConnectedDescription = connect(mapStateToProps)(Description);

export { Description, ConnectedDescription };

export default ConnectedDescription;
