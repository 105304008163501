import { useState, useEffect } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { fetchInitialData } from '@fiverr-private/send_message';

export const useRecentInteractions = () => {
    const { logger } = useGigPageContext();
    const { pathParameters, userId } = getContext();
    const { username: sellerUsername } = pathParameters;

    const [hadRecentInteractions, setHadRecentInteractions] = useState(false);

    useEffect(() => {
        const checkRecentInteractions = async () => {
            try {
                if (userId) {
                    const contactSellerData = await fetchInitialData(sellerUsername);
                    setHadRecentInteractions(!!contactSellerData.priorInteraction);
                }
            } catch (e) {
                if (e instanceof Error) {
                    logger.warn(e.name, {
                        description: 'Error while triggering contact seller popover',
                    });
                }
                setHadRecentInteractions(false);
            }
        };

        checkRecentInteractions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sellerUsername, userId]);

    return { hadRecentInteractions };
};
