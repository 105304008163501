import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { GigPageNavContext, ReportGigButtonWrapperProps } from '../GigPageNavContext/GigPageNavContext';
import { ShareCta } from './ShareCta';
import { ShowMoreCta } from './ShowMoreCta/ShowMoreCta';
import { EditGigCta } from './EditGigCta';

interface ActionCtasProps {
  ReportGigButtonWrapper: React.FC<ReportGigButtonWrapperProps>;
  CollectionCta: React.FC;
}

export const ActionCtas: React.FC<ActionCtasProps> = ({ ReportGigButtonWrapper, CollectionCta }) => {
  const { isPageOwner } = useGigPageContext();

  return (
    <GigPageNavContext.Provider value={{ ReportGigButtonWrapper }}>
      <Stack justifyContent="flexEnd">
        {isPageOwner ? (
          <EditGigCta />
        ) : (
          <>
            <CollectionCta />
            <ShareCta />
            <ShowMoreCta />
          </>
        )}
      </Stack>
    </GigPageNavContext.Provider>
  );
};
