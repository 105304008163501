import React from 'react';
import { Container, Divider, Stack } from '@fiverr-private/layout_components';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import type { SellerProfileProps } from '../SellerProfileProps';
import { MP_CLICKED_SELLER_NAME_ANCHOR } from '../../../../utils/mixPanelEvents';
import { BQ_CLICKED_SELLER_NAME_ANCHOR } from '../../../../utils/bigQueryEvents';
import { ABOUT_SELLER } from '../../../../utils/pageSections';
import { DisplayName } from '../DisplayName';
import { Badges } from '../../Badges/Badges';
import { SellerLevelBadge } from '../../../shared/SellerLevelBadge';
import { AgencyLogo } from '../../../shared/AgencyLogo/AgencyLogo';
import { OverviewRatingResponsive } from './OverviewRatingResponsive';
import { SellerAvatarResponsive } from './SellerAvatarResponsive';
import { OrdersInQueue } from './OrdersInQueue';
import { ProfessionTitle } from './ProfessionTitle';

export function SellerProfileExperiential({ overview, useStickyLayout }: SellerProfileProps) {
  const { seller, biEvents, sectionScroller } = useGigPageContext();
  const { isPro, displayName } = seller;
  const { seller: overviewSeller, gig = {} } = overview;
  const { ordersInQueue, ratingsCount, rating, professionTitle } = gig;
  const { isAgency } = seller;
  const { profilePhoto, name, username } = overviewSeller;
  const avatarShape = isAgency ? 'square' : 'circle';
  const sellerProfileName = username || name;
  const shouldShowRating = ratingsCount > 0 && !!rating;

  const handleSellerNameClick = () => {
    if (!useStickyLayout) {
      return;
    }
    biEvents.sendMixPanelEvent({ eventName: MP_CLICKED_SELLER_NAME_ANCHOR });
    biEvents.sendRawBigQueryEvent({ eventName: BQ_CLICKED_SELLER_NAME_ANCHOR });
    sectionScroller.scroll({ sectionKey: ABOUT_SELLER, forceScroll: false });
  };

  return (
    <Stack gap="4">
      <SellerAvatarResponsive username={username} profilePhoto={profilePhoto} isPro={isPro} shape={avatarShape} />
      <Stack direction="column" gap="1">
        <Stack
          direction={{ default: 'column', lg: 'row' }}
          gap={{ default: '1', lg: '3' }}
          alignItems={{ default: undefined, lg: 'center' }}
        >
          <Stack direction={{ default: 'column', sm: 'row' }} gap="1" alignItems={{ default: undefined, sm: 'center' }}>
            <DisplayName displayName={displayName} username={sellerProfileName} onClick={handleSellerNameClick} />
            <Stack gap="3">
              <Badges />
              <SellerLevelBadge />
            </Stack>
          </Stack>
          {isAgency && <AgencyLogo />}
          {ordersInQueue > 0 && (
            <>
              <Container hidden={{ default: true, lg: false }} height="100%">
                <Divider direction="vertical" />
              </Container>
              <OrdersInQueue count={ordersInQueue} />
            </>
          )}
        </Stack>
        <Stack
          direction={{ default: 'column', sm: 'row' }}
          gap={{ default: '1', sm: '4' }}
          alignItems={{ default: undefined, sm: 'center' }}
        >
          {professionTitle && <ProfessionTitle title={professionTitle} />}
          {shouldShowRating && <OverviewRatingResponsive rating={rating} ratingsCount={ratingsCount} />}
        </Stack>
      </Stack>
    </Stack>
  );
}
