import { updateCustomExtra, updateExtraFast, updateFeature } from '../../../../../actions/packages';
import { CUSTOM_EXTRA_TYPE, EXTRA_FAST_TYPE, FEATURE_TYPE } from '../../../../../utils/packagesUtils/constants';

export const dispatchOnExtraChange =
    ({ type, id, packageId, amount }) =>
    (dispatch) => {
        switch (type) {
            case FEATURE_TYPE:
                dispatch(updateFeature({ featureId: id, amount }));
                break;
            case CUSTOM_EXTRA_TYPE:
                dispatch(updateCustomExtra({ customExtraId: id, amount }));
                break;
            case EXTRA_FAST_TYPE:
                dispatch(updateExtraFast({ value: amount, packageId }));
                break;
            default:
                break;
        }
    };
