import React from 'react';
import { object, string } from 'prop-types';
import { Tooltip } from '@fiverr-private/fit';
import { getBuyerTooltip } from '../../../utils/translation/pricingFactors/buyerTooltip';
import { getLabel } from '../../../utils/translation/pricingFactors/label';

import './style.scss';

const PricingFactorLabel = ({ name }, { general: { subCategoryId } }) => (
    <Tooltip boxContentClassName="gig-page-pricing-factor-tooltip" content={getBuyerTooltip({ name, subCategoryId })}>
        {getLabel({ name, subCategoryId })}
    </Tooltip>
);

PricingFactorLabel.propTypes = {
    name: string.isRequired,
};

PricingFactorLabel.contextTypes = {
    general: object,
};

export default PricingFactorLabel;
