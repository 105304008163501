import React from 'react';
import { Link } from '@fiverr-private/typography';
import { CATEGORIES, SUB_CATEGORIES, NESTED_SUB_CATEGORIES } from '../../types/categories';

const NFT_HELP_CENTRE_ARTICLE_ID = '4411888172177';
const LEGAL_CONSULTING_PRO_HELP_CENTRE_ARTICLE_ID = '12391081658385';
const HELP_CENTER_RESPONSIBLE_CREATION_ARTICLE_URL =
  'https://help.fiverr.com/hc/articles/23062165970321-A-Guide-to-Responsible-Digital-Creation';
const HELP_CENTER_VOICE_SYNTHESIS_AI_GUIDELINES_URL =
  'https://help.fiverr.com/hc/en-us/articles/17240598194321-Voice-Synthesis-AI-Guidelines';

export const getDisclaimers = (pathfinderWrapper) => ({
  [CATEGORIES.GRAPHICS_AND_DESIGN]: {
    title: 'gig_page_perseus.disclaimer.graphics_and_design.title',
    description: 'gig_page_perseus.disclaimer.graphics_and_design.description',
  },
  [CATEGORIES.WRITING_AND_TRANSLATION]: {
    title: 'gig_page_perseus.disclaimer.writing_and_translation.title',
    description: 'gig_page_perseus.disclaimer.writing_and_translation.description',
  },
  [SUB_CATEGORIES.BUY_VIDEO_TESTIMONIALS]: {
    title: 'gig_page_perseus.disclaimer.spokesperson.title',
    description: 'gig_page_perseus.disclaimer.spokesperson.description',
  },
  [SUB_CATEGORIES.UGC]: {
    title: 'gig_page_perseus.disclaimer.ugc.title',
    description: 'gig_page_perseus.disclaimer.ugc.description',
  },
  [SUB_CATEGORIES.SPIRITUAL_AND_HEALING]: {
    title: 'gig_page_perseus.disclaimer.spiritual_healing.title',
    description: 'gig_page_perseus.disclaimer.spiritual_healing.description',
  },
  [SUB_CATEGORIES.ASTROLOGY_AND_PSYCHICS]: {
    title: 'gig_page_perseus.disclaimer.astrology_psychics.title',
    description: 'gig_page_perseus.disclaimer.astrology_psychics.description',
  },
  [NESTED_SUB_CATEGORIES.CALL_CENTER_AND_CALLING]: {
    title: 'gig_page_perseus.disclaimer.call_center_and_calling.title',
    description: 'gig_page_perseus.disclaimer.call_center_and_calling.description',
    i18nTemplates: {
      link: (text) => (
        <a href={pathfinderWrapper.pathfinder('tos')} rel="noreferrer" target="_blank">
          {text}
        </a>
      ),
    },
  },
  [SUB_CATEGORIES.UNBOXING_VIDEOS]: {
    title: 'gig_page_perseus.disclaimer.unboxing_videos.title',
    description: 'gig_page_perseus.disclaimer.unboxing_videos.description',
    i18nTemplates: {
      link: (text) => {
        const path = pathfinderWrapper.pathfinder('community_standards', { slug: 'integrity-authenticity' });
        return (
          <a href={path} rel="noreferrer" target="_blank">
            {text}
          </a>
        );
      },
    },
  },
  [SUB_CATEGORIES.CRYPTOCURRENCY]: {
    title: 'gig_page_perseus.disclaimer.cryptocurrency.title',
    description: 'gig_page_perseus.disclaimer.cryptocurrency.description',
  },
  [SUB_CATEGORIES.ONLINE_TUTORING]: {
    title: 'gig_page_perseus.disclaimer.online_tutoring.title',
    description: 'gig_page_perseus.disclaimer.online_tutoring.description',
    i18nTemplates: {
      link: (text) => {
        const path = pathfinderWrapper.pathfinder('community_standards', { slug: 'prohibited-services' });
        return (
          <a href={path} rel="noreferrer" target="_blank">
            {text}
          </a>
        );
      },
    },
  },
  [SUB_CATEGORIES.RESEARCH_AND_SUMMARIES]: {
    title: 'gig_page_perseus.disclaimer.research_and_summaries.title',
    description: 'gig_page_perseus.disclaimer.research_and_summaries.description',
    i18nTemplates: {
      link: (text) => {
        const path = pathfinderWrapper.pathfinder('community_standards', { slug: 'prohibited-services' });
        return (
          <a href={path} rel="noreferrer" target="_blank">
            {text}
          </a>
        );
      },
    },
  },
  [NESTED_SUB_CATEGORIES.SEO_FULL_SEO_PACKAGE]: {
    title: 'gig_page_perseus.disclaimer.seo_subcategories.title',
    description: 'gig_page_perseus.disclaimer.seo_subcategories.description',
  },
  [NESTED_SUB_CATEGORIES.SEO_OFF_PAGE_SEO]: {
    title: 'gig_page_perseus.disclaimer.seo_subcategories.title',
    description: 'gig_page_perseus.disclaimer.seo_subcategories.description',
  },
  [NESTED_SUB_CATEGORIES.SEO_ON_PAGE_SEO]: {
    title: 'gig_page_perseus.disclaimer.seo_subcategories.title',
    description: 'gig_page_perseus.disclaimer.seo_subcategories.description',
  },
  [NESTED_SUB_CATEGORIES.SEO_TECHNICAL_SEO]: {
    title: 'gig_page_perseus.disclaimer.seo_subcategories.title',
    description: 'gig_page_perseus.disclaimer.seo_subcategories.description',
  },
  [NESTED_SUB_CATEGORIES.SEO_VOICE_SEARCH_SEO]: {
    title: 'gig_page_perseus.disclaimer.seo_subcategories.title',
    description: 'gig_page_perseus.disclaimer.seo_subcategories.description',
  },
  [SUB_CATEGORIES.NFT_ART]: {
    title: 'gig_page_perseus.disclaimer.nft_art.title',
    description: 'gig_page_perseus.disclaimer.nft_art.description',
    i18nTemplates: {
      link: (text) => {
        const path = pathfinderWrapper.pathfinder('help_center_article', { article_id: NFT_HELP_CENTRE_ARTICLE_ID });
        return (
          <a href={path} rel="noreferrer" target="_blank">
            {text}
          </a>
        );
      },
    },
  },
  [SUB_CATEGORIES.NFT_ANIMATION]: {
    title: 'gig_page_perseus.disclaimer.nft_art.title',
    description: 'gig_page_perseus.disclaimer.nft_art.description',
    i18nTemplates: {
      link: (text) => {
        const path = pathfinderWrapper.pathfinder('help_center_article', { article_id: NFT_HELP_CENTRE_ARTICLE_ID });
        return (
          <a href={path} rel="noreferrer" target="_blank">
            {text}
          </a>
        );
      },
    },
  },
  [SUB_CATEGORIES.NFT_DEVELOPMENT]: {
    title: 'gig_page_perseus.disclaimer.nft_development.title',
    description: 'gig_page_perseus.disclaimer.nft_development.description',
    i18nTemplates: {
      link: (text) => {
        const path = pathfinderWrapper.pathfinder('help_center_article', { article_id: NFT_HELP_CENTRE_ARTICLE_ID });
        return (
          <a href={path} rel="noreferrer" target="_blank">
            {text}
          </a>
        );
      },
    },
  },
  [SUB_CATEGORIES.FINANCIAL_CONSULTING]: {
    title: 'gig_page_perseus.disclaimer.financial_consulting.title',
    description: 'gig_page_perseus.disclaimer.financial_consulting.description',
  },
  [SUB_CATEGORIES.LEGAL_CONSULTING]: {
    title: 'gig_page_perseus.disclaimer.legal_consulting_with_link.title',
    description: 'gig_page_perseus.disclaimer.legal_consulting_with_link.description',
    i18nTemplates: {
      link: (text) => {
        const path = pathfinderWrapper.pathfinder('help_center_article', {
          article_id: LEGAL_CONSULTING_PRO_HELP_CENTRE_ARTICLE_ID,
        });
        return (
          <a href={path} rel="noreferrer" target="_blank">
            {text}
          </a>
        );
      },
    },
  },
  [NESTED_SUB_CATEGORIES.AI_VOICE_SERVICE]: {
    title: 'gig_page_perseus.disclaimer.ai_voice_service.title',
    description: 'gig_page_perseus.disclaimer.ai_voice_service.description',
  },
  [SUB_CATEGORIES.ONLINE_INVESTIGATIONS]: {
    title: 'gig_page_perseus.disclaimer.online_investigations.title',
    description: 'gig_page_perseus.disclaimer.online_investigations.description',
  },
  [SUB_CATEGORIES.WEBSITE_DEVELOPMENT]: {
    title: 'gig_page_perseus.disclaimer.website_third_party.title',
    description: 'gig_page_perseus.disclaimer.website_third_party.description',
    i18nTemplates: {
      link: (text) => (
        <Link href={HELP_CENTER_RESPONSIBLE_CREATION_ARTICLE_URL} rel="noreferrer" target="_blank">
          {text}
        </Link>
      ),
    },
  },
  [SUB_CATEGORIES.WEBSITE_MAINTENANCE]: {
    title: 'gig_page_perseus.disclaimer.website_third_party.title',
    description: 'gig_page_perseus.disclaimer.website_third_party.description',
    i18nTemplates: {
      link: (text) => (
        <Link href={HELP_CENTER_RESPONSIBLE_CREATION_ARTICLE_URL} rel="noreferrer" target="_blank">
          {text}
        </Link>
      ),
    },
  },
  [SUB_CATEGORIES.WEB_MOBILE_DESIGN]: {
    title: 'gig_page_perseus.disclaimer.website_third_party.title',
    description: 'gig_page_perseus.disclaimer.website_third_party.description',
    i18nTemplates: {
      link: (text) => (
        <Link href={HELP_CENTER_RESPONSIBLE_CREATION_ARTICLE_URL} rel="noreferrer" target="_blank">
          {text}
        </Link>
      ),
    },
  },
  [SUB_CATEGORIES.AI_AVATAR_DESIGN]: {
    title: 'gig_page_perseus.disclaimer.ai_avatar_design.title',
    description: 'gig_page_perseus.disclaimer.ai_avatar_design.description',
    i18nTemplates: {
      link: (text) => (
        <Link href={HELP_CENTER_VOICE_SYNTHESIS_AI_GUIDELINES_URL} rel="noreferrer" target="_blank">
          {text}
        </Link>
      ),
    },
  },
  [NESTED_SUB_CATEGORIES.VTUBER_MODELS]: {
    title: 'gig_page_perseus.disclaimer.voice_ownership_rights.title',
    description: 'gig_page_perseus.disclaimer.voice_ownership_rights.description',
    i18nTemplates: {
      link: (text) => (
        <Link href={HELP_CENTER_VOICE_SYNTHESIS_AI_GUIDELINES_URL} rel="noreferrer" target="_blank">
          {text}
        </Link>
      ),
    },
  },
  [NESTED_SUB_CATEGORIES.VR_AND_METAVERSE_AVATARS]: {
    title: 'gig_page_perseus.disclaimer.voice_ownership_rights.title',
    description: 'gig_page_perseus.disclaimer.voice_ownership_rights.description',
    i18nTemplates: {
      link: (text) => (
        <Link href={HELP_CENTER_VOICE_SYNTHESIS_AI_GUIDELINES_URL} rel="noreferrer" target="_blank">
          {text}
        </Link>
      ),
    },
  },
  [NESTED_SUB_CATEGORIES.AI_AVATARS]: {
    title: 'gig_page_perseus.disclaimer.voice_ownership_rights.title',
    description: 'gig_page_perseus.disclaimer.voice_ownership_rights.description',
    i18nTemplates: {
      link: (text) => (
        <Link href={HELP_CENTER_VOICE_SYNTHESIS_AI_GUIDELINES_URL} rel="noreferrer" target="_blank">
          {text}
        </Link>
      ),
    },
  },
  [SUB_CATEGORIES.AI_IMAGE_EDITING]: {
    title: 'gig_page_perseus.disclaimer.ai_image_editing.title',
    description: 'gig_page_perseus.disclaimer.ai_image_editing.description',
    i18nTemplates: {
      link: (text) => (
        <Link href={HELP_CENTER_VOICE_SYNTHESIS_AI_GUIDELINES_URL} rel="noreferrer" target="_blank">
          {text}
        </Link>
      ),
    },
  },
  [NESTED_SUB_CATEGORIES.BLOCKCHAIN_SECURITY_AUDITING]: {
    title: 'gig_page_perseus.disclaimer.cryptocurrency.title',
    description: 'gig_page_perseus.disclaimer.cryptocurrency.description',
  },
});
