import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { SellerAvatar, type SellerAvatarProps } from './SellerAvatar';

type SellerAvatarResponsiveProps = Omit<SellerAvatarProps, 'size'>;
export function SellerAvatarResponsive({ ...props }: SellerAvatarResponsiveProps) {
  return (
    <>
      <Container hidden={{ md: true }}>
        <SellerAvatar size="md" {...props} />
      </Container>
      <Container hidden={{ default: true, md: false }}>
        <SellerAvatar size="lg" {...props} />
      </Container>
    </>
  );
}
