import { isEmpty } from 'lodash';

const DEFAULT_MINIMUM_DURATION_PACKAGE_PRICE = 0;
const DISABLED_STATUS = 'DISABLED';

export const calcMinimumDurationPackage = (consultationPackages: any) => {
    if (isEmpty(consultationPackages)) {
        return DEFAULT_MINIMUM_DURATION_PACKAGE_PRICE;
    }

    const activeConsultationPackages = consultationPackages.filter(
        (consultationPackage) => consultationPackage.status !== DISABLED_STATUS
    );

    const minimumPackagePricing = activeConsultationPackages.reduce(
        (minimalDurationPackage, currentDurationPackage) =>
            currentDurationPackage.session_minutes < minimalDurationPackage.session_minutes
                ? currentDurationPackage
                : minimalDurationPackage,
        activeConsultationPackages[0]
    );

    return (minimumPackagePricing && minimumPackagePricing.price) || DEFAULT_MINIMUM_DURATION_PACKAGE_PRICE;
};
