import React from 'react';
import { bool, object, string } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { Reviews } from '@fiverr-private/reviews';
import { inExperiment } from '../../../utils/experiments';
import { LIONS_LOCAL_REVIEWS_NEW } from '../../../utils/experiments/experimentsEnum';
import { ROLLOUTS } from '../../../types/rollouts';
import { buildGigReviewsConfiguration } from '../utils';
import { REVIEWS_TYPES } from '../constants';

const GigReviewsStandalone = (props, { rollouts = {} }) => {
    const { pageCtxId, isMobile, userId, abTests } = getContext();
    const config = buildGigReviewsConfiguration({
        pageCtxId,
        userId,
        gigId: props.reviews?.gig_id,
        isMobile,
        showLocalReview: rollouts[ROLLOUTS.LIONS_LOCAL_REVIEWS_NEW] && inExperiment(LIONS_LOCAL_REVIEWS_NEW, abTests),
    });
    const initialState = { ...props.reviews, ...config, event_source: REVIEWS_TYPES.ONLY_GIG_REVIEWS };

    return <Reviews initialState={initialState} {...props} />;
};

GigReviewsStandalone.propTypes = {
    reviews: object.isRequired,
    enableSearchFilter: bool,
    translationUrl: string,
    isPro: bool,
};

GigReviewsStandalone.contextTypes = {
    rollouts: object,
};

export default GigReviewsStandalone;
