import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import GigCardCarouselWrapper from '../shared/GigCardCarouselWrapper';
import { PromotedGigData } from '../../server/middlewares/addInitialProps/promoted/promotedGigs/types';
import { PROMOTED_MIXED_GIGS } from '../shared/GigCardCarouselHOC';
import ErrorBoundary from '../shared/SafePureComponent/ErrorBoundary';
import { SLIDER_CONFIG } from './config';
import { CarouselHeader } from './Header/CarouselHeader';
import { GIG_CARD_IMPRESSION_NAME, PROMOTED_GIGS_SOURCE } from './constants';

const NONE_PARAM = 'NONE';

interface PromotedCarouselProps {
  isTouch?: boolean;
  isLoggedIn?: boolean;
  promotedGigs: PromotedGigData[];
}

export const PromotedCarousel: React.FC<PromotedCarouselProps> = ({
  isTouch = false,
  isLoggedIn = false,
  promotedGigs,
}) => {
  const { pathfinderWrapper } = useGigPageContext();

  if (!promotedGigs || !promotedGigs.length) {
    return null;
  }

  const { sliderBaseNum, sliderBreakpoints, skeletonBreakpoints } = SLIDER_CONFIG;

  const gigIds = promotedGigs.map((gig) => gig.gigId);
  const sellerIds = promotedGigs.map((gig) => gig.sellerId);
  const promotedGigIds = promotedGigs.filter((gig) => !!gig.auctionId).map((gig) => gig.gigId);
  const auctionGig = promotedGigs.find((gig) => gig.auctionId !== undefined);
  let auctionId = NONE_PARAM;
  if (auctionGig && auctionGig.auctionId !== undefined) {
    auctionId = auctionGig.auctionId;
  }

  const gigIdsStr = gigIds.join(',');
  const sellerStr = sellerIds.join(',');
  const promotedIdsStr = promotedGigIds.join('%2C');

  const ajaxUrl = pathfinderWrapper.pathfinder(
    'gig_page_fetch_recommendations_api',
    {
      gig_ids: gigIdsStr || NONE_PARAM,
      seller_ids: sellerStr || NONE_PARAM,
      auction_id: auctionId,
      source: PROMOTED_GIGS_SOURCE,
    },
    promotedGigIds.length > 0 ? { query: { promoted_gig_ids: promotedIdsStr } } : {}
  );

  return (
    <ErrorBoundary>
      <Container
        padding="14"
        paddingTop="6"
        paddingBottom="4"
        marginX="auto"
        marginY="0"
        maxWidth="1336px"
        data-testid="container"
      >
        <GigCardCarouselWrapper
          sliderBaseNum={sliderBaseNum}
          sliderBreakpoints={sliderBreakpoints}
          skeletonBreakpoints={skeletonBreakpoints}
          type={PROMOTED_MIXED_GIGS}
          carouselPosition={0}
          url={ajaxUrl}
          isLoggedIn={isLoggedIn}
          isTouch={isTouch}
          source={PROMOTED_GIGS_SOURCE}
          viewportType={GIG_CARD_IMPRESSION_NAME}
        >
          <CarouselHeader promotedAuctionId={auctionGig?.auctionId} />
        </GigCardCarouselWrapper>
      </Container>
    </ErrorBoundary>
  );
};
