import { string, shape, number } from 'prop-types';
import { PAGE_NAME } from '../../utils/bigQueryEventsData';

/**
 * @property MACHINE_TRANSLATION_PREVIEW - preview status that shows banner
 * with languages selector and entry point to start translating for chosen language
 * @property BEFORE_PUBLISH - preview status that shows banner that indicates
 * translation layer is created and user can review translations on own gig page
 * @property READY_TRANSLATION_LAYER - preview status that shows banner that indicates
 * translation layer is ready and user can review active translations on own gig page
 */
export const LOCAL_CATALOG_PREVIEW_STATES = {
    MACHINE_TRANSLATION_PREVIEW: 'mt_preview',
    BEFORE_PUBLISH: 'before_publish',
    READY_TRANSLATION_LAYER: 'ready_tl',
};

export const SELLER_COUPON_TYPES = {
    COUPON: 'seller-coupons',
    PROMOTION: 'seller-promotions',
};

export const PROMOTIONS_VIEWED_LOCAL_STORAGE_KEY = 'promotions-viewed-4260';

export const NON_EXP_BQ_PACKAGE_TABLE_SHOW_PARAMS = {
    type: 'gig_compare_packages_is_shown',
    entity: 'client',
    group: 'user_impression',
    action: { type: 'impression' },
    page: {
        name: PAGE_NAME,
        component: {
            name: 'gig_compare_packages',
        },
    },
};

export const LoyaltyIndicationPropType = shape({
    tier: string.isRequired,
    amountOver: number,
    creditBonusPercents: number,
    orderEntitledForCredit: string.isRequired,
});
