import React, { Component } from 'react';
import { bool, string, number, array, object } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Icon } from '@fiverr-private/fit';
import { getContext } from '@fiverr-private/fiverr_context';
import { s_clock, s_refresh } from '@fiverr-private/fit/icons';
import { CURRENCY_CODES } from '@fiverr-private/localization';
import { theme } from '@fiverr-private/theme';
import RecommendedBadge from '../RecommendedBadge';
import { formatPricingFactorValue } from '../../../utils/packagesUtils';
import { getPackageById, getSingleCalculator, isRecurringGig, isTriplePackage } from '../../../reducers/packages';
import { isGigWithActiveWorkProcess } from '../../../reducers/workProcess';
import { shouldShowFeature } from '../../../utils/pricingFactors';
import { isValidCalculator } from '../../../utils/calculator';
import { getPromotionDiscountedPrice } from '../../../utils/getPromotionDiscountedPrice/getPromotionDiscountedPrice';
import { UNLIMITED_REVISION } from '../../../utils/packagesUtils/constants';
import { CrossedPrice } from './PromotionCrossedPrice/CrossedPrice';
import FeatureList from './FeatureList';
import PackageContentCalculator from './PackageContentCalculator';
import Services from './Services';
import CollapsablePackageItem from './CollapsablePackageItem';
import HeaderDefault from './HeaderDefault';
import HeaderRecurring from './HeaderRecurring';

import './style.scss';

export const COLLAPSE_FEATURES_WITH_CALC = 5;
export const COLLAPSE_FEATURES_WITHOUT_CALC = 7;

class PackageContent extends Component {
    constructor() {
        super();

        this.i18nPackages = 'gig_page_perseus.packages';
    }

    formatRevisions() {
        const { revisions } = this.props;
        const numOfRevisions = formatPricingFactorValue(revisions.value);
        const revisionKey = numOfRevisions === 1 ? 'revision' : 'revisions';

        return { key: `${this.i18nPackages}.${revisionKey}`, amount: numOfRevisions };
    }

    formatDeliveryDays() {
        const { currentDuration } = this.props;
        const dayStr = currentDuration === 1 ? 'day' : 'days';

        return <I18n k={`${this.i18nPackages}.delivery_${dayStr}`} params={{ amount: currentDuration }} />;
    }

    filterFeatures() {
        const { features, displayNotIncluded } = this.props;

        return features.filter(({ included }) => shouldShowFeature(included, displayNotIncluded));
    }

    shouldShowRevisions() {
        const { revisions } = this.props;

        return revisions && revisions.value !== 0;
    }

    shouldShowHeader() {
        const { showPrice, title, description } = this.props;

        return showPrice || title || description;
    }

    getHeaderComponent() {
        const { isRecurringGig } = this.props;

        if (!this.shouldShowHeader()) {
            return null;
        }
        return isRecurringGig ? HeaderRecurring : HeaderDefault;
    }

    shouldRenderCollapsableItem(filteredFeatures) {
        const { forceNonCollapsable } = this.props;

        return !forceNonCollapsable && this.isEligibleCollapsableList(filteredFeatures);
    }

    isEligibleCollapsableList(filteredFeatures) {
        const { calculator } = this.props;
        const validCalculator = isValidCalculator(calculator);
        const shouldCollapseWithCalc = validCalculator && filteredFeatures.length > COLLAPSE_FEATURES_WITH_CALC;
        const shouldCollapseWithNoCalc = !validCalculator && filteredFeatures.length > COLLAPSE_FEATURES_WITHOUT_CALC;

        return shouldCollapseWithCalc || shouldCollapseWithNoCalc;
    }

    renderRevisions() {
        const shouldShowRevisions = this.shouldShowRevisions();

        if (!shouldShowRevisions) {
            return null;
        }

        const { key: revisionsKey, amount: revisionsAmount } = this.formatRevisions();
        const unlimitedKey = 'gig_page_perseus.packages.unlimited_revisions';

        return (
            <div className="revisions-wrapper">
                <Icon className="revisions-icon">{s_refresh}</Icon>
                <b className="revisions">
                    {revisionsAmount === UNLIMITED_REVISION ? (
                        <I18n k={unlimitedKey} />
                    ) : (
                        <I18n k={revisionsKey} params={{ amount: revisionsAmount }} />
                    )}
                </b>
            </div>
        );
    }

    render() {
        const {
            title,
            description,
            calculator,
            id,
            currentPrice,
            showPrice,
            recommendedType,
            tabItem,
            className,
            isRecurringGig,
            isMilestoneGig,
        } = this.props;
        const { currencyConverter, general, coupon } = this.context;
        const { isStudio } = general;
        const { abTests, currency } = getContext();

        const roundUpPrice = currency !== CURRENCY_CODES.USD;

        const currentPriceWithPromotionDiscount = isMilestoneGig
            ? null
            : getPromotionDiscountedPrice(currentPrice, coupon, abTests);
        const formattedPrice = currencyConverter.convertWithFee(currentPrice);
        const formattedPriceWithPromotionDiscount = currentPriceWithPromotionDiscount
            ? currencyConverter.convert(currentPriceWithPromotionDiscount)
            : null;
        const filteredFeatures = this.filterFeatures();

        /*
         * TODO: Refactor the header-related logic to encapsulate the calculations around the price
         * and the promotion discount in a separate component.
         */
        const HeaderComponent = this.getHeaderComponent();
        const headerProps = {
            title,
            description,
            formattedPrice: formattedPriceWithPromotionDiscount || formattedPrice,
            showPrice,
            showPriceTooltip: roundUpPrice,
            discountedPriceSection: formattedPriceWithPromotionDiscount && (
                <CrossedPrice
                    fontSize={isRecurringGig ? theme.bodySizes.b_lg : theme.bodySizes.b_md}
                    discountPercentage={coupon.discountValue}
                    price={formattedPrice}
                />
            ),
        };

        const wrapperClassName = classNames('package-content', className);

        return (
            <div className={wrapperClassName}>
                {recommendedType && <RecommendedBadge recommendedType={recommendedType} />}
                {this.shouldShowHeader() && <HeaderComponent {...headerProps} />}
                <article>
                    <div className="additional-info">
                        <div className="delivery-wrapper">
                            <Icon className="delivery-icon">{s_clock}</Icon>
                            <b className="delivery">{this.formatDeliveryDays()}</b>
                        </div>
                        {this.renderRevisions()}
                    </div>
                    {!isStudio && (
                        <>
                            {this.shouldRenderCollapsableItem(filteredFeatures) ? (
                                <CollapsablePackageItem
                                    {...tabItem}
                                    features={filteredFeatures}
                                    calculator={calculator}
                                    packageId={id}
                                />
                            ) : (
                                <>
                                    <FeatureList features={filteredFeatures} />
                                    <PackageContentCalculator calculator={calculator} packageId={id} />
                                </>
                            )}
                        </>
                    )}
                    {isStudio && <Services features={filteredFeatures} packageId={id} />}
                </article>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { packages, packagesTabs } = state;
    const { tabItems } = packagesTabs;
    const displayNotIncluded = isTriplePackage(packages);
    const packageData = getPackageById(packages, ownProps.id);
    const calculator = getSingleCalculator(packages, ownProps.id);

    return {
        ...packageData,
        features: ownProps.features,

        // todo: remove when VO package has a title
        title: packageData.title || ownProps.title,
        displayNotIncluded,
        showPrice: ownProps.showPrice,
        calculator,
        tabItem: tabItems.length ? tabItems[0] : {},
        isRecurringGig: isRecurringGig(packages),
        isMilestoneGig: isGigWithActiveWorkProcess(state),
    };
};

PackageContent.propTypes = {
    title: string,
    description: string,
    features: array,
    calculator: object,
    id: number,
    currentPrice: number,
    currentDuration: number,
    revisions: object,
    showPrice: bool,
    recommendedType: string,
    displayNotIncluded: bool,
    tabItem: object,
    forceNonCollapsable: bool,
    isRecurringGig: bool,
    isSinglePackage: bool,
    isMilestoneGig: bool,
    className: string,
};

PackageContent.defaultProps = {
    features: [],
    showPrice: true,
    displayNotIncluded: true,
    forceNonCollapsable: false,
    isRecurringGig: false,
};

PackageContent.contextTypes = {
    currencyConverter: object,
    general: object,
    coupon: object,
};

export { PackageContent };
export default connect(mapStateToProps)(PackageContent);
