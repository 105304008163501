export const MP_SHOW_PAGE_NAME = 'Gig';
export const MP_CLICKED_NAVBAR = 'Clicked navbar';
export const MP_CLICKED_PACKAGE_SELECT = 'Clicked package select';
export const MP_CLICKED_TAB = 'Gig page tab clicked';
export const MP_CLICKED_COMPARE_PACKAGES = 'Clicked compare packages';
export const MP_CLICKED_SELLER_NAME_ANCHOR = 'Clicked seller name anchor';
export const MP_CLICKED_CONTACT_ME = 'Clicked contact me';
export const MP_CLICKED_GALLERY_FULL_SCREEN = 'Clicked gallery full screen';
export const MP_VIDEO_THUMBNAIL_SIZE = 'gig page - low quality image';
export const MP_SHARE_GIG = 'Gig page share clicked';
export const MP_DISMISS_BOMBA_COUPON = 'Gig Page - Dismiss Coupon bomba';
export const MP_SEEN_BOMBA_COUPON = 'Gig Page - Seen Coupon bomba';
export const MP_SEEN_PROMOTION_MODAL = 'Gig Page - Seen Promotion modal';
export const MP_OPEN_COUPON_ARTICLE = 'Gig Page - Clicked Coupon article link';
export const MP_CLICKED_NEXT_SLIDE = 'Gig gallery clicked next';
export const MP_CLICKED_PREVIOUS_SLIDE = 'Gig gallery previous next';
export const MP_VIEWED_GALLERY_REVIEW = 'Gig gallery viewed review';
export const MP_CLICKED_GALLERY_THUMBNAIL = 'Gig gallery clicked thumbnail';
export const MP_VIEWED_RECURRING_TITLE_EDUCATION_TOOLTIP = 'Gig page - Subscription - Show tooltip';
export const MP_CHANGED_RECURRING_OPTION = 'Gig page - Subscription - Change plan length';
export const MP_CHANGED_CHECKOUT_PLAN = 'Gig page - Subscription - Change checkout plan';
export const MP_LIMITED_EDITION_APPLY = 'limited_edition_apply_click';
export const MP_LIMITED_EDITION_MODAL_OPENED = 'limited_edition_form_show';
export const MP_LIMITED_EDITION_SUBMIT = 'limited_edition_form_submit';
export const MP_VIEWED_LOYALTY_SELLER_BANNER = 'Viewed Loyalty Seller Banner';
export const MP_VIEWED_NOTABLE_CLIENTS = 'Gig page - notable clients viewed';
export const MP_CONTACT_SELLER_OPTIONS_CLICK = 'gig page - contact seller options - click';
export const MP_CLICKED_ON_STICKY_CHAT_OPTION = 'clicked on sticky chat option';
export const MP_CONTACT_SELLER_OPTIONS_SEND_MESSAGE_SUBMIT_SUCCESS =
    'gig page - contact seller options - send message - submit';
export const MP_CONTACT_SELLER_OPTIONS_CUSTOM_ORDER_CLICK = 'gig page - contact seller options - get a quote - click';
export const MP_CONTACT_SELLER_OPTIONS_CUSTOM_ORDER_SUBMIT = 'gig page - contact seller options - get a quote - submit';
export const MP_CONTACT_SELLER_OPTIONS_CUSTOM_ORDER_CLOSE = 'gig page - contact seller options - get a quote - close';
export const MP_BUYER_EXPANDED_WORKFLOW_STEP_LIST = 'Buyer expand workflow step list';
export const MP_CLICKED_ON_MILESTONES_CHEVRON = 'Gig Page - Milestones - chevron clicked';
export const MP_DRAWER_OPEN = 'Gig Page - Drawer - open';
export const MP_DRAWER_CLOSE = 'Gig Page - Drawer - close';
export const MP_DRAWER_CHANGE_PAYMENT_PLAN = 'Gig page - Drawer - change payment plan';
export const MP_DRAWER_CONTINUE_TO_CHECKOUT = 'Gig page - Drawer - continue to check out';
export const MP_DRAWER_CHANGE_SUBSCRIPTION_LENGTH = 'Gig page - Drawer - Change subscription length';
export const MP_DRAWER_CLICKED_ADD_EXTRA = 'Gig page - Drawer - Clicked add extra';
export const MP_DRAWER_CHANGED_EXTRA_QUANTITY = 'Gig page - Drawer - Changed extra quantity';
export const MP_DRAWER_CHANGED_GIG_QUANTITY = 'Gig page - Drawer - Changed gig quantity';
export const MP_REQUEST_TO_ORDER_BUTTON_IS_SHOWN = 'Gig page - Seller Plus - Request to Order button viewed';
export const MP_REQUEST_TO_ORDER_BUTTON_CLICKED = 'Gig page - Seller Plus - Request to Order button clicked';
export const MP_REQUEST_TO_ORDER_MESSAGE_SENT = 'Gig page - Seller Plus - Request to Order message sent';
export const MP_COMPARE_PACKAGES_VIEW = 'Gig page - compare packages viewed';
