import { FIVERR_DOMAIN } from '../../../common/uri/constants';

export const COOKIE_DELIMITER = ';';

export const LAST_VIEWED_GIG_COOKIE = 'last_viewed_gig';
export const LAST_CONTENT_PAGES_COOKIE = 'last_content_pages_';

export const DEFAULT_OPTIONS = {
    domain: FIVERR_DOMAIN,
    path: '/',
};
