import { metric } from '../metric';
import { maxios } from '../maxios';

export const getGigs = async ({ url, type }) => {
    try {
        const { data } = await maxios.get(url, { routeKey: 'fetch_gigs_data' });
        const { gigs } = data;
        if (gigs.length) {
            return gigs;
        } else {
            throw new Error('Gigs carousel received and empty list');
        }
    } catch (error) {
        const status = (error.response && error.response.status) || 'unknown';
        metric.count(`bad_response.carousels.${status}.${type}`);
        throw error;
    }
};
