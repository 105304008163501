import { useContext } from 'react';
import { GigPageContext } from '../components';

export const useGigPageContext = () => {
    const context = useContext(GigPageContext);

    if (context === undefined) {
        throw new Error('useGigPageContext must be used within a GigPageContextProvider');
    }

    return context;
};
