import { SLIDES_TYPE, SUPPORTED_TYPES } from '../../constants';
import { MappedAttachmentTypes } from '../../sellerTranslationTypes';

export const mapAttachmentTypes = (type: SUPPORTED_TYPES): MappedAttachmentTypes => {
    const typeImage = SUPPORTED_TYPES.IMAGE === type;
    const typeVideo = SUPPORTED_TYPES.VIDEO === type;

    return {
        itemScopeType: SLIDES_TYPE[type],
        typeImage,
        typeVideo,
    };
};
