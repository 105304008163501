import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Container } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ProSubCategory } from '../../../../types/seller';
import { ExpertList } from '../../../ExpertList/ExpertList';

import { containerClass } from './NewLayoutSubcategoriesList.ve.css';

interface NewLayoutSubcategoriesListProps {
  proSubcategories: ProSubCategory[];
}

export const NewLayoutSubcategoriesList: React.FC<NewLayoutSubcategoriesListProps> = ({ proSubcategories = [] }) => {
  const {
    seller: { displayName, username },
  } = useGigPageContext();

  const selectedName = displayName || username;

  return (
    <Container className={containerClass} borderWidth="sm" padding="6">
      <Text marginBottom="6">
        {translate('gig_page_perseus.seller_card.pro_subcategories.description', {
          params: {
            displayName: selectedName,
          },
        })}
      </Text>
      <ExpertList proSubcategories={proSubcategories} />
    </Container>
  );
};
