import { Attributes } from '../attributes';

export const CATEGORY = 'category';
export const SUB_CATEGORY = 'sub_category';
export const NESTED_SUB_CATEGORY = 'nested_sub_cat';
export const CATEGORIES_PREFIX = 'categories';
export const SUB_CATEGORIES_PREFIX = 'sub_categories';

export const buildCategoryNameKey = (categoryId) => `${CATEGORIES_PREFIX}.${CATEGORY}_${categoryId}.${Attributes.name}`;

export const buildNestedSubCategoryNameKey = (subCategoryId, nestedSubCategoryId) =>
    `${buildNestedSubCategoryKey(subCategoryId, nestedSubCategoryId)}.${Attributes.name}`;

export const buildSubCategoryNameKey = (subCategoryId) => `${buildSubCategoryKey(subCategoryId)}.${Attributes.name}`;

const buildNestedSubCategoryKey = (subCategoryId, nestedSubCategoryId) =>
    `${buildSubCategoryKey(subCategoryId)}.${NESTED_SUB_CATEGORY}_${nestedSubCategoryId}`;

const buildSubCategoryKey = (subCategoryId) => `${SUB_CATEGORIES_PREFIX}.${SUB_CATEGORY}_${subCategoryId}`;
