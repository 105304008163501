import React, { PureComponent } from 'react';
import ErrorBoundary from './ErrorBoundary';

const SafePureComponentWrapper = (WrappedComponent) => {
    // eslint-disable-next-line react/prefer-stateless-function
    class SafePureComponent extends PureComponent {
        render() {
            return (
                <ErrorBoundary>
                    <WrappedComponent {...this.props} />
                </ErrorBoundary>
            );
        }
    }

    return SafePureComponent;
};

export default SafePureComponentWrapper;
