import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import SafePureComponentWrapper from '../shared/SafePureComponent';

import './style.scss';

const ProCertificateBanner = (props, { pathfinderWrapper }) => {
    const i18nProCertificateBanner = 'gig_page_perseus.pro_certificate_banner';

    return (
        <div className="pro-certificate-banner">
            <h4>
                <I18n k={`${i18nProCertificateBanner}.title`} />
            </h4>
            <div className="body">
                <p>
                    <I18n k={`${i18nProCertificateBanner}.body1`} />
                </p>
                <p>
                    <I18n k={`${i18nProCertificateBanner}.body2`} />
                </p>
            </div>
            {/* eslint-disable react/jsx-no-target-blank */}
            <a href={pathfinderWrapper.pathfinder('pro_landing_page')} target="_blank">
                <I18n k={`${i18nProCertificateBanner}.cta`} />
            </a>
        </div>
    );
};

ProCertificateBanner.contextTypes = {
    pathfinderWrapper: PropTypes.object,
};

export { ProCertificateBanner };

export default SafePureComponentWrapper(ProCertificateBanner);
