import { capitalize, isNil, pick } from 'lodash';
import { RequestContext } from '@fiverr-private/perseus';
import {
    extractPackagesContent,
    extractStudioContent,
    extractWorkflowContent,
    extractWorkProcessContent,
    getTitle,
} from '../../../../utils/ugc/shared';
import { calcAgencyData } from '../../../../utils/calcAgenciesData/calcAgenciesData';
import { MAX_SEO_DESCRIPTION_LENGTH } from './constants';

const extractDescription = ({ machineTranslatedDescription, sellerTranslatedDescription }) => {
    if (!isNil(sellerTranslatedDescription)) {
        return removeRedundantHtml(sellerTranslatedDescription);
    }

    if (!isNil(machineTranslatedDescription)) {
        return removeRedundantHtml(machineTranslatedDescription);
    }

    return '';
};

const removeRedundantHtml = (contentWithMarkup) => contentWithMarkup.replace(/<\/?[^>]+(>|$)/g, '');

/**
 * Extracts gig description text-content from seller translated description markup or machine translated description markup and limit it with specific chars count
 * @param { object } description - contains seller translated gig description markup and machine translated gig description markup
 * @returns {string} gig description text-content with max length in 156 chars
 */
export const extractTranslatedDescriptionContentForSeo = ({
    machineTranslatedDescription,
    sellerTranslatedDescription,
} = {}) => {
    const targetDescriptionWithoutMarkup = extractDescription({
        machineTranslatedDescription: machineTranslatedDescription?.content,
        sellerTranslatedDescription,
    });

    return targetDescriptionWithoutMarkup.slice(0, MAX_SEO_DESCRIPTION_LENGTH);
};

export const extractTranslatedTitleContentForSeo = ({ machineTranslatedTitle, sellerTranslatedTitle } = {}) => {
    const targetTitle = sellerTranslatedTitle || machineTranslatedTitle || '';

    return capitalize(targetTitle);
};

export const hasLocalizedPackagesAndExtras = ({ localizedPackages = [], localizedCustomExtras = [] } = {}) => {
    if (localizedPackages.length === 0 || localizedCustomExtras.length === 0) {
        return false;
    }

    const hasLocalizedPackage = localizedPackages.some(
        ({ localizedTitle, localizedDescription }) => localizedTitle && localizedDescription
    );
    const hasLocalizedCustomExtra = localizedCustomExtras.some(
        ({ localizedTitle, localizedDescription }) => localizedTitle && localizedDescription
    );

    return hasLocalizedPackage && hasLocalizedCustomExtra;
};

/**
 * Extracts gig and seller UGC from initialProps in English and transform to object containing data for MT-request
 * @param {object} initialProps - represents all data about gig and seller received in previous middlewares
 * @returns {object} UGC prepared for MT-request
 */
export const extractDataForMT = ({
    overview,
    description,
    workflow,
    workProcess,
    sellerCard,
    faq,
    seller,
    packages = {},
    studio = {},
    gigSellerTranslation = {},
} = {}) => {
    const { gig } = overview;
    const { title } = gig;
    const { localizedTitle, localizedDescription, localizedFaq } = gigSellerTranslation;
    const { locale } = RequestContext;
    const { isAgency } = calcAgencyData({ seller });

    const titleFromSeller = localizedTitle;
    const descriptionFromSeller = localizedDescription;
    const hasLocalizedFaq = localizedFaq?.length;
    const hasLocalizedPackages = hasLocalizedPackagesAndExtras(gigSellerTranslation);
    const gigTitle = getTitle({ isAgency, title, locale });

    return {
        ...(!titleFromSeller && { gigTitle }),
        ...(!descriptionFromSeller && { description: pick(description, 'content') }),
        rawTitle: title,
        workflow: extractWorkflowContent(workflow),
        workProcess: extractWorkProcessContent(workProcess),
        sellerCard: pick(sellerCard, ['description', 'oneLiner']),
        studio: extractStudioContent(studio),
        ...(!hasLocalizedFaq && { faq }),
        ...(!hasLocalizedPackages && { packages: extractPackagesContent(packages, locale, isAgency) }),
    };
};
