import { metric } from '../../../../utils/metric';
import { maxios } from '../../../../utils/maxios';

/**
 * Get Voice Over Sample
 * @desc Return a buffer array with the sampled audio generated from a given sentence
 * @param {string} sentence - The sentence to sample
 * @param {string} generateVoiceSampleApiEndpoint - The endpoint to call for generating the sample
 * @param {string} pathParameters - The request paramaters
 * @returns {ArrayBuffer} - The sampled audio generated. In case of error - empty Array Buffer will be returned
 */
export const getVoiceOverSample = async ({
    generateVoiceSampleApiEndpoint,
    sentence,
    pathfinderWrapper,
    pathParameters,
}) => {
    try {
        const token = await generateSampleToken({ sentence, pathfinderWrapper, pathParameters });

        return await generateSample(generateVoiceSampleApiEndpoint, token);
    } catch (error) {
        const errorType = extractErrorType(error);
        if (errorType) {
            error.type = errorType;
        }

        error.details = {
            description: 'Voice Over AI Audition Failure: Failed to generate sample.',
            sentence,
        };

        throw error;
    }
};

/**
 * Generate sample token
 * @desc Generate a one time token to be used for sampling via the 3rd party, while it stores the given params to be used on the following generation call
 * @param {string} sentence - The sentence to sample
 * @param {string} sellerUsername - The seller username
 * @param {string} gigSlug - The gig slug
 * @returns {string} - The unique token generated
 */
const generateSampleToken = async ({ sentence, pathfinderWrapper, pathParameters }) => {
    try {
        const { username, slug } = pathParameters;
        const generateSampleTokenEndpoint = pathfinderWrapper.pathfinder('gig_page_voice_over_ai_token', {
            username,
            slug,
        });
        const { data } = await maxios.post(
            generateSampleTokenEndpoint,
            { sentence },
            { routeKey: 'gig_page_voice_over_ai_token' }
        );
        metric.count('voice_over_ai_audition.generate_token.success');

        return data.token;
    } catch (error) {
        metric.count('voice_over_ai_audition.generate_token.error');

        throw error;
    }
};

/**
 * Generate Sample
 * @desc Generate audio sampling via 3rd party, the AI voice result.
 * @param {string} generateVoiceSampleApiEndpoint - The endpoint to call for sampling (3rd party).
 * @param {string} token - One time unique token generated on the 3rd party before.
 * @returns {ArrayBuffer} - The sampled audio generated.
 */
const generateSample = async (generateVoiceSampleApiEndpoint, token) => {
    const config = {
        headers: {
            conversionToken: token,
        },
        responseType: 'blob',
        routeKey: 'generate_voice_sample_api',
    };

    try {
        const { data } = await maxios.post(generateVoiceSampleApiEndpoint, {}, config);
        metric.count('voice_over_ai_audition.generate_sample.success');

        return await data.arrayBuffer();
    } catch (error) {
        metric.count('voice_over_ai_audition.generate_sample.error');

        throw error;
    }
};

/**
 * Generate audio URL from sample
 * @desc Returns a local URL that can be used to play the audio
 * @param {ArrayBuffer} audioArrayBuffer - The array buffer to generate URL for
 * @returns {string} - A URL representing the location of the audio to play
 */
export const generateAudioUrlFromSample = (audioArrayBuffer) => {
    const blob = new Blob([audioArrayBuffer], { type: 'audio/mpeg' });

    return window.URL.createObjectURL(blob);
};

/**
 * Safely extract the error type key
 * @desc Returns a local URL that can be used to play the audio
 * @param {object} error - The error object
 * @returns {string} - The error type string
 */
const extractErrorType = (error) => error?.response?.data?.errorType;
