import { UPDATE_ONLINE_STATUS } from '../../actions/isOnline';

const sellerReducer = () => {
    const initialState = { isOnline: false };

    return (state = initialState, action) => {
        const { type, data } = action;

        switch (type) {
            case UPDATE_ONLINE_STATUS:
                return { isOnline: data.isOnline };

            default:
                return state;
        }
    };
};

// selectors

export const selectIsSellerOnline = ({ seller }) => seller.isOnline;

export default sellerReducer;
