import React, { lazy, Suspense, useState, useCallback } from 'react';
import { string, object } from 'prop-types';
import { noop } from 'lodash';
import { FLOW_CONTEXT_TYPES, shouldDisplayMigrationPopup } from '@fiverr-private/pro_migration';
import { getContext } from '@fiverr-private/fiverr_context';
import { BUSINESS_TRIGGER_CTA, useSuccessSignCtaEvent } from '@fiverr-private/business_success_sign_modal';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { isLoggedInUser } from '../../../utils/isLoggedInUser';
import { onGuestClick } from '../../../utils/contactSeller';
import { FibTriggers } from '../../../utils/fibMigrationFlow';
import { triggerFibMigrationPopup } from '../../../utils/fibMigrationPopup';
import { useOpenUserActivationModalWrapper } from '../UserActivationModalWrapper/utils';
import UserActivationModalWrapper from '../UserActivationModalWrapper';
import { SUPPORTED_ACTIONS } from '../UserActivationModalWrapper/constants';
import { reportCustomOrderClick, reportCustomOrderClose, reportCustomOrderSubmitSuccess } from './utils';
import { getFormData } from './api';

const CUSTOM_ORDER_SOURCE = 'from_gig_page';
const BRIEF_SOURCE = 'gig_page';
const PERSIST_TYPE_BRIEF = 'BRIEF';

const CustomOrderModal = lazy(() =>
    import(/* webpackChunkName: 'CustomOrderModal' */ '@fiverr-private/custom_order/src/entries/CustomOrderModalEntry')
);

const CustomOrder = (
    { children, bqSourceName, triggerId, triggerCopy, triggerPlacement },
    { pathfinderWrapper, biEvents, currencyConverter, currentUser, seller: sellerContext }
) => {
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(() => noop);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { seller: { isAgency } } = useGigPageContext();
    const { shouldRenderAndOpenUserActivationModal } = useOpenUserActivationModalWrapper();
    const [renderAndOpenUserActivationModal, setRenderAndOpenUserActivationModal] = useState(false);

    const isGuest = !isLoggedInUser();
    const biEnrichmentData = biEvents.getEnrichmentData();
    const isValidFormData = !!(formData.seller && formData.categoriesData && formData.attachmentsUploadConfig);

    const sendCustomOrder = useCallback(async () => {
        if (isValidFormData) {
            openModal();
            return;
        }

        setIsLoading(true);

        try {
            const { data } = await getFormData({ pathfinderWrapper });
            setIsLoading(false);
            setFormData(data);
        } catch {
            setIsLoading(false);
        }
    }, [isValidFormData, openModal, pathfinderWrapper]);

    const onClick = async () => {
        reportCustomOrderClick(biEvents, bqSourceName);

        if (isGuest) {
            return onGuestClick({
                biEvents,
                pathfinderWrapper,
                modalOptions: {
                    source: 'gig_page_checkout',
                    triggerId,
                    triggerCopy,
                    triggerPlacement
                }
            });
        }

        if (await shouldRenderAndOpenUserActivationModal(currentUser)) {
            setRenderAndOpenUserActivationModal(true);
            return;
        }

        if (shouldDisplayMigrationPopup()) {
            triggerFibMigrationPopup({
                trigger: FLOW_CONTEXT_TYPES.requestQuote,
                triggerSource: FibTriggers.GetQuote,
                sellerDisplayName: sellerContext.displayName,
            });
            return;
        }

        sendCustomOrder();
    };

    useSuccessSignCtaEvent({
        type: BUSINESS_TRIGGER_CTA.REQUEST_QUOTE,
        source: FibTriggers.GetQuote,
        callback: sendCustomOrder
    });

    const setOpenModalHandle = (open) => {
        setOpenModal(() => open);
    };

    const handleClose = () => {
        if (!isSubmitted) {
            reportCustomOrderClose(biEvents);
        }
    };

    const handleSubmitSuccess = () => {
        setIsSubmitted(true);
        reportCustomOrderSubmitSuccess(biEvents);
    };

    const { pathParameters } = getContext();
    const { slug } = pathParameters;
    const { currency } = currencyConverter;
    const { seller, categoriesData, attachmentsUploadConfig, persistType, submitPath } = formData;
    const childProps = { onClick, disabled: isLoading };
    const sourcePage = persistType === PERSIST_TYPE_BRIEF ? BRIEF_SOURCE : CUSTOM_ORDER_SOURCE;

    return (
        <div className="custom-order">
            {React.Children.map(children, (child) => {
                if (child !== null) {
                    return React.cloneElement(child, childProps);
                }
            })}
            {isValidFormData && (
                <Suspense fallback={<div />}>
                    <CustomOrderModal
                        gigSlug={slug}
                        source={sourcePage}
                        autoOpen={true}
                        trigger={setOpenModalHandle}
                        currency={currency}
                        seller={seller}
                        sellerIsAgency={isAgency}
                        categoriesData={categoriesData}
                        submitPath={submitPath}
                        persistType={persistType}
                        uploadOptions={attachmentsUploadConfig}
                        biEnrichmentData={biEnrichmentData}
                        onSubmitSuccess={handleSubmitSuccess}
                        onClose={handleClose}
                    />
                </Suspense>
            )}
            <UserActivationModalWrapper
                renderAndOpen={renderAndOpenUserActivationModal}
                onClose={() => setRenderAndOpenUserActivationModal(false)}
                action={SUPPORTED_ACTIONS.GET_A_QUOTE}
            />
        </div>
    );
};

CustomOrder.propTypes = {
    children: object,
    bqSourceName: string,
    triggerId: string,
    triggerCopy: string,
    triggerPlacement: string
};

CustomOrder.contextTypes = {
    biEvents: object,
    pathfinderWrapper: object,
    currencyConverter: object,
    currentUser: object,
    seller: object,
};

export default CustomOrder;
