import { getContext } from '@fiverr-private/fiverr_context';
import { GIG_URL_PARAMS, RECOMMENDED_TYPES_URL_PARAMS } from '../../../reducers/packages/utils/constants';
import { AMOUNT_SEPARATOR } from '../constants';
import { formatNumber } from '../../formatNumber/formatNumber';
import { formatBoolean } from '../../formatBoolean/formatBoolean';
import { cleanObject } from '../../cleanObjectAttributes/cleanObject';

export const isCurrentPackageFromUrl = (packageId: number) => {
    const { packageId: selectedPackageIdFromUrl } = extractSelectedQueryParams();
    const { customizedPackageId } = extractCustomizedQueryParams();

    return packageId === selectedPackageIdFromUrl || packageId === customizedPackageId;
};

/**
 * Customized For You / Recommended For You Query Params Flow
 * Gig is Selected from Listings Page with Pre-Selected:Package / Pricing Factors (Filtered) / Calculator Value / Extra Fast
 * This is used when the User Filters the Listings Page to Compare relevant Gigs, and we want to Customize his experience
 * to Match with what was seen on the Listings.
 */
const extractCustomizedQueryParams = () => {
    const { queryParameters = {} } = getContext();

    const {
        [RECOMMENDED_TYPES_URL_PARAMS.SELECTED_PACKAGE_ID]: customizedPackageId,
        [RECOMMENDED_TYPES_URL_PARAMS.EXTRA_FAST]: customizedExtraFast,
        [RECOMMENDED_TYPES_URL_PARAMS.FILTERED_FACTORS]: customizedPricingFactors,
    } = queryParameters as Record<string, string | undefined>;

    return {
        customizedPackageId: formatNumber(customizedPackageId),
        customizedExtraFast: formatBoolean(customizedExtraFast),
        customizedPricingFactors,
    };
};

/**
 * Gig can be Pre-Populated with Extras / Calculator / Selected Package / Gig Quantity.
 * 1. This is used when the User selects options on the Gig before Logging In,
 * since after Login a Redirect will Reset the Gig Store.
 *
 * This can be used for other use cases, such as a -
 * 2. Order Again Button,
 * 3. sharing a Customized Gig Link etc.
 *
 * Note: Flow
 * When a Guest User is redirected to Gig Page from Listings, with a Customized For You / Recommended For You package -
 * and User selects different Extras / Quantities -
 * the Combined State is saved before Logging In. (When opening the Checkout Drawer).
 * This means a combination of `RECOMMENDED_TYPES_URL_PARAMS` and `GIG_URL_PARAMS` is valid, and affects each other.
 */
const extractSelectedQueryParams = () => {
    const { queryParameters = {} } = getContext();

    // Persisted Gig Params / Pricing Factors
    const {
        [GIG_URL_PARAMS.GIG_QUANTITY]: gigQuantity,
        [GIG_URL_PARAMS.SELECTED_PACKAGE_ID]: packageId,
        [GIG_URL_PARAMS.EXTRA_FAST]: extraFast,
        [GIG_URL_PARAMS.NOT_INCLUDED_FEATURES]: features,
        [GIG_URL_PARAMS.CUSTOM_EXTRAS]: customExtras,
        [GIG_URL_PARAMS.OPEN_DRAWER]: openDrawer,
        [GIG_URL_PARAMS.SELECTED_SUBSCRIPTION_OPTION]: subscriptionOptionValue,
    } = queryParameters as Record<string, string | undefined>;
    const { durationQueryParam, durationTimeunitQueryParam } = deserializeSubscriptionOptionQueryParam(
        subscriptionOptionValue as string
    );

    return {
        gigQuantity: formatNumber(gigQuantity),
        packageId: formatNumber(packageId),
        durationQueryParam,
        durationTimeunitQueryParam,
        extraFast: formatBoolean(extraFast),
        openDrawer: formatBoolean(openDrawer),
        features,
        customExtras,
    };
};

/**
 * Calculator Value is a combination of two flows -
 * - Listings Recommendation / Customization
 * - Order Again / Other Package Selection flows (ie. Tytos)
 * - Serialized Combined Flow state when opening Checkout Drawer and redirecting after logging in
 */
const extractCalculatorValue = () => {
    const { queryParameters = {} } = getContext();

    const {
        [RECOMMENDED_TYPES_URL_PARAMS.CALCULATOR_WORD_COUNT]: customizedWordCount,
        [GIG_URL_PARAMS.CALCULATOR_VALUE]: calculatorValue = customizedWordCount,
    } = queryParameters as Record<string, string | undefined>;

    return {
        calculatorValue: formatNumber(calculatorValue),
    };
};

/**
 * URL Query Params can be used to indicate -
 */
export const extractQueryParams = () => {
    const customizedForYouParams = extractCustomizedQueryParams();
    const selectedQueryParams = extractSelectedQueryParams();
    const selectedOrCustomizedCalculatorCount = extractCalculatorValue();

    return cleanObject({
        ...customizedForYouParams,
        ...selectedQueryParams,
        ...selectedOrCustomizedCalculatorCount,
    });
};

/**
 * Handles a string formatted - `3$$month` -> `{durationQueryParam: 3, durationTimeunitQueryParam: 'month'}`
 * @param {string} subscriptionOption
 */
export const deserializeSubscriptionOptionQueryParam = (subscriptionOption: string) => {
    if (!subscriptionOption) {
        return {};
    }
    const [durationQueryParam, durationTimeunitQueryParam] = subscriptionOption.split(AMOUNT_SEPARATOR);

    return {
        durationQueryParam: Number(durationQueryParam),
        durationTimeunitQueryParam,
    };
};
