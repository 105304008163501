import React from 'react';
import { CheckIcon } from '@fiverr-private/visuals';
import { Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';

export const BenefitBullet: React.FC = ({ children }) => (
  <Stack gap="2">
    <Stack width="fit-content" marginTop="0.5">
      <CheckIcon color="business_800" size="sm" />
    </Stack>
    <Text>{children}</Text>
  </Stack>
);
