import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from '@fiverr-private/i18n-react';

import CollapsablePackageItem from '../CollapsablePackageItem';
import { findCalculatorByPackageId } from '../../../../utils/calculator';
import { calculateServiceName, enrichServices, groupFeaturesByService } from '../../../../utils/studios';
import { toggleAll } from '../../../../actions/packagesTabs';

import './style.scss';

/**
 *  Services represents collapsible services display
 *  embedded in package content and replacing packages table on
 *  mobile view.
 *
 *  It currently implements collapsible functionality by itself.
 *  TODO: implement this component with fit3 collapsible. To do so fit needs to support a "show all" api
 */
class Services extends PureComponent {
    constructor(props) {
        super(props);

        this.i18nStudio = 'gig_page_perseus.studio';

        this.onTogglerClick = this.onTogglerClick.bind(this);
    }

    renderToggleShowText() {
        const { showAll } = this.props;
        const toggledKey = showAll ? 'hide_all' : 'show_all';

        return <I18n k={`${this.i18nStudio}.${toggledKey}`} />;
    }

    renderServices() {
        const { packageList, features, packageId, services } = this.props;

        const enrichedServices = enrichServices(services, packageList);
        const featuresByService = groupFeaturesByService(enrichedServices, features);

        return enrichedServices.map((service) => {
            const calculator = findCalculatorByPackageId(service.calculators, packageId);

            return (
                <CollapsablePackageItem
                    key={service.id}
                    {...service}
                    name={calculateServiceName(service.subCategoryId, service.nestedSubCategoryId)}
                    features={featuresByService[service.id]}
                    calculator={calculator}
                    packageId={packageId}
                />
            );
        });
    }

    shouldShowToggler() {
        const { features } = this.props;

        return features.length > 0;
    }

    onTogglerClick(e) {
        e.preventDefault();

        const { onToggleAll } = this.props;
        onToggleAll();
    }

    render() {
        return (
            <div className="services">
                <div className="header">
                    <h6>
                        <I18n k={'gig_page_perseus.general.whats_included'} />
                    </h6>
                    {this.shouldShowToggler() && (
                        <button onClick={this.onTogglerClick}>{this.renderToggleShowText()}</button>
                    )}
                </div>
                {this.renderServices()}
            </div>
        );
    }
}

Services.propTypes = {
    features: PropTypes.array,
    packageId: PropTypes.number,
    packageList: PropTypes.array,
    services: PropTypes.array,
    onToggleAll: PropTypes.func,
    showAll: PropTypes.bool,
};

Services.defaultProps = {
    onToggleAll: () => true,
};

const mapStateToProps = ({ packages, packagesTabs }) => ({
    packageList: packages.packageList,
    services: packagesTabs.tabItems,
    showAll: packagesTabs.showAll,
});

const mapDispatchToProps = (dispatch) => ({
    onToggleAll: () => {
        dispatch(toggleAll());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(Services);
