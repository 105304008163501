import _ from 'lodash';

export const PRICING_FACTOR_PREFIX = 'pricing_factors';
export const CALCULATOR_PREFIX = 'calculator';
export const CALCULATORS_PREFIX = 'calculators';
export const SUB_CATEGORY_PREFIX = 'sub_category';
export const CALCULATORS_KEY = `${PRICING_FACTOR_PREFIX}.${CALCULATORS_PREFIX}`;

/**
 *  Get attribute keys for a specific pricing factor and a sub category.
 *  The keys will be sorted by priority.
 *
 *  For example for:
 *      name: "word_count"
 *      subCategoryId: "353"
 *      attributes: ["label"]
 *
 *  We will get: [
 *      "pricing_factors.word_count.sub_category_353.label"
 *      "pricing_factors.word_count.label"
 *  ]
 *
 *  If [withCalculator] option is provided, we will prioritize calculator configuration: [
 *      "pricing_factors.calculators.word_count_calculator.sub_category_353.label"
 *      "pricing_factors.calculators.word_count_calculator.label"
 *      "pricing_factors.word_count.sub_category_353.label"
 *      "pricing_factors.word_count.label"
 *  ]
 */
export const getAttributeKeys = ({ name, subCategoryId, attributes, options = {} }) => {
    const pricingFactorsKey = getPricingFactorKey(name);
    const basePaths = [pricingFactorsKey];

    if (options.withCalculator) {
        const calculatorKey = getCalculatorKey(name);
        basePaths.unshift(calculatorKey);
    }

    return buildAttributeKeys(basePaths, attributes, subCategoryId);
};

const buildAttributeKeys = (basePaths, attributes, subCategoryId) => {
    const subCategoryKey = getSubCategoryKey(subCategoryId);

    return _.flatMap(basePaths, (basePath) =>
        _.flatMap(attributes, (attribute) => [`${basePath}.${subCategoryKey}.${attribute}`, `${basePath}.${attribute}`])
    );
};

const getCalculatorKey = (name) => `${CALCULATORS_KEY}.${name}_${CALCULATOR_PREFIX}`;

const getPricingFactorKey = (name) => `${PRICING_FACTOR_PREFIX}.${name}`;

export const getSubCategoryKey = (subCategoryId) => `${SUB_CATEGORY_PREFIX}_${subCategoryId}`;
