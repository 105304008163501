const METRIC_TYPES = {
    initial: 'initial',
    fetch: 'fetch',
    error: 'error',
    gigPageInitial: 'gig_page_initial',
};

/**
 * @constant {number}
 * @desc This constant represents the minimal fetch timeout for reviews initial fetch
 * The actual initial fetch timeout will be Math.max(FETCH_INITIAL_MAX_TIMOUT, gig_page_phoenix_fetch_time)
 * **/
const FETCH_INITIAL_MAX_TIMOUT = 110;

module.exports = {
    FETCH_INITIAL_MAX_TIMOUT,
    METRIC_TYPES,
    COUNTRY: 'country',
    DEFAULT_REVIEWS_SORT: 'relevant',
    LOCAL_REVIEW_SORT: 'local_reviews',
    DEFAULT_REVIEWS_LIMIT: 5,
    MOBILE_WEB_EXPERIMENT_LIMIT: 3,
};
