import React from 'react';
import { object } from 'prop-types';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { ReviewsCarousel } from '@fiverr-private/reviews';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import SafePureComponent from '../shared/SafePureComponent';
import { BQ_CLICKED_REVIEWS_RATINGS } from '../../utils/bigQueryEvents';
import { PAGE_NAME } from '../../utils/events';
import { REVIEWS } from '../../utils/pageSections';
import TextButton from './TextButton';
import { validateReviewsSnippet } from './utils';

import './style.scss';

const ReviewsSnippet = ({ reviews = {} }) => {
    const { biEvents, pathfinderWrapper, sectionScroller, isProRebrandingLayout } = useGigPageContext();

    if (!validateReviewsSnippet(reviews)) {
        return null;
    }

    const { pageCtxId } = getContext();

    const enrichData = {
        pageCtxId,
        pageName: PAGE_NAME,
    };

    const { reviews_snippet: reviewsSnippet } = reviews;

    const viewMoreClick = () => {
        biEvents.sendBigQueryEvent({ eventName: BQ_CLICKED_REVIEWS_RATINGS });
        sectionScroller.scroll({ sectionKey: REVIEWS });
    };

    const titleCopy = 'gig_page_perseus.reviews_snippet.title';
    const translationUrl = pathfinderWrapper.pathfinder('gig_page_translate_ugc_api');
    const reviewsButtonClasses = classNames('reviews-button', {
        'new-pro-layout': isProRebrandingLayout,
    });

    return (
        <div className="gig-reviews-snippet">
            <header>
                <h2 className="section-title">
                    <I18n k={titleCopy} />
                </h2>
                <TextButton
                    className={reviewsButtonClasses}
                    text={'gig_page_perseus.reviews_snippet.view_all'}
                    onClick={viewMoreClick}
                />
            </header>
            <ReviewsCarousel
                initialState={{ ...reviews, reviews: reviewsSnippet, ...enrichData }}
                translationUrl={translationUrl}
            />
        </div>
    );
};

ReviewsSnippet.propTypes = {
    reviews: object,
};

export { ReviewsSnippet };
export default SafePureComponent(ReviewsSnippet);
