import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.scss';

class IndicationDots extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndication: 0,
            overflowStart: false,
            overflowEnd: this.isOverflow(),
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { current, count, maxDots } = nextProps;
        const oneBeforeLastIndex = maxDots - 2;
        const prevCurrent = this.props.current;
        let { currentIndication, overflowStart, overflowEnd } = this.state;

        if (current === 0) {
            // First
            currentIndication = 0;
            overflowStart = false;
            overflowEnd = this.isOverflow();
        } else if (current === count - 1) {
            // Last
            currentIndication = this.calculateIndicationDotsCount() - 1;
            overflowEnd = false;
            overflowStart = this.isOverflow();
        } else if (current === prevCurrent + 1 && currentIndication < oneBeforeLastIndex) {
            // Forward
            currentIndication++;
            if (this.isOverflow() && currentIndication === oneBeforeLastIndex) {
                overflowStart = true;
            }
        } else if (current === prevCurrent - 1 && currentIndication > 1) {
            //  Backward
            currentIndication--;
            if (this.isOverflow() && currentIndication === 1) {
                overflowEnd = true;
            }
        }

        this.setState((prevState) => ({
            ...prevState,
            currentIndication,
            overflowStart,
            overflowEnd,
        }));
    }

    calculateIndicationDotsCount() {
        const { count, maxDots } = this.props;
        return Math.min(count, maxDots);
    }

    isActivated(index) {
        const { currentIndication } = this.state;
        return currentIndication === index;
    }

    isOverflow() {
        const { count, maxDots } = this.props;
        return count > maxDots;
    }

    isSmallEnd(index, indicationDotsCount) {
        const { overflowEnd } = this.state;
        return overflowEnd && index === indicationDotsCount - 1;
    }

    isSmallStart(index) {
        const { overflowStart } = this.state;
        return overflowStart && index === 0;
    }

    render() {
        const { count } = this.props;
        const indicationDotsCount = this.calculateIndicationDotsCount();

        if (count < 2) {
            return null;
        }

        return (
            <div className="indication-dots-wrapper">
                {[...Array(indicationDotsCount)].map((element, index) => (
                    <span
                        key={index}
                        className={classNames({
                            active: this.isActivated(index),
                            small: this.isSmallStart(index) || this.isSmallEnd(index, indicationDotsCount),
                        })}
                    />
                ))}
            </div>
        );
    }
}

IndicationDots.propTypes = {
    count: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    maxDots: (props, propName) => {
        if (props[propName] < 3) {
            return new Error('maxDots must be 3 or more!');
        }
    },
};

IndicationDots.defaultProps = {
    maxDots: 6,
};

export default IndicationDots;
