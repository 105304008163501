import React from 'react';
import { Heading } from '@fiverr-private/typography';
import { translate } from '@fiverr-private/i18n-react';

const COPY_KEY = 'gig_page_banners.pro_benefits_banner.title';

export const Title = () => (
  <Heading as="h6" maxWidth={{ default: 173, sm: 'initial' }}>
    {translate(COPY_KEY)}
  </Heading>
);
