import React from 'react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import ProfileImage from '@fiverr-private/profile_image/src/ProfileImage';
import { Avatar, AvatarIcon, AvatarImage } from '@fiverr-private/avatar';
import { CheckStarSolidIcon } from '@fiverr-private/visuals';
import { getContext } from '@fiverr-private/fiverr_context';
import { ROLLOUTS } from '../../../../types/rollouts';

interface SellerAvatarParams {
  profilePhoto: string;
  hasProfilePhoto: boolean;
}

export const SellerAvatar = ({ profilePhoto, hasProfilePhoto }: SellerAvatarParams) => {
  const {
    seller: { isAgency, introVideoUrl, isPro: isProSeller, achievement },
    rollouts,
  } = useGigPageContext();

  const { pathParameters, locale } = getContext();
  const { username } = pathParameters;

  const avatarShape = isAgency ? 'square' : 'circle';
  const inNewSellerLevelBadgesRollout = rollouts[ROLLOUTS.QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION];
  const showOldSellerLevelOnAvatar = !inNewSellerLevelBadgesRollout;

  const profileImageUserProps = {
    username,
    ...(showOldSellerLevelOnAvatar && { achievement }),
    has_profile_photo: hasProfilePhoto,
    profile_photo: profilePhoto,
    is_pro: isProSeller,
    is_seller: true,
  };

  if (isAgency) {
    return (
      <Avatar size="2xl" shape={avatarShape} username={username}>
        <AvatarImage src={profilePhoto} alt={username} />
        {isProSeller && (
          <AvatarIcon padding="1.5" backgroundColor="business_800" position="bottom-right" showRing={false}>
            <CheckStarSolidIcon color="white" size="md" />
          </AvatarIcon>
        )}
      </Avatar>
    );
  }

  return <ProfileImage introVideo={introVideoUrl} user={profileImageUserProps} profileImageSize={96} locale={locale} />;
};
