export const FEATURE_TYPE = 'feature';
export const CUSTOM_EXTRA_TYPE = 'customExtra';
export const EXTRA_FAST_TYPE = 'extraFast';

export const EXTRA_FAST_FEATURE = 'extra_fast';
export const SCRIPT_PROOFREADING_FEATURE = 'script_proofreading';

export const NUMERIC_TYPE = 'NUMERIC';
export const BOOLEAN_TYPE = 'BOOLEAN';

export const BASIC_PACKAGE_ID = 1;
export const STANDARD_PACKAGE_ID = 2;
export const PREMIUM_PACKAGE_ID = 3;

export const RECOMMENDATION_ELIGIBLE_PACKAGES = [STANDARD_PACKAGE_ID, PREMIUM_PACKAGE_ID];

export const UNLIMITED_VALUES = [-1, 999999];
export const UNLIMITED_REVISION = 'unlimited';

export const DEFAULT_TAB_ITEM_ID = -1;

export const CALC_MAX = 500000;
