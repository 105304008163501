import React, { useRef, useState } from 'react';
import { object } from 'prop-types';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { translate } from '@fiverr-private/i18n-react';
import { Collapsible } from '@fiverr-private/fit';
import SafePureComponent from '../shared/SafePureComponent';
import { validateReviews } from '../../utils/mainComponentsValidator';
import { ROLLOUTS } from '../../types/rollouts';
import { BQ_REVIEWS_TOGGLE } from '../../utils/bigQueryEvents';
import { REVIEWS } from '../../utils/pageSections';
import { Waypoint } from '../Waypoint/Waypoint';
import GigReviewsStandalone from './GigReviewsStandalone';
import ReviewsTabs from './ReviewsTabs';
import SellerReviewsStandalone from './SellerReviewsStandalone';
import { reviewsHeaderIsShownEvent } from './Events';
import { GIG_PAGE_REVIEWS_CLASS, REVIEWS_COPIES_PREFIX } from './constants';

import './style.scss';

const getReviewsComponent = (reviewsOnlyForCurrentGig, isSellerReviewsFeatureActive, noReviewsForCurrentGig) => {
    if (reviewsOnlyForCurrentGig || !isSellerReviewsFeatureActive) {
        return GigReviewsStandalone;
    } else if (noReviewsForCurrentGig) {
        return SellerReviewsStandalone;
    }

    return ReviewsTabs;
};

const ReviewsWrapper = ({ reviews = {} }, { general, biEvents, rollouts = {}, pathfinderWrapper }) => {
    const { userId } = getContext();
    const [isExpanded, onExpand, onMinimize] = useExpanded(true);
    const [reviewsHeaderRef] = useImpressionItem(reviewsHeaderIsShownEvent(general.gigId, general.sellerId, userId));
    const observableRef = useRef(null);

    if (!validateReviews({ reviews })) {
        return null;
    }

    const onToggleCollapse = async () => {
        biEvents.sendBigQueryEvent({ eventName: BQ_REVIEWS_TOGGLE });

        return { preventToggle: false };
    };

    const props = {
        enableSearchFilter: rollouts[ROLLOUTS.QUALAS_SEARCH_ON_REVIEWS_FEATURE_FLAG],
        translationUrl: pathfinderWrapper.pathfinder('gig_page_translate_ugc_api'),
        isPro: general.isPro,
        reviews,
    };

    const isSellerReviewsFeatureActive = rollouts[ROLLOUTS.QUAL_SELLER_REVIEWS_FEATURE_FLAG];
    const noReviewsForCurrentGig = !reviews.reviews.length && reviews?.seller_reviews?.reviews;
    const reviewsOnlyForCurrentGig = reviews.total_count === reviews?.seller_reviews?.total_count;
    const ReviewsComponent = getReviewsComponent(
        reviewsOnlyForCurrentGig,
        isSellerReviewsFeatureActive,
        noReviewsForCurrentGig
    );

    return (
        <>
            <Waypoint source={REVIEWS} />
            <div ref={observableRef} />

            {/* This component is shown only on desktop */}
            <div className={classNames(GIG_PAGE_REVIEWS_CLASS, 'desktop-view')}>
                <div ref={reviewsHeaderRef} className="tbody-3 co-text-darker text-bold m-b-16">
                    {translate(`${REVIEWS_COPIES_PREFIX}.reviews_header`)}
                </div>
                <ReviewsComponent {...props} />
            </div>
            {/* This component is shown only on mobile-web */}
            <div id="collapsible-reviews-wrapper" className={classNames(GIG_PAGE_REVIEWS_CLASS, 'mobile-view')}>
                <Collapsible
                    className={classNames({ expanded: isExpanded })}
                    titleWrapperClassName="collapsible-title"
                    chevronClassName="chevron"
                    defaultExpanded
                    onExpand={onExpand}
                    onMinimize={onMinimize}
                    onToggle={onToggleCollapse}
                    title={
                        <div ref={reviewsHeaderRef} className="tbody-3 co-text-darker text-bold">
                            {translate(`${REVIEWS_COPIES_PREFIX}.reviews_header`)}
                        </div>
                    }
                >
                    <div className="p-t-16">
                        <ReviewsComponent {...props} />
                    </div>
                </Collapsible>
            </div>
        </>
    );
};

const useExpanded = (defaultState = false) => {
    const [isExpanded, setIsExpanded] = useState(defaultState);
    const onExpand = () => setIsExpanded(true);
    const onMinimize = () => setIsExpanded(false);

    return [isExpanded, onExpand, onMinimize];
};

ReviewsWrapper.propTypes = {
    reviews: object,
};

ReviewsWrapper.contextTypes = {
    general: object,
    rollouts: object,
    pathfinderWrapper: object,
    biEvents: object,
};

export { ReviewsWrapper };
export default SafePureComponent(ReviewsWrapper);
