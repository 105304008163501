import React from 'react';
import { array, bool, number } from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentPackageValidExtras } from '../../../reducers/packages';
import { BASIC_PACKAGE_ID, EXTRA_FAST_TYPE } from '../../../utils/packagesUtils/constants';
import { isGigWithActiveWorkProcess } from '../../../reducers/workProcess';
import PackageExtra from './PackageExtra';

const PackageExtras = ({ packageId = BASIC_PACKAGE_ID, extras, isMilestoneGig }) => {
    if (!extras.length) {
        return null;
    }

    const filteredExtras = isMilestoneGig ? extras.filter(({ type }) => type !== EXTRA_FAST_TYPE) : extras;

    return (
        <ul className="package-extras">
            {filteredExtras.map((extra, index) => (
                <PackageExtra key={index} {...extra} packageId={packageId} />
            ))}
        </ul>
    );
};

PackageExtras.propTypes = {
    packageId: number,
    extras: array,
    isMilestoneGig: bool,
};

const mapStateToProps = (state) => ({
    extras: getCurrentPackageValidExtras(state),
    isMilestoneGig: isGigWithActiveWorkProcess(state),
});

export { PackageExtras };
export default connect(mapStateToProps)(PackageExtras);
