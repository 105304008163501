import React from 'react';
import { number, object } from 'prop-types';
import classNames from 'classnames';
import { Tooltip } from '@fiverr-private/fit';
import { getCalculatorTooltip } from '../../../utils/translation/pricingFactors/buyerTooltip';
import CalculatorInput from './CalculatorInput';

import './style.scss';

const PackageCalculator = (
    { id, name, currentValue, value, additionalValue, price, packageId },
    { currencyConverter, general: { subCategoryId } }
) => {
    const replacements = {
        includedAmount: value,
        additionalAmountStep: additionalValue,
        additionalPriceStep: currencyConverter.convert(price),
    };

    return (
        <Tooltip
            className={classNames('package-calculator')}
            boxContentClassName="tooltip-box-content"
            appendToBody={false}
            content={getCalculatorTooltip({ name, subCategoryId, replacements })}
        >
            <CalculatorInput packageId={packageId} initValue={value} currentValue={currentValue} id={id} />
        </Tooltip>
    );
};

PackageCalculator.propTypes = {
    id: number,
    price: number,
    name: number,
    currentValue: number,
    value: number,
    additionalValue: number,
    packageId: number,
}.isRequired;

PackageCalculator.contextTypes = {
    currencyConverter: object,
    general: object,
};

export default PackageCalculator;
