import { useOpenUserActivationModal } from '@fiverr-private/user_activation';
import { metric } from '../../../../utils/metric';
import { isLoggedInUser } from '../../../../utils/isLoggedInUser';
import { PENDING } from '../../../../utils/constants/userStatuses';

const METRIC_PREFIX = 'non_activated_messaging';

/**
 * Check if the status of the user is pending
 * @desc CurrentUser is enriched from the page phoenix and because race condition
 * for when the user joins in the page, the domain events could be still not consumed by the phoenix
 * in this case the current user or its status might be still undefined.
 * @param {object|undefined} currentUser - current user
 * @returns {boolean} - true if the status is undefined or equals to pending
 */
const isPendingUser = (currentUser) => {
    if (!isLoggedInUser()) {
        return false;
    }

    const functionMetricPrefix = `${METRIC_PREFIX}.is_pending_user`;
    const status = currentUser?.status;
    if (typeof status === 'undefined') {
        metric.count(`${functionMetricPrefix}.undefined_user_status`);

        return true;
    }

    if (status === PENDING) {
        metric.count(`${functionMetricPrefix}.pending_user_status`);

        return true;
    }

    return false;
};

/*
 * React custom hook
 * @returns {object<Promise<boolean>>} which whether to render and open user activation modal
 */
const useOpenUserActivationModalWrapper = () => {
    const { shouldOpenUserActivationModal } = useOpenUserActivationModal();

    const shouldRenderAndOpenUserActivationModal = async (currentUser) => {
        let shouldOpen = false;

        if (isPendingUser(currentUser)) {
            shouldOpen = await shouldOpenUserActivationModal();
        }

        return shouldOpen;
    };

    return {
        shouldRenderAndOpenUserActivationModal,
    };
};

export { useOpenUserActivationModalWrapper, isPendingUser };
