import React from 'react';
import classNames from 'classnames';
import { number, object } from 'prop-types';
import { Icon, Tooltip } from '@fiverr-private/fit';
import { s_question_circle } from '@fiverr-private/icons';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';

import sassVars from '../index.scss';

const MilestonePrice = ({ milestoneNumber, priceInUsd }, { currencyConverter }) => {
    if (!priceInUsd) {
        return null;
    }
    const { isMobile } = getContext();
    const priceInCents = priceInUsd * 100;
    const formattedPrice = currencyConverter.convert(priceInCents);
    const isFirstMilestone = milestoneNumber === 1;
    const paymentSuffix = isFirstMilestone ? 'pay_now' : 'pay_later';
    const shouldHavePaymentTooltip = !isFirstMilestone && !isMobile;
    const paymentKey = `gig_page_perseus.workProcess.milestone.price.${paymentSuffix}`;

    return (
        <div className={classNames('tbody-7', 'milestone-price')}>
            <b className="tbody-5">{formattedPrice}</b>
            <span className={classNames('flex', 'flex-items-center', { 'first-milestone': isFirstMilestone })}>
                {shouldHavePaymentTooltip && (
                    <Tooltip
                        className="m-r-4 milestone-info-tooltip"
                        content={<I18n k={'gig_page_perseus.workProcess.milestone.price.milestone_payments_tooltip'} />}
                    >
                        <Icon size={12} color={sassVars.grey_800}>
                            {s_question_circle}
                        </Icon>
                    </Tooltip>
                )}
                <I18n k={paymentKey} />
            </span>
        </div>
    );
};

MilestonePrice.propTypes = {
    milestoneNumber: number,
    priceInUsd: number,
};

MilestonePrice.contextTypes = {
    currencyConverter: object,
};

export default MilestonePrice;
