import { shape, string, array, number, bool } from 'prop-types';

export const workflowStepShape = shape({
    predefinedStepId: string,
    packages: array,
    order: number,
    liveSession: bool,
    buyerReview: bool,
    description: string,
    title: string,
});
