import React from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import Waypoint from 'react-waypoint';
import pathfinder from '@fiverr-private/pathfinder';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { LazyPortfolioWidget, ProjectSourceFilter } from '@fiverr-private/portfolio';
import { PortfolioPresence } from '@fiverr-private/portfolio_showcase';
import { Stack } from '@fiverr-private/layout_components';
import { Heading } from '@fiverr-private/typography';
import { getContext } from '@fiverr-private/fiverr_context';
import { translate } from '@fiverr-private/i18n-react';
import { PAGE_NAME } from '../../utils/events';
import WithImpressions from '../shared/withImpressions';
import SafePureComponent from '../shared/SafePureComponent';
import { ROLLOUTS } from '../../types/rollouts';

const CLASS_NAME = 'portfolio-external-wrapper';

const Portfolio = ({ achievement, isPro, profilePhoto, hasProfilePhoto, impressionRef, collectImpressions }) => {
    const {
        seller: { portfolios, isAgency },
        general: { gigId, subCategoryId },
        rollouts: { [ROLLOUTS.QUALAS_GIG_PAGE_WORK_SAMPLES]: inQualasWorkSample },
    } = useGigPageContext();

    const { pathParameters, userId } = getContext();

    if (!portfolios) {
        return null;
    }

    const { username, slug } = pathParameters;
    const seller = {
        username,
        achievement,
        is_pro: isPro,
        profile_photo: profilePhoto,
        has_profile_photo: hasProfilePhoto,
    };
    const { portfolioProjects, totalCount } = portfolios;

    if (!totalCount) {
        return null;
    }

    const origin = PAGE_NAME;
    const portfolioLink = pathfinder(
        'user_portfolio_index',
        { user_id: username },
        {
            query: {
                gig: slug,
                origin,
            },
        }
    );

    const portfolioProps = {
        projectList: portfolioProjects,
        seller,
        origin,
        totalProjects: totalCount,
        isWideTheme: true,
        gigId,
        portfolioLink,
        infinityPaging: true,
        educate: !!userId,
        ...(isAgency && { portfolioTitle: translate('gig_page_perseus.general.portfolio') }),
    };

    return (
        <section className={CLASS_NAME} ref={impressionRef}>
            <Waypoint onEnter={collectImpressions} />
            {inQualasWorkSample ? (
                <Stack direction="column">
                    <Heading as="h4">
                        {isAgency
                            ? translate('gig_page_perseus.general.portfolio')
                            : translate('gig_page_perseus.portfolio.title')}
                    </Heading>
                    <LazyPortfolioWidget
                        componentProps={{
                            biEnrichment: {
                                group: 'seller_portfolio',
                            },
                            projectSource: ProjectSourceFilter.External,
                            subCategoryId,
                            username,
                            cardSize: { md: 'md' },
                            thumbnailsAmount: {
                                default: 2,
                                sm: 4,
                                md: 6,
                                lg: 5,
                                xl: 6,
                            },
                        }}
                    />
                </Stack>
            ) : (
                <PortfolioPresence {...portfolioProps} />
            )}
        </section>
    );
};

Portfolio.propTypes = {
    portfolio: shape({
        portfolioProjects: array,
        nextProjectIds: array,
        projectsCount: number,
    }),
    achievement: number.isRequired,
    isPro: bool,
    hasProfilePhoto: bool,
    profilePhoto: string,
    impressionRef: object,
    collectImpressions: func,
};

const impressionConfig = {
    DOMSelector: `.${CLASS_NAME} .portfolio-presence`,
    eventKey: 'portfolio_impression',
};

export { Portfolio };
export default SafePureComponent(WithImpressions(Portfolio, impressionConfig));
