import React from 'react';
import { Text } from '@fiverr-private/typography';
import { Box, theme } from '@fiverr-private/theme';
import { DiscountTooltip } from './DiscountTooltip';

interface CrossedPriceProps {
  fontSize: keyof typeof theme.bodySizes;
  discountPercentage: number;
  price: number;
}

export const CrossedPrice: React.FC<CrossedPriceProps> = ({
  fontSize = theme.bodySizes.b_md,
  discountPercentage,
  price,
}) => (
  <Text fontWeight="normal" decoration="lineThrough" size={fontSize}>
    {price}
    <Box as="span" marginLeft="2">
      <DiscountTooltip discountPercentage={discountPercentage} />
    </Box>
  </Text>
);
