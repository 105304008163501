import React from 'react';
import { arrayOf, object, shape, string } from 'prop-types';
import { uniq, compact, sortBy, get } from 'lodash';

import './style.scss';

const MetadataAttribute = ({ name, options, metadata }) => {
    const validOptions = uniq(compact(options));
    const sortedOptions = sortBy(validOptions, (option) => get(metadata, `metadataOptions.${option}`, 0));
    const filteredOptions = compact(sortedOptions);

    if (!(name && metadata && filteredOptions.length > 0)) {
        return null;
    }

    return (
        <li className="metadata-attribute">
            <p>{metadata.label}</p>
            <ul>
                {filteredOptions.map(({ label, value }) => (
                    <li key={value}>{label}</li>
                ))}
            </ul>
        </li>
    );
};

MetadataAttribute.propTypes = {
    name: string,
    options: arrayOf(
        shape({
            label: string,
            value: string,
        })
    ),
    metadata: object,
};

export default MetadataAttribute;
