import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_FIVERR_LOCALE } from '@fiverr-private/localization';
import { isGigPaused } from '../gigStatus';

export const isHighlyResponsive = ({ general = {}, seller = {} }) => {
    const { locale } = getContext();
    const { isStudio, gigStatus, traffiqed, isOnVacation } = general;
    const isPaused = isGigPaused(gigStatus, traffiqed);

    return !!seller?.isHighlyResponsive && !isStudio && !isPaused && !isOnVacation && locale === DEFAULT_FIVERR_LOCALE;
};
