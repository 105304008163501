import { LANGUAGE_PROFICIENCY_LEVEL_MAPPING, SUPPORTED_LOCALE_CODES } from './constants';

const isEligibleByLanguageCodeAndLevel = ({ name: languageCode, level: languageLevel }) => {
    if (languageCode === SUPPORTED_LOCALE_CODES.DE) {
        return languageLevel >= LANGUAGE_PROFICIENCY_LEVEL_MAPPING.conversational;
    }

    if (languageCode === SUPPORTED_LOCALE_CODES.FR) {
        return languageLevel >= LANGUAGE_PROFICIENCY_LEVEL_MAPPING.fluent;
    }

    return false;
};

const getTranslationLayerEligibleLanguages = (languages = []) =>
    languages.filter((language) => isEligibleByLanguageCodeAndLevel(language));

export default getTranslationLayerEligibleLanguages;
