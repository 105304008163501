import React from 'react';
import { isEqual } from 'lodash';
import {
  BookConsultationButton,
  BookConsultationButtonTheme,
  Consultation,
  CONSULTATION_DEFAULT_RESPONSE,
  ConsultationData,
} from '@fiverr-private/consultation';
import pathfinder from '@fiverr-private/pathfinder';
import { CurrencyConverter } from '../../types';

export interface BookConsultationProps {
  isPro?: boolean;
  theme?: BookConsultationButtonTheme;
  sellerId: number;
  gigId: number;
  displayName?: string;
  profileImage?: string;
  consultationData: ConsultationData | typeof CONSULTATION_DEFAULT_RESPONSE;
  currencyConverter: CurrencyConverter;
}

const PAGE_NAME = 'gig_page';
const ELEMENT_NAME = 'seller_card';

export const BookConsultation = ({
  theme,
  isPro,
  sellerId,
  gigId,
  displayName,
  profileImage,
  consultationData,
  currencyConverter,
}: BookConsultationProps) => {
  if (!consultationData || isEqual(consultationData, CONSULTATION_DEFAULT_RESPONSE) || !currencyConverter) {
    return null;
  }

  const learnMoreLink = pathfinder(
    'help_center_article',
    { article_id: 12744510059665 },
    { query: { segment: 'buyer' } }
  );

  const biEnrichment = {
    pageName: PAGE_NAME,
    elementName: ELEMENT_NAME,
    group: PAGE_NAME,
    gig: { id: gigId },
    seller: { id: sellerId },
  };

  const mixpanelEnrichment = {
    Origin: PAGE_NAME,
    GigId: gigId,
    SellerId: sellerId,
  };

  return (
    <Consultation
      consultationData={consultationData}
      mixpanelEnrichment={mixpanelEnrichment}
      biEnrichment={biEnrichment}
      sellerId={sellerId}
      sellerName={displayName}
      profilePhoto={profileImage}
      learnMoreLink={learnMoreLink}
      currency={currencyConverter.getCurrency()}
    >
      <BookConsultationButton withIcon theme={theme} isPro={isPro} />
    </Consultation>
  );
};
