// Load BREAKPOINTS from @fiverr-private/orca when correctly typed
import { BREAKPOINTS } from '@fiverr-private/orca/dist/src/components/Carousel/constants';

export const SLIDER_CONFIG = {
    sliderBaseNum: 4,
    sliderBreakpoints: {
        [BREAKPOINTS.BP_1160]: 3,
        [BREAKPOINTS.BP_900]: 2,
        [BREAKPOINTS.BP_600]: 1,
    },
    skeletonBreakpoints: {
        [BREAKPOINTS.BP_1160]: 3,
        [BREAKPOINTS.BP_900]: 2,
        [BREAKPOINTS.BP_600]: 1,
    },
};
