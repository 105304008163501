import { PackageData } from '../../types/gig/interfaces';
import { calcExtrasPrice } from './calcExtrasPrice';
import { calcCalculatorsPrice } from './calcCalculatorsPrice';

export const calcCheckoutPrice = (packageData: PackageData, gigQuantity = 1): number => {
    const { calculators } = packageData;

    const packagePrice = packageData.price * gigQuantity;
    const calculatorsPrice = calcCalculatorsPrice(calculators, gigQuantity);
    const extrasPrice = calcExtrasPrice(packageData);

    return packagePrice + calculatorsPrice + extrasPrice;
};
