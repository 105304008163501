import { isNil } from 'lodash';
import { UNLIMITED_VALUES } from '../packagesUtils/constants';
import { translate } from '../translation';
import { MILESTONE_TYPE } from './constants';

/**
 * Returns the milestone type of the gig
 * @param {boolean} isRecurring flag if gig is recurring
 * @param {boolean} hasWorkflow flag if gig has workflow
 * @param {boolean} hasWorkProcess flag if gig has work process
 * @param {boolean} activeMilestonePay flag if gig has milestone pay enabled
 * @returns {string} the millestone type of the gig
 */
export const getMilestoneType = ({
    isRecurring = false,
    hasWorkflow = false,
    hasWorkProcess = false,
    activeMilestonePay = false,
} = {}) => {
    if (isRecurring) {
        return MILESTONE_TYPE.RECURRING;
    }

    if (hasWorkflow) {
        return MILESTONE_TYPE.WORKFLOW;
    }

    if (activeMilestonePay) {
        return MILESTONE_TYPE.MILESTONE_PAY;
    }

    if (hasWorkProcess) {
        return MILESTONE_TYPE.WORK_PROCESS;
    }

    return MILESTONE_TYPE.NONE;
};

/**
 * Returns a revisions count text for milestone
 * @param {number} revisions number of revisions
 * @returns {string|number} the revisions count to display
 */
export const getRevisionsCount = (revisions) => {
    const noRevisions = isNil(revisions) || revisions === 0;
    const unlimitedRevisions = UNLIMITED_VALUES.includes(revisions);

    if (noRevisions) {
        return translate('gig_page_perseus.general.no');
    }

    if (unlimitedRevisions) {
        return translate('gig_page_perseus.workProcess.milestone.subtitle.revisions.unlimited');
    }

    return revisions;
};
