import React from 'react';
import { array, bool } from 'prop-types';
import { connect } from 'react-redux';
import { I18n } from '@fiverr-private/i18n-react';
import FakeRadio from '../FakeRadio';
import { formatDaysKey } from '../../../utils/packagesUtils';
import { isGigWithActiveWorkProcess } from '../../../reducers/workProcess';

const renderPackageDeliveryTime = (
    { extraFast, duration: days, packageId },
    showSelection,
    isGigWithActiveWorkProcess
) => {
    const shouldRenderSelection = extraFast && showSelection && !isGigWithActiveWorkProcess;

    return (
        <td key={packageId}>
            {shouldRenderSelection ? (
                <FakeRadio packageId={packageId} />
            ) : (
                <>
                    {days} <I18n k={formatDaysKey(days)} />
                </>
            )}
        </td>
    );
};

const DeliveryTime = ({ deliveries, showSelection = true, isGigWithActiveWorkProcess }) => (
    <tr className="delivery-time">
        <td className="package-row-label">
            <I18n k={'gig_page_perseus.packages_table.delivery_time'} />
        </td>
        {deliveries.map((delivery) => renderPackageDeliveryTime(delivery, showSelection, isGigWithActiveWorkProcess))}
    </tr>
);

DeliveryTime.propTypes = {
    deliveries: array,
    showSelection: bool,
    isGigWithActiveWorkProcess: bool,
};

const mapStateToProps = (state) => ({
    deliveries: state.packages.packageList.map(({ extraFast, duration, id }) => ({
        extraFast,
        duration,
        packageId: id,
    })),
    isGigWithActiveWorkProcess: isGigWithActiveWorkProcess(state),
});

export { DeliveryTime };
export default connect(mapStateToProps)(DeliveryTime);
