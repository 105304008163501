export const GIG_URL_PARAMS = {
    SELECTED_PACKAGE_ID: 'pkg',
    GIG_QUANTITY: 'gig_quantity',
    CUSTOM_EXTRAS: 'extras',
    NOT_INCLUDED_FEATURES: 'features',
    EXTRA_FAST: 'fast_delivery',
    CALCULATOR_VALUE: 'calc',
    OPEN_DRAWER: 'openDrawer',
    SELECTED_SUBSCRIPTION_OPTION: 'subscribe',
};

/**
 * Query Param keys used to Customize the Gig Package when selecting a Gig from a Filtered View of the Listings Page.
 * This Pre-Populates the Gig Package Selection, but can Collide with what the User actually selects before opening
 * the Checkout Drawer (which triggers a Login Modal and Redirect).
 * This causes a need with duplication - different param Names for the same attribute.
 * @see extractQueryParams
 */
export const RECOMMENDED_TYPES_URL_PARAMS = {
    SELECTED_PACKAGE_ID: 'pckg_id',
    FILTERED_FACTORS: 'filtered_factors',
    EXTRA_FAST: 'extra_fast',
    CALCULATOR_WORD_COUNT: 'word_count',
};
