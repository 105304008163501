import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { mpClickedSocialSharing } from '../events/mixpanel/clickedSocialSharing';

export const useSocialSharingModal = () => {
    const {
        general: { gigId, gigTitle },
        isPageOwner,
        sellerUsername,
        biEvents,
    } = useGigPageContext();

    const customEventData = {
        detail: {
            source: isPageOwner ? 'gigs_show' : 'gigs_show_buyers',
            resource_type: 'gig',
            resource_id: gigId,
            is_resource_owner: isPageOwner,
            interpolation_variables: {
                username: sellerUsername,
                gig_title: gigTitle,
            },
        },
    };

    const openModal = () => {
        const event = new CustomEvent('open-share-modal', customEventData);
        window.dispatchEvent(event);

        biEvents.sendMixPanelEvent(mpClickedSocialSharing());
    };

    return {
        openModal,
    };
};
