import React from 'react';
import { noop } from 'lodash';
import { Button } from '@fiverr-private/button';

interface ActionCtaProps {
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
  dataTestId?: string;
}

export const ActionCtaBase: React.FC<ActionCtaProps> = ({
  href,
  onClick = noop,
  disabled = false,
  dataTestId,
  children,
}) => (
  <Button
    intent="secondary"
    variant="outline"
    onClick={onClick}
    href={href}
    disabled={disabled}
    data-testid={dataTestId}
  >
    {children}
  </Button>
);
