import React from 'react';
import PropTypes from 'prop-types';
import { I18n, translate } from '@fiverr-private/i18n-react';

import SafePureComponent from '../shared/SafePureComponent';
import { defaultImage, smallImage } from './utils';

import './style.scss';

const ProBanner = (props, { pathfinderWrapper }) => {
    const i18nProBanner = 'gig_page_perseus.pro_banner';

    return (
        <div className="pro-banner">
            <div className="text-wrapper">
                <b className="text-body-1">
                    <I18n k={`${i18nProBanner}.title`} />
                </b>
                <p className="subtext">
                    <I18n k={`${i18nProBanner}.subtitle`} />
                </p>
                {/* eslint-disable react/jsx-no-target-blank */}
                <a href={pathfinderWrapper.pathfinder('pro_landing_page')} target="_blank">
                    <I18n k={`${i18nProBanner}.learn_more`} />
                </a>
            </div>
            <picture>
                <source media="(max-width: 599px)" srcSet={smallImage} />
                <img src={defaultImage} alt={translate(`${i18nProBanner}.image_title`)} />
            </picture>
        </div>
    );
};

ProBanner.contextTypes = {
    pathfinderWrapper: PropTypes.object,
};

export { ProBanner };

export default SafePureComponent(ProBanner);
