import { Feature, PackageData } from '../../types/gig/interfaces';
import { EXTRA_FAST_FEATURE, SCRIPT_PROOFREADING_FEATURE } from './constants';
import { calcExtraFastPrice } from './calcExtraFastPrice';
import { calcProofreadingFeaturePrice } from './calcFeaturePrice';

const NAME_TO_CALC_PRICE = {
    [EXTRA_FAST_FEATURE]: calcExtraFastPrice,
    [SCRIPT_PROOFREADING_FEATURE]: calcProofreadingFeaturePrice,
};

export const calcCurrentExtraPrice = (packageData: PackageData, feature: Feature): number => {
    if (feature.name in NAME_TO_CALC_PRICE) {
        return NAME_TO_CALC_PRICE[feature.name](packageData);
    }

    return feature.currentPrice ?? feature.price;
};
