import React from 'react';
import { number, oneOfType, string } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { numberFormat } from '@fiverr-private/localization';
import { UNLIMITED_REVISION } from '../../../utils/packagesUtils/constants';

const NumberedPricingFactor = ({ value }) => (
    <td>
        {value === UNLIMITED_REVISION ? (
            <I18n k="gig_page_perseus.packages.unlimited" />
        ) : (
            numberFormat({ value, options: { fallbackValue: value } })
        )}
    </td>
);

NumberedPricingFactor.propTypes = {
    value: oneOfType([number, string]),
};

export default NumberedPricingFactor;
