import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { atoms } from '@fiverr-private/theme';
import { Rating } from '@fiverr-private/orca';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { getContext } from '@fiverr-private/fiverr_context';
import RatingWrapper from '../../../shared/RatingWrapper';
import { SellerLevelBadge } from '../../../shared/SellerLevelBadge';
import { isInGigBoosting } from '../../../../utils/experiments/isInGigBoosting';

interface RatingLineProps {
  rating: number;
  ratingsCount: number;
}

export const RatingLine: React.FC<RatingLineProps> = ({ rating, ratingsCount }) => {
  const themeOverride = useRatingTheme();
  return (
    <Stack gap="3" alignItems="center" width="fit-content">
      <RatingWrapper
        className={atoms({ fontSize: 'b_md', lineHeight: 'b_md' })}
        rating={rating}
        ratingsCount={ratingsCount}
        themeOverride={themeOverride}
      />
      <SellerLevelBadge withSeparator />
    </Stack>
  );
};

const useRatingTheme = () => {
  const { rollouts } = useGigPageContext();
  const { abTests } = getContext();
  if (!isInGigBoosting(rollouts, abTests)) return undefined;
  // @ts-expect-error No TS definitions come from Orca.Rating component
  return Rating.THEMES.FIVE_STARS;
};
