/**
 * filter out UGC content already translated by seller for further use in translation request
 * @param {object} originalContent the original untranslated UGC content
 * @param {object} translatedContent the translated UGC content (seller translation)
 * @return {object} non-translated UGC content
 */
export const filterOutSellerTranslatedFields = (originalContent = {}, translatedContent = {}) => {
    const finalContent = Object.assign({}, originalContent);

    Object.keys(finalContent).forEach((key) => {
        if (translatedContent[key]) {
            delete finalContent[key];
        }
    });

    return finalContent;
};
