import pathfinder from '@fiverr-private/pathfinder';
import { triggerSignIn } from '@fiverr-private/identification_triggers';
import { metric } from '../metric';
import { TriggerModalOptions } from './interfaces';

/**
 * Triggers the Sign-in modal from the User Session Package using a proper trigger API.
 * It's a stable way to trigger the modal, as it's not direclty dependent on the global `window` object.
 * Fallbacks to redirecting to `login` standalone page if something goes wrong.
 * @param {Partial<TriggerModalOptions>} options
 */
export const triggerSignInModal = async (options: Partial<TriggerModalOptions> = {}) => {
    try {
        await triggerSignIn({
            modalOptions: {
                ...options,
                triggerType: 'blocked_action',
            },
        });
    } catch {
        metric.count('signing_modal.standalone_redirect.modal_missing');

        fallbackRedirect();
    }
};

const fallbackRedirect = () => {
    const route = pathfinder('login');

    redirect(route);
};

/**
 * Redirect to a given url.
 */
export const redirect = (url: string): void => {
    window.location.href = url;
};
