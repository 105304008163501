import React from 'react';

export interface ReportGigButtonWrapperChildrenProps {
    onReportGigItemClick: () => void;
}

export interface ReportGigButtonWrapperProps {
    children: (props: ReportGigButtonWrapperChildrenProps) => React.ReactNode;
}

export interface GigPageNavContextProviderProps {
    ReportGigButtonWrapper: React.FC<ReportGigButtonWrapperProps>;
}

export const GigPageNavContext = React.createContext<GigPageNavContextProviderProps>(
    {} as GigPageNavContextProviderProps
);

export const useGigPageNavContext = () => {
    const context = React.useContext(GigPageNavContext);

    if (context === undefined) {
        throw new Error('GigPageNavContext must be used within a NotableClientsContextProvider');
    }

    return context;
};
