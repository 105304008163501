import React from 'react';
import { Tag } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';

import './style.scss';

const EducationTitle = () => {
    const TRANSLATION_PREFIX = 'gig_page_perseus.voice_over_ai_audition';

    return (
        <div>
            <I18n k={`${TRANSLATION_PREFIX}.education.title`} />
            <Tag className="new-tag" color={Tag.COLORS.BLUE} theme={Tag.THEMES.HOLLOW}>
                <I18n k={`${TRANSLATION_PREFIX}.new`} />
            </Tag>
        </div>
    );
};

export default EducationTitle;
