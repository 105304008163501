import { getContext } from '@fiverr-private/fiverr_context';

/**
 * Checks whether if should redirect to join page.
 * @return {boolean} whether if should show to join form
 */
export const shouldShowSignUpForm = () => {
    const { userId, isMobile } = getContext();

    return !userId && isMobile;
};
