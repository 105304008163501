const DEFAULT_WIDTH = 1200;

class CloudinaryPdf {
    constructor(res) {
        this.res = res;
        this.getPage = this.getPage.bind(this);
        this.getPages = this.getPages.bind(this);
        this.getPageUrl = this.getPageUrl.bind(this);
    }

    getPageUrl({ path, assetId, page, width }) {
        return `${this.res}/${path}/w_${width},q_auto,f_auto,pg_${page}/${assetId}`;
    }

    getPage({ path, assetId, page = 1, width = DEFAULT_WIDTH }) {
        return new Promise((res, rej) => {
            const img = new Image();
            const src = this.getPageUrl({ path, assetId, page, width });
            img.onload = () => res({ index: page - 1, src });
            img.onerror = () => rej({ index: page - 1, src: null });
            img.src = src;
        });
    }

    getBatch({ path, assetId, fromPage, batchSize, width, onPageLoaded }) {
        const requests = [];
        for (let i = 0; i < batchSize; i++) {
            // Handled rejections (with .catch) are passed to .all as resolved :)
            requests[i] = this.getPage({
                path,
                assetId,
                width,
                page: fromPage + i,
            })
                .then((page) => {
                    onPageLoaded && onPageLoaded(page);
                    return page;
                })
                .catch((err) => err);
        }

        return Promise.all(requests);
    }

    async getPages({ path, assetId, batchSize = 1, onPageLoaded = null, onBatchLoaded = null, width = DEFAULT_WIDTH }) {
        let fromPage = 1;
        const pages = [];
        let batch = {};

        while (!batch.isLastBatch) {
            batch = await this.getValidBatchPages({ path, assetId, fromPage, batchSize, width, onPageLoaded });
            batch.pages.forEach((p) => (pages[p.index] = p.src));
            onBatchLoaded && onBatchLoaded(batch);
            fromPage += batchSize;
        }
        if (!pages.length) {
            throw new Error();
        }

        return pages;
    }

    async getValidBatchPages(batchParams) {
        const { fromPage, batchSize } = batchParams;
        const pages = await this.getBatch(batchParams);
        const validPages = pages.filter((p) => p.src);

        return {
            fromPage,
            batchSize,
            pages: validPages,
            isLastBatch: validPages.length !== pages.length,
        };
    }

    static getPartsFromUrl(url) {
        const matches = url.match(/(https:\/\/.*cloudinary\.com)\/(images?\/(upload\/)?)?(([a-z]_\w+[/,])+)?(.+)/),
            idWithExtension = matches ? matches[6] : null,
            idWithoutExtension = idWithExtension ? idWithExtension.replace(/\.\w+$/, '') : null;
        return {
            res: matches[1],
            path: (matches[2] || '').replace(/\/$/, ''),
            transformations: (matches[4] || '').replace(/\/$/, ''),
            assetId: idWithoutExtension,
        };
    }
}

export default CloudinaryPdf;
