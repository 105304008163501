const { fromPairs, uniq, isNil } = require('lodash');
const {
    getShortLanguageCode,
    getBuyerAssumedLanguage,
    getSellerValidatedLanguages,
    DEFAULT_FIVERR_LANGUAGE,
} = require('@fiverr-private/localization');

const isBuyerLanguageDefault = (buyerAssumedLanguage) => buyerAssumedLanguage === DEFAULT_FIVERR_LANGUAGE;

/**
 * Returns is buyer speak the same language as seller speaks
 * @param buyerAssumedLanguage {String} -
 *      The assumed language the buyer speaks, for example: 'en', 'de'
 * @param languagesArr {Array<{name: String, level: Number}>} -
 *      Array of language objects the seller declares he speaks,
 *      for example: [{name:'en', level:4}]
 * @returns {boolean}
 */
const isBuyerSpeakSellerLanguage = (buyerAssumedLanguage, languagesArr) =>
    languagesArr.some((languageObj) => getShortLanguageCode(languageObj.name) === buyerAssumedLanguage);

const shouldShowSellerLanguage = (buyerAssumedLanguage, sellerValidatedLanguages) =>
    !isBuyerLanguageDefault(buyerAssumedLanguage) &&
    isBuyerSpeakSellerLanguage(buyerAssumedLanguage, sellerValidatedLanguages);

/**
 * Returns the seller validated languages with the common language
 * with the buyer as the first item in the returned array
 * @param buyerAssumedLanguage {String} -
 *      The assumed language the buyer speaks, for example: 'en', 'de'
 * @param proficientLanguages {Array<{name: String, level: Number}>} -
 *      Array of language objects the seller declares he speaks,
 *      for example: [{name:'en', level:4}]
 * @param countryCode {String} -
 *      The country (code) the seller comes from,
 *      for example: 'DE', 'US
 * @returns {Array<{name: String, level: Number}>} -
 *      Array of the seller language objects validated by a business logic,
 *      sorted by the common language with the buyer, and by the level (desc)
 */
const getSortedSellerValidatedLanguages = (buyerAssumedLanguage, { proficientLanguages, countryCode }) => {
    const sellerValidatedLanguages = getSellerValidatedLanguages({ proficientLanguages, countryCode });
    const commonLanguageIndex = sellerValidatedLanguages.findIndex(
        (languageObj) => getShortLanguageCode(languageObj.name) === buyerAssumedLanguage
    );
    if (commonLanguageIndex > -1) {
        const [commonLanguage] = sellerValidatedLanguages.splice(commonLanguageIndex, 1);
        sellerValidatedLanguages.unshift(commonLanguage);
    }

    return sellerValidatedLanguages;
};

/**
 * Given buyer params (most probably from the context)
 * and seller params (proficient languages and country),
 * decide what is the assumed buyer language,
 * what are the validated seller and check if they speak the same language.
 * @param buyer {{locale: String, browserLanguage: String, countryCode: String}} -
 *      params regarding the buyer that should come from the context
 * @param seller {{proficientLanguages: Array<{name: String, level: Number}>, countryCode: String}} -
 *      the languages the seller declares he speaks and his country
 * @returns {{
 *      shouldShowSellerLanguage: Boolean,
 *      commonLanguage: {name: String, level: Number},
 *      sellerValidatedLanguages: Array<{name: String, level: Number}>,
 *      buyerAssumedLanguage: string, sellerCountryCode: string,
 *      sellerLanguages: Array<{name: String, level?: Number}>}}
 */
const getSellerLanguageParams = ({ buyer, seller }) => {
    const { locale, browserLanguage, countryCode: buyerCountryCode } = buyer;
    const { proficientLanguages, countryCode: sellerCountryCode } = seller;
    const buyerAssumedLanguage = getBuyerAssumedLanguage({ locale, browserLanguage, countryCode: buyerCountryCode });
    const sellerValidatedLanguages = getSortedSellerValidatedLanguages(buyerAssumedLanguage, {
        proficientLanguages,
        countryCode: sellerCountryCode,
    });
    const showSellerLanguage = shouldShowSellerLanguage(buyerAssumedLanguage, sellerValidatedLanguages);
    const commonLanguage = isBuyerSpeakSellerLanguage(buyerAssumedLanguage, sellerValidatedLanguages)
        ? sellerValidatedLanguages[0]
        : null;
    const sellerLanguages = getSellerLanguages({ commonLanguage, proficientLanguages });

    return {
        buyerAssumedLanguage,
        sellerValidatedLanguages,
        sellerCountryCode,
        shouldShowSellerLanguage: showSellerLanguage,
        commonLanguage,
        sellerLanguages,
    };
};

/**
 * @desc make an array of languages data objects consisting of "name" (string, for example 'de') and speaking level as 'level' (number, 0-4) if possible
 * from common language and seller proficient languages
 * with next order: common language, other languages.
 *
 * If common language is also included to proficientLanguages, then only first entry included and duplicates should be removed.
 *      for example:
 *       input: commonLanguage = {name:'en', level:4}, proficientLanguages = [{name:'en', level:4}, {name:'de', level:3}]
 *       output: [{name:'en', level:4}, {name:'de', level:3}]
 *
 * @param commonLanguage {name: String, level: Number}, -
 *      array of language objects the seller declares he speaks,
 *      for example: [{name:'en', level:4}]
 * @param proficientLanguages {Array<{name: String, level: Number}>} -
 *      array of language objects the seller declares he speaks,
 *      for example: [{name:'en', level:4}]
 * @returns {Array<{name: String, level?: Number}>} - array of languages objects
 *      for example: [{name:'de'}, {name:'en', level:4}]
 */

const getSellerLanguages = ({ commonLanguage, proficientLanguages = [] }) => {
    const languageLevels = fromPairs(proficientLanguages.map(({ name, level }) => [name, level]));
    const proficientLanguagesByLevelDesc = proficientLanguages.sort(
        (currentLanguage, nextLanguage) => nextLanguage.level - currentLanguage.level
    );

    const languagesByPriority = uniq([
        ...(isNil(commonLanguage) ? [] : [commonLanguage.name]),
        ...proficientLanguagesByLevelDesc.map(({ name }) => name),
    ]);

    return languagesByPriority.map((languageCode) => ({ name: languageCode, level: languageLevels[languageCode] }));
};

module.exports = {
    shouldShowSellerLanguage,
    getSellerLanguageParams,
    getSellerLanguages,
};
