import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { QuestionSolidIcon } from '@fiverr-private/icons';
import { Tooltip } from '@fiverr-private/tooltip';
import { theme } from '@fiverr-private/theme';
import { Text } from '@fiverr-private/typography';

interface DiscountTooltipProps {
  discountPercentage: number;
}

export const DiscountTooltip: React.FC<DiscountTooltipProps> = ({ discountPercentage }) => (
  <Tooltip
    position="top"
    content={
      <Text size={theme.typographySizes.b_sm} fontWeight="normal" color="white">
        <I18n k="gig_page_perseus.packages.price_with_promotion_tooltip" params={{ percentage: discountPercentage }} />
      </Text>
    }
  >
    <QuestionSolidIcon size={12} color={theme.colors.grey_900} />
  </Tooltip>
);
