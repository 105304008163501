export const buildCategoryUrl = ({ categorySlug, pathfinderWrapper, absolute = false }) =>
    pathfinderWrapper.pathfinder(
        'short_category',
        { id: categorySlug },
        { query: { source: 'gig_category_link' }, absolute }
    );

export const buildSubCategoryUrl = ({ categorySlug, subCategorySlug, pathfinderWrapper, absolute = false }) =>
    pathfinderWrapper.pathfinder(
        'short_category_sub_category',
        {
            category_id: categorySlug,
            id: subCategorySlug,
        },
        { query: { source: 'gig_sub_category_link' }, absolute }
    );

export const buildNestedSubCategoryUrl = ({
    categorySlug,
    subCategorySlug,
    nestedSubCategorySlug,
    pathfinderWrapper,
    absolute = false,
}) =>
    pathfinderWrapper.pathfinder(
        'short_category_nested_sub_category',
        {
            category_id: categorySlug,
            id: subCategorySlug,
            nested_sub_category_id: nestedSubCategorySlug,
        },
        { query: { source: 'gig_nested_sub_category_link' }, absolute }
    );
