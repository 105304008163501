import { BigQueryEnrichmentData } from '@fiverr-private/gig_page_context';

const getBaseParams = (eventName: string, enrichmentData: BigQueryEnrichmentData) => ({
    type: eventName,
    entity: 'client',
    user: { id: enrichmentData.userId },
    buyer: { id: enrichmentData.userId },
    seller: { id: enrichmentData.sellerId },
    gig: { id: enrichmentData.gigId },
    page: {
        name: enrichmentData.pageName,
        ctx_id: enrichmentData.pageCtxId,
    },
    platform: enrichmentData.platform,
    uid: enrichmentData.userGuid,
});

export const getBaseImpressionParams = (eventName: string, enrichmentData: BigQueryEnrichmentData) => ({
    ...getBaseParams(eventName, enrichmentData),
    group: 'user_impressions',
    action: { type: 'impression' },
});

export const getBaseActionsParams = (eventName: string, enrichmentData: BigQueryEnrichmentData) => ({
    ...getBaseParams(eventName, enrichmentData),
    group: 'user_actions',
    action: { type: 'click' },
});
