import React from 'react';
import { bool, shape, string, object } from 'prop-types';
import { isEmpty } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { I18n, translate } from '@fiverr-private/i18n-react';
import SafePureComponent from '../shared/SafePureComponent';
import { shouldRenderCustomOrder } from '../../common/customOrder';
import { useExperiments } from '../../hooks/useExperiments';
import { useRecentInteractions } from '../../hooks/useRecentInteractions';
import { BQ_SOURCE_NAME, CONTACT_OPTIONS_TYPE, MP_SOURCE_COMPONENT } from './constants';
import { getContactOptionsType } from './utils';
import ContactSellerOptions from './ContactSellerOptions';
import ContactSellerBtn from './ContactSellerBtn';
import CustomOrderRequestBtn from './CustomOrderRequestBtn';
import { useContactButtonImpression } from './useContactButtonImpression';

import './style.scss';

/** @type {React.FC<{style: React.CSSProperties, customOrder: {currentUserStatus: string, allowCustomOrders: boolean}}>} */
const ContactSeller = ({ style, customOrder }) => {
    const { userId } = getContext();
    const { general, seller, logger, consultationData } = useGigPageContext();
    const { inAIBriefTest } = useExperiments();

    const { isAgency } = seller;
    const contactSellerTitle = isAgency
        ? `gig_page_perseus.contact_seller.title_agency`
        : `gig_page_perseus.contact_seller.title`;

    const customOrderEligible = shouldRenderCustomOrder({
        userId,
        customOrder,
        general,
    });
    const consultationEligible = !isEmpty(consultationData);

    const contactOptionsType = getContactOptionsType(customOrderEligible, consultationEligible, inAIBriefTest);

    const { hadRecentInteractions } = useRecentInteractions();

    const observableRef = useContactButtonImpression({ elementType: contactOptionsType, hadRecentInteractions });

    let component;

    switch (contactOptionsType) {
        case CONTACT_OPTIONS_TYPE.DROPDOWN:
            component = <ContactSellerOptions customOrderEligible={customOrderEligible} />;
            break;
        case CONTACT_OPTIONS_TYPE.GET_A_QUOTE_ONLY:
            component = <CustomOrderRequestBtn />;
            break;
        case CONTACT_OPTIONS_TYPE.CONTACT_SELLER_ONLY:
            component = (
                <ContactSellerBtn
                    mixpanelSourceComponent={MP_SOURCE_COMPONENT}
                    bqSourceName={BQ_SOURCE_NAME}
                    triggerId="Contact Seller"
                    triggerCopy={translate(contactSellerTitle)}
                    triggerPlacement="CTA right"
                >
                    <I18n k={contactSellerTitle} />
                </ContactSellerBtn>
            );
            break;
        case CONTACT_OPTIONS_TYPE.NONE:
            component = null;
            break;
        default:
            logger.error('Floating contact seller experiment error: No contact seller options matched');
            component = null;
    }

    if (!component) {
        return null;
    }

    return (
        <div className="contact-seller" style={style} ref={observableRef}>
            {component}
        </div>
    );
};

ContactSeller.propTypes = {
    style: object,
    customOrder: shape({
        currentUserStatus: string,
        allowCustomOrders: bool,
    }),
};

ContactSeller.defaultProps = {
    customOrder: {
        allowCustomOrders: true,
    },
};

export { ContactSeller };

/** @type {typeof ContactSeller} */
export default SafePureComponent(ContactSeller);
