import { getContext } from '@fiverr-private/fiverr_context';
import { ZEBRAS_CREATE_AI_BRIEF_ON_GIG_PAGE_EXPERIMENT } from '../utils/experiments/experimentsEnum';

const EXPERIMENT_GROUP = 2;
const SUPPORTED_LANGUAGES = ['en-US'];

export const useExperiments = () => {
    const { abTests, locale } = getContext();

    const isUserLanguageSupported = SUPPORTED_LANGUAGES.includes(locale);

    return {
        inAIBriefTest:
            isUserLanguageSupported && abTests[ZEBRAS_CREATE_AI_BRIEF_ON_GIG_PAGE_EXPERIMENT] === EXPERIMENT_GROUP,
    };
};
