import React from 'react';
import { Container, Divider, Stack } from '@fiverr-private/layout_components';

interface BadgeWrapperProps {
  withSeparator?: boolean;
  children: React.ReactNode;
}

export const BadgeWrapper = ({ withSeparator = false, children }: BadgeWrapperProps) => {
  if (!withSeparator) return <>{children}</>;
  return (
    <Stack>
      <Container>
        <Divider direction="vertical" />
      </Container>
      {children}
    </Stack>
  );
};
