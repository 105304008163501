import React from 'react';
import { SellerLevelOneBadge, SellerLevelTwoBadge, TopRatedBadge } from '@fiverr-private/badges';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ROLLOUTS } from '../../../types/rollouts';
import { BadgeWrapper } from './BadgeWrapper';

interface SellerLevelBadgeProps {
  withSeparator?: boolean;
}

export const SellerLevelBadge = ({ withSeparator = false }: SellerLevelBadgeProps) => {
  const {
    seller: { achievement = -1 },
    rollouts,
  } = useGigPageContext();

  const inNewSellerLevelBadgesRollout = rollouts[ROLLOUTS.QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION];
  if (inNewSellerLevelBadgesRollout) {
    if (achievement === 1) {
      return (
        <BadgeWrapper withSeparator={withSeparator}>
          <SellerLevelOneBadge />
        </BadgeWrapper>
      );
    }

    if (achievement === 2) {
      return (
        <BadgeWrapper withSeparator={withSeparator}>
          <SellerLevelTwoBadge />
        </BadgeWrapper>
      );
    }
  }

  if (achievement === 5) {
    return (
      <BadgeWrapper withSeparator={withSeparator}>
        <TopRatedBadge />
      </BadgeWrapper>
    );
  }

  return null;
};
