import React, { useEffect } from 'react';
import { object, func } from 'prop-types';
import { Icon, Tag, Tooltip } from '@fiverr-private/fit';
import { s_question_circle } from '@fiverr-private/icons';
import { EducationTooltip } from '@fiverr-private/orca';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import SafePureComponent from '../shared/SafePureComponent';
import WithImpressions from '../shared/withImpressions';
import EducationTitle from './EducationTitle';
import SamplingSection from './SamplingSection';
import './style.scss';

const MAIN_CLASSNAME = 'voice-over-ai-audition';
const TITLE_CLASSNAME = 'title-wrapper';

const VoiceOverAiAudition = ({ voiceOverAiAudition, impressionRef, collectImpressions }, { logger }) => {
    const { pathParameters } = getContext();
    const { username } = pathParameters;
    const TRANSLATION_PREFIX = 'gig_page_perseus.voice_over_ai_audition';

    useEffect(() => {
        collectImpressions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!voiceOverAiAudition) {
        logger.error('Failed to render VoiceOverAiAudition - missing voiceOverAiAudition payload');
        return null;
    }

    return (
        <div className={MAIN_CLASSNAME} ref={impressionRef}>
            <div className={TITLE_CLASSNAME}>
                <span className="title">
                    <I18n k={`${TRANSLATION_PREFIX}.main.title`} />
                </span>
                <Tooltip
                    containerClassName="voice-over-ai-tooltip-container"
                    boxContentClassName="voice-over-ai-tooltip-box-content"
                    content={<I18n k={`${TRANSLATION_PREFIX}.tooltip`} params={{ seller_username: username }} />}
                >
                    <Icon className="info-icon">{s_question_circle}</Icon>
                </Tooltip>
                <EducationTooltip
                    boxContentClassName="ai-audition-education-box"
                    title={<EducationTitle />}
                    body={<I18n k={`${TRANSLATION_PREFIX}.education.body`} />}
                    closeTitle={<I18n k={`${TRANSLATION_PREFIX}.education.close`} />}
                    tooltipStorageKey={'voice_over_ai_audition_education'}
                    position={EducationTooltip.POSITIONS.RIGHT}
                >
                    <Tag color={Tag.COLORS.BLUE} className="beta-tag">
                        <I18n k={`${TRANSLATION_PREFIX}.beta`} />
                    </Tag>
                </EducationTooltip>
            </div>
            <p className="text">
                {<I18n k={`${TRANSLATION_PREFIX}.main.body`} params={{ seller_username: username }} />}
            </p>

            <SamplingSection voiceOverAiAudition={voiceOverAiAudition} />
        </div>
    );
};

const impressionConfig = {
    DOMSelector: `.${MAIN_CLASSNAME} .${TITLE_CLASSNAME}`,
    eventKey: 'ai_audition_component_is_shown',
};

VoiceOverAiAudition.propTypes = {
    voiceOverAiAudition: object,
    impressionRef: object,
    collectImpressions: func,
};

VoiceOverAiAudition.contextTypes = {
    logger: object,
};

export { VoiceOverAiAudition };
export default SafePureComponent(WithImpressions(VoiceOverAiAudition, impressionConfig));
