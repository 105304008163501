import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { OverviewRating, type OverviewRatingProps } from './OverviewRating';

type OverviewRatingResponsiveProps = Omit<OverviewRatingProps, 'withFiveStars' | 'withWordReviews'>;
export function OverviewRatingResponsive(props: OverviewRatingResponsiveProps) {
  return (
    <>
      <Container hidden={{ sm: true }}>
        <OverviewRating withWordReviews {...props} />
      </Container>
      <Container hidden={{ default: true, sm: false }}>
        <OverviewRating withWordReviews withFiveStars {...props} />
      </Container>
    </>
  );
}
