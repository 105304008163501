import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { r_chevron_down } from '@fiverr-private/fit/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { toggleList } from '../../../../../actions/packagesTabs';
import { DEFAULT_TAB_ITEM_ID } from '../../../../../utils/packagesUtils/constants';

import './style.scss';

const Header = ({ name, collapsed, isCollapsible, onToggle, id }) => {
    const headerClass = classNames('collapsable-header', { collapsed }, { 'hide-collapse-toggle': !isCollapsible });

    const onClick = isCollapsible ? onToggle : null;

    return (
        <h4 className={headerClass} onClick={onClick}>
            {id === DEFAULT_TAB_ITEM_ID ? <I18n k={name} /> : name}
            <Icon className="collapsable-icon">{r_chevron_down}</Icon>
        </h4>
    );
};

Header.defaultTypes = {
    isCollapsible: true,
    onToggle: () => true,
};

Header.propTypes = {
    name: PropTypes.string,
    collapsed: PropTypes.bool,
    isCollapsible: PropTypes.bool,
    id: PropTypes.number,
    onToggle: PropTypes.func,
};

const mapDispatchToProps = (dispatch, { id }) => ({
    onToggle: () => {
        dispatch(toggleList(id));
    },
});

export default connect(null, mapDispatchToProps)(Header);
