import { useState, useEffect } from 'react';

type UseFoldable = (options: { isFoldable: boolean; foldLimit: number; itemsCount: number }) => {
    supportsFolding: boolean;
    isFolded: boolean;
    setIsFolded: (isFolded: boolean) => void;
};

export const useFoldable: UseFoldable = ({ isFoldable, foldLimit, itemsCount }) => {
    const [isFolded, setIsFolded] = useState(false);

    useEffect(() => {
        setIsFolded(isFoldable && itemsCount > foldLimit);
    }, [foldLimit, isFoldable, itemsCount]);

    const shouldShowFoldCta = isFoldable && itemsCount > foldLimit;
    const shouldApplyFold = isFoldable && isFolded;

    return {
        supportsFolding: shouldShowFoldCta,
        isFolded: shouldApplyFold,
        setIsFolded,
    };
};
