import React from 'react';
import { Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';

interface OrdersInQueueProps {
  count: number;
}

export function OrdersInQueue({ count }: OrdersInQueueProps) {
  return (
    <Text color="bodySecondary">
      <I18n k="gig_page_perseus.overview.orders_in_queue_single_plural" params={{ count }} />
    </Text>
  );
}
