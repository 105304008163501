import React from 'react';
import { PencilIcon } from '@fiverr-private/visuals';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { I18n } from '@fiverr-private/i18n-react';
import { ActionCtaBase } from './ActionCtaBase';

export const EditGigCta: React.FC = () => {
  const { pathParameters = {} } = getContext();
  const { username, slug } = pathParameters;
  const { pathfinderWrapper } = useGigPageContext();

  const editGigLink = pathfinderWrapper.pathfinder('edit_user_manage_gig', {
    user_id: username,
    id: slug,
  });

  return (
    <ActionCtaBase href={editGigLink}>
      <PencilIcon />
      <I18n k="gig_page_nav.action_ctas.edit_gig.cta" />
    </ActionCtaBase>
  );
};
