import React from 'react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { isNonExperientialGigTheme } from '../../../utils/theme/isNonExperientialGigTheme';
import { SellerProfileExperiential } from './SellerProfileExperiential';
import type { SellerProfileProps } from './SellerProfileProps';

export const SellerProfileWrapper: React.FC<SellerProfileProps> = (props) => {
  const { gigTheme } = useGigPageContext();
  const isExperientialGig = !isNonExperientialGigTheme(gigTheme);
  if (!isExperientialGig) return null;
  return <SellerProfileExperiential {...props} />;
};
