import React from 'react';
import { number, object } from 'prop-types';
import { isValidCalculator } from '../../../../utils/calculator';
import { getCalculatorFormattedLabel } from '../../../../utils/translation/pricingFactors/label';
import PackageCalculator from '../../PackageCalculator';

import './style.scss';

const PackageContentCalculator = ({ calculator, packageId }, { general: { subCategoryId } }) => {
    if (!isValidCalculator(calculator)) {
        return null;
    }

    const { name, value } = calculator;

    return (
        <div className="calculator">
            <label>{getCalculatorFormattedLabel({ name, value, subCategoryId })}</label>
            <PackageCalculator packageId={packageId} {...calculator} />
        </div>
    );
};

PackageContentCalculator.propTypes = {
    packageId: number,
    calculator: object,
};

PackageContentCalculator.contextTypes = {
    general: object,
};

export default PackageContentCalculator;
