import React from 'react';
import { SellerRating, type SellerRatingProps } from '@fiverr-private/gig_seller_info';
import { useOnReviewsClick } from '../../../../../../hooks/useOnReviewsClick';

export type OverviewRatingProps = Omit<SellerRatingProps, 'onReviewsClick'>;

export function OverviewRating(props: OverviewRatingProps) {
  const onReviewsClick = useOnReviewsClick();
  return <SellerRating onReviewsClick={onReviewsClick} {...props} />;
}
