import { isEmpty } from 'lodash';

/**
 * Get the current gig's milestones with the translated UGC content
 * @param {object} workProcess the original work process data
 * @param {object} currentWorkProcessUGC The current ugc of work process
 * @return {Array<workProcessMilestonesType>} milestones array with translated content
 */
export const getUgcTranslatedMilestones = ({ workProcess, currentWorkProcessUGC }) => {
    if ([workProcess, currentWorkProcessUGC].some(isEmpty)) {
        return [];
    }

    return currentWorkProcessUGC.milestones.map(
        ({ title: milestoneTitle, description: milestoneDescription, steps }, milestonesIndex) => {
            const currentMilestone = workProcess.milestones[milestonesIndex];
            const processSteps = isEmpty(steps)
                ? []
                : steps.map(({ title: stepsTitle, description: stepsDescription }, stepsIndex) => ({
                      ...currentMilestone.steps[stepsIndex],
                      title: stepsTitle,
                      description: stepsDescription,
                  }));

            return {
                ...currentMilestone,
                title: milestoneTitle,
                description: milestoneDescription,
                steps: processSteps,
            };
        }
    );
};
