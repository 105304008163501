import React from 'react';
import { isEmpty } from 'lodash';
import { hasTranslation, I18n, translate } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { buildNestedSubCategoryNameKey, buildSubCategoryNameKey } from '../../utils/translation/categories';
import SafePureComponent from '../shared/SafePureComponent';

import './style.scss';

const buildMoreServicesTitle = (subCategoryNameKey: string, isAgency: boolean) => {
  const isValidSubCategory = hasTranslation(subCategoryNameKey);
  const subCategoryName = isValidSubCategory ? translate(subCategoryNameKey) : '';
  const titleParams = { sub_category_name: subCategoryName };

  return isAgency
    ? translate('gig_page_perseus.more_services.title_agency', { params: titleParams })
    : translate('gig_page_perseus.more_services.title', { params: titleParams });
};

const _MoreServices = () => {
  const { general, seller, currencyConverter, moreServices, pathfinderWrapper, sellerUsername } = useGigPageContext();
  const { subCategoryId, isStudio } = general;
  const { isAgency } = seller;
  const subCategoryNameKey = buildSubCategoryNameKey(subCategoryId);
  const title = buildMoreServicesTitle(subCategoryNameKey, isAgency);

  if (!moreServices || isEmpty(moreServices?.otherNestedSubCategoryGigs) || isStudio) {
    return null;
  }

  const { otherNestedSubCategoryGigs } = moreServices;

  return (
    <div className="more-services">
      <header>
        <h2 className="more-services-title">{title}</h2>
      </header>
      <ul>
        {otherNestedSubCategoryGigs.map(
          ({ serviceSubcategoryId, serviceNestedSubcategoryId, serviceSlug, startPrice }, index) => {
            const url = pathfinderWrapper.pathfinder(
              'gig_page',
              { username: sellerUsername, slug: serviceSlug },
              { query: { from_service_type_box: 'true' } }
            );
            const translatedService = serviceNestedSubcategoryId
              ? translate(buildNestedSubCategoryNameKey(serviceSubcategoryId, serviceNestedSubcategoryId))
              : translate(subCategoryNameKey);

            return (
              <li key={index} className="box-shadow-z1">
                <a href={url}>
                  <p>{translatedService}</p>
                  <I18n k={'gig_page_perseus.more_services.starting_at'} /> {currencyConverter.convert(startPrice)}
                </a>
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

export const MoreServices = SafePureComponent(_MoreServices);
