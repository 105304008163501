import React, { useState, useEffect } from 'react';
import { object } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { Alert } from '@fiverr-private/orca';
import { translate } from '@fiverr-private/i18n-react';
import { getSellerLanguageNativeName } from '@fiverr-private/localization';
import { BQ_LOCAL_CATALOG_HUMAN_TRANSLATION_PREVIEW_VIEWED } from '../../../utils/bigQueryEvents';
import { LOCAL_CATALOG_PREVIEW_STATES } from '../../GigPage/constants';

const TranslationLayerPreviewBanner = (_, { general, biEvents, gigSellerTranslation }) => {
    const { queryParameters } = getContext();
    const { preview_state, forced_locale } = queryParameters;
    const { sellerId } = general;
    const { gigTitle, description } = gigSellerTranslation;
    useEffect(() => {
        biEvents.sendBigQueryEvent({
            eventName: BQ_LOCAL_CATALOG_HUMAN_TRANSLATION_PREVIEW_VIEWED,
            params: {
                sellerId,
                forcedLocale: forced_locale,
                status: preview_state,
                title: gigTitle,
                description,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [shouldShowBanner, setShouldShowBanner] = useState(true);
    if (!shouldShowBanner) {
        return null;
    }
    if (preview_state === LOCAL_CATALOG_PREVIEW_STATES.BEFORE_PUBLISH) {
        return (
            <Alert
                text={translate('gig_page_perseus.translation_layer_preview.before_publish.content')}
                theme={Alert.THEMES.WARNING}
                className="m-b-16"
            />
        );
    }
    if (preview_state === LOCAL_CATALOG_PREVIEW_STATES.READY_TRANSLATION_LAYER) {
        return (
            <Alert
                title={translate('gig_page_perseus.translation_layer_preview.ready_translation_layer.title')}
                text={translate('gig_page_perseus.translation_layer_preview.ready_translation_layer.description', {
                    params: { language: getSellerLanguageNativeName(forced_locale) },
                })}
                theme={Alert.THEMES.INFORMATION}
                actionText={translate('gig_page_perseus.translation_layer_preview.ready_translation_layer.cta')}
                onActionClick={() => setShouldShowBanner(false)}
                className="m-b-16"
            />
        );
    }

    return null;
};

TranslationLayerPreviewBanner.contextTypes = {
    general: object,
    biEvents: object,
    gigSellerTranslation: object,
};

export default TranslationLayerPreviewBanner;
