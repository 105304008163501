import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Title } from './Title';
import { Sparkle } from './Sparkle';
import { BenefitBullets } from './BenefitBullets/BenefitBullets';

export const CompactContent = () => (
  <Stack hidden={{ sm: true }} direction="column" gap="3" paddingY="4">
    <Stack justifyContent="spaceBetween" gap="6">
      <Title />
      <Sparkle />
    </Stack>
    <BenefitBullets />
  </Stack>
);
