import React, { useEffect, useRef, useState } from 'react';
import { bool, func, object, oneOf } from 'prop-types';
import { isNil } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { isLoggedInUser } from '../../../utils/isLoggedInUser';
import { SUPPORTED_ACTIONS } from './constants';

const PAGE_NAME = 'gig_page';
const BASE_KEY = 'gig_page_perseus.user_activation_modal';

const UserActivationModalWrapper = (
    { renderAndOpen, onClose, action = SUPPORTED_ACTIONS.CONTACT_SELLER },
    { logger }
) => {
    const userActivationPackageRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);

    useEffect(() => {
        const lazyLoadModal = async () => {
            try {
                userActivationPackageRef.current = await import(
                    /* webpackChunkName: 'UserActivationModal' */ '@fiverr-private/user_activation'
                );
            } catch (error) {
                logger.error(error, { description: 'Failed to lazy load UserActivationModal modal.' });
            }
        };

        const render = async () => {
            if (!isNil(userActivationPackageRef.current)) {
                openModal();
                return;
            }

            await lazyLoadModal();
            openModal();
        };

        if (renderAndOpen && isLoggedInUser()) {
            render();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [renderAndOpen]);

    const handleClose = () => {
        onClose();
        setIsOpen(false);
    };

    const resolveDescription = () => {
        const { pathParameters } = getContext();
        const { username: sellerUsername } = pathParameters;

        return <I18n k={`${BASE_KEY}.${action}.description`} params={{ sellerUsername }} />;
    };

    const modalHasBeenLoaded = !isNil(userActivationPackageRef.current);
    const UserActivationModal = modalHasBeenLoaded
        ? userActivationPackageRef.current.UserActivationModal
        : EmptyComponent;

    return (
        <>
            {isLoggedInUser() && (
                <UserActivationModal
                    isOpen={isOpen}
                    onClose={handleClose}
                    pageName={PAGE_NAME}
                    description={resolveDescription()}
                />
            )}
        </>
    );
};

const EmptyComponent = () => null;

UserActivationModalWrapper.propTypes = {
    renderAndOpen: bool,
    onClose: func,
    action: oneOf(Object.values(SUPPORTED_ACTIONS)),
};

UserActivationModalWrapper.contextTypes = {
    logger: object,
};

export default UserActivationModalWrapper;
