import React, { useState, useEffect } from 'react';
import { object, bool, func, string, number } from 'prop-types';
import { isEmpty, noop } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { LazyComponent } from '@fiverr-private/orca';
import { fetchInitialData } from '@fiverr-private/send_message';
import { PAGE_NAME } from '../../../../../../utils/bigQueryEventsData';
import { MP_REQUEST_TO_ORDER_MESSAGE_SENT } from '../../../../../../utils/mixPanelEvents';
import { PACKAGE_ID_TO_TYPE } from '../constants';

const RequestToOrderPopup = (
    { shouldRender = false, bqSourceName, onClose = noop, packageId },
    { logger, general = {}, biEvents }
) => {
    const [showModal, setShowModal] = useState(false);
    const [initialData, setInitialData] = useState();

    useEffect(() => {
        const loadModalData = async () => {
            try {
                const initialData = await fetchModalData();

                setInitialData(initialData);

                openModal();
            } catch (error) {
                reportModalInitializationError(error);
            }
        };

        shouldRender && loadModalData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldRender]);

    const reportLazyLoadError = (error) => {
        logger.error(error, {
            description: 'Failed to lazy load @fiverr-private/send_message package, request to order entry.',
        });
    };

    const reportModalInitializationError = (error) => {
        logger.error(error, {
            description: 'Failed to initialize the Request to order popup',
        });
    };

    const getSellerUsername = () => {
        const { pathParameters } = getContext();
        const { username: sellerUsername } = pathParameters;

        return sellerUsername;
    };

    const fetchModalData = async () => {
        const sellerUsername = getSellerUsername();
        const data = await fetchInitialData(sellerUsername);

        setInitialData(data);

        return data;
    };

    const openModal = () => {
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);

        onClose();
    };

    const getModalProps = () => {
        const { sellerId, gigId } = general;
        const recipientInfo = initialData?.recipient || {};
        const show = showModal && !isEmpty(initialData);
        const defaultProps = {
            onClose: handleModalClose,
            onSubmitSuccess: () =>
                biEvents.sendMixPanelEvent({
                    eventName: MP_REQUEST_TO_ORDER_MESSAGE_SENT,
                }),
            relatedGigId: gigId,
            recipientInfo: {
                ...recipientInfo,
                sellerId,
            },
            attachmentsUploadConfig: initialData?.attachmentsUploadConfig,
            source: `${PAGE_NAME}_${bqSourceName}`,
            isOpen: show,
            pageName: PAGE_NAME,
            relatedGigPackageType: PACKAGE_ID_TO_TYPE[packageId],
        };

        return { ...defaultProps, show };
    };

    const lazyImport = () =>
        import(
            /* webpackChunkName: 'RequestToOrderModal' */ '@fiverr-private/send_message/src/ContactSellerModal/entries/RequestToOrderModalEntry'
        );

    return (
        <LazyComponent
            lazyImport={lazyImport}
            fallback={null}
            shouldImport={shouldRender}
            componentProps={getModalProps()}
            onError={reportLazyLoadError}
        />
    );
};

RequestToOrderPopup.propTypes = {
    shouldRender: bool,
    onClose: func,
    bqSourceName: string,
    packageId: number,
};

RequestToOrderPopup.contextTypes = {
    general: object,
    biEvents: object,
    logger: object,
};

export default RequestToOrderPopup;
