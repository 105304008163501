module.exports = {
    DRAFT: 'draft',
    ONHOLD: 'onhold',
    SUSPENDED: 'suspended',
    APPROVED: 'approved',
    DELETED: 'deleted',
    DENIED: 'denied',
    BLOCKED: 'blocked',
    SUSPIC: 'suspic',
    REJECTED: 'rejected',
    PENDING: 'pending',
    ATTENTION: 'attention',
};
