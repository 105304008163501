import React, { useEffect, useState } from 'react';
import { shape, string, number, object } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Icon } from '@fiverr-private/fit';
import { r_times, s_tag } from '@fiverr-private/fit/icons';
import { numberFormat, NUMBER_STYLES } from '@fiverr-private/localization';
import SafePureComponent from '../shared/SafePureComponent';
import { MP_DISMISS_BOMBA_COUPON, MP_OPEN_COUPON_ARTICLE, MP_SEEN_BOMBA_COUPON } from '../../utils/mixPanelEvents';
import { BQ_OPEN_COUPON_ARTICLE, BQ_SEEN_BOMBA_COUPON } from '../../utils/bigQueryEvents';
import { GROSS_PERCENT_TYPE } from './constants';

import './style.scss';

const LEARN_MORE_LINK = 'https://www.fiverr.com/support/articles/360017987437-Seller-Coupons';

export const CouponBanner = ({ coupon }, { biEvents }) => {
    const [dismissed, setDismissed] = useState(false);
    const { discountValue, code, id, providerName, discountType, deliveryPackId } = coupon;
    const formattedDiscountValue = numberFormat({
        value: discountValue / 100,
        options: {
            fallbackValue: `${discountValue}%`,
            style: NUMBER_STYLES.PERCENT,
        },
    });

    const discount = discountType === GROSS_PERCENT_TYPE ? formattedDiscountValue : discountValue;
    const translateParams = {
        discount,
        sellerName: providerName,
        code,
    };
    const bigQueryParams = {
        couponId: id,
        discount: discountValue / 100,
        deliveryPackId,
        code,
    };
    const mixpanelParams = {
        Flow: 'Receiving a coupon',
        'Bomba Type': 'Coupons',
    };

    const handleDismiss = () => {
        setDismissed(true);
        biEvents.sendMixPanelEvent({
            eventName: MP_DISMISS_BOMBA_COUPON,
            params: mixpanelParams,
        });
    };

    const handleOpenArticle = () => {
        biEvents.sendMixPanelEvent({
            eventName: MP_OPEN_COUPON_ARTICLE,
            params: {
                ...mixpanelParams,
                'Article Link': LEARN_MORE_LINK,
            },
        });
        biEvents.sendBigQueryEvent({
            eventName: BQ_OPEN_COUPON_ARTICLE,
            params: {
                type: BQ_OPEN_COUPON_ARTICLE,
                ...bigQueryParams,
            },
        });
    };

    useEffect(() => {
        biEvents.sendMixPanelEvent({
            eventName: MP_SEEN_BOMBA_COUPON,
            params: {
                ...mixpanelParams,
                Discount: discount,
            },
        });
        biEvents.sendBigQueryEvent({
            eventName: BQ_SEEN_BOMBA_COUPON,
            params: {
                type: BQ_SEEN_BOMBA_COUPON,
                ...bigQueryParams,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (dismissed) {
        return null;
    }

    return (
        <div className="top-panel-wrap coupon">
            <div className="banner-message">
                <div className="banner-text tbody-6">
                    <Icon className="type-icon">{s_tag}</Icon>
                    <I18n k={'gig_page_perseus.coupon.content'} params={translateParams} />{' '}
                    <a href={LEARN_MORE_LINK} target="_blank" rel="noreferrer" onClick={handleOpenArticle}>
                        <I18n k={'gig_page_perseus.coupon.learn_more'} />
                    </a>
                </div>
                <Icon size={10} className="close-icon" onClick={handleDismiss}>
                    {r_times}
                </Icon>
            </div>
        </div>
    );
};

CouponBanner.contextTypes = {
    biEvents: object,
};

CouponBanner.propTypes = {
    coupon: shape({
        code: string.isRequired,
        id: string.isRequired,
        type: string.isRequired,
        discountValue: number.isRequired,
        discountType: string.isRequired,
        providerName: string.isRequired,
        deliveryPackId: string,
    }),
};

export default SafePureComponent(CouponBanner);
