import React, { PureComponent } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Image extends PureComponent {
    constructor(props) {
        super(props);

        // Bindings
        this.handleLoad = this.handleLoad.bind(this);

        // Initial state
        this.state = {
            // We seed the value of this state key from props.
            // Next time the image receive props, we'll do a manual check.
            // In other words, once mounted, if the componenet hasBeenRequested
            // before, it can't go back to false.
            hasBeenRequested: props.isActive || !props.lazyLoad,
            isLoaded: false,
            type: null,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isActive, lazyLoad, src } = this.props;
        if (nextProps.isActive || !nextProps.lazyLoad) {
            this.setState({ hasBeenRequested: true });
        }
        if (src !== nextProps.src) {
            // When loading a new image...
            this.setState({
                hasBeenRequested: isActive || !lazyLoad,
            });
        }
    }

    handleLoad(e) {
        if (e) {
            const { onLoad, onDimensions } = this.props,
                { naturalWidth: width, naturalHeight: height } = e.target;

            typeof onLoad === 'function' && onLoad(e);
            typeof onDimensions === 'function' && onDimensions({ width, height });

            this.setState({ isLoaded: true });
        }
    }

    render() {
        const { alt, src, placeholderSrc, onClick } = this.props,
            { hasBeenRequested, isLoaded } = this.state,
            classes = classNames({ 'image-loaded': isLoaded });

        const placeholder = placeholderSrc ? (
            <img onClick={onClick} alt={alt} src={placeholderSrc} onLoad={this.handleLoad} className={classes} />
        ) : null;

        return hasBeenRequested ? (
            <img onClick={onClick} alt={alt} src={src} onLoad={this.handleLoad} className={classes} />
        ) : (
            placeholder
        );
    }
}

Image.propTypes = {
    isActive: PropTypes.bool.isRequired,
    src: PropTypes.string.isRequired,
    placeholderSrc: PropTypes.string,
    alt: PropTypes.string,
    onDimensions: PropTypes.func,
    lazyLoad: PropTypes.bool,
    onLoad: PropTypes.func,
    onClick: PropTypes.func,
};

Image.defaultProps = {
    placeholderSrc: null,
    alt: '',
    onDimensions: null,
    lazyLoad: false,
    onClick: _.noop,
};

export default Image;
