import { isEmpty } from 'lodash';
import { getSelectedPackageId } from '../packages';
import { getTranslatedMilestones } from '../ugc';

const workProcessReducer = ({ active_pay, milestones = [] } = {}) => {
    const initialState = {
        activePay: !!active_pay,
        milestones,
    };

    return (state = initialState, action = {}) => {
        switch (action.type) {
            default:
                return state;
        }
    };
};

// Selectors

/**
 * Check if current gig has milestone payment enabled
 * @param {object} state redux state
 * @return {boolean} is milestone payment enabled
 */
export const isActiveMilestonePay = (state) => {
    // This can be accessed with raw data/reducer data of work process
    // This special handling will be removed once reducer is removed
    const {
        workProcess: { active_pay, activePay = false },
    } = state;

    return active_pay || activePay;
};

/**
 * Check if current gig has milestones
 * @param {object} state redux state
 * @return {bool} is gig has defined milestones
 */
export const workProcessHasMilestones = (state) => !isEmpty(selectWorkProcessMilestonesForCurrentPackage(state));

/**
 * Check if current gig can be purchased with milestones
 * @param {object} state redux state
 * @return {bool} can purchase gig with milestones
 */
export const isGigWithActiveWorkProcess = (state) => isActiveMilestonePay(state) && workProcessHasMilestones(state);

/**
 * Get the milestones for current package
 * @param {object} state redux state
 * @return {array} array of milestones for current package, with UGC translation
 */
export const selectWorkProcessMilestonesForCurrentPackage = (state) => {
    const milestones = getTranslatedMilestones(state);
    const selectedPackageId = getSelectedPackageId(state);
    const currentPackageSelector = ({ package_id }) => package_id === selectedPackageId;

    return milestones
        .map(({ packages = [], ...rest }) => {
            const [currentPackage] = packages.filter(currentPackageSelector);

            return { ...rest, currentPackage };
        })
        .filter(({ currentPackage }) => !isEmpty(currentPackage));
};

/**
 * Get the total price for milestones for current package
 * @note Doesn't include Extras / Calculators
 * @param {object} state redux state
 * @return {number} total price for milestones payment
 */
export const selectTotalMilestonesPrice = (state) => {
    const filteredMilestones = selectWorkProcessMilestonesForCurrentPackage(state);

    return filteredMilestones.reduce((prevPrice, milestone) => prevPrice + getMilestonePrice(milestone), 0);
};

/**
 * Get the first milestone for current package
 * @note --Doesn't-- include Extras / Calculators
 * @param {object} state redux state
 * @return {object} first milestone in current package
 */
export const selectFirstMilestone = (state) => {
    const [firstMilestone] = selectWorkProcessMilestonesForCurrentPackage(state);
    return firstMilestone;
};

/**
 * Get the first milestone's price for current package (in cents)
 * @param {object} state redux state
 * @return {number} first milestone's price in current package
 */
export const selectFirstMilestonePrice = (state) => getMilestonePrice(selectFirstMilestone(state));

/**
 * Get the first milestone's title for current package
 * @param {object} state redux state
 * @return {string} first milestone's title in current package
 */
export const selectFirstMilestoneTitle = (state) => getMilestoneTitle(selectFirstMilestone(state));

/**
 * Get the first milestone's duration for current package
 * @param {object} state redux state
 * @return {number} first milestone's duration in current package
 */
export const selectFirstMilestoneDuration = (state) => getMilestoneDuration(selectFirstMilestone(state));

/**
 * Get the first milestone's revisions number for current package
 * @param {object} state redux state
 * @return {number} first milestone's revisions number in current package
 */
export const selectFirstMilestoneRevisions = (state) => getMilestoneRevisions(selectFirstMilestone(state));

/**
 * Get the milestone's price for current package (in cents)
 * @param {object} milestone object
 * @return {number} milestone's price in current package
 */
export const getMilestonePrice = ({ currentPackage: { price } }) => price * 100;

/**
 * Get the milestone's title for current package
 * @param {object} milestone object
 * @return {number} milestone's title in current package
 */
export const getMilestoneTitle = ({ title }) => title;

/**
 * Get the milestone's duration for current package
 * @param {object} milestone object
 * @return {number} milestone's duration in current package
 */
export const getMilestoneDuration = ({ currentPackage: { duration } }) => duration;

/**
 * Get the milestone's revisions number for current package
 * @param {object} milestone object
 * @return {number} milestone's revisions number in current package
 */
export const getMilestoneRevisions = ({ currentPackage: { revisions } }) => revisions;

// End of selectors

export default workProcessReducer;
