const { SUB_CATEGORIES } = require('../../types/categories');

const RECURRING_NONE_OPTION = {
    id: '0',
    duration: 0,
    discountPercentage: 0,
};

/**
 * All categories which are tested out for recurring as default option
 * @type {number[]}
 */
const DEFAULT_RECURRING_TESTED_OUT_SUB_CATEGORIES = [
    SUB_CATEGORIES.SOCIAL_MARKETING,
    SUB_CATEGORIES.SEARCH_ENGINE_OPTIMIZATION,
    SUB_CATEGORIES.LOCAL_SEO,
    SUB_CATEGORIES.VIDEO_MARKETING,
    SUB_CATEGORIES.MUSIC_PROMOTION,
    SUB_CATEGORIES.VIRTUAL_ASSISTANT,
    SUB_CATEGORIES.SEARCH_ENGINE_MARKETING,
    SUB_CATEGORIES.SOCIAL_MEDIA_DESIGN,
    SUB_CATEGORIES.SOCIAL_MEDIA_ADVERTISING,
    SUB_CATEGORIES.PODCAST_EDITING,
    SUB_CATEGORIES.SOCIAL_MEDIA_COPY,
    SUB_CATEGORIES.SOCIAL_MEDIA_VIDEOS,
    SUB_CATEGORIES.ECOMMERCE_MARKETING,
    SUB_CATEGORIES.PODCAST_MARKETING,
    SUB_CATEGORIES.ARTICLES_BLOG_POSTS,
    SUB_CATEGORIES.VIDEO_EDITING,
    SUB_CATEGORIES.MIXING_MASTERING,
];

module.exports = {
    RECURRING_NONE_OPTION,
    DEFAULT_RECURRING_TESTED_OUT_SUB_CATEGORIES,
};
