/**
 * Builds BigQuery event info object for machine translation BI reporting.
 *
 * @param {Object} translatedBySeller - Local Catalog Translation Layer model
 */
export const buildTranslationLayerBQProps = (translatedBySeller = {}) => {
    const { attachments = [], gigTitle } = translatedBySeller;
    const imagesCount = attachments.filter(({ slide }) => slide.typeImage).length;
    const includesVideo = attachments.some(({ slide }) => slide.typeVideo);

    return {
        gig: {
            translation_layer: {
                includes_video: includesVideo,
                images_count: imagesCount,
                title: gigTitle,
            },
        },
    };
};
