import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const HAVE_FUTURE_DATA = 3;

interface VideoLoopHookParams {
    videoRef: React.RefObject<HTMLVideoElement>;
    numOfLoops: number;
    videoType: string;
}

export const useVideoLoop = ({ numOfLoops, videoType, videoRef }: VideoLoopHookParams) => {
    // const videoRef = useRef<HTMLVideoElement>(null);
    const playCount = useRef(0);
    const [playTriggered, setPlayTriggered] = useState(false);
    const [videoLoaded, setVideoLoaded] = useState(false);

    const play = useCallback(() => {
        playCount.current++;
        videoRef.current?.play();
    }, [videoRef]);

    const triggerPlay = () => setPlayTriggered(true);

    const onVideoEnded = () => {
        if (playCount.current < numOfLoops) {
            play();
        }
    };

    const onVideoCanPlayThrough = () => setVideoLoaded(true);

    useEffect(() => {
        if (videoRef.current && videoRef.current.readyState >= HAVE_FUTURE_DATA) {
            setVideoLoaded(true);
        }
        if (playTriggered && videoLoaded) {
            play();
        }
    }, [playTriggered, videoLoaded, videoRef, play]);

    const canPlayVideoType = useMemo(() => {
        if (typeof document === 'undefined') {
            return false;
        }

        const video = document.createElement('video');
        return video.canPlayType(videoType) === 'probably';
    }, [videoType]);

    return {
        videoRef,
        playVideo: triggerPlay,
        onVideoEnded,
        onVideoCanPlayThrough,
        videoReady: videoLoaded && canPlayVideoType,
    };
};
