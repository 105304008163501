import { useGigPageContext } from '@fiverr-private/gig_page_context';

export const useContactAvailability = () => {
    const { general } = useGigPageContext();
    const { isOnVacation, isLimitedEdition, gigStatus } = general;
    const statusEligible = gigStatus !== 'onhold' && gigStatus !== 'suspended';
    const isContactAvailable = !isOnVacation && !isLimitedEdition && statusEligible;

    return { isContactAvailable };
};
