import { shape, string, arrayOf, number, bool, oneOfType } from 'prop-types';

const workProcessStep = shape({
    order: number,
    description: string,
    title: string,
    packages: arrayOf(number),
});

const workProcessPackage = shape({
    package_id: number,
    price: number,
    duration: number,
    revisions: number,
});

export const workProcessStepsType = arrayOf(workProcessStep);

const workProcessMilestone = shape({
    order: number,
    title: string,
    description: string,
    currentPackage: workProcessPackage,
    steps: workProcessStepsType,
});

export const workProcessMilestoneType = workProcessMilestone;
export const workProcessMilestonesType = arrayOf(workProcessMilestone);
export const workProcessItemPriceType = oneOfType([bool, number]);
