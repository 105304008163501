import { getContext } from '@fiverr-private/fiverr_context';
import { getVariation } from '../../../../../utils/experiments';
import { HAWKS_CONTINUE_CTA_COPY } from '../../../../../utils/experiments/experimentsEnum';
import { VARIANTS } from '../../../../../types/variants';

const TEST_VARIATION_TO_COPY_KEY = {
    [VARIANTS.A]: 'gig_page_perseus.packages.continue',
    [VARIANTS.B]: 'gig_page_perseus.packages.order_this_service',
    [VARIANTS.C]: 'gig_page_perseus.packages.see_order_options',
};

export const getCheckoutCtaCopyKey = (): string => {
    const { abTests } = getContext();
    const testVariation = getVariation(HAWKS_CONTINUE_CTA_COPY, abTests);

    return TEST_VARIATION_TO_COPY_KEY[testVariation];
};
