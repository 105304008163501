import { localStorage } from '@fiverr-private/futile';

/**
 * The storage key for keeping a flag of whether the user has seen
 * the work process education modal
 * @type {String}
 */
export const SEEN_WP_EDUCATION_LOCAL_STORAGE_KEY = 'seen_work_process_education';

/**
 * Get the local storage key for if seen the work process education modal
 * @return {boolean} true iff the user has seen the work process education modal (According to local storage)
 */
export const hasSeenWorkProcessEducationModal = () => localStorage.get(SEEN_WP_EDUCATION_LOCAL_STORAGE_KEY) === true;

/**
 * Set the local storage key for seen the work process education modal
 */
export const markSeenWorkProcessEducationModal = () => localStorage.set(SEEN_WP_EDUCATION_LOCAL_STORAGE_KEY, true);
