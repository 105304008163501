import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FeatureList from '../FeatureList';
import PackageContentCalculator from '../PackageContentCalculator';
import { isValidCalculator } from '../../../../utils/calculator';
import Header from './Header';

import './style.scss';

const CollapsablePackageItem = ({ packageId, features, calculator, id, name, collapsed }) => {
    const hasFeatures = features.length > 0;
    const isCollapsible = hasFeatures || isValidCalculator(calculator);
    const contentClass = classNames('collapsible-content', { collapsed: isCollapsible && collapsed });

    return (
        <div className="collapsable-package-item">
            <Header name={name} id={id} collapsed={collapsed} isCollapsible={isCollapsible} />
            {isCollapsible && (
                <div className={contentClass}>
                    <FeatureList features={features} />
                    <PackageContentCalculator packageId={packageId} calculator={calculator} />
                </div>
            )}
        </div>
    );
};

CollapsablePackageItem.propTypes = {
    features: PropTypes.array,
    calculator: PropTypes.object,
    packageId: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    collapsed: PropTypes.bool,
};

export default CollapsablePackageItem;
