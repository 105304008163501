import React, { PureComponent } from 'react';
import { bool, func, object, number } from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { s_play_circle } from '@fiverr-private/fit/icons';
import { MP_VIDEO_THUMBNAIL_SIZE } from '../../../../../utils/mixPanelEvents';
import { BQ_CLICKED_GALLERY_VIDEO } from '../../../../../utils/bigQueryEvents';
import Image from '../../components/Image';
import GigReview from '../../GigReview';
import SlideHOC from '../slideHOC';

class VideoSlide extends PureComponent {
    constructor(props) {
        super(props);

        this.showVideoIframe = this.showVideoIframe.bind(this);
        this.renderVideo = this.renderVideo.bind(this);
        this.renderThumbnail = this.renderThumbnail.bind(this);
        this.reportThumbnailSize = this.reportThumbnailSize.bind(this);

        // Initial state
        this.state = {
            showingPlayer: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { flashReview, hideReview, isShowingReview, isActive } = this.props;

        if (!nextProps.isShowingReview && isShowingReview) {
            hideReview();
        }
        if (nextProps.isActive && !isActive) {
            flashReview();
        }
        if (!nextProps.isActive && isActive) {
            this.setState({ showingPlayer: false });
        }
    }

    componentDidUpdate() {
        const { showingPlayer } = this.state;
        if (showingPlayer && this.videoNode && this.videoNode.play) {
            this.videoNode.play();
        }
    }

    reportPlayEvent() {
        const { biEvents } = this.context;
        const { index } = this.props;
        const bigQueryParams = { slideIndex: index };
        biEvents.sendBigQueryEvent({ eventName: BQ_CLICKED_GALLERY_VIDEO, params: bigQueryParams });
    }

    showVideoIframe(e) {
        e && e.preventDefault();
        this.setState({ showingPlayer: true });
        this.reportPlayEvent();
    }

    renderVideo() {
        const { toggleReview } = this.props;

        const {
            slide: { videoSrc, media },
            expanded,
        } = this.props;
        const poster = expanded ? media.original : media.medium;

        return (
            <video
                src={videoSrc}
                poster={poster}
                onClick={toggleReview}
                controls={true}
                ref={(node) => (this.videoNode = node)}
                controlsList="nodownload"
            />
        );
    }

    reportThumbnailSize({ width, height }) {
        const { biEvents } = this.context;
        if (width <= 300 || height <= 300) {
            biEvents.sendMixPanelEvent({
                eventName: MP_VIDEO_THUMBNAIL_SIZE,
                params: {
                    width,
                    height,
                },
            });
        }
    }

    renderThumbnail() {
        const {
                slide: { name, media },
                isActive,
                expanded,
                preload,
                toggleReview,
            } = this.props,
            lazyLoad = !isActive && !preload;

        return (
            <figure className="thumbnail" onClick={toggleReview}>
                <Icon size={80} className="slide-play-button" onClick={this.showVideoIframe}>
                    {s_play_circle}
                </Icon>
                <Image
                    lazyLoad={lazyLoad}
                    isActive={isActive}
                    alt={name}
                    onDimensions={this.reportThumbnailSize}
                    src={expanded ? media.original : media.medium}
                />
            </figure>
        );
    }

    render() {
        const { review, isShowingReview, onClick, isActive, expanded } = this.props;
        const { showingPlayer } = this.state;
        const slideClasses = classNames('slide', 'slide-video', { 'active-review': isShowingReview && isActive });
        const showReview = expanded ? review : review && !showingPlayer;

        return (
            <div className={slideClasses} onClick={onClick}>
                {this.renderThumbnail()}
                {showingPlayer && this.renderVideo()}
                {showReview && <GigReview {...review} compact={!expanded} />}
            </div>
        );
    }
}

VideoSlide.propTypes = {
    slide: object.isRequired,
    isActive: bool.isRequired,
    index: number.isRequired,
    expanded: bool,
    onClick: func,
    preload: bool,
    flashReview: func,
    toggleReview: func,
    hideReview: func,
    isShowingReview: bool,
    review: object,
};

VideoSlide.contextTypes = {
    biEvents: object,
};

export { VideoSlide };
export default SlideHOC(VideoSlide);
