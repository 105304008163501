import { merge } from 'lodash';
import { BigQueryEnrichmentData } from '@fiverr-private/gig_page_context';
import { getBaseActionsParams } from '../../../events/base';

export interface BQEventData extends BigQueryEnrichmentData {
    organizationId?: string;
}

const BQ_PRO_BANNER_CLICKED_ON_CLOSE = 'clicked_on_pro_vetted_banner_close';

export const bqClickedOnProVettedBannerClose = ({ organizationId, ...enrichmentData }: BQEventData) =>
    merge({}, getBaseActionsParams(BQ_PRO_BANNER_CLICKED_ON_CLOSE, enrichmentData), {
        organization: { id: organizationId },
        visit: { id: enrichmentData.userGuid },
        page: {
            element: { type: 'banner' },
        },
        out_of_session: false,
    });
