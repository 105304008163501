export class Vector2d {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    toArray() {
        return [this.x, this.y];
    }

    toObject() {
        return { x: this.x, y: this.y };
    }

    /**
     * Multiply Scalar with Vector returns a Vector.
     *
     * @param {number} l scalar to multiply with
     * @return {this} Vector2d
     */
    multScalar(l) {
        return new Vector2d(l * this.x, l * this.y);
    }

    /**
     * Adding two vectors is another vector.
     *
     * @param {Array<number>} vector 2d vector.
     * @return {Vector2d} Sum vector.
     */
    add(vector) {
        if (!(vector instanceof Vector2d)) {
            throw new Error('Vector2d::add Invalid vector passed.');
        }
        return new Vector2d(this.x + vector.x, this.y + vector.y);
    }

    /**
     * Subtract two vectors.
     *
     * @param {Vector2d} vector 2d vector.
     * @return {Vector2d} Difference vector.
     */
    subs(vector) {
        if (!(vector instanceof Vector2d)) {
            throw new Error('Vector2d::subs Invalid vector passed.');
        }
        return new Vector2d(this.x - vector.x, this.y - vector.y);
    }

    /**
     * Dot product of two vectors is scalar.
     *
     * @param {Array<number>} a 2d vector.
     * @param {Array<number>} b 2d vector.
     * @return {number} scalar inner product.
     */
    dot(vector) {
        if (!(vector instanceof Vector2d)) {
            throw new Error('Vector2d::dot Invalid vector passed.');
        }
        return this.x * vector.x + this.y * vector.y;
    }

    /**
     * Exterior Product of two vectors is a pseudoscalar.
     *
     * @param {Array<number>} a 2d vector.
     * @param {Array<number>} b 2d vector.
     * @return {number} psuedo-scalar exterior product.
     */
    wedge(vector) {
        if (!(vector instanceof Vector2d)) {
            throw new Error('Vector2d::wedge Invalid vector passed.');
        }
        return this.x * vector.y - this.y * vector.x;
    }

    /**
     * Apply Matrix on Vector returns a Vector.
     *
     * @param {Array<Array<number>>} A 2x2 Matrix
     * @param {Array<number>} x 2d vector.
     * @return {Array<number>} 2d vector linear product.
     */
    applyMatrix(matrix) {
        if (!(matrix instanceof Matrix)) {
            throw new Error('Vector2d::applyMatrix Invalid matrix passed.');
        }
        const a = matrix.toVector(0);
        const b = matrix.toVector(1);
        return a.multScalar(this.x).add(b.multScalar(this.y));
    }
}

export class Matrix {
    constructor(matrixArray) {
        if (!Array.isArray(matrixArray)) {
            throw new Error('Matrix::constructor Invalid array passed.');
        }
        this.value = matrixArray;
    }

    toArray() {
        return [[...this.value[0]], [...this.value[1]]];
    }

    toVector(row) {
        return new Vector2d(this.value[row][0], this.value[row][1]);
    }

    index(row, col) {
        return this.value[row][col];
    }

    applyToVector(vector) {
        if (!(vector instanceof Vector2d)) {
            throw new Error('Matrix::applyToVector Invalid vector passed.');
        }
        const a = this.toVector(0);
        const b = this.toVector(1);
        return a.multScalar(vector.x).add(b.multScalar(vector.y));
    }

    multMatrix(matrix) {
        if (!(matrix instanceof Matrix)) {
            throw new Error('Matrix::multMatrix Invalid matrix passed.');
        }
        const a = matrix.toVector(0);
        const b = matrix.toVector(1);
        return new Matrix([a.applyMatrix(this).toArray(), b.applyMatrix(this).toArray()]);
    }

    /**
     * Creates the default rotation matrix
     *
     * @param {number} xProjection (cos(theta))
     * @param {number} yProjection (sin(theta))
     * @return {Matrix} Rotation matrix.
     */
    static getRotationFromProjection(xProjection, yProjection) {
        return new Matrix([
            [xProjection, yProjection],
            [-yProjection, xProjection],
        ]);
    }
}

export class Transform {
    /**
     * Represents a transform operation, Ax + b
     *
     * @constructor
     *
     * @param {Array<Array<number>>} A 2x2 Matrix.
     * @param {Array<number>} b 2d scalar.
     */
    constructor(
        matrix = new Matrix([
            [1, 0],
            [0, 1],
        ]),
        scalar2d = new Vector2d(0, 0)
    ) {
        if (!(matrix instanceof Matrix)) {
            throw new Error('Transform::constructor Invalid matrix passed');
        }
        if (!(scalar2d instanceof Vector2d)) {
            throw new Error('Transform::constructor Invalid vector passed');
        }
        this.matrix = matrix;
        this.scalar2dVector = scalar2d;
    }

    toCSS() {
        return `matrix(${this.matrix.index(0, 0)}, ${this.matrix.index(0, 1)}, ${this.matrix.index(
            1,
            0
        )}, ${this.matrix.index(1, 1)}, ${this.scalar2dVector.x}, ${this.scalar2dVector.y})`;
    }

    /**
     * Multiply two transforms.
     * Defined as
     *  (T o U) (x) = T(U(x))
     *
     * Derivation:
     *  T(U(x))
     *   = T(U.A(x) + U.b)
     *   = T.A(U.A(x) + U.b)) + T.b
     *   = T.A(U.A(x)) + T.A(U.b) + T.b
     *
     * @param {Transform} transform
     * @return {Transform} T o U
     */
    multTransform(transform) {
        if (!(transform instanceof Transform)) {
            throw new Error('Transform::multTransform Invalid transform passed');
        }
        const m = this.matrix.multMatrix(transform.matrix);
        const v = this.scalar2dVector.add(transform.scalar2dVector.applyMatrix(this.matrix));
        return new Transform(m, v);
    }

    /**
     * Experimental
     */
    getRotation() {
        const a = this.matrix.index(0, 0),
            b = this.matrix.index(0, 1),
            deg = Math.round(Math.atan2(b, a) * (180 / Math.PI));
        return deg;
    }

    /**
     * Experimental
     */
    getScale() {
        const e = (this.matrix.index(0, 0) + this.matrix.index(1, 1)) / 2,
            f = (this.matrix.index(0, 0) - this.matrix.index(1, 1)) / 2,
            g = (this.matrix.index(1, 0) + this.matrix.index(0, 1)) / 2,
            h = (this.matrix.index(1, 0) - this.matrix.index(0, 1)) / 2,
            q = Math.sqrt(e * e + h * h),
            r = Math.sqrt(f * f + g * g);
        return { x: q + r, y: q - r };
    }

    /**
     * Returns matrix that transforms vector a to vector b.
     *
     * @param {Vector2d} a 2d vector.
     * @param {Vector2d} b 2d vector.
     * @param {boolean} withRotation Should I calculate rotation?
     * @return {Matrix} Rotation + Scale matrix
     */
    static getTransformMatrixFromPoints(vectorA, vectorB, withRotation = false) {
        if (!(vectorA instanceof Vector2d)) {
            throw new Error('Transform::scale Invalid vector passed');
        }
        if (!(vectorB instanceof Vector2d)) {
            throw new Error('Transform::scale Invalid vector passed');
        }
        if (withRotation) {
            const alen = vectorA.dot(vectorA);
            const sig = vectorA.dot(vectorB);
            const del = vectorA.wedge(vectorB);
            return Matrix.getRotationFromProjection(sig / alen, del / alen);
        } else {
            const alen = Math.sqrt(vectorA.dot(vectorA));
            const blen = Math.sqrt(vectorB.dot(vectorB));
            return Matrix.getRotationFromProjection(blen / alen, 0); // scale, 0
        }
    }

    /**
     * This function ultimately does all the magic. It returns a Transform based on
     * the difference of two sets of points (two fingers initial, two fingers end, aka pinch diff)
     *
     * @param {Array<Vector2d>} srcPoints Initial array of points (vectors).
     * @param {Array<Vector2d>} destPoints Final array of points (vectors).
     * @param {boolean} withRotation Should I calculate rotation?
     * @return {Transform}
     */
    static getTransformFromMultiPoints(srcPoints, destPoints, withRotation = false) {
        const a = srcPoints.length < 2 ? new Vector2d(1, 1) : srcPoints[1].subs(srcPoints[0]);

        const b = destPoints.length < 2 ? new Vector2d(1, 1) : destPoints[1].subs(destPoints[0]);

        const transformMatrix = Transform.getTransformMatrixFromPoints(a, b, withRotation);
        const fromWithRotation = srcPoints[0].applyMatrix(transformMatrix); // Position of from[0] if rotation is applied.
        const transformVector = destPoints[0].subs(fromWithRotation); // Since to[0] = rs + t
        return new Transform(transformMatrix, transformVector);
    }
}
