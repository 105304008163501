import React from 'react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { Container, Stack } from '@fiverr-private/layout_components';
import { BookConsultation } from '@fiverr-private/gig_seller_info';
import { BQ_CLICKED_SELLER_LINK } from '../../../../utils/bigQueryEvents';
import { getSellerUrl } from '../../../../utils/getSellerUrl/getSellerUrl';
import OneLiner from '../OneLiner';
import ContactMe from '../ContactMe';
import { AgencyLogo } from '../../../shared/AgencyLogo/AgencyLogo';
import { AgencySize } from '../../../shared/AgencySize/AgencySize';
import { RatingLine } from './RatingLine';
import { SellerDetails } from './SellerDetails';
import { SellerAvatar } from './SellerAvatar';

export interface ExperientialProfileInfoProps {
  oneLiner: string;
  ratingsCount: number;
  rating: number;
  hasProfilePhoto: boolean;
  profilePhoto: string;
}

export const ExperientialProfileInfo: React.FC<ExperientialProfileInfoProps> = ({
  oneLiner,
  ratingsCount,
  rating,
  hasProfilePhoto,
  profilePhoto,
}) => {
  const {
    general: { sellerId, gigId },
    seller: { isAgency, displayName, photoUrl },
    pathfinderWrapper,
    biEvents,
    currencyConverter,
    consultationData,
  } = useGigPageContext();

  const showRatingLine = ratingsCount > 0 && !!rating;
  const sellerUrl = getSellerUrl({ pathfinderWrapper });
  const showOneLiner = !!oneLiner && !isAgency;

  const onSellerLinkClick = () => {
    biEvents.sendRawBigQueryEvent({ eventName: BQ_CLICKED_SELLER_LINK });
  };

  return (
    <Container paddingBottom="6">
      <Stack
        gap={{ sm: '5', default: '4' }}
        direction={{ sm: 'row', default: 'column' }}
        alignItems={{ sm: 'stretch', default: 'center' }}
        justifyContent={{ sm: 'flexStart', default: 'center' }}
      >
        <SellerAvatar hasProfilePhoto={hasProfilePhoto} profilePhoto={profilePhoto} />
        <Stack direction="column" gap="2" justifyContent="center" alignItems={{ sm: 'flexStart', default: 'center' }}>
          <SellerDetails sellerUrl={sellerUrl} onLinkClick={onSellerLinkClick} />
          {isAgency && (
            <Stack gap="3" justifyContent={{ sm: 'flexStart', default: 'center' }}>
              <AgencyLogo labelSize="b_lg" />
              <AgencySize />
            </Stack>
          )}
          {showOneLiner && <OneLiner oneLiner={oneLiner} />}
          {showRatingLine && <RatingLine rating={rating} ratingsCount={ratingsCount} />}
        </Stack>
      </Stack>
      <Stack gap="4">
        <ContactMe />
        <Container marginTop="5">
          <BookConsultation
            isPro={true}
            sellerId={sellerId}
            gigId={gigId}
            displayName={displayName}
            profileImage={photoUrl}
            currencyConverter={currencyConverter}
            consultationData={consultationData}
          />
        </Container>
      </Stack>
    </Container>
  );
};
