import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';

import './style.scss';

const TranslatedBadge = () => (
    <p className="translated-badge">
        <I18n k="gig_page_perseus.ugc.translated_by_amazon" />
    </p>
);

export default TranslatedBadge;
