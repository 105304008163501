import { localStorage } from '@fiverr-private/futile/lib/storage';

class AudioUtils {
    static handlePressEvents(keyPressed, togglePlayCallback, seekCallback, setVolumeCallback, currentVolume) {
        if (keyPressed === 32 && togglePlayCallback) {
            // spacebar
            togglePlayCallback();
        } else if (keyPressed === 39 && seekCallback) {
            // right arrow
            seekCallback(2);
        } else if (keyPressed === 37 && seekCallback) {
            // left arrow
            seekCallback(-2);
        } else if (keyPressed === 38 && setVolumeCallback) {
            // up arrow
            setVolumeCallback(currentVolume + 0.2);
        } else if (keyPressed === 40 && setVolumeCallback) {
            // down arrow
            setVolumeCallback(currentVolume - 0.2);
        }
    }

    static getVolume() {
        const defaultVolume = 0.8,
            preferenceVolume = localStorage.get('gig-gallery-slider-volume'),
            newVolume = AudioUtils.validVolume(preferenceVolume) ? preferenceVolume : defaultVolume;

        return newVolume;
    }

    static setVolume(volume) {
        if (!AudioUtils.validVolume(volume)) {
            return false;
        }

        localStorage.set('gig-gallery-slider-volume', volume);
        return true;
    }

    static validVolume(volume) {
        if (typeof volume !== 'number' || volume < 0 || volume > 1) {
            return false;
        }
        return true;
    }

    static audioDurationToText(duration) {
        if (typeof duration !== 'number') {
            return '00:00';
        }

        const minutes = Math.floor(duration / 60);
        const seconds = Math.floor(duration - minutes * 60);
        return `${AudioUtils.padZerosToLeft(minutes, 2)}:${AudioUtils.padZerosToLeft(seconds, 2)}`;
    }

    static padZerosToLeft(string, length) {
        // :tafnuk:
        return (new Array(length + 1).join('0') + string).slice(-length);
    }
}
export default AudioUtils;
