import ReactDOM from 'react-dom';
import { SectionScrollerReturn, WayPointInteractionParams, ScrollParams } from '@fiverr-private/gig_page_context';
import { ABOUT_EXPERT, OVERVIEW, TOP_PAGE } from '../pageSections';
import { BUSINESS_SCROLL_OFFSET, SCROLL_DURATION, SCROLL_OFFSET, WAYPOINT_OFFSET } from '../constants';
import scrollToComponent from '../scrollToComponent';

export function sectionScroller(): SectionScrollerReturn {
    let currentSection = OVERVIEW;
    let useWaypoints = true;
    const refsObj = {};

    const scroll = ({ sectionKey, forceScroll }: ScrollParams) => {
        const ref = refsObj[sectionKey];
        const element = ReactDOM.findDOMNode(ref); /* eslint-disable-line react/no-find-dom-node */

        if (element && (sectionKey !== currentSection || forceScroll)) {
            const nextSectionKey = sectionKey === ABOUT_EXPERT ? TOP_PAGE : sectionKey;
            const scrollOffset = sectionKey === ABOUT_EXPERT ? BUSINESS_SCROLL_OFFSET : SCROLL_OFFSET;

            currentSection = nextSectionKey;
            useWaypoints = false;

            const scroller = scrollToComponent(element as Element, {
                offset: scrollOffset,
                align: 'top',
                duration: SCROLL_DURATION,
            });

            // we need a timeout here, otherwise the waypoints fire
            // right at the end of the scroll
            scroller.onComplete(() => {
                setTimeout(() => {
                    useWaypoints = true;
                }, 50);
            });
        }
    };

    const onEnterWaypoint = ({ section, data }: WayPointInteractionParams) => {
        if (!section || !useWaypoints) {
            return;
        }

        const scrollingUp = data.currentPosition === 'inside' && data.previousPosition === 'above';

        if (section !== currentSection && scrollingUp) {
            currentSection = section;
        }
    };

    const onLeaveWaypoint = ({ section, data }: WayPointInteractionParams) => {
        if (!section || !useWaypoints) {
            return;
        }

        if (section !== currentSection) {
            if (data.waypointTop < WAYPOINT_OFFSET) {
                currentSection = section;
            }
        }
    };

    return {
        currentSection,
        refsObj,
        scroll,
        onEnterWaypoint,
        onLeaveWaypoint,
    };
}
