import React, { useState, forwardRef, useRef, useImperativeHandle } from 'react';
import classNames from 'classnames';
import { bool, object, string, func } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { Icon } from '@fiverr-private/fit';
import { s_pause_circle, s_play_circle, r_spinner_animated } from '@fiverr-private/icons';
import Logger from '../../../../utils/logger';
import { sendPlayClickedBiEvent, sendSampleGenerationSuccessBiEvent, sendErrorBiEvent } from '../biEvents';
import { ERRORS } from '../../../../utils/voiceOverAiAudition/constants';
import { generateAuditionId } from '../utils';
import { getVoiceOverSample, generateAudioUrlFromSample } from './utils';
import { STATUS } from './constants';

import './style.scss';

// eslint-disable-next-line react/display-name
const Player = forwardRef(
    ({ voiceOverAiAudition, pendingSentence, enabled, setError, clearError, biEvents, pathfinderWrapper }, ref) => {
        const { generateVoiceSampleApiEndpoint } = voiceOverAiAudition;
        const [audioUrl, setAudioUrl] = useState();
        const [isLoading, setIsLoading] = useState(false);
        const [playingSentence, setPlayingSentence] = useState('');
        const [status, setStatus] = useState(STATUS.PAUSED);
        const [auditionId, setAuditionId] = useState();
        const player = useRef();
        const logger = Logger.getInstance();

        const togglePlay = () => {
            if (!enabled) {
                return;
            }

            isPaused() ? handlePlay() : pauseSample();
        };

        const handlePlay = async () => {
            if (hasSentenceChanged()) {
                const newAuditionId = createNewAuditionId();

                sendPlayClickedBiEvent({ biEvents, auditionId: newAuditionId, sentence: pendingSentence });
                try {
                    await changeSample(newAuditionId);
                    sendSampleGenerationSuccessBiEvent({
                        biEvents,
                        auditionId: newAuditionId,
                        sentence: pendingSentence,
                    });
                } catch (error) {
                    const errorType = error.type || ERRORS.GENERAL_ERROR;

                    setError(errorType);
                    logger.error(error, { description: 'Voice Over AI Audition Failure: Failed to load audio sample' });
                    sendErrorBiEvent({
                        biEvents,
                        auditionId: newAuditionId,
                        sentence: pendingSentence,
                        error: errorType,
                    });
                }
            } else {
                sendPlayClickedBiEvent({ biEvents, auditionId, sentence: pendingSentence });
                playSample();
            }
        };

        const createNewAuditionId = () => {
            const newId = generateAuditionId();

            setAuditionId(newId);
            return newId;
        };

        const changeSample = async () => {
            clearError();
            setIsLoading(true);
            resetAudio();

            try {
                await loadAudio();
                setPlayingSentence(pendingSentence);
                playSample();
            } finally {
                setIsLoading(false);
            }
        };

        const loadAudio = async () => {
            const { pathParameters } = getContext();

            const voiceOverSample = await getVoiceOverSample({
                generateVoiceSampleApiEndpoint,
                sentence: pendingSentence,
                pathfinderWrapper,
                pathParameters,
            });

            const url = generateAudioUrlFromSample(voiceOverSample);

            setAudioUrl(url);
        };

        const playSample = () => player.current.play();
        const pauseSample = () => player.current.pause();
        const isPaused = () => player.current.paused;
        const onSamplePlay = () => setStatus(STATUS.PLAYING);
        const onSamplePaused = () => setStatus(STATUS.PAUSED);

        const hasSentenceChanged = () => playingSentence !== pendingSentence;

        const resetAudio = () => setAudioUrl('');

        const iconByStatus = {
            [STATUS.PAUSED]: s_play_circle,
            [STATUS.PLAYING]: s_pause_circle,
        };

        useImperativeHandle(ref, () => ({
            play: () => togglePlay(),
        }));

        return (
            <div className="player">
                <audio ref={player} src={audioUrl} onPlay={onSamplePlay} onPause={onSamplePaused} />
                {isLoading ? (
                    <Icon className="loading-button">{r_spinner_animated}</Icon>
                ) : (
                    <Icon
                        size={42}
                        className={classNames('control-button', { disabled: !enabled })}
                        onClick={togglePlay}
                    >
                        {iconByStatus[status]}
                    </Icon>
                )}
            </div>
        );
    }
);

Player.propTypes = {
    voiceOverAiAudition: object,
    pendingSentence: string,
    enabled: bool,
    setError: func,
    clearError: func,
    biEvents: object,
    pathfinderWrapper: object,
};

export default Player;
