import React from 'react';
import { func, number } from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import Quantity from '../Quantity';
import { BASIC_PACKAGE_ID } from '../../../utils/packagesUtils/constants';
import { getPackageById } from '../../../reducers/packages';
import { updateQuantity } from '../../../actions/packages';

import './style.scss';

const GigQuantity = ({ quantity, price, onGigQuantityChange = noop }) => (
    <div className="gig-quantity">
        <b>
            <I18n k={'gig_page_perseus.packages.gig_quantity'} />
        </b>
        <Quantity quantity={quantity} price={price} onQuantityChange={onGigQuantityChange} />
    </div>
);

GigQuantity.propTypes = {
    quantity: number,
    price: number,
    onGigQuantityChange: func,
};

GigQuantity.defaultProps = {
    onChange: noop,
};

const mapDispatchToProps = (dispatch) => ({
    onGigQuantityChange: (quantity) => {
        dispatch(updateQuantity({ quantity }));
    },
});

const mapStateToProps = ({ packages }) => {
    const { price } = getPackageById(packages, BASIC_PACKAGE_ID);

    return {
        price,
        quantity: packages.quantity,
    };
};

export { GigQuantity };

export default connect(mapStateToProps, mapDispatchToProps)(GigQuantity);
