import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@fiverr-private/fit';
import { r_volume_high, r_volume_low, r_volume_off } from '@fiverr-private/fit/icons';

import './volumeBar.scss';

class AudioVolume extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };

        this.preVolume = this.props.volume;
        this.openBar = this.setBarStatus.bind(this, true);
        this.closeBar = this.setBarStatus.bind(this, false);
        this.handleChange = this.handleChange.bind(this);
        this.handleMute = this.handleMute.bind(this);
    }

    setBarStatus(isOpen) {
        this.setState({ isOpen });
    }

    handleChange({ target }) {
        const volume = parseFloat(target.value);

        this.preVolume = volume;
        this.props.handleVolumeChange(volume);
    }

    handleMute() {
        const volume = this.props.volume;

        if (volume !== 0) {
            this.props.handleVolumeChange(0);
        } else {
            this.props.handleVolumeChange(this.preVolume);
        }
    }

    getVolumeIcon() {
        const { volume } = this.props;
        let icon;

        if (volume === 0) {
            icon = r_volume_off;
        } else if (volume <= 0.5) {
            icon = r_volume_low;
        } else {
            icon = r_volume_high;
        }

        return icon;
    }

    render() {
        const { isOpen } = this.state;
        const { volume } = this.props;

        const inputStyle = { '--min': 0, '--max': 1, '--val': volume };
        const volumeIcon = this.getVolumeIcon();

        return (
            <div className="volume-bar" onMouseOver={this.openBar} onMouseLeave={this.closeBar}>
                <Icon className="volume-button" size={20} onClick={this.handleMute}>
                    {volumeIcon}
                </Icon>
                {isOpen && (
                    <input
                        id="vol-control"
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        style={inputStyle}
                        value={volume}
                        onChange={this.handleChange}
                    />
                )}
            </div>
        );
    }
}

AudioVolume.propTypes = {
    volume: PropTypes.number,
    handleVolumeChange: PropTypes.func,
};

export default AudioVolume;
