import { triggerSignUp as triggerSignUpHandler } from '@fiverr-private/identification_triggers';
import { BQ_SIGNUP_CLICK } from '../bigQueryEvents';

/**
 * Triggers the Sign-Up modal from the User Session Package using a proper trigger API.
 * It's a stable way to trigger the modal, as it's not direclty dependent on the global `window` object.
 * Fallbacks to redirecting to `join` standalone page if something goes wrong.
 * @param {object} pathfinderWrapper
 * @param {Partial<TriggerModalOptions>} options
 */
const triggerSignUpApi = async ({ pathfinderWrapper, options }) => {
    try {
        await triggerSignUpHandler({
            modalOptions: {
                ...options,
                triggerType: 'blocked_action'
            },
        });
    } catch {
        window.location.assign(pathfinderWrapper.pathfinder('join'));
    }
};

/**
 *
 * @param {object} biEvents
 * @param {object} pathfinderWrapper
 * @param {Partial<TriggerModalOptions>} options
 */
export const triggerSignUp = async ({ biEvents, pathfinderWrapper, options }) => {
    biEvents.sendBigQueryEvent({ eventName: BQ_SIGNUP_CLICK });

    return triggerSignUpApi({ pathfinderWrapper, options });
};
