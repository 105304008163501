import React, { useState } from 'react';
import { connect } from 'react-redux';
import { object } from 'prop-types';
import classNames from 'classnames';
import { CollapsibleGroup, Collapsible, Icon } from '@fiverr-private/fit';
import { l_chevron_right } from '@fiverr-private/fit/icons';
import { I18n } from '@fiverr-private/i18n-react';
import SafePureComponent from '../shared/SafePureComponent';
import { validateFaq } from '../../utils/mainComponentsValidator';
import { getCurrentContent } from '../../utils/ugc/getCurrentContent';
import { FaqData } from '../../types/gig/interfaces';
import { isTranslated } from '../../utils/ugc/isTranslated';
import { BQ_FAQ_EXPANSION } from '../../utils/bigQueryEvents';
import TranslatedBadge from '../shared/TranslatedBadge';
import SellerTranslationBadgeWrapper from '../shared/SellerTranslationBadgeWrapper';
import { Waypoint } from '../Waypoint/Waypoint';
import { FAQ } from '../../utils/pageSections';
import './style.scss';

const Faq = ({ faq, showTranslatedBadge = false }: FaqProps, { biEvents }) => {
  const [collapsed, setCollapsed] = useState(true);

  if (!validateFaq({ faq })) {
    return null;
  }

  const toggleCollapsed = async () => {
    if (collapsed) {
      biEvents.sendBigQueryEvent({ eventName: BQ_FAQ_EXPANSION });
    }

    setCollapsed(!collapsed);
  };

  const { questionsAndAnswers } = faq;

  return (
    <>
      <Waypoint source={FAQ} />
      <div className={classNames('faq-collapsable', { 'is-collapsed': collapsed })}>
        <h2 className="section-title" onClick={toggleCollapsed}>
          <SellerTranslationBadgeWrapper translationKey="faq">
            <>
              <Icon className="faq-collapser" size={14}>
                {l_chevron_right}
              </Icon>
              <I18n k={'gig_page_perseus.faq.title'} />
            </>
          </SellerTranslationBadgeWrapper>
        </h2>
        {showTranslatedBadge && <TranslatedBadge />}
        <CollapsibleGroup className="faq-collapsible-group">
          {questionsAndAnswers.map(
            ({ question, answer }, index) =>
              question &&
              answer && (
                <Collapsible
                  className="faq-collapsible"
                  titleWrapperClassName="faq-collapsible-title"
                  contentClassName="faq-collapsible-content"
                  chevronWrapperClassName="faq-collapsible-chevron"
                  title={<p>{question}</p>}
                  key={index}
                >
                  <p>{answer}</p>
                </Collapsible>
              )
          )}
        </CollapsibleGroup>
      </div>
    </>
  );
};

interface FaqProps {
  faq: FaqData;
  showTranslatedBadge: boolean;
}

Faq.contextTypes = {
  biEvents: object,
};

const mapStateToProps = ({ ugc }) => {
  const { faq } = getCurrentContent({ ugc });

  return {
    faq,
    showTranslatedBadge: isTranslated(ugc),
  };
};

const ConnectedFaq = connect(mapStateToProps)(Faq);

export { Faq, ConnectedFaq };
export default SafePureComponent(ConnectedFaq);
