import Logger from '../logger';
import { metric } from '../metric';
import { maxios } from '../maxios';

const fetchReviewAsync = async ({ orderId, pathfinderWrapper }) => {
    try {
        const ajaxUrl = pathfinderWrapper.pathfinder('gig_page_order_review_api', { id: orderId });
        const { data } = await maxios.get(ajaxUrl, { routeKey: 'gig_page_order_review_api' });
        return data;
    } catch (error) {
        const logger = Logger.getInstance();
        logger.warn(error, { description: 'Error while fetching review', orderId });
        metric.count('warnings.review.slide_hoc.fetch_review_async.failed');
        return null;
    }
};

export default fetchReviewAsync;
