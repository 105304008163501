import React, { PureComponent } from 'react';
import { throttle } from 'lodash';
import { bool, func, object } from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Icon } from '@fiverr-private/fit';
import { s_fullscreen } from '@fiverr-private/fit/icons';
import Image from '../../components/Image';
import PinchToZoom from '../../components/PinchToZoom';
import GigReview from '../../GigReview';
import SlideHOC from '../slideHOC';

class ImageSlide extends PureComponent {
    constructor(props) {
        super(props);

        // Bindings
        this.throttledUpdateScrollStatus = throttle(this.updateScrollStatus.bind(this), 250);
        this.handleDimensions = this.handleDimensions.bind(this);

        // Refs
        this.wrapper = null;

        // Initial state
        this.state = {
            isLongImage: false,
            isScrollable: false,
            hasBeenScrolled: false,
        };
    }

    componentDidMount() {
        const { flashReview } = this.props;
        if (this.props.isActive) {
            flashReview();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { flashReview, hideReview, isShowingReview, isActive } = this.props;

        if (!nextProps.isShowingReview && isShowingReview) {
            hideReview();
        }

        if (nextProps.isActive && !isActive) {
            flashReview();
        }
    }

    updateScrollStatus() {
        if (!this.wrapper) {
            return;
        }
        this.setState((prevState) => {
            if (!prevState.hasBeenScrolled) {
                return {
                    isScrollable: this.wrapper.scrollHeight > this.wrapper.offsetHeight,
                    hasBeenScrolled: this.wrapper.scrollTop > 5,
                };
            }
        });
    }

    handleDimensions({ width, height }) {
        const longAspectRatio = 1 / 1.5; // We consider an image with an aspect ratio of 1:1.5 or taller a "Long image";
        const imageAspectRatio = width / height;
        if (imageAspectRatio <= longAspectRatio) {
            this.setState({ isLongImage: true }, this.throttledUpdateScrollStatus);
        }
    }

    render() {
        const {
            slide: { name, media },
            isActive,
            onClick,
            expanded,
            onZoomActive,
            onZoomIdle,
            preload,
            review,
            toggleReview,
            isShowingReview,
        } = this.props;

        const { isLongImage, isScrollable, hasBeenScrolled } = this.state,
            figureClasses = classNames('thumbnail', { 'long-image': isLongImage }),
            slideClasses = classNames('slide', 'slide-image', {
                'active-review': isShowingReview && isActive,
                'scroll-hint': isScrollable && !hasBeenScrolled && !isShowingReview,
            }),
            lazyLoad = !isActive && !preload,
            src = expanded ? media.original : media.medium;

        const thumbnail = (
            <figure
                className={figureClasses}
                tabIndex="0"
                ref={(node) => (this.wrapper = node)}
                onScroll={this.throttledUpdateScrollStatus}
            >
                <Image
                    lazyLoad={lazyLoad}
                    isActive={isActive}
                    alt={name}
                    src={src}
                    onDimensions={this.handleDimensions}
                    onClick={toggleReview}
                />
            </figure>
        );

        return (
            <div className={slideClasses} onClick={onClick}>
                {expanded ? (
                    <PinchToZoom
                        rotate={false}
                        minScale={1.5}
                        onActive={onZoomActive}
                        onIdle={onZoomIdle}
                        preventSingleTouchEvents={!isLongImage}
                    >
                        {thumbnail}
                    </PinchToZoom>
                ) : (
                    thumbnail
                )}
                {review && <GigReview {...review} compact={!expanded} />}
                {!expanded && (
                    <div className="slide-fullscreen">
                        <Icon size={13} className="fullscreen-icon">
                            {s_fullscreen}
                        </Icon>
                        <I18n k={'gig_page_perseus.gallery.full_screen'} />
                    </div>
                )}
            </div>
        );
    }
}

ImageSlide.propTypes = {
    isActive: bool,
    slide: object,
    onClick: func,
    expanded: bool,
    onZoomActive: func,
    onZoomIdle: func,
    preload: bool,
    flashReview: func,
    toggleReview: func,
    hideReview: func,
    isShowingReview: bool,
    review: object,
};

export { ImageSlide };
export default SlideHOC(ImageSlide);
