import { cloneDeep } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { hasTranslation } from '@fiverr-private/i18n-react';
import { calcCurrentDuration, isFeatureFiltered, isValidVoPackage } from '../../../utils/packagesUtils';
import { getVoCalculator, isWordCountCalculator, VO_TYPE } from '../../../utils/calculator';
import { CALC_MAX, RECOMMENDATION_ELIGIBLE_PACKAGES } from '../../../utils/packagesUtils/constants';
import { RECOMMENDED_TYPES } from '../../../utils/packagesUtils/types/recommendedTypes';
import { CHECKOUT_PLANS } from '../../../utils/checkoutPlan/constants';
import { getAttributeKeys } from '../../../utils/translation/pricingFactors';
import { Attributes } from '../../../utils/translation/attributes';
import { enrichTooltipWithIWill } from '../../../utils/ugc/shared';
import { extractQueryParams, isCurrentPackageFromUrl } from '../../../utils/queryParams/extract/extractQueryParams';
import { buildMapFromQueryParamList } from '../../../utils/queryParams/extract/buildMapFromQueryParamList';
import { calcCheckoutPrice } from '../../../utils/packagesUtils/calcCheckoutPrice';
import { RECOMMENDED_TYPES_URL_PARAMS } from './constants';

export const getRecommendedType = ({ id, queryParameters = {}, features = [] }) => {
    const {
        [RECOMMENDED_TYPES_URL_PARAMS.SELECTED_PACKAGE_ID]: packageId,
        [RECOMMENDED_TYPES_URL_PARAMS.EXTRA_FAST]: extraFast,
    } = queryParameters;

    const isReferencedPackage = packageId === id.toString();
    const hasCustomizedFeature = features.some((feature) => isFeatureFiltered(feature));
    const isCustomizedEligible = hasCustomizedFeature || !!extraFast;
    const isRecommendedEligible = RECOMMENDATION_ELIGIBLE_PACKAGES.includes(id);

    if (isReferencedPackage && isCustomizedEligible) {
        return RECOMMENDED_TYPES.CUSTOMIZED;
    } else if (isReferencedPackage && isRecommendedEligible) {
        return RECOMMENDED_TYPES.RECOMMENDED;
    }

    return '';
};

export const sortPackages = (packageList) => {
    packageList.sort((package1, package2) => {
        if (package1.id < package2.id) {
            return -1;
        } else {
            return 1;
        }
    });
};

export const calculateVoPackageData = (packageData) => {
    const { calculators = [] } = packageData;
    const newCalculators = calculators.map((calculator) => {
        if (isWordCountCalculator(calculator)) {
            return { ...calculator, type: VO_TYPE };
        } else {
            return calculator;
        }
    });

    return {
        calculators: newCalculators,
    };
};

/**
 * Prepares list of customExtras DTOs for Store, including enrichment with URL Params list of pre-selected Extras
 * @param {CustomExtra[]} customExtras
 * @return {(*&{duration: number, buyerTooltip: String|React.Component, currentDuration: number})[]}
 */
const initCustomExtras = (customExtras) => {
    const { customExtras: selectedCustomExtras } = extractQueryParams();
    const selectedCustomExtrasMap = buildMapFromQueryParamList(selectedCustomExtras);
    const { locale } = getContext();

    return customExtras.map((customExtra) => {
        const formattedDuration = formatDuration(customExtra.duration);
        const injectedBuyerTooltip = enrichTooltipWithIWill(customExtra.buyerTooltip, locale);

        const selectedCustomExtraAmountFromUrl = selectedCustomExtrasMap[customExtra.label];
        const amount = selectedCustomExtraAmountFromUrl ? selectedCustomExtraAmountFromUrl : customExtra.amount;

        return {
            ...customExtra,
            ...(amount && { amount }),
            buyerTooltip: injectedBuyerTooltip,
            duration: formattedDuration,
            currentDuration: formattedDuration,
        };
    });
};

/**
 * For filtering out PF that don't have translations (were probably a deleted PF)
 * @param {Feature} feature
 * @param {number} subCategoryId
 * @return {Boolean}
 */
const checkFeatureInPricingFactors = (feature, subCategoryId) => {
    const { name } = feature;
    const attributes = [Attributes.name, Attributes.label, Attributes.title, Attributes.buyerTitle];
    const attributeKeys = getAttributeKeys({ name, subCategoryId, attributes });

    return hasTranslation(attributeKeys);
};

/**
 * Prepares list of Feature DTOs for Store, including enrichment with URL Params list of pre-selected Features
 * @param {Feature[]}features
 * @param {number} subCategoryId - used to check if the Feature has a Translation. (is valid)
 * @return {(*&{duration: number, amount: *|number, currentDuration: number, currentPrice: *})[]}
 */
const initFeatures = (features, subCategoryId) => {
    const { features: selectedFeatures } = extractQueryParams();
    const selectedFeaturesMap = buildMapFromQueryParamList(selectedFeatures);

    return features
        .filter((feature) => checkFeatureInPricingFactors(feature, subCategoryId))
        .map((feature) => {
            const customizedForYouAmount = isFeatureFiltered(feature) ? 1 : 0;
            const isNotIncludedFeature = !feature.included;
            const selectedFeatureAmount = isNotIncludedFeature ? selectedFeaturesMap[feature.name] : undefined;
            const amount = selectedFeatureAmount ? Number(selectedFeatureAmount) : customizedForYouAmount;

            const currentPrice = feature.price;
            const formattedDuration = formatDuration(feature.duration);

            return {
                ...feature,
                duration: formattedDuration,
                amount,
                currentPrice,
                currentDuration: formattedDuration,
            };
        });
};

/**
 * Prepares list of Calculator DTOs for Store, including enrichment with URL Params list of pre-selected Calculators
 * Pre selected Calculator amount is possible either in the Listings Page (one Mechanism) or from the Gig Page
 * NOTE: While there is supposedly support for a list of Calculators, there is never more than one.
 * @param {Calculator[]} calculators
 * @param packageId
 * @return {(*&{currentValue: number})[]}
 */
const initCalculators = (calculators, packageId) =>
    calculators.map((calculator) => {
        const { calculatorValue: selectedCalculatorValue } = extractQueryParams();
        const calculatorValue =
            isCurrentPackageFromUrl(packageId) && selectedCalculatorValue ? selectedCalculatorValue : calculator.value;
        const constrainedValue = Math.max(Number(calculatorValue), calculator.value);

        return {
            ...calculator,
            currentValue: constrainedValue,
        };
    });

/**
 * Prepares ExtraFast DTO for Store, including enrichment with URL Params list of pre-selected Extra Fast
 * @param {ExtraFast} extraFast
 * @param {number} packageId
 * @return {*&{duration: number, currentDuration: number, currentPrice: *, currentValue: (number)}}
 */
const initExtraFast = (extraFast, packageId) => {
    const { extraFast: extraFastFromUrl, customizedExtraFast: extraFastSelectedFromListings } = extractQueryParams();

    const duration = formatDuration(extraFast.duration);
    const extraFastSelected = (isCurrentPackageFromUrl(packageId) && extraFastFromUrl) || extraFastSelectedFromListings;
    const currentValue = extraFastSelected ? 1 : 0;
    const currentPrice = extraFast.price;

    return {
        ...extraFast,
        currentValue,
        currentPrice,
        duration,
        currentDuration: duration,
    };
};

const formatDuration = (duration) => (duration > 0 ? duration / 24 : 0);

export const getNewPrice = (packageData, newCurrentPrice) => {
    // If new calculated price is lower than the initial package price we'll set it to be the initial price
    // The calculator itself will still be updated with the inserted value
    if (newCurrentPrice < packageData.price) {
        return packageData.price;
    }

    return newCurrentPrice;
};

export const formatVoCalculators = (calculators, wordCount) =>
    calculators.map((calculator) => {
        const { value } = calculator;
        let currentValue = value;

        if (isWordCountCalculator(calculator)) {
            currentValue = Math.min(wordCount, CALC_MAX);
        }

        return { ...calculator, currentValue };
    });

export const formatVoPackageData = (packageData, scriptLength) => {
    packageData.calculators = formatVoCalculators(packageData.calculators, scriptLength);
    const calculator = getVoCalculator(packageData.calculators);
    const voData = { calculatorId: calculator.id };

    return calculateVoPackageData(packageData, voData);
};

export const initGigQuantity = () => {
    const { gigQuantity = 1 } = extractQueryParams();

    return Math.max(gigQuantity, 1);
};

export const buildPackageListForInitialState = (packages, general) => {
    let packageList = [];
    const { subCategoryId } = general;
    const { queryParameters } = getContext();
    const { gigQuantity } = extractQueryParams();

    if (packages && packages.packageList) {
        const clonedPackages = cloneDeep(packages);
        sortPackages(clonedPackages.packageList);
        packageList = clonedPackages.packageList;
    }

    return packageList.map((packageData) => {
        const { duration, id, calculators, extraFast, features, customExtras } = packageData;

        const formattedPackageData = {
            ...packageData,
            duration: formatDuration(duration),
            calculators: calculators ? initCalculators(calculators, id) : [],
            extraFast: extraFast ? initExtraFast(extraFast, id) : null,
            recommendedType: getRecommendedType({ id, queryParameters, features }),
            features: features ? initFeatures(features, subCategoryId) : [],
            customExtras: customExtras ? initCustomExtras(customExtras) : [],
        };

        const { calculatorValue } = extractQueryParams();
        let formattedVoPackageData = {};
        if (isValidVoPackage(calculators, subCategoryId) && calculatorValue) {
            const constrainedCalculatorValue = Math.max(calculatorValue, calculators[0].value);

            formattedVoPackageData = formatVoPackageData(formattedPackageData, constrainedCalculatorValue);
        }

        return {
            ...formattedPackageData,
            ...formattedVoPackageData,
            currentPrice: calcCheckoutPrice(formattedPackageData, gigQuantity),
            currentDuration: calcCurrentDuration(formattedPackageData),
        };
    });
};

/**
 * Returns What Payment Option is available for the Gig.
 * it's mutually exclusive - Milestones / Subscription / Single Order
 * @param {boolean | bool} isGigWithActiveWorkProcess
 * @param {boolean | bool} isRecurringGig
 */
export const calcPaymentOption = (isGigWithActiveWorkProcess, isRecurringGig) => {
    if (isGigWithActiveWorkProcess) {
        return CHECKOUT_PLANS.MILESTONES_ORDER;
    } else if (isRecurringGig) {
        return CHECKOUT_PLANS.RECURRING_ORDER;
    } else {
        return CHECKOUT_PLANS.SINGLE_ORDER;
    }
};
