import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Vector2d, Matrix, Transform } from './mathUtils';

const resetTransform = new Transform(
    new Matrix([
        [1, 0],
        [0, 1],
    ]),
    new Vector2d(0, 0)
);

class PinchToZoom extends Component {
    constructor(props) {
        super(props);

        this.originalTransform = resetTransform;
        this.currTransform = this.originalTransform;
        this.resultantTransform = this.originalTransform;

        this.srcCoords = [new Vector2d(0, 0)];
        this.destCoords = [new Vector2d(0, 0)];

        this.currNumOfFingers = 0;
        this.currScale = 1;

        // Refs
        this.node = null;

        // Bindings
        this.handleTouchEvent = this.handleTouchEvent.bind(this);
        this.handleTouchStart = this.handleTouchStart.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.handleTouchEnd = this.handleTouchEnd.bind(this);
        this.preventDefaultEvents = this.preventDefaultEvents.bind(this);

        this.state = {
            transform: this.originalTransform,
            interacting: false,
        };
    }

    componentDidMount() {
        // React doesn’t actually attach event handlers to the nodes themselves.
        // When React starts up, it starts listening for all events at the top
        // level using a single event listener.
        // In other words, with React, events are to be observed, not mutated
        // or intercepted. :o
        // For this reason, We're going to have to attach good'ol native DOM
        // event to our component to block the native interactions of the
        // browser (zoom, scroll, etc).
        if (this.node) {
            this.node.addEventListener('touchmove', this.preventDefaultEvents, false);
        }
    }

    componentWillUnmount() {
        this.node && this.node.removeEventListener('touchmove', this.preventDefaultEvents);
    }

    preventDefaultEvents(nativeEvent) {
        // Ideally I would've like to only fire this if (nativeEvent.scale !== 1) but when there's
        // momentum scrolling going on, event preventDefault is ignored
        if (
            this.currScale > 1 ||
            this.props.preventSingleTouchEvents ||
            (nativeEvent && nativeEvent.touches && nativeEvent.touches.length > 1)
        ) {
            nativeEvent.preventDefault();
        }
    }

    // Pre render
    previewZoom() {
        const deltaTransform = Transform.getTransformFromMultiPoints(
            this.srcCoords,
            this.destCoords,
            this.props.rotate
        );
        this.resultantTransform = deltaTransform.multTransform(this.currTransform);
        this.repaint();
    }

    setZoom(transform) {
        this.resultantTransform = transform;
        this.repaint();
    }
    finalize() {
        this.currTransform = this.resultantTransform;
    }
    repaint() {
        this.setState({
            transform: this.resultantTransform,
        });
    }
    reset() {
        this.currTransform = this.originalTransform;
        this.resultantTransform = this.originalTransform;
        this.currScale = 1;
        this.repaint();
    }

    getTouchCoords(touchList) {
        // Turns Touch objects into an array of {x,y} coordinates relative to the zoomable element
        const { left, top } = this.cachedBounds || { left: 0, top: 0 };
        return [...(touchList || [])].map((touch) => new Vector2d(touch.clientX - left, touch.clientY - top));
    }
    setSrcAndDest(points) {
        this.srcCoords = points;
        this.destCoords = points;
    }

    setDest(points) {
        this.destCoords = points;
    }

    handleTouchEvent(points) {
        const { onIdle, onActive, minScale } = this.props,
            numOfFingers = points.length;

        if (numOfFingers === 1 && this.currScale === 1) {
            // Do nothing if trying to pan on non zoomed image
            this.currNumOfFingers = numOfFingers;
            return;
        }

        this.setState({ interacting: points.length });

        if (numOfFingers !== this.currNumOfFingers) {
            this.currNumOfFingers = numOfFingers;
            this.currScale = this.resultantTransform.getScale().x;
            if (numOfFingers !== 0) {
                // Touch started
                this.finalize(); // this.currTransform = this.resultantTransform;
                this.setSrcAndDest(points);
            } else {
                // Touch ended
                this.currScale <= minScale && this.reset();
                this.currScale === 1 ? onIdle() : onActive();
            }
        } else {
            // Moving
            this.setDest(points);
            this.previewZoom();
        }
    }

    handleTouchStart(e) {
        if (e.scale > 1) {
            return;
        }
        this.cachedBounds = { left: this.node.offsetLeft, top: this.node.offsetTop };
        const points = this.getTouchCoords(e.touches);
        this.handleTouchEvent(points);
    }

    handleTouchMove(e) {
        const points = this.getTouchCoords(e.touches);
        this.handleTouchEvent(points);
    }

    handleTouchEnd(e) {
        const points = this.getTouchCoords(e.touches);
        this.handleTouchEvent(points);
    }

    render() {
        const { children } = this.props;
        const { transform, interacting } = this.state;

        const styles = {
            willChange: 'transform',
            transformOrigin: '0 0',
            transform: transform.toCSS(),
            transitionDuration: interacting ? '0.0s' : '0.3s',
        };

        const wrapperProps = {
            className: 'pinch-to-zoom-component',
            style: styles,
            ref: (el) => (this.node = el),
            onTouchStart: this.handleTouchStart,
            onTouchMove: this.handleTouchMove,
            onTouchEnd: this.handleTouchEnd,
        };

        return <div {...wrapperProps}>{children}</div>;
    }
}

PinchToZoom.propTypes = {
    preventSingleTouchEvents: PropTypes.bool,
    rotate: PropTypes.bool,
    onIdle: PropTypes.func,
    onActive: PropTypes.func,
    minScale: PropTypes.number,
    children: PropTypes.object,
};

export default PinchToZoom;
