import React from 'react';
import { PaypalPayLaterLearnMore, PAYPAL_LEARN_MORE_STYLES } from '@fiverr-private/payment';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { Container } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import { buildPaypalCreditLearnClickedEvent } from './buildPaypalCreditLearnClickedEvent';
import { buildPaypalPaylaterMessageIsShownEvent } from './buildPaypalPaylaterMessageIsShownEvent';

export interface PayLaterGigPageMessageProps {
  paypalClientToken: string;
}

export const PayLaterGigPageMessage: React.FC<PayLaterGigPageMessageProps> = ({ paypalClientToken }) => {
  const { biEvents, sellerUsername, general } = useGigPageContext();
  const { gigId, sellerId, userId, pageCtxId, pageName, platform, userGuid } = biEvents.getBigQueryEnrichmentData();
  const { organizationId } = getContext();
  const contextId = (organizationId || userId)?.toString() || '';
  const eventSource = 'gig_page';
  const [containerShownRef] = useImpressionItem({
    enrichment: buildPaypalPaylaterMessageIsShownEvent({
      eventSource,
      sellerUsername,
      general,
      gigId,
      sellerId,
      userId,
      pageCtxId,
      pageName,
      platform,
      userGuid,
      organizationId,
      contextId,
    }),
  });

  return (
    <Container paddingTop="1" order={55} minHeight={44} paddingX={{ md: '6' }} ref={containerShownRef}>
      <PaypalPayLaterLearnMore
        clientToken={paypalClientToken}
        paypalStyle={PAYPAL_LEARN_MORE_STYLES.LEFT}
        onClick={() =>
          biEvents.sendRawBigQueryEvent(
            buildPaypalCreditLearnClickedEvent({
              eventSource,
              sellerUsername,
              general,
              gigId,
              sellerId,
              userId,
              pageCtxId,
              pageName,
              platform,
              userGuid,
              organizationId,
              contextId,
            })
          )
        }
      />
    </Container>
  );
};
