import _ from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { buildDescriptionSeo } from '../../../utils/buildMetadata';
import { isGigApproved } from '../../../utils/gigStatus';
import { sanitizeSeo } from '../utils';
import { GENERAL_SCHEMA, IN_STOCK, OUT_OF_STOCK, BRAND, FIVERR } from '../constants';

export const shouldFormatSeo = (seo) => seo && !_.isEmpty(seo.schemaMarkup);

/**
 * Format SEO related data in sanitized JSON
 * @param {object} seo the SEO-data from initial props.
 * @param {object} gigRating gig rating from reviews service response
 * @param {object} pathfinderWrapper the context's pathfinder wrapper.
 * @param {number} packagesCount number of packages
 * @param {object} general the context's general.
 * @param {string} localizedGigTitle translated gig title
 * @param {string} localizedDescription translated gig description
 * @returns {JSON} formatted and sanitized SEO data in JSON format
 */
export const formatSeo = ({
    seo,
    gigRating,
    pathfinderWrapper,
    packagesCount,
    general,
    localizedGigTitle,
    localizedDescription,
}) => {
    const { pathParameters, locale } = getContext();
    const { schemaMarkup, description } = seo;
    const { username, slug } = pathParameters;
    const { gigStatus, subCategoryId } = general;
    const availability = isGigApproved(gigStatus) ? IN_STOCK : OUT_OF_STOCK;
    const descriptionSeo = localizedDescription || buildDescriptionSeo(description, locale, subCategoryId);

    const formattedSeo = {
        '@context': GENERAL_SCHEMA,
        '@type': schemaMarkup.type,
        name: localizedGigTitle || schemaMarkup.gigTitle,
        image: schemaMarkup.gigImage,
        brand: {
            '@type': BRAND,
            name: FIVERR,
        },
        description: descriptionSeo,
    };

    const gigRatings = schemaMarkup.gigRatings,
        gigOffers = schemaMarkup.gigOffers;

    if (gigRatings) {
        formattedSeo.aggregateRating = {
            '@type': gigRatings.type,
            ratingValue: gigRating,
            reviewCount: gigRatings.count,
            bestRating: gigRatings.best,
            worstRating: gigRatings.worst,
        };
    }

    if (gigOffers) {
        formattedSeo.offers = {
            '@type': gigOffers.type,
            url: pathfinderWrapper.pathfinder('gig_page', { username, slug }, { absolute: true }),
            price: gigOffers.price,
            lowPrice: gigOffers.lowPrice,
            highPrice: gigOffers.highPrice,
            priceCurrency: gigOffers.currency,
            offerCount: packagesCount,
            availability: `${GENERAL_SCHEMA}${availability}`,
        };
    }

    return sanitizeSeo(formattedSeo);
};
