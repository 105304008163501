import React from 'react';
import { Container } from '@fiverr-private/layout_components';
import { AvatarIcon } from '@fiverr-private/avatar';
import { CheckStarSolidIcon } from '@fiverr-private/visuals';

export function ProBadge() {
  return (
    <Container hidden={{ default: true, sm: false }}>
      <AvatarIcon padding="1" backgroundColor="business_800" position="bottom-right" showRing={false}>
        <CheckStarSolidIcon color="white" size="xs" />
      </AvatarIcon>
    </Container>
  );
}
