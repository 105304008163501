import { SELECT_CHECKOUT_PLAN, SET_CHECKOUT_DRAWER_OPEN } from '../../actions/checkoutPlan';
import { CHECKOUT_PLANS } from '../../utils/checkoutPlan/constants';
import { isRecurringGig } from '../packages';
import { getDefaultCheckoutPlan } from './utils';

/**
 * This creates a reducer for the checkoutPlan store
 * @param workProcess the work process data of the gig
 * @param {object} general
 * @param {object} packages
 * @returns {object} a reducer for the checkout plan store
 */
const checkoutPlanReducer = ({ workProcess = {}, general, packages = {} }) => {
    const isRecurring = isRecurringGig(packages);
    const selectedCheckoutPlan = getDefaultCheckoutPlan({ workProcess, general, isRecurring });

    const initialState = {
        selectedCheckoutPlan,
        checkoutDrawerOpen: false,
    };

    return (state = initialState, action) => {
        switch (action.type) {
            case SELECT_CHECKOUT_PLAN:
                const { selectedCheckoutPlan } = action.data;

                return {
                    ...state,
                    selectedCheckoutPlan,
                };
            case SET_CHECKOUT_DRAWER_OPEN:
                const { checkoutDrawerOpen } = action.data;

                return {
                    ...state,
                    checkoutDrawerOpen,
                };
            default:
                return state;
        }
    };
};

// Selectors
/**
 * Get the currently selected checkout plan
 * @param {object} state redux state
 * @returns {string} selected checkout plan
 */
export const getSelectedCheckoutPlan = ({ checkoutPlan: { selectedCheckoutPlan } }) => selectedCheckoutPlan;

/**
 * Get if the currently selected checkout plan is single order
 * @param {object} state redux state
 * @returns {boolean} is the currently selected checkout plan is single order
 */
export const isSingleOrderSelected = ({ checkoutPlan: { selectedCheckoutPlan } }) =>
    selectedCheckoutPlan === CHECKOUT_PLANS.SINGLE_ORDER;

/**
 * Get if the currently selected checkout plan is recurring order
 * @param {object} state redux state
 * @returns {boolean} is the currently selected checkout plan is recurring order
 */
export const isRecurringOrderSelected = ({ checkoutPlan: { selectedCheckoutPlan } }) =>
    selectedCheckoutPlan === CHECKOUT_PLANS.RECURRING_ORDER;

/**
 * Get if the currently selected checkout plan is milestones order
 * @param {object} state redux state
 * @returns {boolean} is the currently selected checkout plan is milestonespayment
 */
export const isMilestonesOrderSelected = ({ checkoutPlan: { selectedCheckoutPlan } }) =>
    selectedCheckoutPlan === CHECKOUT_PLANS.MILESTONES_ORDER;

/**
 * A flag that indicates whether the checkout drawer is open
 * @param {object} state redux state
 * @returns {boolean} is the checkout drawer open
 */
export const isCheckoutDrawerOpened = ({ checkoutPlan: { checkoutDrawerOpen } }) => checkoutDrawerOpen;

export default checkoutPlanReducer;
