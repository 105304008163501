import { Calculator } from '../../types/gig/interfaces';

export const calcCalculatorAdditionalPrice = (calculator: Calculator): number => {
    const { value, currentValue, price, additionalValue } = calculator;
    const delta = Number(currentValue) - value;

    if (delta <= 0) {
        return 0;
    }

    const additionalUnitsToBasePrice = Math.ceil(delta / additionalValue);

    return additionalUnitsToBasePrice * price;
};
