import Cookies from 'universal-cookie';
import { MONTH_IN_MS } from '@fiverr-private/futile';
import { PRO_BANNER_WAS_CLOSED_COOKIE } from '../../../server/cookies';

const TTL_MONTHS = 2;
const TTL_MS = MONTH_IN_MS * TTL_MONTHS;

const cookies = new Cookies();

const setCookie = (name: string, value: string | object) => {
    cookies.set(name, value, {
        expires: new Date(Date.now() + TTL_MS),
        path: '/',
    });
};

export const setWasClosed = () => {
    setCookie(PRO_BANNER_WAS_CLOSED_COOKIE, 'true');
};
