import { createImpressionEvent } from '../base';
import { VOICE_OVER_AI_AUDITION_PARAMS } from '../constants';

/**
 * BI impression event for the Voice Over AI Audition component.
 * @param {string} type The event's type.
 * @returns {object} parameters for the event.
 */
export const createVoiceOverAiAuditionComponentIsShown = (type) =>
    createImpressionEvent(type, VOICE_OVER_AI_AUDITION_PARAMS);
