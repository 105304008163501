import { getContext } from '@fiverr-private/fiverr_context';

/**
 * Returns the seller username of the current gig
 */
export const getSellerUsername = (): string => {
    const { pathParameters = {} } = getContext();
    const { username } = pathParameters;

    return username;
};
