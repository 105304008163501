import React from 'react';
import { object, string } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { Button } from '@fiverr-private/fit';
import { EducationTooltip } from '@fiverr-private/orca';
import { I18n } from '@fiverr-private/i18n-react';
import SocialSharing from '../shared/SocialSharing';
import TopPanel from '../shared/TopPanel';
import SafePureComponent from '../shared/SafePureComponent';
import Illustration from './assets/Illustration.svg';
import PromoteYourGig from './PromoteYourGig';
import style from './style.module.scss';

const SellerBanner = ({ promotionStatus }, { general }) => {
    const { gigTitle } = general;
    const {
        pathParameters: { username },
    } = getContext();
    const showPromotedBanner = !!promotionStatus;

    const COPY_PREFIX = 'gig_page_perseus.seller_banner';
    let shareClassName = style.shareBtn;
    let shareCopyKey = 'share_your_gig_button_text';
    let body = `${COPY_PREFIX}.body_share`;
    let btnFill;

    if (showPromotedBanner) {
        shareClassName = style.shareWithPromoteBtn;
        shareCopyKey = 'share_button_text';
        body = `${COPY_PREFIX}.body_share_and_promote`;
        btnFill = Button.FILLS.GHOST;
    }

    return (
        <TopPanel customClass={style.topPanel}>
            <img className={style.illustrationIcon} src={Illustration} alt="" />
            <div className={style.mainSection}>
                <header className={style.label}>
                    <h2 className={style.title}>
                        <I18n k={`${COPY_PREFIX}.title`} />
                    </h2>
                    <p className={style.body}>
                        {' '}
                        <I18n k={body} />
                    </p>
                </header>
                <div className={style.buttonsSection}>
                    {showPromotedBanner && (
                        <EducationTooltip
                            className={style.educationToolTip}
                            contentClassName={style.eductionPopOverContainer}
                            title={<I18n k={`${COPY_PREFIX}.education.title`} />}
                            boxClassName={style.educationToolTipBox}
                            body={<I18n k={`${COPY_PREFIX}.education.body`} />}
                            badgeTitle={<I18n k={`${COPY_PREFIX}.education.new`} />}
                            closeTitle={<I18n k={`${COPY_PREFIX}.education.close`} />}
                            tooltipStorageKey={'promote_your_gig_education'}
                            position={EducationTooltip.POSITIONS.BOTTOM}
                        >
                            <PromoteYourGig
                                username={username}
                                customClassName={style.promoteBtn}
                                promotionStatus={promotionStatus}
                            />
                        </EducationTooltip>
                    )}
                    <SocialSharing
                        gigTitle={gigTitle}
                        customClass={shareClassName}
                        sellerName={username}
                        isPageOwner
                        title={<I18n k={`${COPY_PREFIX}.${shareCopyKey}`} />}
                        btnFill={btnFill}
                    />
                </div>
            </div>
        </TopPanel>
    );
};

SellerBanner.propTypes = {
    promotionStatus: string,
};

SellerBanner.contextTypes = {
    general: object,
};

export default SafePureComponent(SellerBanner);
