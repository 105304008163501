/**
 * Calculates the regular duration (when no extra fast is accounted for)
 *
 * Example:
 * maxNumWordsPerPackageDuration: 400
 * maxNumWordsPerOneMoreDay: 1000
 * duration : 2
 *
 * scriptLength in range of 0 - 400 : 2
 * scriptLength in range of 401 - 1400 : 2 + 1
 * scriptLength in range of 1401 - 2400 : 2 + 1 + 1
 */
export const calculateRegularDuration = ({ scriptLength, packageData }) => {
    const { duration, maxNumWordsPerOneMoreDay, maxNumWordsPerPackageDuration } = packageData;

    if (scriptLength <= maxNumWordsPerPackageDuration) {
        return duration;
    }

    const additionalWordEffort = scriptLength - maxNumWordsPerPackageDuration;
    const additionalDays = Math.ceil(additionalWordEffort / maxNumWordsPerOneMoreDay);
    return duration + additionalDays;
};
