import React from 'react';
import { Icon } from '@fiverr-private/fit';
import { s_info_circle } from '@fiverr-private/fit/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import SafePureComponent from '../shared/SafePureComponent';
import { getDisclaimers } from './Disclaimer.config';

import './style.scss';

const _Disclaimer = () => {
  const { general, pathfinderWrapper } = useGigPageContext();
  const { categoryId, subCategoryId, nestedSubCategoryId, isStudio } = general;
  const disclaimers = getDisclaimers(pathfinderWrapper);

  const verticalDisclaimer = disclaimers[Number(categoryId)];
  const specificDisclaimer = disclaimers[Number(nestedSubCategoryId)] || disclaimers[Number(subCategoryId)];
  const disclaimersToShow = [verticalDisclaimer, specificDisclaimer].filter(Boolean);

  if (disclaimersToShow.length === 0 || isStudio) {
    return null;
  }

  return (
    <>
      {disclaimersToShow.map(({ title, description, i18nTemplates }, index) => (
        <div key={index} className="disclaimer">
          <header>
            <h6>
              <Icon size={12} className="disclaimer-icon">
                {s_info_circle}
              </Icon>
              <I18n k={title} />
            </h6>
          </header>

          <p>
            <I18n k={description} templates={i18nTemplates} />
          </p>
        </div>
      ))}
    </>
  );
};

export const Disclaimer = SafePureComponent(_Disclaimer);
