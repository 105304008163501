import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, object, shape, string, bool, func } from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import SafePureComponent from '../shared/SafePureComponent';
import WithImpressions from '../shared/withImpressions';
import { validateDescription } from '../../utils/mainComponentsValidator';
import { sanitize } from '../../utils/sanitization';
import { getCurrentContent } from '../../utils/ugc/getCurrentContent';
import { isTranslated } from '../../utils/ugc/isTranslated';
import { BQ_GIG_DESCRIPTION_SEE_MORE } from '../../utils/bigQueryEvents';
import TranslatedBadge from '../shared/TranslatedBadge';
import { shouldShowSignUpForm } from '../../utils/shouldShowSignUpForm';
import { triggerSignUp } from '../../utils/signUp';
import SellerTranslationBadgeWrapper from '../shared/SellerTranslationBadgeWrapper';
import { isFieldTranslatedBySeller } from '../../utils/ugc/isFieldTranslatedBySeller';
import { DESCRIPTION } from '../../utils/pageSections';
import { Waypoint } from '../Waypoint/Waypoint';
import Metadata from './Metadata';

import './style.scss';

const createMarkup = (data) => (data ? { __html: data } : null);
const CLASSNAME = 'gig-description';

class Description extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showInfo: false,
        };

        this.toggleCollapsed = this.toggleCollapsed.bind(this);
    }

    async toggleCollapsed(event) {
        event.preventDefault();

        const { showInfo } = this.state;
        const { biEvents, pathfinderWrapper } = this.context;

        if (!showInfo) {
            this.reportShowInfoEvent();

            if (shouldShowSignUpForm()) {
                triggerSignUp({
                    biEvents,
                    pathfinderWrapper,
                    options: { source: 'gig_page_description' }
                });
            }
        }

        this.setState({
            showInfo: !showInfo,
        });
    }

    reportShowInfoEvent() {
        const { biEvents } = this.context;

        biEvents.sendBigQueryEvent({ eventName: BQ_GIG_DESCRIPTION_SEE_MORE });
    }

    onEnterWaypoint = () => {
        this.props.collectImpressions();
    };

    render() {
        const { showInfo } = this.state;
        const { description, metadataAttributes, showTranslatedBadge } = this.props;
        const { isBusinessLayout, isProRebrandingLayout } = this.context;

        if (!validateDescription({ description })) {
            return null;
        }

        const showContent = classNames('description-content', { show: showInfo });
        const classes = classNames(CLASSNAME, { 'with-translated-badge': showTranslatedBadge });
        const { content } = description;
        const sanitizedContent = createMarkup(sanitize(content));
        const showKey = showInfo ? 'see_less' : 'see_more';

        const titleCopy = isBusinessLayout
            ? 'gig_page_perseus.top_nav.page_sections.theService'
            : 'gig_page_perseus.description.title';

        const descriptionCollapseButtonClasses = classNames('collapse-button', {
            'new-pro-layout': isBusinessLayout || isProRebrandingLayout,
        });

        return (
            <>
                <Waypoint source={DESCRIPTION} />
                <div className={classes}>
                    <header>
                        <h2 className="section-title">
                            <SellerTranslationBadgeWrapper translationKey="description">
                                <I18n k={titleCopy} />
                            </SellerTranslationBadgeWrapper>
                        </h2>
                        {showTranslatedBadge && <TranslatedBadge />}
                    </header>
                    {sanitizedContent && (
                        <div className="description-wrapper">
                            <Waypoint onEnter={this.onEnterWaypoint} />
                            <div className={showContent} dangerouslySetInnerHTML={sanitizedContent} />
                            <div className="cover" />
                            <button className={descriptionCollapseButtonClasses} onClick={this.toggleCollapsed}>
                                <I18n k={`gig_page_perseus.description.${showKey}`} />
                            </button>
                        </div>
                    )}
                    <Metadata metadataAttributes={metadataAttributes} />
                </div>
            </>
        );
    }
}

Description.propTypes = {
    showTranslatedBadge: bool,
    description: shape({
        content: string,
    }),
    metadataAttributes: arrayOf(
        shape({
            name: string,
            options: arrayOf(string),
        })
    ),
    collectImpressions: func,
};

Description.contextTypes = {
    general: object,
    biEvents: object,
    pathfinderWrapper: object,
    isBusinessLayout: bool,
    isProRebrandingLayout: bool,
};

const mapStateToProps = ({ ugc }) => {
    const { description } = getCurrentContent({ ugc });
    const isFieldFromSellerTranslations = isFieldTranslatedBySeller(ugc, 'description');
    const isContentTranslated = isTranslated(ugc);

    return {
        description,
        showTranslatedBadge: isContentTranslated && !isFieldFromSellerTranslations,
    };
};

const ConnectedDescription = connect(mapStateToProps)(Description);

const impressionConfig = {
    DOMSelector: `.${CLASSNAME} .description-content`,
    eventKey: 'gig_description',
};

export { Description, ConnectedDescription };
export default SafePureComponent(WithImpressions(ConnectedDescription, impressionConfig));
