import { isEmpty, isArray, pick } from 'lodash';

/**
 * Get the title of the gig on client-side(!) as entered by the seller (UGC) together with the correct
 * Prefix of "I will" for regular seller or "Our studio will" for studios.
 * For example: If the gig title is "design a logo for you"
 * Output when isStudio is false should be: "I will design a logo for you"
 * @param {Boolean} isAgency - Is the gig seller an Agency of Sellers
 * @param {String} title - The gig title the seller entered (UGC)
 * @param {String} locale - The current locale
 * @returns {String} Translation of the gig title prefix with the UGC as parameter
 */
export const getTitle = ({ isAgency, title, locale }) => {
    const key = isAgency ?
        'gig_page_perseus.general.our_agency_will' :
        'gig_page_perseus.general.i_will';

    return i18n.t(key, { $scope: locale, title });
};

export const enrichTooltipWithIWill = (buyerTooltip, locale, isAgency = false) =>
    isAgency ?
    i18n.t('gig_page_perseus.general.our_agency_will', { $scope: locale, title: buyerTooltip }) :
    i18n.t('gig_page_perseus.general.i_will', { $scope: locale, title: buyerTooltip });

export const extractPackagesContent = ({ packageList = [] }, locale, isAgency) => {
    const packages = {};

    packageList.forEach(({ title, description, customExtras = [], id }) => {
        packages[id] = {
            title,
            description,
            customExtras: customExtras.map(({ label, buyerTooltip }) => ({
                label,
                buyerTooltip: enrichTooltipWithIWill(buyerTooltip, locale, isAgency),
            })),
        };
    });

    return packages;
};

export const extractStudioContent = ({ info }) => pick(info, ['tagline']);

/**
 * Extract workflow content for machine translations.
 * @param {Object} workflow
 * @returns Workflow texts content
 */
export const extractWorkflowContent = (workflow) => {
    if (isEmpty(workflow)) {
        return {};
    }

    return {
        steps: workflow.steps.map((step) => pick(step, ['title', 'description'])),
    };
};

/**
 * Extract workProcess content for machine translations.
 * @param {Object} workProcess
 * @returns {Object} workProcess texts content
 */
export const extractWorkProcessContent = (workProcess) => {
    if (isEmpty(workProcess) || !isArray(workProcess.milestones)) {
        return {};
    }

    return {
        milestones: workProcess.milestones.map(({ title, description, steps }) => ({
            title,
            description,
            steps: isArray(steps) ? steps.map(({ title, description }) => ({ title, description })) : [],
        })),
    };
};
