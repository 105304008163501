import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ABOUT_SELLER, RECOMMENDATIONS } from '../../../utils/pageSections';
import { GIG_SUB_HEADER } from '../../../utils/events';
import SellerCard from '../../SellerCard';
import Description from '../../Description';
import Workflow from '../../Workflow';
import WorkProcess from '../../WorkProcess';
import Faq from '../../Faq';
import Reviews from '../../Reviews';
import Tags from '../../Tags';
import PackagesTable from '../../PackagesTable';
import OrderDetails from '../../OrderDetails';
import Gallery from '../../Gallery';
import Overview from '../../Overview';
import Portfolio from '../../Portfolio';
import { OutOfOffice } from '../../OutOfOffice/OutOfOffice';
import { Disclaimer } from '../../Disclaimer/Disclaimer';
import { MoreServices } from '../../MoreServices/MoreServices';
import ProVideo from '../../ProVideo';
import ProBanner from '../../ProBanner';
import ProCertificateBanner from '../../ProCertificateBanner';
import ReviewsSnippet from '../../ReviewsSnippet';
import UGCTranslateButton from '../../UGCTranslateButton';
import LimitedEditionBanner from '../../LimitedEdition/Banner';
import PersonalizationWrapper from '../../PersonalizationWrapper';
import VoiceOverAiAudition from '../../VoiceOverAiAudition';
import SellerTranslationBadgeWrapper from '../../shared/SellerTranslationBadgeWrapper';
import { SECTION_NAMES } from '../../PersonalizationWrapper/constants';
import { LayoutProps } from '../gigThemesConfig';
import { Waypoint } from '../../Waypoint/Waypoint';
import { OffersHourlyRatesBanner } from '../../OffersHourlyRates/OffersHourlyRatesBanner';

const calculateAboutSellerTitleKey = (isAgency: boolean, isBusinessLayout: boolean) => {
  if (isBusinessLayout) {
    return isAgency ? 'gig_page_perseus.general.about_us' : 'gig_page_perseus.general.about_me';
  }

  return isAgency ? 'gig_page_perseus.general.about_this_agency' : 'gig_page_perseus.general.about_the_seller_new';
};

export const ExperientialGigPage = (props: LayoutProps) => {
  const {
    overview,
    repeatScore,
    notableClients,
    voiceOverAiAudition,
    gallery,
    reviews,
    workflow,
    seller,
    sellerCard,
    tags,
    info,
    description,
    shouldRenderUGCTranslateButton,
    username,
    portfolioProps,
    useStickyLayout,
    paymentType,
    shouldRenderComparePersonalizedSection,
  } = props;

  const {
    isBusinessLayout,
    seller: { isAgency },
  } = useGigPageContext();
  const aboutTheSellerTitleKey = calculateAboutSellerTitleKey(isAgency, isBusinessLayout);

  const shouldRenderProBanner = props.shouldRenderProBanner();
  const shouldRenderReviews = props.shouldRenderReviews();
  const shouldRenderProVideo = props.shouldRenderProVideo();
  const shouldRenderLimitedEditionBanner = props.shouldRenderLimitedEditionBanner();
  const shouldRenderNewProBanner = props.shouldRenderNewProBanner();
  const { metadataAttributes: descriptionMetadata } = description;
  const sellerName = seller?.user?.profile?.displayName || username;

  return (
    <>
      {shouldRenderProBanner && <ProBanner />}
      {shouldRenderLimitedEditionBanner && <LimitedEditionBanner />}
      <OutOfOffice />
      <Overview
        overview={overview}
        studioInfo={info}
        useStickyLayout={useStickyLayout}
        repeatScore={repeatScore}
        notableClients={notableClients}
        showProBanner={shouldRenderNewProBanner}
      />
      {shouldRenderUGCTranslateButton && <UGCTranslateButton componentName={GIG_SUB_HEADER} />}

      {voiceOverAiAudition && <VoiceOverAiAudition voiceOverAiAudition={voiceOverAiAudition} />}

      <Gallery gallery={gallery} />
      {shouldRenderReviews && <ReviewsSnippet reviews={reviews} />}

      <Description metadataAttributes={descriptionMetadata} />

      <Workflow workflow={workflow} />
      <WorkProcess paymentType={paymentType} />

      <Disclaimer />

      {shouldRenderProBanner && <ProCertificateBanner />}

      <>
        <Waypoint source={ABOUT_SELLER} />
        <h2 className="section-title about-the-seller">
          <span>
            <SellerTranslationBadgeWrapper>
              <I18n k={aboutTheSellerTitleKey} params={{ sellerName }} />
            </SellerTranslationBadgeWrapper>
          </span>
        </h2>
        <SellerCard {...sellerCard} seller={seller} notableClients={notableClients} sellerName={username} />
        <Portfolio {...portfolioProps} />
      </>

      <div>
        <PackagesTable />
        <OffersHourlyRatesBanner seller={seller} />
      </div>
      <OrderDetails />

      <Waypoint source={RECOMMENDATIONS} />
      {shouldRenderComparePersonalizedSection() && (
        <PersonalizationWrapper sectionName={SECTION_NAMES.COMPARE} className="personalized-content-compare" />
      )}

      <MoreServices />
      {shouldRenderProVideo && <ProVideo />}
      <Faq />

      {shouldRenderReviews && <Reviews reviews={reviews} />}

      <Tags tags={tags} />
    </>
  );
};
