import { logger } from '@fiverr-private/obs';
import { localStorage } from '@fiverr-private/futile';
import { PROMOTIONS_VIEWED_LOCAL_STORAGE_KEY, SELLER_COUPON_TYPES } from '../GigPage/constants';

export const getViewedPromotions = () => {
    let views = [];
    try {
        views = JSON.parse(localStorage.get(PROMOTIONS_VIEWED_LOCAL_STORAGE_KEY) || '[]');
    } catch (e) {
        logger.warn('PromotionModal: Failed to parse promotion views from LocalStorage');
    }
    return views;
};

export const shouldShowPromotionModal = (coupon, gigId) => {
    if (coupon) {
        const { expiresAt, id, type } = coupon;
        const expireDate = new Date(expiresAt);
        expireDate.setDate(expireDate.getDate() + 1);
        const expired = expireDate.getTime() < new Date().getTime();

        try {
            const views = getViewedPromotions();
            const couponWasViewed = views.includes(`${id}-${gigId}`);
            if (type === SELLER_COUPON_TYPES.PROMOTION && !expired && !couponWasViewed) {
                return true;
            }
        } catch {
            this.logger.warn('GigPage: Failed to parse promotion views from LocalStorage');
        }
    }
    return false;
};

export const addCouponToLocalStorage = (couponId, gigId) => {
    try {
        const views = getViewedPromotions();
        views.push(`${couponId}-${gigId}`);
        localStorage.set(PROMOTIONS_VIEWED_LOCAL_STORAGE_KEY, JSON.stringify(views));
    } catch {
        logger.warn('PromotionModal: Failed to parse promotion views from LocalStorage');
    }
};
