import React, { useState, useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import MediaQuery from 'react-responsive';
import Sticky from 'react-stickynode';
import { MEDIA_GRID_MD } from '../../utils/constants';
import SafePureComponentWrapper from '../shared/SafePureComponent';
import SidebarContent from './SidebarContent';

import './style.scss';

// The offset from the top of window where the top of the element will be, when sticky state is triggered
// in our case, we have the .gig-page.padding = 32
const REACT_STICKY_TOP_OFFSET = 32;

const Sidebar = ({ customOrder, studio, useStickyLayout, containerClassName = '', setRef, seller }) => {
    const [allowSticky, setAllowSticky] = useState(false);

    const contentProps = {
        customOrder,
        studio,
        useStickyLayout,
        seller,
    };

    const sidebarClassName = 'sidebar';
    const sidebarStickyStyleClassName = classNames(sidebarClassName, 'style-sticky');

    useEffect(() => setAllowSticky(true), []);

    return (
        <MediaQuery query={`(min-width: ${MEDIA_GRID_MD})`}>
            {(matches) =>
                matches && allowSticky ? (
                    <Sticky
                        enabled={useStickyLayout}
                        top={REACT_STICKY_TOP_OFFSET}
                        innerClass="inner-sticky"
                        bottomBoundary={`.${containerClassName}`}
                        className={classNames(sidebarClassName, 'react-sticky')}
                    >
                        <SidebarContent {...contentProps} />
                    </Sticky>
                ) : (
                    <SidebarContent {...contentProps} className={sidebarStickyStyleClassName} setRef={setRef} />
                )
            }
        </MediaQuery>
    );
};

Sidebar.propTypes = {
    studio: object,
    customOrder: object,
    useStickyLayout: bool,
    containerClassName: string,
    setRef: func,
    seller: object,
};

export { Sidebar };
export default SafePureComponentWrapper(Sidebar);
