export const MEDIA_GRID_UNDER_SM = '599px';
export const MEDIA_GRID_MD = '900px';
export const SCROLL_DURATION = 270;
export const PAGE_OFFSET = 78;
export const BUSINESS_PAGE_OFFSET = 10;
export const BUSINESS_SCROLL_OFFSET = 0 - BUSINESS_PAGE_OFFSET;
export const SCROLL_OFFSET = 0 - PAGE_OFFSET;
export const WAYPOINT_OFFSET = 1 + PAGE_OFFSET;

/**
 * @constant {string}
 * @desc Prefix for grafana lazy load import monitoring.
 * **/
export const LAZY_LOAD_ERROR_PREFIX = 'lazy_load_import';
