import { sessionStorage } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { triggerSignInModal } from '../../../../../utils/triggerSignInModal';
import { extractQueryParams } from '../../../../../utils/queryParams/extract/extractQueryParams';
import { TRIGGER_DRAWER_KEY } from './constants';

/** Check if the Sign In Modal is loaded and sign user In.
 * Make sure to run this code in CSR only. (use useEffect Hook)
 * `urlParamMap` is used to encode Search Query in URL Params so that the user Selections will be loaded on Page Reload.
 * @param {number} gigId
 * @param {object} urlParamMap
 */
export const triggerSignIn = (gigId: number, urlParamMap: Map<string, string>, modalOptions = {}) => {
    const { experience } = getContext();
    const onSuccessHook = () => !experience?.isBusiness && saveDrawerTriggerAndRefreshPage(gigId, urlParamMap);

    triggerSignInModal({ ...modalOptions, afterSigningSuccess: onSuccessHook });
};

/** Saves a flag to Session Storage so that after the page refresh - the Drawer will open without an extra click
 * Also handles saving User selected Package State to URL params, so that package will open the same,
 * without resetting its values.
 * @param {number} gigId - current gig
 * @param {object} urlParamsMap
 */
export const saveDrawerTriggerAndRefreshPage = (gigId: number, urlParamsMap = {}) => {
    sessionStorage.set(TRIGGER_DRAWER_KEY, gigId);

    const searchParams = new URLSearchParams(window.location.search);

    Object.keys(urlParamsMap).forEach((key) => {
        const value = urlParamsMap[key];
        if (value) {
            searchParams.set(key, value);
        } else {
            // clear empty values from Search Params
            searchParams.delete(key);
        }
    });

    window.location.search = searchParams.toString();
};

/** Removes Drawer Trigger from Session Storage - When component is loaded */
export const removeDrawerTrigger = () => {
    if (getDrawerTrigger()) {
        sessionStorage.remove(TRIGGER_DRAWER_KEY);
    }
};

/** Check if Drawer Trigger exists and is equal to current Gig ID
 * @param gigId
 */
export const shouldTriggerDrawerOpen = (gigId: number) => {
    const { openDrawer: openDrawerQueryParam } = extractQueryParams();
    const drawerTrigger = getDrawerTrigger();
    const triggeredByCurrentGig = drawerTrigger === gigId;

    return triggeredByCurrentGig || openDrawerQueryParam;
};

/** Checks Session Storage for the Drawer Trigger key */
const getDrawerTrigger = () => sessionStorage.get(TRIGGER_DRAWER_KEY);
