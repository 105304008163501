import { CHECKOUT_PLANS } from '../../../utils/checkoutPlan/constants';
import { isTestedOutRecurringSubCategory } from '../../../utils/recurring';
import { isActiveMilestonePay } from '../../workProcess';
import { extractQueryParams } from '../../../utils/queryParams/extract/extractQueryParams';

/**
 * A flag that indicates whether the gig has subscriptions and in relevant SCs for default subscription checkout
 * Also supports a SubscriptionId queryParam to handle `OrderAgain` from Order Page
 * @param {Number} subCategoryId
 * @param {boolean} isRecurringGig
 * @returns {boolean} is in rollout
 */
export const isRecurringOrderDefaultCheckoutPlan = (subCategoryId, isRecurringGig) => {
    if (!isRecurringGig) {
        return false;
    }

    const { durationQueryParam, durationTimeunitQueryParam } = extractQueryParams();
    const hasValidSubscriptionParams = durationQueryParam && durationTimeunitQueryParam;

    const inRecurringOptionSubCategory = isTestedOutRecurringSubCategory(subCategoryId);

    return inRecurringOptionSubCategory || hasValidSubscriptionParams;
};

/**
 * The enum value of the default checkout plan
 * @param {object} workProcess
 * @param {object} general
 * @param {boolean} isRecurring
 * @returns {string} The default checkout plan
 */
export const getDefaultCheckoutPlan = ({ workProcess, general, isRecurring }) => {
    const { subCategoryId } = general;

    if (isRecurringOrderDefaultCheckoutPlan(subCategoryId, isRecurring)) {
        return CHECKOUT_PLANS.RECURRING_ORDER;
    }

    return isActiveMilestonePay({ workProcess }) ? CHECKOUT_PLANS.MILESTONES_ORDER : CHECKOUT_PLANS.SINGLE_ORDER;
};
