import { isNaN, isNil } from 'lodash';

/**
 * returns `undefined` if not a number.
 * @param {string | undefined } value
 * @return {number | undefined}
 */
export const formatNumber = (value: string | undefined) => {
    if (isNil(value)) {
        return undefined;
    }

    const parseNumber = Number(value);
    const isNumeric = !isNaN(parseNumber);

    return isNumeric ? parseNumber : undefined;
};
