/**
 * Shutterstock pricing factors translation root
 * @type {string}
 */
export const SHUTTERSTOCK_ROOT = 'gig_page_perseus.packages.shutterstock';

/** Shutterstock Included PF keys */
export const SHUTTERSTOCK_PF: Record<any, string> = {
    IMAGES: 'commercially_licensed_images',
    MUSIC: 'commercially_licensed_music',
    VIDEO_CLIPS: 'commercially_licensed_video_clips',

    /** Shutterstock Not-Included PF keys */
    IMAGES_EXTRA: 'additional_commercially_licensed_images',
    MUSIC_EXTRA: 'additional_commercially_licensed_music',
    VIDEO_CLIPS_EXTRA: 'additional_commercially_licensed_video_clips',
};

/**
 * Shutterstock pricing factors names
 * Maps between the pricing factor name and its tooltip translation key
 * @type {Object<string>}
 */
export const SHUTTERSTOCK_METRIC_REPORT_KEYS: Record<string, string> = {
    [SHUTTERSTOCK_PF.IMAGES]: 'hasStockImagePF',
    [SHUTTERSTOCK_PF.MUSIC]: 'hasStockMusicPF',
    [SHUTTERSTOCK_PF.VIDEO_CLIPS]: 'hasStockVideoPF',
    [SHUTTERSTOCK_PF.IMAGES_EXTRA]: 'hasStockImageExtra',
    [SHUTTERSTOCK_PF.MUSIC_EXTRA]: 'hasStockMusicExtra',
    [SHUTTERSTOCK_PF.VIDEO_CLIPS_EXTRA]: 'hasStockVideoExtra',
};

/**
 * Shutterstock pricing factors names
 * Maps between the pricing factor name and its tooltip translation key
 * @type {Object<string>}
 */
export const SHUTTERSTOCK_PRICING_FACTORS: Record<string, string> = {
    [SHUTTERSTOCK_PF.IMAGES]: `${SHUTTERSTOCK_ROOT}.types.images`,
    [SHUTTERSTOCK_PF.MUSIC]: `${SHUTTERSTOCK_ROOT}.types.music_clips`,
    [SHUTTERSTOCK_PF.VIDEO_CLIPS]: `${SHUTTERSTOCK_ROOT}.types.video_clips`,
};

/**
 * Shutterstock help center article anchored to the correct section
 * @type {string}
 */
export const SHUTTERSTOCK_HELP_CENTER_ARTICLE_ANCHOR = '8388315006353#h_01GAGDEATH0Y1Z8VX0PM8APP23';
