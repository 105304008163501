import { pick } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import {
    extractPackagesContent,
    extractStudioContent,
    extractWorkflowContent,
    extractWorkProcessContent,
    getTitle,
} from '../shared';
import { calcAgencyData } from '../../calcAgenciesData/calcAgenciesData';

export const extractUGC = ({
    seller,
    overview,
    description,
    workflow,
    workProcess,
    sellerCard,
    faq,
    packages = {},
    studio = {},
}) => {
    const { gig } = overview;
    const { title } = gig;
    const { locale } = getContext();
    const { isAgency } = calcAgencyData({ seller });

    return {
        gigTitle: getTitle({ isAgency, title, locale }),
        description: pick(description, 'content'),
        workflow: extractWorkflowContent(workflow),
        workProcess: extractWorkProcessContent(workProcess),
        sellerCard: pick(sellerCard, ['description', 'oneLiner']),
        studio: extractStudioContent(studio),
        faq,
        packages: extractPackagesContent(packages, locale, isAgency),
    };
};
