import React, { Component } from 'react';
import { object, bool } from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import { logger } from '@fiverr-private/obs';
import { metric } from '../../utils/metric';
import { maxios } from '../../utils/maxios';
import SafePureComponent from '../shared/SafePureComponent';
import GigGallery from './GigGallery';

import './style.scss';

export const WAVEFORM_BATCH_SIZE = 5;

const formatSlides = (gallery) => {
    if (!gallery || !gallery.slides) {
        return [];
    }

    return gallery.slides.filter((slideObj) => slideObj && !!slideObj.slide).map((slideObj) => slideObj.slide);
};

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slides: formatSlides(props.gallery),
        };
    }

    async componentDidMount() {
        const { slides } = this.state;
        const { general, pathfinderWrapper } = this.context;

        const waveformIds = slides.reduce((acc, slide) => {
            if (slide.itemScopeType === 'AudioSlide') {
                acc.push(slide.waveformId);
            }

            return acc;
        }, []);

        while (waveformIds.length) {
            const waveformIdsBatch = waveformIds.splice(0, WAVEFORM_BATCH_SIZE);
            const url = pathfinderWrapper.pathfinder('gig_page_fetch_waveforms_api', {
                gig_id: general.gigId,
                waveform_ids: waveformIdsBatch,
            });

            try {
                const { data } = await maxios.get(url, { routeKey: 'gig_page_fetch_waveforms_api' });
                const { waveforms } = data;

                if (!Array.isArray(waveforms) || !waveforms.length) {
                    return;
                }

                const newSlides = this.state.slides.map((slide) => {
                    const waveform = waveforms.find((waveform) => waveform.id === slide.waveformId);
                    if (waveform) {
                        return { ...slide, waveform: waveform.waveformData };
                    } else {
                        return slide;
                    }
                });

                this.setState({
                    slides: newSlides,
                });
            } catch (error) {
                const status = (error.response && error.response.status) || 'unknown';
                metric.count(`bad_response.waveform.${status}`);
                logger.warn(error, { message: 'Failed to fetch waveforms' });
            }
        }
    }

    componentDidUpdate(prevProps) {
        const { gallery } = this.props;

        if (!isEqual(prevProps.gallery, gallery)) {
            this.setState({
                slides: formatSlides(gallery),
            });
        }
    }

    render() {
        const { slides } = this.state;
        const { isFullView } = this.props;

        return <GigGallery slides={slides} isFullView={isFullView} />;
    }
}

Gallery.propTypes = {
    gallery: object.isRequired,
    isFullView: bool,
};

Gallery.defaultProps = {
    isFullView: true,
};

Gallery.contextTypes = {
    general: object,
    pathfinderWrapper: object,
};

const mapStateToProps = ({ ugc }, ownProps) => {
    const { gallery = {} } = ownProps;
    const {
        showOriginal,
        ugcMap: { translatedBySeller },
    } = ugc;

    const isAttachmentsFromTLEmpty = isEmpty(translatedBySeller?.attachments);
    const shouldShowAttachmentsFromTL = !showOriginal && !isAttachmentsFromTLEmpty;

    if (shouldShowAttachmentsFromTL) {
        const { slides = [] } = gallery;
        const workSamples = slides.filter(({ slide }) => slide?.isWorkSample);
        const localizedAttachmentsWithWorkSamples = translatedBySeller?.attachments.concat(workSamples);

        return {
            gallery: {
                ...gallery,
                slides: localizedAttachmentsWithWorkSamples,
            },
        };
    }

    return {
        gallery,
    };
};

const ConnectedGallery = connect(mapStateToProps)(Gallery);

export { Gallery };
export default SafePureComponent(ConnectedGallery);
