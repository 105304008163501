import React from 'react';
import { shape, string, number } from 'prop-types';
import { SELLER_COUPON_TYPES } from '../GigPage/constants';
import { CouponBanner } from '../CouponBanner';
import { PromotionBanner } from '../PromotionBanner';

const COMPONENT_TYPE_MAP = {
    [SELLER_COUPON_TYPES.COUPON]: CouponBanner,
    [SELLER_COUPON_TYPES.PROMOTION]: PromotionBanner,
};

const CouponBannerContainer = ({ coupon }) => {
    const Component = COMPONENT_TYPE_MAP[coupon.type];

    if (Component) {
        return <Component coupon={coupon} />;
    }
    return null;
};

CouponBannerContainer.propTypes = {
    coupon: shape({
        code: string.isRequired,
        id: string.isRequired,
        type: string.isRequired,
        discountValue: number.isRequired,
        discountType: string.isRequired,
        providerName: string.isRequired,
        deliveryPackId: string,
    }).isRequired,
};

export default CouponBannerContainer;
