import { ConsumerExperimentsConfigs, ExperimentsConfigMap, RolloutResultsMap } from '../types';
import { isExperimentVariation } from '../..';
import { buildExperimentsMap } from './utils';

/** contains the Config and the Calculated Experiment Group */
let calculatedExperiments: ExperimentsConfigMap = {};

/**
 * Initializes Util
 * @param rollouts - rollouts from Perseus Server
 * @param experimentConfigs - Client Experiments Configuration
 * @param propsForEligibility - any props needed for the `isEligible` calculation - such as - Store etc.
 */
export const initClientExperiments = (
    rollouts: RolloutResultsMap,
    experimentConfigs: ConsumerExperimentsConfigs,
    propsForEligibility: any = {}
): void => {
    calculatedExperiments = buildExperimentsMap(experimentConfigs, rollouts, propsForEligibility);
};

export const inClientExperiment = (experimentId: number): boolean => {
    const variation = getClientExperimentVariation(experimentId);

    return isExperimentVariation(variation);
};

/**
 * Returns the Calculated Experiment Group.
 * @ref https://docs.fiverr-gw.com/platform/experiments/technical/experiments_package#calculategroup
 * @param experimentId
 * @return {number | undefined}
 */
export const getClientExperimentVariation = (experimentId: number): number | undefined => {
    const calculatedExperiment = calculatedExperiments[experimentId];

    return calculatedExperiment?.calculatedGroup;
};

/** Only to be used in Client Experiment Allocation */
export const getCalculatedExperiments = (): ExperimentsConfigMap => calculatedExperiments;
