import React from 'react';
import { bool, number, object, string } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { CheckThickIcon } from '@fiverr-private/icons';
import { TooltipIcon } from '@fiverr-private/orca';
import pathfinder from '@fiverr-private/pathfinder';
import { isTriplePackage } from '../../../../reducers/packages';
import { shouldShowFeature } from '../../../../utils/pricingFactors';
import { translateLabelBasedOnCategory } from '../../../../utils/packagesUtils';
import { SHUTTERSTOCK_PRICING_FACTORS, SHUTTERSTOCK_ROOT, SHUTTERSTOCK_HELP_CENTER_ARTICLE_ANCHOR } from './constants';

import classes from './styles.module.scss';

const Feature = (
    { type, name, value, included = false, displayNotIncluded = true },
    { general, isBusinessLayout, isProRebrandingLayout }
) => {
    const { subCategoryId } = general;

    const formatLabel = translateLabelBasedOnCategory({ type, name, value, subCategoryId });
    if (!shouldShowFeature(included, displayNotIncluded)) {
        return null;
    }

    const shutterstockTooltip = SHUTTERSTOCK_PRICING_FACTORS[name];
    const shutterstockArticleLink = pathfinder('help_center_article', {
        article_id: SHUTTERSTOCK_HELP_CENTER_ARTICLE_ANCHOR,
    });
    const shutterstockTooltipTemplates = {
        link: (text) => (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a className={classes.tooltipLink} href={shutterstockArticleLink} target="_blank">
                {text}
            </a>
        ),
    };
    const iconClasses = classNames(classes.featureCheckIcon, {
        [classes.included]: included,
        [classes.newProLayout]: isBusinessLayout || isProRebrandingLayout,
    });

    return (
        <li className={classNames(classes.feature, 'flex', 'flex-items-center')}>
            <Icon className={iconClasses}>
                <CheckThickIcon />
            </Icon>
            {formatLabel()}
            {!!shutterstockTooltip && (
                <div className={classes.shutterstockTooltipIcon} data-testid="shutterstock-tooltip-icon">
                    <TooltipIcon
                        appendToBody={false}
                        boxContentClassName={classes.shutterstockTooltipBox}
                        size={12}
                        content={
                            <I18n
                                k={`${SHUTTERSTOCK_ROOT}.tooltip`}
                                params={{ type: translate(shutterstockTooltip) }}
                                templates={shutterstockTooltipTemplates}
                            />
                        }
                    ></TooltipIcon>
                </div>
            )}
        </li>
    );
};

Feature.propTypes = {
    name: string,
    value: number,
    included: bool,
    displayNotIncluded: bool,
    type: string,
};

Feature.contextTypes = {
    general: object,
    isBusinessLayout: bool,
    isProRebrandingLayout: bool,
};

const mapStateToProps = ({ packages }, ownProps) => {
    const displayNotIncluded = isTriplePackage(packages);

    return {
        ...ownProps,
        displayNotIncluded,
    };
};

export { Feature };
export default connect(mapStateToProps)(Feature);
