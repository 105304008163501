import { isNil } from 'lodash';
import React from 'react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { Stack } from '@fiverr-private/layout_components';
import { EmployeeIcon } from '@fiverr-private/visuals';
import { Text } from '@fiverr-private/typography';
import { I18n } from '@fiverr-private/i18n-react';

export const AgencySize = () => {
  const {
    seller: { agency },
  } = useGigPageContext();
  const { teamSize } = agency || {};

  if (isNil(teamSize)) {
    return null;
  }

  return (
    <Stack alignItems="center" gap="2" justifyContent="center" width="auto">
      <EmployeeIcon size="md" color="grey_1200" />
      <Text size="b_lg" fontWeight="semibold">
        <I18n k="gig_page_perseus.seller_card.team_size" params={{ count: teamSize }} />
      </Text>
    </Stack>
  );
};
