import React from 'react';
import { connect } from 'react-redux';
import { array, arrayOf, bool, number, object, string } from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import { getCurrentContent } from '../../utils/ugc/getCurrentContent';
import SafePureComponent from '../shared/SafePureComponent';
import StatsDesc from './StatsDesc';
import { ProSubcategories } from './ProSubcategories/ProSubcategories';
import { ExperientialProfileInfo } from './ProfileInfo/ProfileInfoProRebranding/ExperientialProfileInfo';

import './style.scss';

/** Below the fold seller card */
const SellerCard = (props) => {
    const {
        description,
        isPro,
        proSubCategories,
        recentDelivery,
        countryCode,
        memberSince,
        responseTime,
        oneLiner,
        hasProfilePhoto,
        profilePhoto,
        sellerName,
        notableClients,
        seller,
    } = props;

    const showPro = isPro && !_.isEmpty(proSubCategories);

    return (
        <div className="below-the-fold-experiential-seller-card-order">
            <div className={classNames('seller-card', { pro: isPro })}>
                <ExperientialProfileInfo
                    oneLiner={oneLiner}
                    ratingsCount={seller?.rating?.count}
                    rating={seller?.rating?.score}
                    hasProfilePhoto={hasProfilePhoto}
                    profilePhoto={profilePhoto}
                />
                <ProSubcategories proSubcategories={proSubCategories} isPro={isPro} />
                <StatsDesc
                    description={description}
                    recentDelivery={recentDelivery}
                    countryCode={countryCode}
                    memberSince={memberSince}
                    responseTime={responseTime}
                    profilePhoto={profilePhoto}
                    sellerName={sellerName}
                    notableClients={notableClients}
                    isPro={showPro}
                />
            </div>
        </div>
    );
};

SellerCard.propTypes = {
    description: string,
    isPro: bool,
    proSubCategories: array,
    recentDelivery: number,
    countryCode: string,
    memberSince: number,
    responseTime: number,
    oneLiner: string,
    seller: object,
    hasProfilePhoto: bool,
    profilePhoto: string,
    sellerName: string,
    notableClients: arrayOf(object),
};

const mapStateToProps = ({ ugc }) => {
    const { sellerCard } = getCurrentContent({ ugc });

    return {
        description: sellerCard.description,
        oneLiner: sellerCard.oneLiner,
    };
};

const ConnectedSellerCard = connect(mapStateToProps)(SellerCard);

export { SellerCard, ConnectedSellerCard };
export default SafePureComponent(ConnectedSellerCard);
