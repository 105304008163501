import { getContext } from '@fiverr-private/fiverr_context';

/**
 * Generate a link to freelancer (seller) page with contextual info, like from listings to freelancer-page / gig-page
 * @param {object} params
 * @param {{pathfinder: import('@fiverr-private/pathfinder')}} params.pathfinderWrapper
 */
export const getSellerUrl = ({ pathfinderWrapper }) => {
    const {
        pathParameters: { username, slug },
        queryParameters: { ref, pckg_id, fiverr_choice } = {},
    } = getContext();

    const queryParams = {
        source: 'gig_page',
        ...(pckg_id && { gigs: `slug:${slug},pckg_id:${pckg_id}` }),
        ...(ref && { ref }),
        ...(fiverr_choice && { is_choice: fiverr_choice.toString() }),
    };

    return pathfinderWrapper.pathfinder('short_user', { id: username }, { query: queryParams });
};
