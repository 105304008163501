import { Calculator } from '../../types/gig/interfaces';
import { isValidCalculator } from '../calculator';
import { calcCalculatorAdditionalPrice } from './calcCalculatorAdditionalPrice';

export const calcCalculatorsPrice = (calculators: Calculator[], gigQuantity: number): number => {
    let currentPrice = 0;
    calculators.forEach((calculator) => {
        if (isValidCalculator(calculator)) {
            currentPrice += calcCalculatorAdditionalPrice(calculator) * gigQuantity;
        }
    });

    return currentPrice;
};
