import _ from 'lodash';
import { VISIBLE } from '../constants';

export const validateAttributes = (metadataAttributes) => {
    const validAttributes = _.compact(metadataAttributes);

    return _.filter(validAttributes, (attribute) => _.get(attribute, 'metadata.visibility', VISIBLE) === VISIBLE);
};

export const sortAttributes = (metadataAttributes) =>
    _.sortBy(metadataAttributes, (attribute) => _.get(attribute, 'metadata.order', 0));
