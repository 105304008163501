import React from 'react';
import { object, number, arrayOf, bool } from 'prop-types';
import { connect } from 'react-redux';
import { merge } from 'lodash';
import MediaQuery from 'react-responsive';
import { translate } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { useIsClient } from '@fiverr-private/hooks';
import SafePureComponent from '../shared/SafePureComponent';
import { shouldShowSelectPackage } from '../../utils/shouldShowSelectPackage';
import { alignPackagesFeatures } from '../../utils/packagesUtils';
import { enrichFeaturesRowData, preparePricingFactors } from '../../utils/pricingFactors';
import { calculateServiceName, enrichServices, groupFeaturesByService } from '../../utils/studios';
import { flattenCalculators } from '../../utils/calculator';
import { shouldShowRequestToOrderBtn } from '../PackagesTabs/TabContent/TabFooter/RequestToOrderBtn/utils/shouldShowRequestToOrderBtn';
import SellerTranslationBadgeWrapper from '../shared/SellerTranslationBadgeWrapper';
import { isTriplePackage } from '../../reducers/packages';
import { validatePackagesTable } from '../../utils/mainComponentsValidator';
import { MP_COMPARE_PACKAGES_VIEW } from '../../utils/mixPanelEvents';
import { NON_EXP_BQ_PACKAGE_TABLE_SHOW_PARAMS } from '../GigPage/constants';
import { MEDIA_GRID_MD } from '../../utils/constants';
import { Waypoint } from '../Waypoint/Waypoint';
import { PACKAGES_TABLE } from '../../utils/pageSections';
import PackageType from './PackageType';
import Description from './Description';
import Revisions from './Revisions';
import DeliveryTime from './DeliveryTime';
import CalculatorRow from './CalculatorRow';
import SelectPackage from './SelectPackage';
import PricingFactorRow from './PricingFactorRow';
import ServiceNameRow from './ServiceNameRow';

import './style.scss';

const renderServiceCalculator = (calculators) => {
    if (calculators) {
        return <CalculatorRow calculatorRowData={calculators} />;
    }
};

const renderServiceFeatures = (features) => {
    if (features) {
        return features.map((feature) => <PricingFactorRow key={feature.id} {...feature} />);
    }
};

const renderServices = (packageList, services) => {
    const enrichedServices = enrichServices(services, packageList);
    const enrichedFeatures = enrichFeaturesRowData(packageList);
    const featuresByService = groupFeaturesByService(enrichedServices, enrichedFeatures);

    return enrichedServices.map(({ id, subCategoryId, nestedSubCategoryId, calculators }) => [
        <ServiceNameRow key={id} name={calculateServiceName(subCategoryId, nestedSubCategoryId)} />,
        renderServiceCalculator(calculators),
        renderServiceFeatures(featuresByService[id]),
    ]);
};

const renderPricingFactorRows = (packageList) => {
    const pricingFactors = preparePricingFactors(packageList);

    return pricingFactors.map((pricingFactor) => <PricingFactorRow key={pricingFactor.id} {...pricingFactor} />);
};

const renderSingleCalculatorRow = (packageList) => {
    const calculatorRowData = flattenCalculators(packageList);

    if (calculatorRowData.length !== packageList.length) {
        return;
    }

    return <CalculatorRow calculatorRowData={calculatorRowData} />;
};

const Table = ({ packageList, packageId, services }, { general, rollouts, gig: { instantOrderSettings } }) => {
    const { isStudio } = general;
    const showRequestToOrder = shouldShowRequestToOrderBtn({ instantOrderSettings, packageId, rollouts });
    const showSelectPackage = shouldShowSelectPackage(general);

    return (
        <div className="gig-page-packages-table">
            <h2 className="section-title">
                <SellerTranslationBadgeWrapper translationKey="packages">
                    {translate('gig_page_perseus.packages_table.title')}
                </SellerTranslationBadgeWrapper>
            </h2>
            <table>
                <colgroup>
                    <col />
                    {packageList.map(({ id }) => (
                        <col key={id} />
                    ))}
                </colgroup>
                <tbody>
                    <PackageType />
                    <Description />
                    {isStudio && <Revisions />}
                    {isStudio && <DeliveryTime showSelection={false} />}
                    {isStudio && renderServices(packageList, services)}
                    {!isStudio && renderPricingFactorRows(packageList)}
                    {!isStudio && <Revisions />}
                    {!isStudio && <DeliveryTime />}
                    {!isStudio && renderSingleCalculatorRow(packageList)}
                    {showSelectPackage && <SelectPackage hideButtons={showRequestToOrder} />}
                </tbody>
            </table>
        </div>
    );
};

Table.propTypes = {
    packageList: arrayOf(object),
    packageId: number,
    services: arrayOf(object),
};

Table.contextTypes = {
    general: object,
    rollouts: object,
    gig: object,
};

const mapStateToTableProps = ({ packages }) => {
    const { packageList, selectedPackageId } = packages;

    return {
        packageList: alignPackagesFeatures(packageList),
        packageId: selectedPackageId,
    };
};

const TableConnect = connect(mapStateToTableProps)(Table);

const PackagesTableWrapper = ({ showPackagesTable }) => {
    const { biEvents, studio = {} } = useGigPageContext();
    const isClient = useIsClient();

    const reportPackagesTableVisible = () => {
        const baseBqData = biEvents.getBigQueryEnrichmentData();

        biEvents.sendMixPanelEvent({ eventName: MP_COMPARE_PACKAGES_VIEW });
        biEvents.sendRawBigQueryEvent(
            merge({}, NON_EXP_BQ_PACKAGE_TABLE_SHOW_PARAMS, {
                user: { id: baseBqData.userId },
                seller: { id: baseBqData.sellerId },
                gig: { id: baseBqData.gigId },
                page: { ctx_id: baseBqData.pageCtxId },
            })
        );
    };

    if (!isClient || !showPackagesTable) {
        return null;
    }

    const { services } = studio;

    return (
        <MediaQuery query={`(min-width: ${MEDIA_GRID_MD})`}>
            <Waypoint source={PACKAGES_TABLE} onEnter={reportPackagesTableVisible} />
            <TableConnect services={services} />
        </MediaQuery>
    );
};

PackagesTableWrapper.propTypes = {
    showPackagesTable: bool,
};

const mapStateToTableFormProps = ({ packages }) => ({
    showPackagesTable: validatePackagesTable({ isTriplePackage: isTriplePackage(packages) }),
});
const PackagesTableWrapperConnected = connect(mapStateToTableFormProps)(PackagesTableWrapper);

export { PackagesTableWrapper, Table };
export default SafePureComponent(PackagesTableWrapperConnected);
