import React from 'react';
import { workProcessStepsType } from '../../propTypes';

import './index.scss';

const MilestoneExpandedSteps = ({ steps }) => (
    <ul className="tbody-7 step-container">
        {steps.map(({ title, description, order }) => (
            <li key={order} className="expanded-step-item">
                <b>{title}:</b>
                <span>{description}</span>
            </li>
        ))}
    </ul>
);

MilestoneExpandedSteps.propTypes = {
    steps: workProcessStepsType.isRequired,
};

export default MilestoneExpandedSteps;
