/*

This is pretty much a copy of:

react-scroll-to-component
https://github.com/flyingant/react-scroll-to-component

scroll-to
https://github.com/component/scroll-to

but switches to use a different Tween library
and fixes the SSR rendering

*/

import { env } from '@fiverr-private/futile';

const TWEEN = env.browser ? require('@tweenjs/tween.js') : () => undefined;

const scrollTo = (x, y, options) => {
    options = options || {};

    // animate
    const animate = (time) => {
        requestAnimationFrame(animate);
        TWEEN.update(time);
    };

    requestAnimationFrame(animate);

    // start position
    const coords = scrollPos();

    // setup tween
    return new TWEEN.Tween(coords)
        .to({ x, y }, options.duration || 1000)
        .easing(TWEEN.Easing.Circular.Out)
        .onUpdate(() => {
            window.scrollTo(coords.x | 0, coords.y | 0);
        })
        .start();
};

const scrollPos = () => {
    const y = window.pageYOffset || document.documentElement.scrollTop;
    const x = window.pageXOffset || document.documentElement.scrollLeft;
    return { x, y };
};

const calculateScrollOffset = (ele, offset, alignment) => {
    const body = document.body;
    const html = document.documentElement;

    const eleRect = ele.getBoundingClientRect();
    const clientHeight = html.clientHeight;
    const documentHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
    );

    offset = offset || 0; // additional offset to top

    let scrollPosition;
    switch (alignment) {
        case 'top':
            scrollPosition = eleRect.top;
            break;
        case 'middle':
            scrollPosition = eleRect.bottom - clientHeight / 2 - eleRect.height / 2;
            break;
        case 'bottom':
            scrollPosition = eleRect.bottom - clientHeight;
            break;
        default:
            scrollPosition = eleRect.bottom - clientHeight / 2 - eleRect.height / 2;
            break; // default to middle
    }

    const maxScrollPosition = documentHeight - clientHeight;

    return Math.min(scrollPosition + offset + window.pageYOffset, maxScrollPosition);
};

/**
 *
 * @param {Element} element
 * @param {object} options
 * @returns {object} A tween.js object
 */
const scrollToComponent = (element, options) => {
    options = options || {
        offset: 0,
        align: 'middle',
    };

    return scrollTo(0, calculateScrollOffset(element, options.offset, options.align), options);
};

export default scrollToComponent;
