import { translate } from '@fiverr-private/machine_translation';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { metric } from '../../metric';
import Logger from '../../logger';
import { ERROR_CONTEXT, METRIC_COUNT_PATH } from './constants';

export const translateUGC = async ({ content, sourceInfo, context }) => {
    try {
        const { abTests, queryParameters } = getContext();
        const forcedLocaleParam = queryParameters?.forced_locale;
        const translateURL = pathfinder('gig_page_translate_ugc_api');
        const translateURLWithForcedLocale = pathfinder(
            'gig_page_translate_ugc_api',
            {},
            { query: { forced_locale: forcedLocaleParam } }
        );
        const params = {
            text: content,
            url: forcedLocaleParam ? translateURLWithForcedLocale : translateURL,
            retry: true,
            sourceInfo,
            abTests,
            context,
        };

        const { translatedBlob } = await translate(params);

        return translatedBlob;
    } catch (error) {
        const logger = Logger.getInstance();

        metric.count(METRIC_COUNT_PATH);
        logger.error(error, { context: ERROR_CONTEXT });

        throw error;
    }
};
