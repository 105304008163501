import { CurrencyConverter } from '@fiverr-private/futile/lib/number';
import { currencyFormat } from '@fiverr-private/localization';

class GigCurrencyConverter {
    constructor(currency) {
        this.currencyConverter = new CurrencyConverter(currency);
        this.options = {
            commaSeparate: true,
        };
        this.currency = currency;
    }

    convert(price) {
        // Take care of conversion rate (instead of futile)
        let convertedPrice = (price / 100) * this.currency.rate;

        // Take care of rounding when currency.forceRoundFromAmount exceeded (instead of futile)
        if (this.currency.forceRound && convertedPrice >= this.currency.forceRoundFromAmount) {
            convertedPrice = Math.ceil(convertedPrice);
        }

        return currencyFormat({
            value: convertedPrice,
            currencyCode: this.currency.name,
            options: {
                fallbackValue: this.currencyConverter.convert(price / 100, this.options),
            },
        });
    }

    convertWithFee(price) {
        // Take care of conversion rate (instead of futile)
        let convertedPrice = (price / 100) * this.currency.rate;

        // Take care of rounding when currency.forceRoundFromAmount exceeded (instead of futile)
        if (this.currency.forceRound && convertedPrice >= this.currency.forceRoundFromAmount) {
            convertedPrice = Math.ceil(convertedPrice);
        }

        // TODO: align this logic with the logic of `convert` (add `toFixed`) and call `this.convert(priceWithFee)`
        // instead of duplicating the code.

        const fixedPrice = convertedPrice.toFixed(2);

        return currencyFormat({
            value: fixedPrice,
            currencyCode: this.currency.name,
            options: {
                fallbackValue: this.currencyConverter.convert(price / 100, this.options),
            },
        });
    }

    getCurrency() {
        return this.currency;
    }
}

export default GigCurrencyConverter;
