import React, { useState } from 'react';
import { bool, object, string } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { Tab, TabGroup } from '@fiverr-private/orca';
import { Reviews } from '@fiverr-private/reviews';
import { BQ_CLICKED_ON_GIG_REVIEWS_SECTION, BQ_CLICKED_ON_SELLER_REVIEWS_SECTION } from '../../../utils/bigQueryEvents';
import { REVIEWS_TYPES } from '../constants';
import { gigReviewsSectionIsShownEvent, sellerReviewsSectionIsShownEvent } from '../Events';
import { buildGigReviewsConfiguration, buildSellerReviewsConfiguration, formatSimpleNumber } from '../utils';
import GigsTabTitle from './GigsTabTitle';
import SellersTabTitle from './SellersTabTitle';

const gigReviews = 'gig-reviews';
const sellerReviews = 'seller-reviews';

const ReviewsTabs = (
    { reviews, enableSearchFilter, translationUrl },
    { biEvents, general }
) => {
    const [currentTab, setCurrentTab] = useState(gigReviews);

    const { pageCtxId, isMobile, userId } = getContext();
    const { isPro, sellerId } = general;

    const gigReviewsConfig = buildGigReviewsConfiguration({ pageCtxId, userId, gigId: reviews.gig_id, isMobile });
    const sellerReviewsConfig = buildSellerReviewsConfiguration(pageCtxId, userId, sellerId, isMobile);
    const [sellerReviewsTabRef] = useImpressionItem(sellerReviewsSectionIsShownEvent(reviews.gig_id, sellerId, userId));
    const [gigReviewsTabRef] = useImpressionItem(gigReviewsSectionIsShownEvent(reviews.gig_id, sellerId, userId));
    const gigReviewsCount = formatSimpleNumber(reviews.total_count);
    const sellerReviewsCount = formatSimpleNumber(reviews?.seller_reviews?.total_count);
    const gigReviewsInitialState = { ...reviews, ...gigReviewsConfig, event_source: REVIEWS_TYPES.GIG_REVIEWS_SECTION };
    const sellerReviewsInitialState = {
        ...reviews.seller_reviews,
        ...sellerReviewsConfig,
        event_source: REVIEWS_TYPES.SELLER_REVIEWS_SECTION,
    };

    const onTabSwitch = (_, name) => {
        if (currentTab !== name) {
            const eventName =
                name === gigReviews ? BQ_CLICKED_ON_GIG_REVIEWS_SECTION : BQ_CLICKED_ON_SELLER_REVIEWS_SECTION;
            biEvents.sendBigQueryEvent({ eventName });
            setCurrentTab(name);
        }
    };

    return (
        <TabGroup onSwitch={onTabSwitch}>
            <Tab title={<GigsTabTitle ref={gigReviewsTabRef} reviewsCount={gigReviewsCount} />} name={gigReviews}>
                <div className="m-t-32">
                    <Reviews
                        initialState={gigReviewsInitialState}
                        key="gig-reviews"
                        isPro={isPro}
                        enableSearchFilter={enableSearchFilter}
                        translationUrl={translationUrl}
                    />
                </div>
            </Tab>
            <Tab
                title={<SellersTabTitle ref={sellerReviewsTabRef} reviewsCount={sellerReviewsCount} />}
                name={sellerReviews}
            >
                <div className="m-t-32">
                    <Reviews
                        initialState={sellerReviewsInitialState}
                        key="seller-reviews"
                        isPro={isPro}
                        enableSearchFilter={enableSearchFilter}
                        translationUrl={translationUrl}
                        displayPurchasedGig
                    />
                </div>
            </Tab>
        </TabGroup>
    );
};

ReviewsTabs.propTypes = {
    reviews: object.isRequired,
    enableSearchFilter: bool,
    translationUrl: string,
};

ReviewsTabs.contextTypes = {
    general: object,
    biEvents: object,
};

export default ReviewsTabs;
