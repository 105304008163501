import React from 'react';
import { noop } from 'lodash';
import { EllipsisIcon } from '@fiverr-private/visuals';
import { Popover, PopoverAnchor, PopoverChevron, PopoverContent } from '@fiverr-private/popover';
import { Stack } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ActionCtaBase } from '../ActionCtaBase';
import { useGigPageNavContext } from '../../GigPageNavContext/GigPageNavContext';
import { ReportGigItem } from './ReportGigItem';

export const ShowMoreCta: React.FC = () => {
  const { userId } = getContext();
  const { currentUser } = useGigPageContext();
  const { ReportGigButtonWrapper } = useGigPageNavContext();

  const isLoggedIn = !!userId;
  const isRestricted = currentUser?.status === 'restricted';

  if (!isLoggedIn || isRestricted) {
    return null;
  }

  // This is a temp solution to solve clicking outside a Popover on a modal.
  // When refactoring ReportGig component, to use the new modal - add to PopoverContent the prop: renderWhenClosed
  // and all this logic will be redundant
  return (
    <ReportGigButtonWrapper>
      {({ onReportGigItemClick }) => (
        <Popover position="bottom">
          <PopoverAnchor>
            <ActionCtaBase onClick={noop} dataTestId="show_more_test_id">
              <EllipsisIcon />
            </ActionCtaBase>
          </PopoverAnchor>
          <PopoverContent disablePadding>
            <PopoverChevron />
            <Stack marginY="2" direction="column">
              <ReportGigItem onClick={onReportGigItemClick} />
            </Stack>
          </PopoverContent>
        </Popover>
      )}
    </ReportGigButtonWrapper>
  );
};
