import React, { useEffect } from 'react';
import { bool, number, object, string } from 'prop-types';
import { howLongAgo } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import RatingWrapper from '../../../shared/RatingWrapper';
import { MP_VIEWED_GALLERY_REVIEW } from '../../../../utils/mixPanelEvents';

import './index.scss';

const GigReview = ({ compact, buyerName, buyerProfilePicture, text, ratingValue, createdAt }, { biEvents }) => {
    const { locale } = getContext();

    const getTimeAgo = () => {
        const dateCreatedAt = new Date(createdAt),
            isInvalidDate = isNaN(dateCreatedAt);

        return isInvalidDate
            ? null
            : howLongAgo(dateCreatedAt, { prefix: 'gig_page_perseus.general.how_long_ago.', locale });
    };

    const renderAvatar = () => {
        const initial = (buyerName || ' ').substr(0, 1); // Get the buyer's username first letter
        const element = buyerProfilePicture ? (
            <img src={buyerProfilePicture} alt={buyerName} width="128" height="128" />
        ) : (
            <span className="missing-image-user">{initial}</span>
        );

        return element;
    };

    const renderCompactReview = () => (
        <footer className="gig-review compact">
            <blockquote>{text}</blockquote>
            <small>
                <I18n k={'gig_page_perseus.gallery.reviewed_by'} /> {buyerName} {getTimeAgo()}
            </small>
        </footer>
    );

    const renderFullReview = () => {
        const buyerProfileUrl = `/${buyerName}`,
            ago = getTimeAgo();

        return (
            <footer className="gig-review">
                <figure className="gig-review-avatar">{renderAvatar()}</figure>
                <a className="gig-review-user" href={buyerProfileUrl}>
                    {buyerName}
                </a>
                <section className="review-snippet">
                    <RatingWrapper rating={ratingValue} />
                    <small className="gig-review-ago">{ago}</small>
                </section>
                <blockquote className="gig-review-comment">{text}</blockquote>
            </footer>
        );
    };

    useEffect(() => {
        biEvents.sendMixPanelEvent({
            eventName: MP_VIEWED_GALLERY_REVIEW,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return compact ? renderCompactReview() : renderFullReview();
};

GigReview.contextTypes = {
    biEvents: object,
};

GigReview.propTypes = {
    compact: bool,
    buyerName: string.isRequired,
    buyerProfilePicture: string,
    text: string.isRequired,
    ratingValue: number.isRequired,
    createdAt: string,
};

GigReview.defaultProps = {
    compact: false,
};

export default GigReview;
