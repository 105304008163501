import { getContext } from '@fiverr-private/fiverr_context';

/**
 * Returns Boolean -
 *   If the Notable Clients component should be rendered
 *
 * @param {Array<object>} notableClients
 * @returns {boolean}
 */
export const shouldRenderNotableClients = (notableClients) => {
    const { isMobile } = getContext();

    return !isMobile && !!notableClients?.length;
};
