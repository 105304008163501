import { getContext } from '@fiverr-private/fiverr_context';
import Logger from '../../../../utils/logger';
import { maxios } from '../../../../utils/maxios';

export const getFormData = async ({ pathfinderWrapper }) => {
    const { pathParameters } = getContext();
    const { username, slug } = pathParameters;

    const url = pathfinderWrapper.pathfinder('new_custom_order_request_form', { seller: username, gig: slug });

    try {
        const { data } = await maxios.get(url, { routeKey: 'new_custom_order_request_form' });
        return data;
    } catch (error) {
        const logger = Logger.getInstance();
        logger.error(error, {
            description: 'Failed to fetch custom order data',
            username,
            slug,
        });

        throw error;
    }
};
