import { find, isEmpty } from 'lodash';
import {
    buildLabelForExtraFast,
    calcCurrentDuration,
    calculateExtraFastDuration,
    getPackageTypeById,
    isFeatureFiltered,
    isVoiceOver,
} from '../../utils/packagesUtils';
import { mergePackageUGCs } from '../../utils/ugc/mergePackageUGCs';
import { UGC_UPDATED } from '../../actions/ugc';
import {
    SELECT_PACKAGE,
    SELECT_RECURRING_OPTION,
    UPDATE_CALCULATOR,
    UPDATE_CUSTOM_EXTRA,
    UPDATE_EXTRA_FAST,
    UPDATE_FEATURE,
    UPDATE_QUANTITY,
} from '../../actions/packages';
import { getDefaultRecurringOption, getDefaultRecurringOptionId } from '../../utils/recurring';
import calcDefaultSelectedPackage from '../../utils/calcDefaultSelectedPackage';
import { isGigWithActiveWorkProcess } from '../workProcess';
import {
    CUSTOM_EXTRA_TYPE,
    EXTRA_FAST_TYPE,
    FEATURE_TYPE,
    SCRIPT_PROOFREADING_FEATURE,
} from '../../utils/packagesUtils/constants';
import { countAvailablePricingFactors } from '../../components/PaymentDrawer/ExtrasList/utils/ExtrasBuilder';
import { calcCheckoutPrice } from '../../utils/packagesUtils/calcCheckoutPrice';
import { buildPackageListForInitialState, calcPaymentOption, initGigQuantity } from './utils';

const packagesReducer = ({ packages = {}, general, ugc = {} }) => {
    const { subCategoryId } = general;
    const isVoiceOverGig = isVoiceOver({ subCategoryId });

    const { recurringOptions } = packages;
    const packageList = buildPackageListForInitialState(packages, general);
    const gigQuantity = initGigQuantity();

    const initialState = {
        packageList: mergePackageUGCs({ packageList, ugc, isVoiceOverGig }),
        selectedPackageId: calcDefaultSelectedPackage(packageList),
        quantity: gigQuantity,
        recurringOptions,
        selectedRecurringOptionId: getDefaultRecurringOptionId(recurringOptions),
    };

    return (state = initialState, action) => {
        switch (action.type) {
            case UGC_UPDATED:
                return {
                    ...state,
                    packageList: mergePackageUGCs({ packageList: state.packageList, ugc: action.data.ugc }),
                };
            case UPDATE_CALCULATOR:
                return {
                    ...state,
                    packageList: state.packageList.map((packageData) => {
                        const { calculators } = packageData;
                        const { calculatorId, value, packageId } = action.data;

                        const newCalculators = calculators.map((calculator) => {
                            if (calculator.id === calculatorId && packageData.id === packageId) {
                                return { ...calculator, currentValue: value };
                            } else {
                                return calculator;
                            }
                        });
                        const newPackageData = { ...packageData, calculators: newCalculators };

                        return {
                            ...newPackageData,
                            currentPrice: calcCheckoutPrice(newPackageData, state.quantity),
                            currentDuration: calcCurrentDuration(newPackageData),
                        };
                    }),
                };
            case UPDATE_EXTRA_FAST:
                return {
                    ...state,
                    packageList: state.packageList.map((packageData) => {
                        if (packageData.id !== action.data.packageId) {
                            return packageData;
                        }

                        const { extraFast } = packageData,
                            newExtraFast = { ...extraFast, currentValue: action.data.value },
                            newPackageData = { ...packageData, extraFast: newExtraFast };

                        return {
                            ...newPackageData,
                            currentPrice: calcCheckoutPrice(newPackageData, state.quantity),
                            currentDuration: calcCurrentDuration(newPackageData),
                        };
                    }),
                };
            case SELECT_PACKAGE:
                return {
                    ...state,
                    selectedPackageId: action.data.packageId,
                };
            case UPDATE_FEATURE:
                return {
                    ...state,
                    packageList: state.packageList.map((packageData) => {
                        const { features } = packageData;

                        const newFeatures = features.map((feature) => {
                            if (feature.id !== action.data.featureId) {
                                return feature;
                            } else {
                                const amount = action.data.amount;
                                const considerAmountInDurationCalculation = isVoiceOverGig && amount > 0;
                                const currentDuration = considerAmountInDurationCalculation
                                    ? feature.duration * amount
                                    : feature.duration;
                                return { ...feature, amount, currentDuration };
                            }
                        });
                        const newPackageData = { ...packageData, features: newFeatures };

                        return {
                            ...newPackageData,
                            currentPrice: calcCheckoutPrice(newPackageData, state.quantity),
                            currentDuration: calcCurrentDuration(newPackageData),
                        };
                    }),
                };
            case UPDATE_CUSTOM_EXTRA:
                return {
                    ...state,
                    packageList: state.packageList.map((packageData) => {
                        const { customExtras } = packageData;

                        const newCustomExtras = customExtras.map((customExtra) => {
                            if (customExtra.id !== action.data.customExtraId) {
                                return customExtra;
                            } else {
                                return { ...customExtra, amount: action.data.amount };
                            }
                        });
                        const newPackageData = { ...packageData, customExtras: newCustomExtras };

                        return {
                            ...newPackageData,
                            currentPrice: calcCheckoutPrice(newPackageData, state.quantity),
                            currentDuration: calcCurrentDuration(newPackageData),
                        };
                    }),
                };
            case UPDATE_QUANTITY:
                return {
                    ...state,
                    packageList: state.packageList.map((packageData) => ({
                        ...packageData,
                        currentPrice: calcCheckoutPrice(packageData, action.data.quantity),
                        currentDuration: calcCurrentDuration(packageData, action.data.quantity),
                    })),
                    quantity: action.data.quantity,
                };
            case SELECT_RECURRING_OPTION:
                const { selectedRecurringOptionId } = action.data;

                return {
                    ...state,
                    selectedRecurringOptionId,
                };
            default:
                return state;
        }
    };
};

// Selectors

/**
 * Get gig package by its id
 * @param {GigPackage[]} packageList
 * @param {number} packageId
 * @return {GigPackage}
 */
export const getPackageById = ({ packageList }, packageId) => packageList.find(({ id }) => id === packageId);

export const isTriplePackage = ({ packageList }) => packageList.length === 3;

export const isSinglePackage = ({ packageList }) => packageList.length === 1;

export const getSingleCalculator = (state, packageId) => {
    const { calculators } = getPackageById(state, packageId);

    return calculators && calculators.length ? calculators[0] : null;
};

export const hasCalculatorInCurrentPackage = (state) => {
    const currentPackage = getCurrentPackage(state);

    return !isEmpty(currentPackage?.calculators);
};

export const getRecurringOptions = ({ recurringOptions }) => recurringOptions;

export const getSelectedRecurringOptionId = ({ selectedRecurringOptionId }) => selectedRecurringOptionId;

export const getSelectedRecurringOption = (packages) => {
    const { recurringOptions, selectedRecurringOptionId } = packages;
    const recurringOption = find(recurringOptions, { id: selectedRecurringOptionId });

    return recurringOption || getDefaultRecurringOption(recurringOptions);
};

export const isRecurringGig = ({ recurringOptions }) => !isEmpty(recurringOptions);

export const getPaymentOption = (state) => {
    const { packages } = state;
    const isMilestoneGig = isGigWithActiveWorkProcess(state);
    const isSubscriptionGig = isRecurringGig(packages);

    return calcPaymentOption(isMilestoneGig, isSubscriptionGig);
};

/**
 * Get number of packages of gig
 * @param {object} state redux state
 * @return {number} number of packages in gig
 */
export const getPackageListCount = ({
    packages: {
        packageList: { length: packageListCount },
    },
}) => packageListCount;

/**
 * Get current selected package id
 * @param {object} state redux state
 * @return {number} selected package id
 */
export const getSelectedPackageId = ({ packages: { selectedPackageId } }) => selectedPackageId;

/**
 * Get current selected package
 * @param {GigPackage[]} packageList
 * @param {number} selectedPackageId
 * @return {GigPackage}
 */
export const getCurrentPackage = ({ packages: { packageList, selectedPackageId } }) =>
    getPackageById({ packageList }, selectedPackageId);

/**
 * Get current selected package price
 * @note The price is calculated here: `calcCheckoutPrice()`
 * @ref /src/apps/gig_page/utils/packagesUtils/events.js
 * @param {object} state redux state
 * @return {number} selected package price in cents
 */
export const getCurrentPackagePrice = ({ packages: { packageList, selectedPackageId, quantity } }) => {
    const packageData = getPackageById({ packageList }, selectedPackageId);

    return calcCheckoutPrice(packageData, quantity);
};

/**
 * Get current package type (basic, standard, premium etc.)
 * @param {object} state redux state
 * @return {object} selected package translation key (from `copies.js`)
 */
export const getPackageType = ({ packages: { selectedPackageId } }) => getPackageTypeById(selectedPackageId);

/**
 * Get current selected package duration
 * @param {object} state redux state
 * @return {number} selected package duration in days
 */
export const getCurrentPackageDuration = ({ packages: { packageList, selectedPackageId } }) => {
    const { currentDuration } = getPackageById({ packageList }, selectedPackageId);

    return currentDuration;
};

/**
 * Get current selected package revisions
 * @param {object} state redux state
 * @return {number|null} selected package number of revisions
 */
export const getCurrentPackageRevisions = ({ packages: { packageList, selectedPackageId } }) => {
    const { revisions } = getPackageById({ packageList }, selectedPackageId);

    if (isEmpty(revisions)) {
        return null;
    }
    const { value } = revisions;

    return value;
};

/**
 * Get the features that are included in the current package
 * @param {object} state redux state
 * @returns {object[]} features that are included in current package
 */
export const getCurrentPackageIncludedFeatures = ({ packages: { packageList, selectedPackageId } }) => {
    const { features } = getPackageById({ packageList }, selectedPackageId);

    return features.filter((feature) => feature.included);
};

/**
 * Checks if the current selected package is valid for the checkout drawer experience.
 * @param {object} state  - redux state
 * @returns {Boolean}
 */
export const isSinglePaymentValidForCheckoutDrawer = (state) => {
    const { packages } = state;
    const selectedPackageId = getSelectedPackageId(state);
    const milestonesGig = isGigWithActiveWorkProcess(state);
    const recurringGig = isRecurringGig(packages);
    const selectedPackageData = getPackageById(packages, selectedPackageId) || {};
    const validExtras = getCurrentPackageValidExtras(state);
    const { calculators } = selectedPackageData;
    const hasCalculators = !isEmpty(calculators);

    return validExtras.length > 0 && !milestonesGig && !recurringGig && !hasCalculators;
};

/**
 * Checks if the current selected package is valid for the checkout drawer experience.
 * @param {object} state  - redux state
 * @returns {Boolean}
 */
export const isMilestoneOrSubscriptionGigWithExtras = (state) => {
    const { packages } = state;
    const milestonesGig = isGigWithActiveWorkProcess(state);
    const recurringGig = isRecurringGig(packages);
    const currentPackage = getCurrentPackage(state) || {};
    const extrasCount = countAvailablePricingFactors(currentPackage);
    const { calculators } = currentPackage;
    const noCalculators = isEmpty(calculators);

    return (milestonesGig || recurringGig) && noCalculators && extrasCount > 0;
};

/**
 * Returns valid extras from the selected package.
 * @param {object} state  - redux state
 * @returns {Array}
 */
export const getCurrentPackageValidExtras = ({ packages: { packageList, selectedPackageId } }) => {
    const packageData = getPackageById({ packageList }, selectedPackageId) || {};
    const { features = [], customExtras = [], extraFast } = packageData;
    let validExtras = [];

    if (extraFast && extraFast.currentDuration && extraFast.currentPrice) {
        validExtras.push({
            ...extraFast,
            amount: extraFast.currentValue,
            label: buildLabelForExtraFast({ currentDuration: calculateExtraFastDuration(packageData) }),
            type: EXTRA_FAST_TYPE,
            selected: extraFast.selected,
            duration: extraFast.duration,
        });
    }

    validExtras = validExtras.concat(
        features.reduce((acc, feature) => {
            const isFeatureNotIncluded = !feature.included || isFeatureFiltered(feature);
            if (isFeatureNotIncluded && feature.label && feature.currentPrice) {
                const extendedFeature = {
                    ...feature,
                    type: FEATURE_TYPE,
                    id: feature.id,
                    currentPrice: feature.currentPrice,
                };
                if (feature.name === SCRIPT_PROOFREADING_FEATURE) {
                    acc.unshift(extendedFeature);
                } else {
                    acc.push(extendedFeature);
                }
            }

            return acc;
        }, [])
    );

    validExtras = validExtras.concat(
        customExtras.reduce((acc, customExtra) => {
            if (customExtra.label && customExtra.price) {
                acc.push({
                    ...customExtra,
                    type: CUSTOM_EXTRA_TYPE,
                    id: customExtra.id,
                    currentPrice: customExtra.price,
                });
            }

            return acc;
        }, [])
    );

    return validExtras;
};

export const getGigQuantity = ({ packages: { quantity } }) => quantity;

// End of selectors

export default packagesReducer;
