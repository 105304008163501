import React from 'react';
import PropTypes from 'prop-types';
import AudioUtils from './audioUtils';

const AudioLength = ({ currentPosition, totalLength }) => (
    <div className="audio-length">
        <span>{AudioUtils.audioDurationToText(currentPosition)}</span> /{' '}
        <span>{AudioUtils.audioDurationToText(totalLength)}</span>
    </div>
);

AudioLength.propTypes = {
    currentPosition: PropTypes.number,
    totalLength: PropTypes.number,
};

export default AudioLength;
