import React, { useState, useRef } from 'react';
import { node } from 'prop-types';
import { LazyComponent } from '@fiverr-private/orca';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { metric } from '../../../../utils/metric';
import { BQ_CLICKED_REPORT_GIG } from '../../../../utils/bigQueryEvents';

const ReportBtn = ({ children }) => {
    const {
        general: { isTrustedUser },
        biEvents,
        logger,
    } = useGigPageContext();
    const [importReportModal, setImportReportModal] = useState(false);
    const openModal = useRef();

    const onReportBtnClick = () => {
        biEvents.sendBigQueryEvent({ eventName: BQ_CLICKED_REPORT_GIG });
        metric.count('report_gig.click');

        if (openModal.current) {
            openModal.current();
        } else {
            setImportReportModal(true);
        }
    };

    const lazyImport = () => import(/* webpackChunkName: 'ReportGig' */ '../ReportModal');

    const onLazyImportError = (error) => {
        logger.error(error, {
            description: 'Failed to lazy report gig modal',
        });
    };

    const setOpenModal = (open) => {
        openModal.current = open;
    };

    const childrenProps = {
        onReportGigItemClick: onReportBtnClick,
    };

    return (
        <>
            {children(childrenProps)}
            <LazyComponent
                lazyImport={lazyImport}
                shouldImport={importReportModal}
                onError={onLazyImportError}
                componentProps={{ trigger: setOpenModal, isTrustedUser }}
            />
        </>
    );
};

ReportBtn.propTypes = {
    children: node,
};

export default ReportBtn;
