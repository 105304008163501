import React from 'react';
import { array, object } from 'prop-types';
import { getCalculatorFormattedLabel } from '../../../utils/translation/pricingFactors/label';
import PackageCalculator from '../../shared/PackageCalculator';

import './style.scss';

const CalculatorRow = ({ calculatorRowData = [] }, { general: { subCategoryId } }) => {
    if (calculatorRowData.length !== 3) {
        return null;
    }
    const { name, value } = calculatorRowData[0];

    return (
        <tr className="calculator-row">
            <td className="package-row-label">{getCalculatorFormattedLabel({ name, value, subCategoryId })}</td>
            {calculatorRowData.map((calculator) => (
                <td key={calculator.packageId}>
                    <PackageCalculator {...calculator} />
                </td>
            ))}
        </tr>
    );
};

CalculatorRow.propTypes = {
    calculatorRowData: array,
};

CalculatorRow.contextTypes = {
    general: object,
};

export default CalculatorRow;
