import { BiEvents } from '@fiverr-private/gig_page_context';

export const reportImpressionEvent = (biEvents: BiEvents) => {
    const { sellerId, gigId, userId, userGuid, pageCtxId, pageName } = biEvents.getBigQueryEnrichmentData();

    biEvents.sendRawBigQueryEvent({
        type: 'viewed_contact_seller_hourly_Gig',
        group: 'user_impression',
        action: {
            type: 'impression',
        },
        page: {
            name: pageName,
            ctx_id: pageCtxId,
        },
        component: {
            name: 'contact_seller_hourly',
            type: 'modal',
        },
        user: {
            id: userId,
            uuid: userGuid,
        },
        seller: {
            id: sellerId,
        },
        gig: {
            id: gigId,
        },
    });
};

export const reportClickEvent = (biEvents: BiEvents) => {
    const { sellerId, gigId, userId, userGuid, pageCtxId, pageName } = biEvents.getBigQueryEnrichmentData();

    biEvents.sendRawBigQueryEvent({
        type: 'clicked_on_contact_seller_hourly_Gig',
        group: 'user_actions',
        action: {
            type: 'click',
        },
        page: {
            name: pageName,
            ctx_id: pageCtxId,
        },
        component: {
            name: 'contact_seller_hourly',
            type: 'Button',
        },
        user: {
            id: userId,
            uuid: userGuid,
        },
        seller: {
            id: sellerId,
        },
        gig: {
            id: gigId,
        },
    });
};
