import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { enrichMedia } from '../enrichMedia';

import './index.scss';

class GalleryThumbnails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            viewportWidth: 0,
            contentWidth: 0,
            x: 0,
        };

        this.cachedInfo = []; // Caches thumb dimensions and positions

        // Refs
        this.wrapper = null; // Component wrapper
        this.thumbs = null; // Thumbs wrapper

        // Bindings
        this.handleNav = this.handleNav.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.throttledUpdateDimensions = throttle(this.updateDimensions.bind(this), 250);
        this.centerThumbnail = this.centerThumbnail.bind(this);
        this.getThumbnailInfo = this.getThumbnailInfo.bind(this);
        this.setPosition = this.setPosition.bind(this);
        this.renderThumbnail = this.renderThumbnail.bind(this);
        this.onError = this.onError.bind(this);
    }

    componentDidMount() {
        this.addImageLoadListeners();
        window.addEventListener('resize', this.throttledUpdateDimensions, false);
    }

    componentDidUpdate(prevProps) {
        const { slides, selectedIndex } = this.props,
            { x, viewportWidth } = this.state;

        // When receiving new props (new images), we need to listen for "img loaded" events again
        // and clear the thumbnail dimensions cache.
        if (slides !== prevProps.slides) {
            this.addImageLoadListeners();
            this.cachedInfo = [];
        }

        // If a new selectedIndex is being passed, we check to see if the corresponding thumbnail
        // is "in view", and if not, we put it in the center.
        if (selectedIndex !== prevProps.selectedIndex) {
            const thumb = this.getThumbnailInfo(selectedIndex);

            // If the thumbnail is not within the viewport...
            if (-x > thumb.x || -x + viewportWidth < thumb.x + thumb.width) {
                this.centerThumbnail(selectedIndex);
            }
        }
    }

    componentWillUnmount() {
        this.removeImageLoadListeners();
        window.removeEventListener('resize', this.throttledUpdateDimensions);
    }

    handleNav(relativeDistanceFraction, e) {
        // This function moves the slider (x) by a percentage of the viewport
        // for instance, if 0.5 is passed, half of the visible area will be
        // scrolled out and the equivalent of the non visible, in.
        e && e.preventDefault();
        const { x, viewportWidth, contentWidth } = this.state,
            delta = viewportWidth * relativeDistanceFraction;
        this.setPosition(x + delta, viewportWidth, contentWidth);
    }

    handleSelect(index, e) {
        e && e.preventDefault();
        const { onChange, slides } = this.props;
        if (typeof onChange === 'function') {
            onChange(index, slides[index]);
        }
    }

    setPosition(x, viewportWidth, contentWidth) {
        // This function sets the state of the component but before it makes
        // sure the value of X is not out-of-bounds and if it is, it sets it to
        // the closest bound. We're dealing with an inverse axis for scrolling.
        // The comments bellow are for illustration porpuses. The actual
        // comparisons are inverter < = >   :)

        // The maximum value of X is the width of the viewport minus the width
        // of the thumbs
        const maxX = viewportWidth - contentWidth;
        let showPrev = true,
            showNext = true;

        // If the provided value of X is larger than the max value, we set it to
        // max and hide the "next (>)" button.
        if (x <= maxX) {
            x = maxX;
            showNext = false;
        }

        // If the provided value of X is less than 0, we set it back to 0 and
        // hide the "prev (<)" button.
        if (x >= 0) {
            x = 0;
            showPrev = false;
        }

        // If the viewport/container is larger than the thumbs/content, we set X
        // to 0 and hide both the "prev" and "next" buttons.
        if (viewportWidth >= contentWidth) {
            x = 0;
            showPrev = false;
            showNext = false;
        }

        // Set the state with the sanitized data.
        this.setState({ x, viewportWidth, contentWidth, showPrev, showNext });
    }

    updateDimensions() {
        if (!this.wrapper) {
            return;
        } // Exit early of refs haven't been set
        const viewportWidth = this.wrapper.offsetWidth,
            contentWidth = this.thumbs.offsetWidth,
            { x } = this.state;
        this.setPosition(x, viewportWidth, contentWidth);
    }

    centerThumbnail(index) {
        if (!this.wrapper) {
            return;
        }
        const { viewportWidth, contentWidth } = this.state,
            thumb = this.getThumbnailInfo(index),
            x = (viewportWidth - thumb.width) / 2 - thumb.x; // Centered thumb X
        this.setPosition(x, viewportWidth, contentWidth);
    }

    // This function gets the thumbnails position and offset from the DOM.
    // We cache this values to avoid making calculations more than once.
    getThumbnailInfo(index) {
        if (!this.wrapper) {
            return;
        }
        if (this.cachedInfo[index]) {
            return this.cachedInfo[index];
        }
        const thumbNodes = this.wrapper.querySelectorAll('.thumbnail'),
            offsetX = thumbNodes[0].offsetLeft,
            info = {
                width: thumbNodes[index].offsetWidth,
                x: thumbNodes[index].offsetLeft - offsetX,
            };
        this.cachedInfo[index] = info;
        return info;
    }

    // This component presents an interesting problem. To be able to scroll we need
    // to know the width of the thumbnails container, but this container's width depends on
    // the size of the images that it loads, and the images are loader after the first render.
    // If we try to fire an event once all the images have been loaded, we could run into
    // the problem of images not firing onLoad because they've already been loaded, so a
    // better way to solve this, is to updateDimensions on each image onLoad.
    // To avoid multiple re-renders we can throttle the calls.
    addImageLoadListeners() {
        const images = Array.from(window.document.querySelectorAll('img'));
        this.removeImageLoadListeners();
        images.forEach((image) => image.addEventListener('load', this.throttledUpdateDimensions, false));
    }

    removeImageLoadListeners() {
        const images = Array.from(window.document.querySelectorAll('img'));
        images.forEach((image) => image.removeEventListener('load', this.throttledUpdateDimensions, false));
    }

    async onError(error) {
        const { logger } = this.context;
        const { src } = error.target;

        try {
            const response = await fetch(src);
            if (response.status === 404) {
                logger.warn('Failed to load Gig gallery thumbnail', { source: 'gig-gallery-package', src });
            }
            // eslint-disable-next-line no-empty
        } catch (e) {}
        const { fallbackUrl } = this.props;
        fallbackUrl && (error.target.src = fallbackUrl);
    }

    renderThumbnail(slide, index) {
        const { selectedIndex } = this.props,
            media = enrichMedia(slide),
            classes = classNames({
                thumbnail: true,
                active: index === selectedIndex,
            });

        return (
            <a key={index} href="#" className={classes} onClick={(e) => this.handleSelect(index, e)} rel="nofollow">
                <img src={media.small} alt={slide.name} onError={this.onError} />
            </a>
        );
    }

    render() {
        const { slides, slideOffset } = this.props,
            { x, showPrev, showNext } = this.state,
            thumbs = slides.map(this.renderThumbnail), // Build an array of <img>'s with fallback
            styles = { transform: `translateX(${x}px)` };

        return (
            <div className="gallery-thumbnails" ref={(el) => (this.wrapper = el)}>
                {showPrev && (
                    <a href="#" className="nav-prev" onClick={(e) => this.handleNav(slideOffset, e)} rel="nofollow" />
                )}
                {showNext && (
                    <a href="#" className="nav-next" onClick={(e) => this.handleNav(-slideOffset, e)} rel="nofollow" />
                )}
                <div className="thumbs-container" style={styles} ref={(el) => (this.thumbs = el)}>
                    {thumbs}
                </div>
            </div>
        );
    }
}

GalleryThumbnails.propTypes = {
    slides: PropTypes.array.isRequired,
    selectedIndex: PropTypes.number,
    onChange: PropTypes.func,
    fallbackUrl: PropTypes.string,

    // This is the distance the slider is going to move when pressing the nav buttons
    // If set to 1, the thumbs will slide by 1x the viewport/container with. If set to
    // 0.5, 50% the container width.
    slideOffset: PropTypes.number,
};

GalleryThumbnails.defaultProps = {
    selectedIndex: -1, // Nothing is selected by default
    onChange: null,
    fallbackUrl: null,
    slideOffset: 0.8,
};

GalleryThumbnails.contextTypes = {
    logger: PropTypes.object,
};

export default GalleryThumbnails;
