import React from 'react';
import { InfoOutlineIcon } from '@fiverr-private/icons';
import { grey_1100 } from '@fiverr-private/sass/helpers';
import { Tooltip } from '@fiverr-private/fit';
import './style.scss';
import { I18n } from '@fiverr-private/i18n-react';

const PriceTooltipIcon = () => (
    <Tooltip
        boxClassName="price-tooltip"
        className="price-tooltip-wrapper"
        content={<I18n k="gig_page_perseus.packages.price_tooltip" />}
        position={Tooltip.POSITIONS.BOTTOM}
    >
        <InfoOutlineIcon size={16} color={grey_1100} />
    </Tooltip>
);

export default PriceTooltipIcon;
