import React, { useEffect } from 'react';
import { number, shape, string } from 'prop-types';
import { SafeComponent, Modal } from '@fiverr-private/orca';
import { Button } from '@fiverr-private/fit';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { I18n } from '@fiverr-private/i18n-react';
import {
    numberFormat,
    currencyFormat,
    NUMBER_STYLES,
    dateTimeFormat,
    CURRENCY_CODES,
    DATETIME_ELEMENT_STYLES,
} from '@fiverr-private/localization';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { BQ_SEEN_PROMOTION_MODAL } from '../../utils/bigQueryEvents';
import { MP_SEEN_PROMOTION_MODAL } from '../../utils/mixPanelEvents';
import { addCouponToLocalStorage } from './utils';

import classes from './styles.module.scss';

export const PromotionModal = ({ coupon, profilePhoto, gigId }) => {
    const {
        biEvents,
        seller: { isAgency },
    } = useGigPageContext();
    const [isModalOpen, setModalIsOpen] = React.useState(true);
    const avatarShape = isAgency ? 'square' : 'circle';
    const promotionSubtitleKey = isAgency
        ? 'gig_page_perseus.promotion_modal.subtitle_agency'
        : 'gig_page_perseus.promotion_modal.subtitle';
    const { discountValue, expiresAt, providerName, minOrderAmount, id } = coupon;
    const convertedMinOrder = currencyFormat({ value: minOrderAmount / 100, currencyCode: CURRENCY_CODES.USD });
    const discount = numberFormat({
        value: discountValue / 100,
        options: {
            style: NUMBER_STYLES.PERCENT,
        },
    });
    const date = dateTimeFormat({
        value: new Date(expiresAt),
        options: {
            year: DATETIME_ELEMENT_STYLES.NUMERIC,
            month: DATETIME_ELEMENT_STYLES.SHORT,
            day: DATETIME_ELEMENT_STYLES.TWO_DIGIT,
        },
    });

    const handleClose = () => {
        setModalIsOpen(false);
        addCouponToLocalStorage(id, gigId);
    };

    useEffect(() => {
        const enrichData = biEvents.getBigQueryEnrichmentData();
        biEvents.sendRawBigQueryEvent({
            type: BQ_SEEN_PROMOTION_MODAL,
            group: 'user_impressions',
            sub_group: 'seller_plus_events',
            page: {
                name: enrichData.pageName,
                component: {
                    name: 'promotion_popup',
                    type: 'pop-up',
                },
            },
            user: { id: enrichData.userId },
            seller: { id: enrichData.sellerId },
            gig: { id: enrichData.gigId },
            promotion: { id },
        });

        biEvents.sendMixPanelEvent({
            eventName: MP_SEEN_PROMOTION_MODAL,
            params: {
                promotion: coupon,
                user: { id: enrichData.userId },
                seller: { id: enrichData.sellerId },
                gig: { id: enrichData.gigId },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Modal isOpen={isModalOpen} className={classes.promotionModal} onClose={handleClose}>
                <Modal.Layout centered>
                    <Modal.Header hasCloseBtn={true} className={classes.header} />
                    <Modal.Body>
                        <div className={`m-t-32 ${classes.avatar}`} data-testid="avatar">
                            <Avatar username={providerName} size="lg" shape={avatarShape}>
                                <AvatarImage alt={providerName} src={profilePhoto} />
                            </Avatar>
                        </div>
                        <h3 className={`text-display-3 m-t-20 ${classes.title}`} data-testid="title">
                            <I18n k="gig_page_perseus.promotion_modal.title" params={{ discount }} />
                        </h3>
                        <p className={`co-grey-1100 m-t-12 ${classes.subtitle}`} data-testid="subtitle">
                            <I18n
                                k={promotionSubtitleKey}
                                params={{
                                    discount,
                                    sellerName: providerName,
                                }}
                            />
                        </p>
                        <p
                            className={`tbody-6 co-grey-1100 m-t-16 t-a-center ${classes.details}`}
                            data-testid="details"
                        >
                            <I18n
                                k="gig_page_perseus.promotion_modal.details1"
                                params={{
                                    minAmount: convertedMinOrder,
                                }}
                            />
                            <br />
                            <I18n k="gig_page_perseus.promotion_modal.details2" params={{ date }} />
                        </p>
                    </Modal.Body>
                    <Modal.Footer className={classes.footer}>
                        <Button onClick={handleClose}>
                            <I18n k="gig_page_perseus.promotion_modal.cta" />
                        </Button>
                    </Modal.Footer>
                </Modal.Layout>
            </Modal>
        </React.Fragment>
    );
};

PromotionModal.propTypes = {
    coupon: shape({
        code: string.isRequired,
        id: string.isRequired,
        couponType: string.isRequired,
        discountValue: number.isRequired,
        discountType: string.isRequired,
        providerName: string.isRequired,
        minOrderAmount: number.isRequired,
        expiresAt: string.isRequired,
        deliveryPackId: string,
    }),
    profilePhoto: string.isRequired,
    gigId: number.isRequired,
};

export default SafeComponent(PromotionModal);
