import { isEmpty } from 'lodash';
import { MT_CONTEXTS } from '@fiverr-private/machine_translation';
import { translateUGC } from '../../utils/ugc/translate';
import { filterOutSellerTranslatedFields } from '../../utils/ugc/filterOutSellerTranslatedFields';

export const UGC_UPDATED = 'UGC_UPDATED';
export const ugcUpdated = () => (dispatch, getState) => {
    const { ugc } = getState();

    dispatch({
        type: UGC_UPDATED,
        data: {
            ugc,
        },
    });
};

const hasExtrasInLocalizedPackages = (packages = []) => packages.some(({ customExtras }) => !isEmpty(customExtras));

const prepareSellerTranslationData = (sellerTranslation = {}) => {
    const { packages = {}, ...restSellerTranslation } = sellerTranslation;
    const packagesArray = Object.values(packages);

    // we might have localized packages without localized custom extras
    // in this case we should send packages to MT-service and show packages from seller translation and custom extras from MT
    const hasLocalizedExtras = hasExtrasInLocalizedPackages(packagesArray);

    return {
        ...restSellerTranslation,
        ...(hasLocalizedExtras && { packages }),
    };
};

export const TOGGLE_UGC = 'TOGGLE_UGC';
export const toggleUGC = (sourceInfo) => async (dispatch, getState) => {
    const { ugc = {} } = getState();
    const { showOriginal, ugcMap } = ugc;

    const shouldFetchTranslation = showOriginal && isEmpty(ugcMap.translated);

    if (!shouldFetchTranslation) {
        dispatch({
            type: TOGGLE_UGC,
        });
    } else {
        const sellerTranslatedContent = prepareSellerTranslationData(ugcMap.translatedBySeller);
        const contentToTranslate = filterOutSellerTranslatedFields(ugcMap.original, sellerTranslatedContent);
        const translatedUGC = await translateUGC({
            content: contentToTranslate,
            sourceInfo,
            context: {
                gigTitle: MT_CONTEXTS.GIG_TITLE,
            },
        });

        dispatch({
            type: TOGGLE_UGC,
            data: {
                translatedUGC,
            },
        });
    }

    dispatch(ugcUpdated());
};
