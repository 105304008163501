import React from 'react';
import { FlagIcon } from '@fiverr-private/visuals';
import { Text } from '@fiverr-private/typography';
import { Stack } from '@fiverr-private/layout_components';
import { I18n } from '@fiverr-private/i18n-react';

interface ReportGigItemProps {
  onClick: () => void;
}

export const ReportGigItem: React.FC<ReportGigItemProps> = ({ onClick }) => (
  <Stack
    paddingY="2"
    paddingX="4"
    alignItems="center"
    cursor="pointer"
    backgroundColor={{ hover: 'grey_200' }}
    onClick={onClick}
  >
    <FlagIcon />
    <Text>
      <I18n k="gig_page_nav.action_ctas.report_this_gig.cta" />
    </Text>
  </Stack>
);
