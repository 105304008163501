import { isNumber } from 'lodash';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { VARIANTS } from '../../../types/variants';
import Logger from '../../logger';
import { getVariation } from '..';
import { maxios } from '../../maxios';

class DynamicAllocationService {
    constructor() {
        this._allocated = {};
    }

    /**
     * initialize the allocated state with the user context AB tests
     * @private
     */
    init() {
        const { abTests = {} } = getContext();
        this._allocated = { ...abTests };
    }

    /**
     * for given experiment Id checks wither or not the user is already allocated
     *
     * @param experimentId
     * @returns {boolean}
     * @private
     */
    _isAllocated(experimentId) {
        return isNumber(this._allocated[experimentId]);
    }

    /**
     * get allocation group for given experiment Id
     * returns group A as default (in case we don't have allocation for this experiment Id)
     *
     * @param experimentId
     * @returns {number|number|Number|*}
     * @private
     */
    _getAllocation(experimentId) {
        const allocatedExperiment = this._allocated[experimentId] || {};

        return allocatedExperiment || VARIANTS.A;
    }

    /**
     * api call client allocation route, to allocate users to experiment on demand
     * expected to get called with the experiment id and number of groups to allocate.
     *
     * @param experimentId
     * @param numOfGroups
     * @returns {Promise<{failedToAllocate: boolean}| { experiment_id: number, group: number, state: string }>}
     */
    async _dynamicAllocate({ experimentId, numOfGroups = VARIANTS.B }) {
        const allocationUrl = pathfinder('experiment_allocate_api');

        try {
            const { data } = await maxios.post(
                allocationUrl,
                { experiment_id: experimentId, num_of_groups: numOfGroups },
                { routeKey: 'experiment_allocate_api' }
            );

            return data;
        } catch (error) {
            const logger = Logger.getInstance();
            logger.error(error, {
                description: 'Failed to call allocate experiment request',
                experimentId,
            });

            throw error;
        }
    }

    /**
     * check's and allocates user to given experiment id in case he is not already allocated.
     *
     * @param experimentId
     * @param numOfGroups
     * @returns {Promise<number|number|Number|*>}
     */
    async allocate({ experimentId, numOfGroups }) {
        if (this._isAllocated(experimentId)) {
            return this._getAllocation(experimentId);
        }

        try {
            const { group } = await this._dynamicAllocate({ experimentId, numOfGroups });

            const variation = getVariation(experimentId, { [experimentId]: group });

            this._allocated[experimentId] = variation;

            return variation;
        } catch (error) {
            return VARIANTS.A;
        }
    }
}

export default new DynamicAllocationService();
