import React from 'react';
import { bool, func, object } from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import { MP_CLICKED_COMPARE_PACKAGES } from '../../../../../utils/mixPanelEvents';

import styles from './style.module.scss';

const CompareBtn = (
    { onComparePackagesClick = noop },
    { general, biEvents, isBusinessLayout, isProRebrandingLayout }
) => {
    const { isPro } = general;
    const onClick = (event) => {
        event.preventDefault();

        biEvents.sendMixPanelEvent({ eventName: MP_CLICKED_COMPARE_PACKAGES });

        onComparePackagesClick();
    };

    const getAdditionalBtnComparePackagesClass = () => {
        if (isBusinessLayout || isProRebrandingLayout) {
            return styles.newProLayout;
        } else if (isPro) {
            return styles.marketplaceProGig;
        } else {
            return '';
        }
    };

    const compareBtnClasses = classNames(styles.btnComparePackages, 'tbody-6', getAdditionalBtnComparePackagesClass());

    return (
        <button className={compareBtnClasses} onClick={onClick}>
            <I18n k={'gig_page_perseus.packages_tabs.compare_packages'} />
        </button>
    );
};

CompareBtn.propTypes = {
    onComparePackagesClick: func,
};

CompareBtn.contextTypes = {
    general: {
        isPro: bool,
    },
    biEvents: object,
    isBusinessLayout: bool,
    isProRebrandingLayout: bool,
};

export default CompareBtn;
