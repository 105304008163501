import React, { lazy, Suspense } from 'react';
import { bool, func, object } from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { s_play_circle, s_pause_circle } from '@fiverr-private/fit/icons';
import VolumeBar from '../../components/VolumeBar';
import Image from '../../components/Image';
import GigReview from '../../GigReview';
import { enrichMedia } from '../../enrichMedia';
import SlideHOC from '../slideHOC';
import AudioUtils from './audioUtils';
import AudioLength from './audioLength';

const Waveform = lazy(() => import(/* webpackChunkName: 'Waveform' */ './waveform'));

import '../slide.scss';
import './audio.scss';

const VISUAL_OPTIONS = {
    barWidth: 2,
    waveColor: 'white',
    progressColor: '#1DBF73',
    height: 60,
    normalize: true,
};

class AudioSlide extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPosition: 0,
            totalLength: 0,
            isPlaying: false,
            isLoaded: false,
            isLoading: false,
            volume: AudioUtils.getVolume(),
            renderWaveform: false,
        };

        this.handleTogglePlay = this.handleTogglePlay.bind(this);
        this.handlePosChange = this.handlePosChange.bind(this);
        this.handlePlayerReady = this.handlePlayerReady.bind(this);
        this.handleVolumeChange = this.handleVolumeChange.bind(this);
        this.handleKeydown = this.handleKeydown.bind(this);
        this.seek = this.seek.bind(this);
        this.loadAudioPlayer = this.loadAudioPlayer.bind(this);
        this.renderAudioPlayer = this.renderAudioPlayer.bind(this);
    }

    componentDidMount() {
        this.setState({
            renderWaveform: true,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { flashReview, hideReview, isShowingReview, isActive } = this.props;

        if (!nextProps.isShowingReview && isShowingReview) {
            hideReview();
        }
        if (nextProps.isActive && !isActive) {
            flashReview();
        }
        if (!nextProps.isActive && isActive) {
            this.setState({ isPlaying: false, isLoaded: false });
        }

        // Update volume if it was updated in another audio slide
        if (nextProps.isActive) {
            this.setState({ volume: AudioUtils.getVolume() });
        }
    }

    handleTogglePlay(e) {
        e && e.preventDefault();

        this.setState({
            isPlaying: !this.state.isPlaying,
        });
    }

    handlePosChange(e) {
        const {
            originalArgs: [currentPosition],
        } = e;

        this.setState({
            currentPosition,
        });
    }

    handlePlayerReady(e) {
        // stupid waveform wrapper will not expose what I need.
        this.setState({
            totalLength: e.wavesurfer.getDuration(),
            isLoaded: true,
            isLoading: false,
            isPlaying: true,
        });
    }

    handleKeydown(e) {
        e && e.preventDefault();
        const { volume } = this.state,
            { isActive } = this.props;

        if (!isActive) {
            return;
        }
        AudioUtils.handlePressEvents(e.keyCode, this.handleTogglePlay, this.seek, this.handleVolumeChange, volume);
    }

    handleVolumeChange(volume) {
        if (!AudioUtils.validVolume(volume)) {
            return;
        }
        const newVolume = parseFloat(volume.toFixed(2));

        this.setState(
            {
                volume: newVolume,
            },
            () => AudioUtils.setVolume(newVolume)
        );
    }

    seek(offset) {
        const { currentPosition, totalLength } = this.state;
        let nextPosition = currentPosition + offset;

        if (nextPosition < 0) {
            nextPosition = 0;
        } else if (nextPosition > totalLength) {
            nextPosition = totalLength;
        }

        this.setState({
            currentPosition: nextPosition,
        });
    }

    loadAudioPlayer(e) {
        e && e.preventDefault();

        this.setState({
            isLoading: true,
        });
    }

    renderAudioPlayer() {
        const { volume, isPlaying, currentPosition, totalLength, renderWaveform } = this.state;
        const {
            slide: { audioSrc, title },
            isActive,
        } = this.props;

        return (
            <div className="audio-player">
                <div className="top-bar">
                    <h4>{title}</h4>
                    <AudioLength currentPosition={currentPosition} totalLength={totalLength} />
                    <VolumeBar volume={volume} handleVolumeChange={this.handleVolumeChange} />
                </div>
                <div className="wrap-bottom-bar">
                    <div className="bottom-bar">
                        <Icon size={44} className="play-button" onClick={this.handleTogglePlay}>
                            {isPlaying ? s_pause_circle : s_play_circle}
                        </Icon>
                        <div className="waveform">
                            {renderWaveform && (
                                <Suspense fallback={<div />}>
                                    <Waveform
                                        audioFile={audioSrc}
                                        pos={this.state.currentPosition}
                                        onPosChange={this.handlePosChange}
                                        onReady={this.handlePlayerReady}
                                        playing={isPlaying && isActive}
                                        options={VISUAL_OPTIONS}
                                        volume={volume}
                                        backend="MediaElement"
                                    />
                                </Suspense>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { isLoading, isLoaded } = this.state;
        const { isActive, expanded, onClick, preload, slide, toggleReview, review, isShowingReview } = this.props;
        const { name } = slide;
        const media = enrichMedia(slide);
        const showPlayButton = !isLoading && !isLoaded;
        const thumbClasses = classNames('thumbnail', 'audio', { 'in-progress': isLoading });
        const slideClasses = classNames('slide', 'slide-audio', { 'active-review': isShowingReview && isActive });
        const lazyLoad = !isActive && !preload;
        const showReview = expanded ? review : review && showPlayButton;

        return (
            <div className={slideClasses} onKeyDown={this.handleKeydown} onClick={onClick}>
                <div className={thumbClasses} tabIndex="0" onClick={toggleReview}>
                    {showPlayButton && (
                        <Icon size={80} className="slide-play-button" onClick={this.loadAudioPlayer}>
                            {s_play_circle}
                        </Icon>
                    )}

                    <Image
                        lazyLoad={lazyLoad}
                        isActive={isActive}
                        alt={name}
                        src={expanded ? media.original : media.medium}
                    />

                    {!showPlayButton && this.renderAudioPlayer()}
                </div>
                {showReview && <GigReview {...review} compact={!expanded} />}
            </div>
        );
    }
}

AudioSlide.propTypes = {
    isActive: bool,
    slide: object,
    onClick: func,
    expanded: bool,
    preload: bool,
    flashReview: func,
    toggleReview: func,
    hideReview: func,
    isShowingReview: bool,
    review: object,
};

export { AudioSlide };
export default SlideHOC(AudioSlide);
