import React from 'react';
import classNames from 'classnames';
import { bool, node, string } from 'prop-types';
import SellerTranslationBadgeWrapper from '../../SellerTranslationBadgeWrapper';
import { useShowPromotionDiscountedPriceBIEvent } from '../hooks/useShowPromotionDiscountedPriceBIEvent';
import PriceTooltipIcon from '../PriceTooltipIcon';

import './style.scss';

const HeaderDefault = ({ title, showPrice, formattedPrice, description, showPriceTooltip, discountedPriceSection }) => {
    useShowPromotionDiscountedPriceBIEvent(!!discountedPriceSection);

    return (
        <header className="header-default">
            <h3 className={classNames({ 'm-b-24': !!discountedPriceSection })}>
                {title && (
                    <b className="title">
                        <SellerTranslationBadgeWrapper translationKey="packages">{title}</SellerTranslationBadgeWrapper>
                    </b>
                )}
                {showPrice && (
                    <div className="price-wrapper">
                        {!showPriceTooltip && <span className="price">{formattedPrice}</span>}
                        {showPriceTooltip && (
                            <span className="price-with-tooltip">
                                <span className="price">{formattedPrice}</span>
                                {showPriceTooltip && <PriceTooltipIcon />}
                            </span>
                        )}
                        {discountedPriceSection && <div className="m-t-4">{discountedPriceSection}</div>}
                    </div>
                )}
            </h3>
            {description && <p>{description}</p>}
        </header>
    );
};

HeaderDefault.propTypes = {
    title: string,
    showPrice: bool,
    showPriceTooltip: bool,
    formattedPrice: string,
    description: string,
    discountedPriceSection: node,
};

export default HeaderDefault;
