import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';

import './style.scss';

const StudioBadge = () => (
    <span className="studio-badge">
        <I18n k={'gig_page_perseus.studio.studio'} />
    </span>
);

export default StudioBadge;
