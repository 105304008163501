const xss = require('xss');
const { XSS_STRIP, XSS_WHITELIST } = require('./constants');

/**
 * Sanitizes text for safe display
 * @param {string} text
 * @param {Record<string, array>} whiteList
 * @returns {string|null}
 */
const sanitize = (text, whiteList = XSS_WHITELIST) => {
    if (!text) {
        return null;
    }

    return xss(removeDuplicatedBreakLines(text), {
        whiteList,
        stripIgnoreTag: true,
        stripIgnoreTagBody: XSS_STRIP,
    });
};

const removeDuplicatedBreakLines = (text) => {
    const regex = /<br><br>(<br>)+/g;
    const replaceTo = '<br><br>';

    return text.replace(regex, replaceTo);
};

module.exports = {
    sanitize,
};
