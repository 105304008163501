import React from 'react';
import { omit } from 'lodash';
import { GigPageContextProviderProps } from '../../interfaces/gigPageContext';
import { getSellerUsername } from '../../utils/getSellerUsername';
import { isPageOwner } from '../../utils/isPageOwner';
import { GigPageContext } from './GigPageContext';

export const GigPageContextProvider: React.FC<GigPageContextProviderProps> = ({ children, ...props }) => (
  <GigPageContext.Provider
    value={{
      isPageOwner: isPageOwner(props.general),
      sellerUsername: getSellerUsername() || props.sellerUsername,
      ...omit(props, 'sellerUsername'),
    }}
  >
    {children}
  </GigPageContext.Provider>
);
