import React from 'react';
import { isEqual } from 'lodash';
import {
  Consultation,
  CONSULTATION_DEFAULT_RESPONSE,
  ConsultationButtonItem,
  ConsultationData,
} from '@fiverr-private/consultation';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import pathfinder from '@fiverr-private/pathfinder';
import GigCurrencyConverter from '../../../utils/gigCurrencyConverter';
import { calcMinimumDurationPackage } from './calcMinimumDurationPackage';

export const ConsultationButton = () => {
  const {
    consultationData,
    currencyConverter,
    general: { sellerId, gigId },
    seller: { displayName, photoUrl },
  } = useGigPageContext();

  if (!consultationData || isEqual(consultationData, CONSULTATION_DEFAULT_RESPONSE)) {
    return null;
  }

  const { pricing } = consultationData as ConsultationData;

  const minimumPackagePrice = calcMinimumDurationPackage(pricing);

  const learnMoreLink = pathfinder(
    'help_center_article',
    { article_id: 12744510059665 },
    { query: { segment: 'buyer' } }
  );

  const biEnrichment = {
    pageName: 'gig_page',
    elementName: 'contact_seller_direct',
    group: 'gig_page',
    gig: { id: gigId },
    seller: { id: sellerId },
  };

  const mixpanelEnrichment = {
    Origin: 'gig_page',
    GigId: gigId,
    SellerId: sellerId,
  };

  const currency = (currencyConverter as GigCurrencyConverter).currency;

  return (
    <Consultation
      consultationData={consultationData}
      mixpanelEnrichment={mixpanelEnrichment}
      biEnrichment={biEnrichment}
      sellerId={sellerId}
      sellerName={displayName}
      profilePhoto={photoUrl}
      learnMoreLink={learnMoreLink}
      currency={currencyConverter.getCurrency()}
    >
      <ConsultationButtonItem currency={currency} price={minimumPackagePrice} />
    </Consultation>
  );
};
