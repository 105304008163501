import { startCase } from 'lodash';
import {
    BQ_CLICKED_CUSTOM_ORDER,
    BQ_CLICKED_PACKAGE_SELECT,
    BQ_CLICKED_REPORT_GIG,
    BQ_GIG_DESCRIPTION_SEE_MORE,
    BQ_CLICKED_CONTACT_SELLER,
    BQ_CLICKED_PACKAGES_TAB,
    BQ_CLICKED_REVIEWS_SNIPPET_MORE,
    BQ_SEEN_BOMBA_COUPON,
    BQ_OPEN_COUPON_ARTICLE,
    BQ_SELLER_BUYER_SPEAK_SAME_LANGUAGE,
    BQ_CLICKED_GALLERY_VIDEO,
    BQ_CLICKED_SELLER_LINK,
    BQ_CLICKED_SELLER_NAME_ANCHOR,
    BQ_CLICKED_TOPNAV,
    BQ_CLICKED_REVIEWS_RATINGS,
    BQ_CLICKED_ON_CONTACT_SELLER_OPTIONS,
    BQ_SIGNUP_CLICK,
    BQ_NOTABLE_CLIENTS_ABOVE_THE_FOLD_SHOW,
    BQ_NOTABLE_CLIENTS_BELOW_THE_FOLD_SHOW,
    BQ_NOTABLE_CLIENT_GIG_ELIGIBLE,
    BQ_CLICKED_ON_CONTINUE_IN_WORK_PROCESS_DRAWER,
    BQ_VOICE_OVER_AI_AUDITION_SUCCESS,
    BQ_VOICE_OVER_AI_AUDITION_CLICKED_ON_PLAY,
    BQ_VOICE_OVER_AI_AUDITION_ERROR_MESSAGE_IS_SHOWN,
    BQ_PAYMENT_DRAWER_IS_SHOWN,
    BQ_CLICK_ON_SUBSCRIBE_OPTION,
    BQ_CLICKED_PROMOTE_YOUR_GIG,
    BQ_VIEWED_PROMOTE_YOUR_GIG,
    BQ_RETURNING_BUYER_SUB_MODAL_SHOW,
    BQ_RETURNING_BUYER_SUB_MODAL_CLICK_CTA,
    BQ_CLICKED_ON_STICKY_CHAT_OPTION,
    BQ_REQUEST_TO_ORDER_ED_TOOLTIP_IS_SHOWN,
    BQ_REQUEST_TO_ORDER_BUTTON_IS_SHOWN,
    BQ_REQUEST_TO_ORDER_BUTTON_CLICKED,
    BQ_CHECKOUT_DRAWER_VIEWED,
    BQ_CHECKOUT_GIG_ELIGIBLE,
    BQ_FAQ_EXPANSION,
    BQ_SELLER_DETAILS_SEE_MORE,
    BQ_REVIEWS_TOGGLE,
    BQ_CLICKED_SHOW_MORE_EXTRAS,
    BQ_CLICKED_ON_CONTINUE_TO_CHECKOUT,
    BQ_CLICKED_ON_GIG_REVIEWS_SECTION,
    BQ_CLICKED_ON_SELLER_REVIEWS_SECTION,
    BQ_LOCAL_CATALOG_MACHINE_TRANSLATION_PREVIEW_VIEWED,
    BQ_LOCAL_CATALOG_HUMAN_TRANSLATION_PREVIEW_VIEWED,
    BQ_LOCAL_CATALOG_GIG_TRANSLATION_LAYER_VIEWED,
    BQ_LOCAL_CATALOG_GIG_IN_ORIGINAL_LANGUAGE_CLICKED,
    BQ_LOCAL_CATALOG_GIG_IN_LOCAL_LANGUAGE_CLICKED,
} from '../bigQueryEvents';
import { RECURRING_TIME_UNITS } from '../../types/recurringTimeUnits';
import { TIME_UNITS } from '../../types/timeUnits';
import { GIG_TYPES } from '../../types/gig';
import * as pageSections from '../pageSections';

/** Note: `page.name:PAGE_NAME` is reported by default */
export const PAGE_NAME = 'gig_page';

export const clickActionData = {
    group: 'user_action',
    sub_group: 'website_actions',
    action: {
        type: 'click',
    },
    entity: 'client',
    out_of_session: false,
};

export const createBqPackageSelectParams = (data) => ({
    type: BQ_CLICKED_PACKAGE_SELECT,
    group: startCase(data.source),
    uid: data.userGuid,
    user: { id: data.userId },
    seller: { id: data.sellerId },
    category: { id: data.categoryId },
    nested_sub_category: { id: data.nestedSubCategoryId },
    sub_category: { id: data.subCategoryId },
    action: { type: 'click' },

    aux: { package_selected: data.id },
    package: { id: data.id },
    page: {
        element: {
            ...data?.page?.element, // metadata array of Pricing Factors + Position in list
            type: data.paymentOption,
        },
    },
    ...getRecurringParams(data),
});

const getRecurringParams = ({ selectedRecurringOption }) =>
    selectedRecurringOption ? buildRecurringOptionParams(selectedRecurringOption) : {};

const buildRecurringOptionParams = ({ id, duration, durationTimeUnit, discountPercentage }) => ({
    subscription_option: {
        type: GIG_TYPES.RECURRING,
        id,
        duration,
        time_unit: transformTimeUnit(durationTimeUnit),
        discount: discountPercentage,
    },
});

const transformTimeUnit = (durationTimeUnit) => {
    switch (durationTimeUnit) {
        case RECURRING_TIME_UNITS.WEEK:
            return TIME_UNITS.WEEK;
        case RECURRING_TIME_UNITS.MONTH:
            return TIME_UNITS.MONTH;
        default:
            return TIME_UNITS.MONTH;
    }
};

export const createBqCustomOrderClickParams = (data) => ({
    type: BQ_CLICKED_CUSTOM_ORDER,
    event_source: data.eventSource,
    group: 'buyer_request',
    action: {
        type: 'click',
    },
    seller: { id: data.sellerId },
    page: {
        element: {
            type: 'button',
            text: 'get_a_quote',
        },
    },
    feature: {
        name: 'get_a_quote',
    },
});

export const createBqReportGigClickParams = (data) => ({
    type: BQ_CLICKED_REPORT_GIG,
    group: PAGE_NAME,
    seller: { id: data.sellerId },
    out_of_session: false,
    entity: 'client',
});

/**
 * BI event that is sent when clicking on see more gig description.
 * @param {object} data event data
 * @param {number} data.sellerId seller user id
 * @param {number} data.userId current user id
 * @returns {object} parameters for the event
 */
export const createBqDescriptionSeeMoreParams = ({ sellerId, userId }) => ({
    type: BQ_GIG_DESCRIPTION_SEE_MORE,
    group: PAGE_NAME,
    seller: { id: sellerId },
    buyer: { id: userId },
    page: {
        element: {
            type: 'text',
            name: 'see_more_gig_description',
        },
        component: {
            name: 'gig_description',
        },
    },
    ...clickActionData,
});

/**
 * BI event that is sent when clicking on see more seller details.
 * @param {object} data event data
 * @param {number} data.sellerId seller user id
 * @param {number} data.userId current user id
 * @returns {object} parameters for the event
 */
export const createBqSellerDetailsSeeMoreParams = ({ sellerId, userId }) => ({
    type: BQ_SELLER_DETAILS_SEE_MORE,
    group: PAGE_NAME,
    seller: { id: sellerId },
    buyer: { id: userId },
    page: {
        element: {
            type: 'text',
            name: 'see_more_seller_details',
        },
        component: {
            name: 'seller_details',
        },
    },
    ...clickActionData,
});

/**
 * BI event that is sent when clicking on FAQ expansion.
 * @param {object} data event data
 * @param {number} data.sellerId seller user id
 * @param {number} data.userId current user id
 * @returns {object} parameters for the event
 */
export const createBqFAQExpansionParams = ({ sellerId, userId }) => ({
    type: BQ_FAQ_EXPANSION,
    group: PAGE_NAME,
    seller: { id: sellerId },
    buyer: { id: userId },
    page: {
        element: {
            type: 'text',
            name: 'faq_expansion',
        },
        component: {
            name: 'faq',
        },
    },
    ...clickActionData,
});

/**
 * BI event that is sent when clicking on reviews toggle.
 * @param {object} data event data
 * @param {number} data.sellerId seller user id
 * @param {number} data.userId current user id
 * @returns {object} parameters for the event
 */
export const createBqReviewsToggleParams = ({ sellerId, userId }) => ({
    type: BQ_REVIEWS_TOGGLE,
    group: PAGE_NAME,
    seller: { id: sellerId },
    buyer: { id: userId },
    page: {
        element: {
            type: 'text',
            name: 'reviews_toggle',
        },
        component: {
            name: 'reviews',
        },
    },
    ...clickActionData,
});

export const createBqContactSellerClickParams = (data) => ({
    type: BQ_CLICKED_CONTACT_SELLER,
    event_source: data.eventSource,
    seller: { id: data.sellerId },
    page: {
        element: {
            type: 'button',
        },
        component: {
            name: data.name,
        },
    },
    ...clickActionData,
});

export const createBqClickedPackagesTab = (data) => ({
    type: BQ_CLICKED_PACKAGES_TAB,
    seller: { id: data.sellerId },
    page: {
        element: {
            type: 'tab',
            ...data.page.element,
        },
    },
    ...clickActionData,
});

export const createBqReviewsSnippetMoreClickParams = (data) => ({
    type: BQ_CLICKED_REVIEWS_SNIPPET_MORE,
    seller: { id: data.sellerId },
    page: {
        element: {
            type: 'button',
            name: 'reviews snippet',
        },
        component: {
            name: data.name,
        },
    },
    domain: {
        name: 'quality',
    },
    publisher: {
        domain: 'algos',
        sub_domain: 'quality',
    },
    ...clickActionData,
});

export const createGigGalleryReportParams = (data) => ({
    type: data.type,
    group: 'gig_page_gallery',
});

export const createSeenCouponParams = ({ userId, couponId, deliveryPackId, discount, sellerId, code }) => ({
    type: BQ_SEEN_BOMBA_COUPON,
    event_source: PAGE_NAME,
    group: 'user_impressions',
    seller: {
        id: sellerId,
    },
    feature: {
        name: 'coupon',
    },
    entity: 'client',
    coupon: {
        type: 'seller_coupon',
        invitee_user_id: userId,
        id: couponId,
        promo_code: code,
        invitation_id: deliveryPackId,
        discount_percent: discount,
    },
});

export const createOpenCouponArticleParams = ({ userId, couponId, deliveryPackId, discount, sellerId, code }) => ({
    type: BQ_OPEN_COUPON_ARTICLE,
    event_source: PAGE_NAME,
    seller: {
        id: sellerId,
    },
    feature: {
        name: 'coupon',
    },
    coupon: {
        type: 'seller_coupon',
        invitee_user_id: userId,
        id: couponId,
        promo_code: code,
        invitation_id: deliveryPackId,
        discount_percent: discount,
    },
    ...clickActionData,
});

export const createBqSellerAndBuyerSpeakSameLanguageParams = ({ sellerId, commonLanguage }) => ({
    type: BQ_SELLER_BUYER_SPEAK_SAME_LANGUAGE,
    group: PAGE_NAME,
    seller: {
        id: sellerId,
        profile: {
            language: commonLanguage.name,
            language_level: String(commonLanguage.level),
        },
    },
    domain: {
        name: 'localization',
    },
});

export const createBqClickedGalleryVideoParams = ({ slideIndex }) => ({
    type: BQ_CLICKED_GALLERY_VIDEO,
    position: slideIndex,
    page: {
        component: {
            name: 'gig_page_video',
        },
    },
    ...clickActionData,
});

export const createBqClickedSellerNameAnchor = () => ({
    type: BQ_CLICKED_SELLER_NAME_ANCHOR,
    page: {
        component: {
            name: 'gig_page_seller_icon',
        },
    },
    ...clickActionData,
});

export const createBqClickedSellerLinkParams = () => ({
    type: BQ_CLICKED_SELLER_LINK,
    page: {
        component: {
            name: 'gig_page_seller_name',
        },
    },
    ...clickActionData,
});

export const createBqClickedReviewsRatingsParams = () => ({
    type: BQ_CLICKED_REVIEWS_RATINGS,
    page: {
        component: {
            name: 'gig_page_rating_stars',
        },
    },
    ...clickActionData,
});

export const createBqTopNavClickParams = ({ sectionKey }) => {
    const sectionEventNameMap = {
        [pageSections.OVERVIEW]: 'overview',
        [pageSections.THE_SERVICE]: 'about_this_service',
        [pageSections.DESCRIPTION]: 'description',
        [pageSections.FAQ]: 'FAQ',
        [pageSections.REVIEWS]: 'reviews',
        [pageSections.ORDER_DETAILS]: 'order_details',
        [pageSections.RECOMMENDATIONS]: 'recommendations',
        [pageSections.ABOUT_SELLER]: 'about_the_seller',
        [pageSections.PACKAGES_TABLE]: 'compare_packages',
    };

    return {
        type: BQ_CLICKED_TOPNAV,
        page: {
            element: {
                name: sectionEventNameMap[sectionKey],
            },
            component: {
                name: 'gig_page_top_bar',
            },
        },
        ...clickActionData,
    };
};

export const createBqContactSellerOptionsClickParams = (data) => ({
    type: BQ_CLICKED_ON_CONTACT_SELLER_OPTIONS,
    event_source: data.eventSource,
    seller: { id: data.sellerId },
    page: {
        element: {
            type: 'button',
        },
        component: {
            name: data.name,
        },
    },
    action: {
        type: 'click',
    },
    group: 'buyer_request',
});

export const createBqStickyChatClickParams = (data) => ({
    type: BQ_CLICKED_ON_STICKY_CHAT_OPTION,
    event_source: PAGE_NAME,
    seller: { id: data.sellerId },
    page: {
        element: {
            name: 'sticky_chat',
            text: JSON.stringify({
                'online/offline': data.isOnline ? 'online' : 'offline',
                avg_response_time: `${data.responseTime}_hour`,
            }),
        },
    },
    action: {
        type: 'click',
    },
    group: 'user_actions',
});

export const createBqSignupClickParams = () => ({
    type: BQ_SIGNUP_CLICK,
    group: 'signin flow',
});

export const createBqNotableClientsAboveFoldShowParams = () => ({
    type: BQ_NOTABLE_CLIENTS_ABOVE_THE_FOLD_SHOW,
    group: 'user actions',
});

export const createBqNotableClientsBelowFoldShowParams = () => ({
    type: BQ_NOTABLE_CLIENTS_BELOW_THE_FOLD_SHOW,
    group: 'user actions',
});

export const createBqNotableClientsListHoverdParams = ({ sellerId, source, clients }) => ({
    type: BQ_NOTABLE_CLIENT_GIG_ELIGIBLE,
    group: 'user actions',
    page: {
        component: {
            num_of_elements: clients?.length,
            metadata: clients?.join(),
            position: source,
        },
    },
    seller: {
        id: sellerId,
    },
});

/**
 * Create BQ event data for when user clicks Continue CTA in Milestone payment drawer
 * @param {Object} data to use for enriching the event data
 * @returns {Object} The data for the BQ event
 */
export const createBqContinueInWorkProcessPaymentClickedParams = (data) => ({
    type: BQ_CLICKED_ON_CONTINUE_IN_WORK_PROCESS_DRAWER,
    group: 'user_action',
    entity: 'client',
    event_source: data.event_source,
    action: {
        type: 'click',
    },
    gig: {
        workflow_creation: {
            milestone_pay: data.isMilestonesOrderSelected,
        },
    },
    seller: {
        id: data.sellerId,
    },
    buyer: {
        id: data.userId,
    },
});

/**
 * BI event that will be sent whenever a new Voice Over AI sample was generated successfully.
 * @param {object} data The event's data.
 * @param {number} data.sellerId Gig's seller ID.
 * @param {number} data.auditionId A UUID representing a single sample.
 *                                 Will be generated for every playing of a new sentence (or in case of sentence errors).
 * @param {string} data.sentence The sentence sampled.
 * @returns {object} parameters for the event.
 */
export const createVoiceOverAiAuditionSuccessParams = ({ sellerId, auditionId, sentence }) => ({
    type: BQ_VOICE_OVER_AI_AUDITION_SUCCESS,
    group: 'technical_events',
    entity: 'client',
    seller: { id: sellerId },
    page: {
        element: {
            id: auditionId,
            text: sentence,
        },
    },
    action: {
        type: 'click',
    },
});

/**
 * BI event that will be sent whenever the user clicked the play button, or pressed enter in order to play.
 * @param {object} data The event's data.
 * @param {number} data.sellerId Gig's seller ID.
 * @param {number} data.auditionId A UUID representing a single sample.
 *                                 Will be generated for every playing of a new sentence (or in case of sentence errors).
 * @param {string} data.sentence The sentence sampled.
 * @returns {object} parameters for the event.
 */
export const createVoiceOverAiAuditionClickedOnPlayParams = ({ sellerId, auditionId, sentence }) => ({
    type: BQ_VOICE_OVER_AI_AUDITION_CLICKED_ON_PLAY,
    group: 'technical_events',
    entity: 'client',
    seller: { id: sellerId },
    page: {
        element: {
            id: auditionId,
            text: sentence,
        },
    },
    action: {
        type: 'click',
    },
});

/**
 * BI event that will be sent whenever an error has occurred which prevents sample generations.
 * @param {object} data The event's data.
 * @param {number} data.sellerId Gig's seller ID.
 * @param {number} data.auditionId A UUID representing a single sample.
 *                                 Will be generated for every playing of a new sentence (or in case of sentence errors).
 * @param {string} data.sentence The sentence sampled.
 * @param {string} data.error The error, can be any of the errors in './errors' ERRORS object.
 * @returns {object} parameters for the event.
 */
export const createVoiceOverAiAuditionErrorMessageIsShownParams = ({ sellerId, auditionId, sentence, error }) => ({
    type: BQ_VOICE_OVER_AI_AUDITION_ERROR_MESSAGE_IS_SHOWN,
    group: 'technical_events',
    entity: 'client',
    seller: { id: sellerId },
    page: {
        element: {
            id: auditionId,
            type: 'error_message',
            text: sentence,
            metadata: error,
        },
    },
});

/**
 * BI event that will be sent whenever opening the payment drawer
 * @param {object} data The event's data
 * @param {number} data.sellerId Gig's seller ID
 * @param {number} data.userId Current user ID
 * @param {string} [data.paymentOption] Gig's payment option
 * @param {object} [data.page] Gig's payment page
 * @returns {object} parameters for the event
 */
export const createSeenPaymentDrawerParams = ({ userId, sellerId, paymentOption, page, packageData, user, localization, payment, gig }) => ({
    type: BQ_PAYMENT_DRAWER_IS_SHOWN,
    group: 'component_view',
    sub_group: 'drawer',
    seller: {
        id: sellerId,
    },
    buyer: {
        id: userId,
    },
    entity: 'client',
    page: {
        ...page,
        element: {
            type: paymentOption
        }
    },
    package: packageData,
    user,
    localization,
    payment,
    gig
});

/**
 * BI event that will be sent whenever choosing subscription payment option in the drawer
 * @param {object} data The event's data
 * @param {number} data.sellerId Gig's seller ID
 * @param {number} data.userId Current user ID
 * @returns {object} parameters for the event
 */
export const createClickedOnSubscriptionPaymentOptionParams = ({ sellerId, userId }) => ({
    ...clickActionData,
    type: BQ_CLICK_ON_SUBSCRIBE_OPTION,
    seller: { id: sellerId },
    buyer: {
        id: userId,
    },
});

/**
 * Create BQ event data for when user clicks Promote your gig in the gig page
 * @param {Object} data to use for enriching the event data
 * @param {number} data.userId Gig's seller and current user ID
 * @param {string} data.promotionStatus Promoted gigs program status of the seller
 * @returns {Object} The data for the BQ event
 */
export const createClickPromoteEventParams = ({ userId, promotionStatus }) => ({
    type: BQ_CLICKED_PROMOTE_YOUR_GIG,
    feature: {
        name: 'promoted_gigs',
    },
    group: 'user_actions',
    action: {
        type: 'click',
    },
    user: {
        id: userId,
    },
    page: {
        component: {
            name: promotionStatus,
        },
    },
});

/**
 * Create BQ event data for when user sees Promote your gig in the gig page
 * @param {Object} data to use for enriching the event data
 * @param {number} data.userId Gig's seller and current user ID
 * @param {string} data.promotionStatus Promoted gigs program status of the seller
 * @returns {Object} The data for the BQ event
 */
export const createImpPromoteEventParams = ({ userId, promotionStatus }) => ({
    type: BQ_VIEWED_PROMOTE_YOUR_GIG,
    feature: {
        name: 'promoted_gigs',
    },
    group: 'user_impressions',
    action: {
        type: 'impression',
    },
    user: {
        id: userId,
    },
    page: {
        component: {
            name: promotionStatus,
        },
    },
});

/**
 * Creates params for the pop_up_subscription_gig_repeat_buyer_is_shown BQ event
 * @returns {object} parameters for bq event
 */
export const createBqReturningBuyerSubModalShow = () => ({
    type: BQ_RETURNING_BUYER_SUB_MODAL_SHOW,
    group: 'user_impression',
    entity: 'client',
});

/**
 * Creates params for the clicked_on_subscription_gig_repeat_buyer_pop_up BQ event
 * @param {object} data event data
 * @param {string} data.ctaType the CTA type that was clicked
 * @returns {object} parameters for bq event
 */
export const createBqReturningBuyerSubModalClickCta = ({ ctaType }) => ({
    type: BQ_RETURNING_BUYER_SUB_MODAL_CLICK_CTA,
    page: {
        element: {
            type: ctaType,
        },
    },
    ...clickActionData,
});

export const createBqRequestToOrderEducationTooltipIsShow = () => ({
    type: BQ_REQUEST_TO_ORDER_ED_TOOLTIP_IS_SHOWN,
    group: 'user_impressions',
    sub_group: 'seller_plus_events',
    action: {
        type: 'impression',
    },
    page: {
        element: {
            type: 'education_tooltip',
            name: 'request_to_order',
        },
    },
});

export const createBqRequestToOrderBtnIsShown = () => ({
    type: BQ_REQUEST_TO_ORDER_BUTTON_IS_SHOWN,
    group: 'user_impressions',
    action: {
        type: 'impression',
    },
    sub_group: 'seller_plus_events',
    page: {
        element: {
            text: 'Request to order',
            type: 'button',
            name: 'request_to_order',
        },
    },
});

export const createBqRequestToOrderBtnClick = () => ({
    type: BQ_REQUEST_TO_ORDER_BUTTON_CLICKED,
    group: 'user_actions',
    sub_group: 'seller_plus_events',
    action: {
        type: 'click',
    },
    page: {
        element: {
            text: 'Request to order',
            type: 'button',
            name: 'request_to_order',
        },
    },
});

/**
 * BI event that will be sent whenever the checkout drawer is presented
 * The event is used for updating braze on buyer abandoned checkout
 * @param {object} data The event's data
 * @returns {object} parameters for the event
 */
export const createCheckoutDrawerViewed = ({ gig, seller, buyer }) => ({
    type: BQ_CHECKOUT_DRAWER_VIEWED,
    group: 'technical_events',
    entity: 'business',
    buyer: {
        username: buyer.username,
    },
    gig: {
        title: gig.title,
    },
    seller: {
        id: seller.id,
        username: seller.username,
    },
});

/**
 * BI event that will be sent whenever the gig is eligible for Checkout Drawer Test
 * @returns {object} parameters for the event
 */
export const createGigEligibleForCheckoutDrawerExperiment = () => ({
    type: BQ_CHECKOUT_GIG_ELIGIBLE,
    group: 'technical_events',
    entity: 'business',
});

/**
 * BI event that will be sent when the Extras are inside a collapsible and the user clicks the Show More Extras button
 * @param {object} page.element.type - state of Collapsible - 'open' / 'closed'.
 * @returns {object} parameters for the event
 */
export const createCheckoutDrawerClickedShowMoreExtras = ({ page }) => ({
    type: BQ_CLICKED_SHOW_MORE_EXTRAS,
    group: 'technical_events',
    entity: 'business',
    page,
});

/**
 * BI event that will be sent when the user click the Continue CTA that opens the Checkout Drawer
 * @returns {object} parameters for the event
 */
export const createClickedOnContinueToCheckout = () => ({
    type: BQ_CLICKED_ON_CONTINUE_TO_CHECKOUT,
    group: 'user_actions',
    entity: 'business',
});

/**
 * BI event that is sent when clicking on gig reviews section/tab
 * @param {object} data event data
 * @param {number} data.sellerId seller user id
 * @param {number} data.userId current user id
 * @param {string} data.pageElementName page element name where the allocation occurred
 * @returns {object} parameters for the event
 */
export const createBqClickedOnGigReviewsSection = ({ sellerId, userId, pageElementName }) => ({
    ...clickActionData,
    type: BQ_CLICKED_ON_GIG_REVIEWS_SECTION,
    group: 'ratings',
    event_source: 'seller_reviews_section',
    seller: { id: sellerId },
    buyer: { id: userId },
    page: {
        element: {
            name: pageElementName,
            type: 'tab',
            text: 'For this Gig',
        },
        component: {
            name: 'reviews',
        },
    },
});

/**
 * BI event that is sent when clicking on seller reviews section/tab
 * @param {object} data event data
 * @param {number} data.sellerId seller user id
 * @param {number} data.userId current user id
 * @param {string} data.pageElementName page element name where the allocation occurred
 * @returns {object} parameters for the event
 */
export const createBqClickedOnSellerReviewsSection = ({ sellerId, userId, pageElementName }) => ({
    ...clickActionData,
    type: BQ_CLICKED_ON_SELLER_REVIEWS_SECTION,
    group: 'ratings',
    event_source: 'gig_reviews_section',
    seller: { id: sellerId },
    buyer: { id: userId },
    page: {
        element: {
            name: pageElementName,
            type: 'tab',
            text: 'For this seller',
        },
        component: {
            name: 'reviews',
        },
    },
});

const buildLocalCatalogBannerShownParams = ({ sellerId, forcedLocale, title, status, description }) => ({
    group: 'user_impressions',
    entity: 'client',
    seller: { id: sellerId },
    gig: {
        translation_layer: {
            language_code: forcedLocale,
            status,
            title,
            description,
        },
    },
    domain: {
        name: 'localization',
    },
    action: {
        type: 'impression',
    },
});
export const createBqLocalCatalogMTBannerShown = ({ sellerId, forcedLocale, title, description, status }) => ({
    type: BQ_LOCAL_CATALOG_MACHINE_TRANSLATION_PREVIEW_VIEWED,
    ...buildLocalCatalogBannerShownParams({
        sellerId,
        forcedLocale,
        title,
        description,
        status,
    }),
});

export const createBqLocalCatalogHTBannerShown = ({ sellerId, forcedLocale, title, description, status }) => ({
    type: BQ_LOCAL_CATALOG_HUMAN_TRANSLATION_PREVIEW_VIEWED,
    ...buildLocalCatalogBannerShownParams({
        sellerId,
        forcedLocale,
        title,
        description,
        status,
    }),
});

const buildLocalCatalogTranslationLayerViewedParams = ({ sellerId, gig, group, actionType }) => ({
    group,
    entity: 'client',
    seller: { id: sellerId },
    domain: {
        name: 'localization',
    },
    action: {
        type: actionType,
    },
    gig,
});

export const createBqLocalCatalogGigTranslationLayerViewed = ({ sellerId, gig }) => ({
    type: BQ_LOCAL_CATALOG_GIG_TRANSLATION_LAYER_VIEWED,
    ...buildLocalCatalogTranslationLayerViewedParams({
        sellerId,
        gig,
        group: 'user_view',
        actionType: 'user_view',
    }),
});

export const createBqLocalCatalogGigInOriginalLanguageClicked = ({ sellerId, gig }) => ({
    type: BQ_LOCAL_CATALOG_GIG_IN_ORIGINAL_LANGUAGE_CLICKED,
    ...buildLocalCatalogTranslationLayerViewedParams({
        sellerId,
        gig,
        group: 'user_action',
        actionType: 'click',
    }),
});

export const createBqLocalCatalogGigInLocalLanguageClicked = ({ sellerId, gig }) => ({
    type: BQ_LOCAL_CATALOG_GIG_IN_LOCAL_LANGUAGE_CLICKED,
    ...buildLocalCatalogTranslationLayerViewedParams({
        sellerId,
        gig,
        group: 'user_action',
        actionType: 'click',
    }),
});
