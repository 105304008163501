import React, { useState, useRef } from 'react';
import { object } from 'prop-types';
import SampleInput from './SampleInput';
import Player from './Player';

import './style.scss';

const SamplingSection = ({ voiceOverAiAudition }, { biEvents, pathfinderWrapper }) => {
    const [enabled, setEnabled] = useState(false);
    const [inputSentence, setInputSentence] = useState('');
    const [error, setError] = useState('');
    const player = useRef();

    const onSampleInputUpdate = (sampleSentence) => {
        const shouldEnable = sampleSentence.length > 0;

        setInputSentence(sampleSentence);
        setEnabled(shouldEnable);
    };

    const onSampleInputSubmit = () => player.current.play();
    const updateError = (error) => setError(error);
    const clearError = () => setError('');

    return (
        <div className="sampling-section">
            <Player
                voiceOverAiAudition={voiceOverAiAudition}
                enabled={enabled}
                pendingSentence={inputSentence}
                ref={player}
                setError={updateError}
                clearError={clearError}
                biEvents={biEvents}
                pathfinderWrapper={pathfinderWrapper}
            />
            <SampleInput
                inputSentence={inputSentence}
                onSampleInputUpdate={onSampleInputUpdate}
                onSampleInputSubmit={onSampleInputSubmit}
                error={error}
                setError={updateError}
                clearError={clearError}
            />
        </div>
    );
};

SamplingSection.propTypes = {
    voiceOverAiAudition: object,
};

SamplingSection.contextTypes = {
    pathfinderWrapper: object,
    biEvents: object,
};

export default SamplingSection;
