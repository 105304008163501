import _ from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import {
    buildCategoryNameKey,
    buildNestedSubCategoryNameKey,
    buildSubCategoryNameKey,
} from '../../../utils/translation/categories';
import { getCategoryUrl, getNestedSubCategoryUrl, getSubCategoryUrl } from '../../../utils/routes';
import { sanitizeSeo } from '../utils';

import { GENERAL_SCHEMA, TYPE_BREADCRUMBS, TYPE_LIST_ITEM } from '../constants';

/**
 * Format sanitized JSON with breadcrumbs data for SEO purposes
 * @param {object} general the context's general.
 * @param {string} gigTitle translated gig title
 * @param {object} pathfinderWrapper the context's pathfinder wrapper.
 * @returns {JSON} formatted and sanitized breadcrumbs-data in JSON format
 */
export const formatBreadcrumbs = ({ general, gigTitle, pathfinderWrapper }) => {
    const {
        categoryId,
        subCategoryId,
        nestedSubCategoryId,
        categorySlug,
        subCategorySlug,
        nestedSubCategorySlug,
        isPro,
    } = general;
    const { pathParameters, locale } = getContext();
    const { username, slug } = pathParameters;

    const showCategory = categoryId && categorySlug;
    const showSubCategory = showCategory && subCategoryId && subCategorySlug;
    const showNestedSubCategory = showSubCategory && nestedSubCategoryId && nestedSubCategorySlug;

    const breadcrumbsItems = [];

    if (showCategory) {
        breadcrumbsItems.push({
            name: i18n.t(buildCategoryNameKey(categoryId), { $scope: locale }),
            url: getCategoryUrl({ categorySlug, isPro, pathfinderWrapper, absolute: true }),
        });
    }

    if (showSubCategory) {
        breadcrumbsItems.push({
            name: i18n.t(buildSubCategoryNameKey(subCategoryId), { $scope: locale }),
            url: getSubCategoryUrl({ categorySlug, subCategorySlug, isPro, pathfinderWrapper, absolute: true }),
        });
    }

    if (showNestedSubCategory) {
        breadcrumbsItems.push({
            name: i18n.t(buildNestedSubCategoryNameKey(subCategoryId, nestedSubCategoryId), { $scope: locale }),
            url: getNestedSubCategoryUrl({
                categorySlug,
                subCategorySlug,
                nestedSubCategorySlug,
                pathfinderWrapper,
                absolute: true,
            }),
        });
    }

    breadcrumbsItems.push({
        name: gigTitle,
        url: pathfinderWrapper.pathfinder('short_v4_user_gig', { username, slug }, { absolute: true }),
    });

    return sanitizeSeo({
        '@context': GENERAL_SCHEMA,
        '@type': TYPE_BREADCRUMBS,
        itemListElement: _.map(breadcrumbsItems, formatBreadcrumbItem),
    });
};

const formatBreadcrumbItem = (item, index) => ({
    '@type': TYPE_LIST_ITEM,
    position: index + 1,
    name: item.name,
    item: item.url,
});
