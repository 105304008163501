import React from 'react';
import { isEmpty } from 'lodash';
import { string } from 'prop-types';

import classes from './styles.module.scss';

const SubscriptionPackageDescription = ({ title, description }) => {
    if (isEmpty(title) && isEmpty(description)) {
        return null;
    }

    return (
        <p className={classes.packageDescription}>
            <b>{title}</b> {description}
        </p>
    );
};

SubscriptionPackageDescription.propTypes = {
    title: string,
    description: string,
};

export default SubscriptionPackageDescription;
