/**
 * returns `undefined` if not 'true' or 'false'.
 * @param {string | undefined } value
 * @return {boolean | undefined}
 */
export const formatBoolean = (value: string | undefined) => {
    const isBooleanString = value === 'true' || value === 'false';

    if (!isBooleanString) {
        return undefined;
    }

    return value === 'true';
};
