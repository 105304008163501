import React from 'react';
import { object } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';

import { stringifyToLanguageCodes } from '../../../../utils/languages';
import LanguagesTooltip from '../LanguagesTooltip';

const Languages = (props, { sellerLanguageParams }) => {
    const sellerLanguages = sellerLanguageParams?.sellerLanguages;
    const languagesToShow = stringifyToLanguageCodes(sellerLanguages);

    return (
        <div>
            <p>
                <I18n k={'gig_page_perseus.seller_card.seller_languages.languages'} />
            </p>
            <LanguagesTooltip languages={sellerLanguages}>
                <strong data-testid="languages-to-show">{languagesToShow}</strong>
            </LanguagesTooltip>
        </div>
    );
};

Languages.contextTypes = {
    sellerLanguageParams: object,
};
export default Languages;
