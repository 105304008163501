export const buildTagUrl = (pathfinderWrapper, tagData) => {
    switch (tagData.formattedClassification.type) {
        case 'search':
            return pathfinderWrapper.pathfinder('search_gigs', null, {
                query: { query: tagData.formattedClassification.key },
            });
        case 'differentTag':
            return pathfinderWrapper.pathfinder('tag_page', { id: tagData.formattedClassification.key });
        case 'tagPage':
            return pathfinderWrapper.pathfinder('tag_page', { id: tagData.slug });
        case 'category':
            return pathfinderWrapper.pathfinder('short_category', { id: tagData.formattedClassification.key });
        case 'subCategory':
            const { categorySlug, subCategorySlug } = tagData.formattedClassification.key;
            return pathfinderWrapper.pathfinder('short_category_sub_category', {
                id: subCategorySlug,
                category_id: categorySlug,
            });
        case 'skill':
            return pathfinderWrapper.pathfinder('skill', { skill: tagData.formattedClassification.key });
        case 'customUrl':
        default:
            return `/${tagData.formattedClassification.key}`;
    }
};
