import React, { useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { arrayOf, number, object } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { Icon, Tooltip } from '@fiverr-private/fit';
import { s_info_circle } from '@fiverr-private/icons';
import { getCurrentContent } from '../../utils/ugc/getCurrentContent';
import { getPackageTypeById } from '../../utils/packagesUtils';
import { MP_BUYER_EXPANDED_WORKFLOW_STEP_LIST } from '../../utils/mixPanelEvents';
import SafePureComponent from '../shared/SafePureComponent';
import { workflowStepShape } from './propTypes';
import WorkflowItem from './WorkflowItem';
import sassVars from './style.scss';

const { seeMoreThresholdItemsCount } = sassVars;

const Workflow = ({ workflowSteps, selectedPackageId, packageListCount }, { biEvents, general }) => {
    const { sellerId } = general;

    const [seeMoreClicked, setSeeMoreClicked] = useState(false);
    let numericIndex = 1;

    if (workflowSteps.length === 0) {
        return null;
    }

    const { userId, locale } = getContext();
    const packageType = i18n.t(getPackageTypeById(selectedPackageId), { $scope: locale });

    const onSeeMoreClick = () => {
        setSeeMoreClicked(true);

        if (userId !== sellerId) {
            biEvents.sendMixPanelEvent({
                eventName: MP_BUYER_EXPANDED_WORKFLOW_STEP_LIST,
            });
        }
    };

    const stepsElements = workflowSteps
        .map(({ uid, packages, liveSession, buyerReview, title, description }) => {
            // If step is not relevant for selected package then ignore it.
            if (!packages.includes(selectedPackageId)) {
                return null;
            }

            return (
                <WorkflowItem
                    key={uid}
                    title={title}
                    description={description}
                    liveSession={liveSession}
                    buyerReview={buyerReview}
                    index={numericIndex++}
                />
            );
        })
        .filter(Boolean);

    const showSeeMore = stepsElements.length > seeMoreThresholdItemsCount && !seeMoreClicked;

    // If stepsElements is empty then all workflow steps are not relevant to selected package
    // and then we want to hide the whole workflow section.
    if (stepsElements.length === 0) {
        return null;
    }

    return (
        <section className="gig-workflow">
            <header className="m-b-40">
                <h2 className="section-title">
                    <I18n k="gig_page_perseus.workflow.header.title" />
                </h2>
                <p className="subtitle">
                    <span className="m-r-8">
                        <I18n
                            k="gig_page_perseus.workflow.header.package_subtitle"
                            params={{ packageType, count: packageListCount }}
                        />
                    </span>
                    <Tooltip
                        className="workflow-tooltip"
                        boxContentClassName="workflow-tooltip-content"
                        content={<I18n k="gig_page_perseus.workflow.header.package_tooltip" params={{ packageType }} />}
                    >
                        <Icon>{s_info_circle}</Icon>
                    </Tooltip>
                </p>
            </header>
            <ul className={classNames('m-l-16', { 'show-see-more': showSeeMore })}>{stepsElements}</ul>
            {showSeeMore && (
                <button className="show-more-btn" onClick={onSeeMoreClick}>
                    <I18n k="gig_page_perseus.workflow.see_more" />
                </button>
            )}
        </section>
    );
};

Workflow.contextTypes = {
    general: object,
    biEvents: object,
};

Workflow.propTypes = {
    workflowSteps: arrayOf(workflowStepShape),
    packageListCount: number,
    selectedPackageId: number,
};

const mapStateToProps = ({ ugc, packages }, { workflow }) => {
    const { workflow: currentWorkflowUGC } = getCurrentContent({ ugc });
    const {
        selectedPackageId,
        packageList: { length: packageListCount },
    } = packages;

    let workflowSteps;

    if ([workflow, currentWorkflowUGC].some(isEmpty)) {
        workflowSteps = [];
    } else {
        workflowSteps = currentWorkflowUGC.steps.map(({ title, description }, index) => {
            const { uid, packages, liveSession, buyerReview } = workflow.steps[index];

            return {
                uid,
                title,
                description,
                packages,
                liveSession,
                buyerReview,
            };
        });
    }

    return {
        packageListCount,
        selectedPackageId,
        workflowSteps,
    };
};

export { Workflow };

const ConnectedWorkflow = connect(mapStateToProps)(Workflow);

export default SafePureComponent(ConnectedWorkflow);
