import { merge } from 'lodash';
import { Ugc, UgcFields } from '../../../types/ugc';

interface GetCurrentContentParams {
    ugc: Ugc;
}

export const getCurrentContent = ({ ugc }: GetCurrentContentParams): UgcFields => {
    const { showOriginal, ugcMap } = ugc;

    return showOriginal ? ugcMap.original : merge(ugcMap.translated, ugcMap.translatedBySeller);
};
