/**
 * Possible errors for Voice Over AI Feature
 * @type {Object<string>}
 */
const ERRORS = {
    ILLEGAL_CHAR: 'illegal_char',
    BAD_CONTENT: 'bad_content',
    MAX_SENTENCE_LENGTH: 'max_sentence_length',
    NON_SAMPLED_SELLER: 'non_sampled_seller',
    FETCH_PHOENIX_ERROR: 'fetch_phoenix_error',
    TOKEN_3RD_PARTY_API_CALL: 'token_3rd_party_api_call',
    GENERAL_ERROR: 'general_error',
};

/**
 * The max sentence length for a sampled sentence
 * @type {number}
 */
const MAX_SENTENCE_LENGTH = 100;

module.exports = {
    ERRORS,
    MAX_SENTENCE_LENGTH,
};
