import React, { useState } from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { string, bool, number, object } from 'prop-types';
import { Icon } from '@fiverr-private/fit';
import { getContext } from '@fiverr-private/fiverr_context';
import { s_flag_straight } from '@fiverr-private/icons';
import { MP_CLICKED_ON_MILESTONES_CHEVRON } from '../../../utils/mixPanelEvents';
import { workProcessItemPriceType, workProcessStepsType } from '../propTypes';
import { FLAG_ICON_SIZE, FLAG_ICON_SIZE_MOBILE } from '../constants';
import MilestoneExpandedSteps from './MilestoneExpandedSteps';
import MilestoneCollapsedSteps from './MilestoneCollapsedSteps';
import MilestoneHeader from './MilestoneHeader';

import './index.scss';

const WorkProcessItem = (
    {
        milestoneNumber,
        title,
        description,
        price: priceInUsd,
        duration,
        revisions,
        steps = [],
        selectedPackageId,
        defaultExpandedSteps = false,
    },
    { biEvents }
) => {
    const onStepsToggle = () => {
        setExpandedSteps((prevState) => !prevState);
        biEvents.sendMixPanelEvent({
            eventName: MP_CLICKED_ON_MILESTONES_CHEVRON,
        });
    };

    const { isMobile } = getContext();
    const [expandedSteps, setExpandedSteps] = useState(defaultExpandedSteps);
    const filteredSteps = steps.filter(({ packages = [] }) => packages.includes(selectedPackageId));
    const StepsElement = expandedSteps ? MilestoneExpandedSteps : MilestoneCollapsedSteps;
    const wrapperClassName = classNames('work-process-milestone', {
        'first-milestone': milestoneNumber === 1 && priceInUsd,
        expanded: expandedSteps,
    });

    const hasSteps = !isEmpty(filteredSteps);

    const milestoneIconSize = isMobile ? FLAG_ICON_SIZE_MOBILE : FLAG_ICON_SIZE;
    const isTextIcon = !priceInUsd;
    const iconToShow = isTextIcon ? milestoneNumber : s_flag_straight;
    const iconClassNames = classNames('flag-icon', { 'text-icon': isTextIcon });
    const showIcon = !isTextIcon || !isMobile;

    return (
        <li className={wrapperClassName}>
            {showIcon && (
                <div className="milestone-icon-circle">
                    <Icon className={iconClassNames} size={milestoneIconSize}>
                        {iconToShow}
                    </Icon>
                </div>
            )}
            <section className="flex flex-col">
                <MilestoneHeader
                    title={title}
                    duration={duration}
                    revisions={revisions}
                    hasSteps={hasSteps}
                    expandedSteps={expandedSteps}
                    milestoneNumber={milestoneNumber}
                    priceInUsd={priceInUsd}
                    onStepsToggle={onStepsToggle}
                />
                <div className="milestone-item-body">
                    <p className="milestone-description">{description}</p>
                    {hasSteps && <StepsElement steps={filteredSteps} />}
                </div>
            </section>
        </li>
    );
};

WorkProcessItem.propTypes = {
    milestoneNumber: number.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    price: workProcessItemPriceType,
    duration: number.isRequired,
    revisions: number.isRequired,
    steps: workProcessStepsType,
    selectedPackageId: number.isRequired,
    defaultExpandedSteps: bool,
};

WorkProcessItem.contextTypes = {
    biEvents: object,
};

export default WorkProcessItem;
