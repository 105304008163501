import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import SafePureComponent from '../shared/SafePureComponent';
import StudioBadge from './StudioBadge';
import './style.scss';

const StudioBox = (props, context) => {
    const { pathfinderWrapper } = context;
    const STUDIO_HOMEPAGE_URL = pathfinderWrapper.pathfinder('studios', null, { absolute: true });
    const i18nStudio = 'gig_page_perseus.studio';

    return (
        <div className="studio-box">
            <StudioBadge />
            <p className="text-body-2">
                <I18n k={`${i18nStudio}.title`} />
            </p>
            {/* eslint-disable react/jsx-no-target-blank */}
            <a href={STUDIO_HOMEPAGE_URL} target="_blank">
                <I18n k={`${i18nStudio}.learn_more`} />
            </a>
        </div>
    );
};

StudioBox.contextTypes = {
    pathfinderWrapper: PropTypes.object,
};

export { StudioBox };
export default SafePureComponent(StudioBox);
