import { ELEMENT_INTERSECTION_RATIO, IMPRESSION_THRESHOLD } from './constants';

/**
 * ImpressionObserver calls callback once over impression
 * param @element HTMLElement to observe the impression on
 * param @callback function to call when observed
 */
class ImpressionObserver {
    constructor({ element, callback }) {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio >= ELEMENT_INTERSECTION_RATIO) {
                    this.release();
                    callback();
                }
            });
        };

        this.observer = new IntersectionObserver(handleIntersection, {
            threshold: IMPRESSION_THRESHOLD,
        });

        this.observer.observe(element);
    }

    release() {
        this.observer.disconnect();
    }
}

export default ImpressionObserver;
