import { isLoggedInUser } from '../../isLoggedInUser';
import { getCurrentPackage, getGigQuantity, getSelectedPackageId } from '../../../reducers/packages';
import { getExtras } from '../../../components/PaymentDrawer/ExtrasList/utils/ExtrasBuilder';
import { GIG_URL_PARAMS } from '../../../reducers/packages/utils/constants';
import { CustomExtra, Feature } from '../../../types/gig/interfaces';
import { AMOUNT_SEPARATOR } from '../constants';
import { extractQueryParams } from '../extract/extractQueryParams';

/**
 * Builds a map of user selected Package attributes from the Redux Store state.
 * In case the Listings Page customized the Package (due to Feature Filtering)
 * and the User Deselected or Changed the Selection (Package / Feature Amounts)
 * We need to add a different Package ID attribute to the URL Params, to designate a needed override.
 *
 * Example Input -
 * Gig is Customized with -  { pckg_id:1, CalculatorValue: 350, Feature: Model3D, extra_fast: true }
 * Example for Returned value:
 * {
 *    pkg:2,
 *    gig_quantity: 3,
 *    extras: "3d_model$$4,foo$$1"
 *    features: "additional_revisions$$3,bar$$9"
 *    fast_delivery: true
 *    calc: 400
 * }
 */
export const buildUrlParamMapFromState = (state) => {
    if (isLoggedInUser()) {
        return {};
    }

    const { customizedPackageId } = extractQueryParams();
    const selectedPackageId: number = getSelectedPackageId(state);
    const currentPackage = getCurrentPackage(state);
    const { calculators = [] } = currentPackage;
    const [calculator] = calculators;
    const calculatorCurrentValue = calculator?.currentValue;
    const calculatorDefaultValue = calculator?.value;
    const hasCalculatorValueChanged = calculatorCurrentValue !== calculatorDefaultValue;

    const { notIncludedFeatures, customExtras, extraFast = {} } = getExtras(currentPackage);
    const gigQuantity: number = getGigQuantity(state);
    const hasExtraFast: boolean = extraFast?.currentValue === 1 && !extraFast?.included;

    const { extrasUrlParam, featuresUrlParam } = buildExtrasUrlParam(notIncludedFeatures, customExtras);

    const hasExtrasSelected = extrasUrlParam || featuresUrlParam || hasExtraFast || hasCalculatorValueChanged;
    const hasPackageCustomizedFromListingsPage = customizedPackageId && customizedPackageId !== selectedPackageId;
    const hasPackageChanged = hasPackageCustomizedFromListingsPage || selectedPackageId > 1;

    const needToOverrideGigDefaults = hasExtrasSelected || hasPackageChanged || gigQuantity > 1;

    return {
        [GIG_URL_PARAMS.CUSTOM_EXTRAS]: extrasUrlParam,
        [GIG_URL_PARAMS.NOT_INCLUDED_FEATURES]: featuresUrlParam,
        [GIG_URL_PARAMS.EXTRA_FAST]: hasExtraFast,
        [GIG_URL_PARAMS.GIG_QUANTITY]: gigQuantity > 1 ? gigQuantity : undefined,
        [GIG_URL_PARAMS.CALCULATOR_VALUE]: calculatorCurrentValue,
        ...(needToOverrideGigDefaults && { [GIG_URL_PARAMS.SELECTED_PACKAGE_ID]: selectedPackageId }),
    };
};

/**
 *
 * @param {Feature[]} notIncludedFeatures
 * @param {CustomExtra[]} customExtras
 * @return { extrasUrlParam: string, featuresUrlParam: string }
 */
export const buildExtrasUrlParam = (
    notIncludedFeatures: Feature[] | [],
    customExtras: CustomExtra[]
): { extrasUrlParam: string; featuresUrlParam: string } => {
    const featuresUrlParam: string = notIncludedFeatures
        .map((feature) => buildUrlParamWithAmount(feature?.name, feature?.amount))
        .join(',');

    const extrasUrlParam: string = customExtras
        .map((extra) => buildUrlParamWithAmount(extra?.label, extra?.amount))
        .join(',');

    return { extrasUrlParam, featuresUrlParam };
};
const buildUrlParamWithAmount = (label: string, amount = 0): string => {
    const hasAmount = amount > 0;

    return hasAmount ? `${label}${AMOUNT_SEPARATOR}${amount}` : '';
};
