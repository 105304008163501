import { Packages } from '../../../types/gig/interfaces';
import { SHUTTERSTOCK_METRIC_REPORT_KEYS } from '../../shared/PackageContent/Feature/constants';

export const buildShutterStockExtrasEventData = (packages: Packages) => {
    const { packageList } = packages;
    return packageList.reduce((acc, curr) => {
        const { features = [] } = curr;
        features.forEach(({ name }) => {
            const key = SHUTTERSTOCK_METRIC_REPORT_KEYS[name];
            if (key) {
                acc[key] = true;
            }
        });

        return acc;
    }, {});
};
