import React from 'react';
import { string, bool, number } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Icon } from '@fiverr-private/fit';
import { b_zoom, s_box } from '@fiverr-private/icons';
import sassVars from './style.scss';

const WorkflowItem = ({ title, description, liveSession, buyerReview, index }) => {
    const showFooter = liveSession || buyerReview;

    return (
        <li className="workflow-step flex p-b-36 p-l-32">
            <div className="numeric-circle">{index}</div>
            <section>
                <strong className="flex m-b-8 step-title">{title}</strong>
                <p className="step-desc">{description}</p>
                {showFooter && (
                    <footer className="m-t-16">
                        <b className="step-includes m-r-16">
                            <I18n k="gig_page_perseus.workflow.step_includes" />
                        </b>
                        <section className="badges">
                            {liveSession && (
                                <div className="flex flex-center badge">
                                    <Icon className="icn-video-meetup">{b_zoom}</Icon>
                                    <I18n k="gig_page_perseus.workflow.video_meetup" />
                                </div>
                            )}
                            {buyerReview && (
                                <div className="flex flex-center badge">
                                    <Icon className="icn-your-feedback" color={sassVars.pink600}>
                                        {s_box}
                                    </Icon>
                                    <I18n k="gig_page_perseus.workflow.your_feedback" />
                                </div>
                            )}
                        </section>
                    </footer>
                )}
            </section>
        </li>
    );
};

WorkflowItem.propTypes = {
    title: string,
    description: string,
    liveSession: bool,
    buyerReview: bool,
    index: number,
};

export default WorkflowItem;
