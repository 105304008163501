import React from 'react';
import { bool, number, object, string } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { translate } from '@fiverr-private/i18n-react';
import { Reviews } from '@fiverr-private/reviews';
import { buildSellerReviewsConfiguration } from '../utils';
import { REVIEWS_COPIES_PREFIX, REVIEWS_TYPES } from '../constants';

const SellerReviewsStandalone = ({ reviews = { seller_reviews: {} }, sellerId, ...props }) => {
    const { pageCtxId, isMobile, userId } = getContext();
    const config = buildSellerReviewsConfiguration(pageCtxId, userId, sellerId, isMobile);
    const initialState = { ...reviews.seller_reviews, ...config, event_source: REVIEWS_TYPES.ONLY_SELLER_REVIEWS };

    return (
        <>
            <div className="tbody-5 text-semi-bold m-b-12">
                {translate(`${REVIEWS_COPIES_PREFIX}.gig_contains_no_reviews`)}
            </div>
            <Reviews initialState={initialState} {...props} />
        </>
    );
};

SellerReviewsStandalone.propTypes = {
    reviews: object.isRequired,
    sellerId: number,
    enableSearchFilter: bool,
    translationUrl: string,
    isPro: bool,
};

export default SellerReviewsStandalone;
