import React from 'react';
import { object } from 'prop-types';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import ContactTrigger from '../../../shared/ContactTrigger';
import { reportContactSellerClick } from '../../../../utils/contactSeller';

import './style.scss';

const bqSourceName = 'about_the_seller';

const ContactMe = () => {
    const { biEvents, general, seller } = useGigPageContext();
    const { isAgency } = seller;
    const ctaTitle = isAgency ?
        `gig_page_perseus.seller_card.contact_us` :
        `gig_page_perseus.seller_card.contact_me`;

    const reportContactMeClick = () =>
        reportContactSellerClick({
            biEvents,
            general,
            mixpanelSourceComponent: 'Gig page seller card',
            bqSourceName,
            metricKey: 'contact_me.click',
        });

    return (
        <ContactTrigger
            onClick={reportContactMeClick}
            source="contact-me"
            className="btn-contact-me"
            bqSourceName={bqSourceName}
            triggerId="Contact Seller"
            triggerCopy={translate(ctaTitle)}
            triggerPlacement="Seller section bottom"
        >
            {(triggerProps) => (
                <Button {...triggerProps}>
                    <I18n k={ctaTitle} />
                </Button>
            )}
        </ContactTrigger>
    );
};

ContactMe.contextTypes = {
    biEvents: object,
    general: object,
};

export default ContactMe;
