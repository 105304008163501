import { get, isEmpty } from 'lodash';
import { NUMBER_STYLES, numberFormat } from '@fiverr-private/localization';
import { extractQueryParams } from '../queryParams/extract/extractQueryParams';
import { DEFAULT_RECURRING_TESTED_OUT_SUB_CATEGORIES, RECURRING_NONE_OPTION } from './constants';

export const getDefaultRecurringOptionId = (recurringOptions = []) => {
    const { durationQueryParam, durationTimeunitQueryParam } = extractQueryParams();

    const subscriptionOption = recurringOptions.find(
        ({ duration, durationTimeUnit }) =>
            duration === durationQueryParam && durationTimeUnit.includes(durationTimeunitQueryParam)
    );

    if (subscriptionOption) {
        return subscriptionOption.id;
    }

    const recurringOption = getDefaultRecurringOption(recurringOptions);
    return get(recurringOption, 'id');
};

export const getDefaultRecurringOption = (recurringOptions = []) => {
    const [recurringOption] = recurringOptions;

    return recurringOption;
};

export const isNoneRecurringOption = (recurringOptionId) => recurringOptionId === RECURRING_NONE_OPTION.id;

/**
 * Returns only the recurring options that have a discount
 * @param {array} recurringOptions the recurring options
 * @returns {array} the recurringOptions that have a discount
 */
const getRecurringOptionsWithDiscount = (recurringOptions) =>
    recurringOptions.filter(({ discountPercentage }) => discountPercentage);

/**
 * Choose the discountPercentage of a recurring option, according to comparator function
 * @param {array} recurringOptions the recurring options
 * @param {function(a,b): boolean} compare return true if should choose `a` over `b`
 * @returns {number} the minimal discount
 */
const getComparatorDiscount = ({ recurringOptions, compare }) => {
    const recurringOptionsWithDiscount = getRecurringOptionsWithDiscount(recurringOptions);

    if (isEmpty(recurringOptionsWithDiscount)) {
        return 0;
    }

    const selectedOption = recurringOptionsWithDiscount.reduce((prev, curr) =>
        compare(prev.discountPercentage, curr.discountPercentage) ? prev : curr
    );

    return selectedOption.discountPercentage;
};

/**
 * A flag that indicates whether the sub category is one of the sub categories which is tested out for default recurring
 * @param {Number} subCategoryId
 * @returns {boolean} is one of the sub categories
 */
export const isTestedOutRecurringSubCategory = (subCategoryId) =>
    DEFAULT_RECURRING_TESTED_OUT_SUB_CATEGORIES.includes(subCategoryId);

/**
 * Return the minimal discount in recurring options
 * @param {array} recurringOptions the recurring options
 * @returns {number} the minimal discount
 */
export const getMinDiscount = (recurringOptions = []) =>
    getComparatorDiscount({
        recurringOptions,
        compare: (prevDiscount, currDiscount) => prevDiscount < currDiscount,
    });

/**
 * Return the maximal discount in recurring options
 * @param {array} recurringOptions the recurring options
 * @returns {number} the maximal discount
 */
export const getMaxDiscount = (recurringOptions = []) =>
    getComparatorDiscount({
        recurringOptions,
        compare: (prevDiscount, currDiscount) => prevDiscount > currDiscount,
    });

/**
 * This method returns discount info of a recurring gig:
 * Min/max discount percentage (formatted, e.g. '50%'), if it has discount, and if it single discount
 * @param {array} recurringOptions the gig's recurring options
 * @returns {{percentageMax: string, percentageMin: string, hasDiscount: boolean, singleDiscount: boolean}}
 */
export const getRecurringDiscountInfo = (recurringOptions = []) => {
    const maxDiscountPercentage = getMaxDiscount(recurringOptions);
    const minDiscountPercentage = getMinDiscount(recurringOptions);
    const singleDiscount = minDiscountPercentage === maxDiscountPercentage;
    const hasDiscount = minDiscountPercentage > 0;
    const percentageMax = numberFormat({
        value: maxDiscountPercentage / 100,
        options: {
            style: NUMBER_STYLES.PERCENT,
        },
    });
    const percentageMin = numberFormat({
        value: minDiscountPercentage / 100,
        options: {
            style: NUMBER_STYLES.PERCENT,
        },
    });

    return {
        singleDiscount,
        hasDiscount,
        percentageMax,
        percentageMin,
    };
};

/**
 * Return whether we should show recommended for text
 * @param {object} general
 * @returns {boolean} should show recommended for
 */
export const shouldShowRecommendedFor = ({ general }) => {
    const { subCategoryId } = general;

    return isTestedOutRecurringSubCategory(subCategoryId);
};
