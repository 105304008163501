import { PAGE_NAME } from '../../../utils/bigQueryEventsData';

export const keyCode = {
    ENTER: 13,
    SPACE_BAR: 32,
    ARROW_LEFT: 37,
    ARROW_UP: 38,
    ARROW_RIGHT: 39,
    ARROW_DOWN: 40,
};

export const NON_EXP_BQ_CLICK_ZOOM_PARAMS = {
    entity: 'client',
    type: 'clicked_zoom',
    group: 'user_actions',
    action: { type: 'click' },
    page: {
        name: PAGE_NAME,
        element: {
            name: 'gallery_item',
        },
        component: {
            name: 'gig_gallery',
        },
    },
};
