import React from 'react';
import { func, object, string } from 'prop-types';
import { noop } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';

const CheckoutCta = ({ className, price, onClick = noop }) => {
    const btnColor = Button.COLORS.BLACK;
    const continueLabel = translate('gig_page_perseus.packages.continue');

    return (
        <Button className={className} onClick={onClick} type="submit" color={btnColor}>
            <span>{continueLabel}</span>
            <span> ({price})</span>
        </Button>
    );
};

CheckoutCta.propTypes = {
    className: string,
    price: string,
    onClick: func,
};

CheckoutCta.contextTypes = {
    general: object,
};

export default CheckoutCta;
