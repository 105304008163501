import { CustomExtra, Feature, PackageData } from '../../types/gig/interfaces';
import { isVoiceOverCalculator } from '../calculator';
import { SCRIPT_PROOFREADING_FEATURE } from './constants';

type CalcFeaturePrice = (packageData: PackageData, feature: Feature | CustomExtra) => number;

export const calcProofreadingFeaturePrice = (packageData: PackageData) => {
    const { features = [] } = packageData;
    const feature = features.find(({ name }) => name === SCRIPT_PROOFREADING_FEATURE);
    const voCalculator = packageData.calculators.find(isVoiceOverCalculator);
    const scriptLength = voCalculator?.currentValue;

    if (!scriptLength || !feature) {
        return 0;
    }

    const proofreadingValuable = feature.value > 0 && feature.price;

    if (proofreadingValuable) {
        const proofreadingQuantity = Math.ceil(scriptLength / feature.value);
        return proofreadingQuantity * feature.price;
    }

    return feature.price;
};

const FEATURE_NAME_TO_PRICE_CALCULATION: Record<string, CalcFeaturePrice> = {
    [SCRIPT_PROOFREADING_FEATURE]: (packageData: PackageData) => calcProofreadingFeaturePrice(packageData),
};

export const calcFeaturePrice = (packageData: PackageData, feature: Feature | CustomExtra): number => {
    const customCalc = 'name' in feature ? FEATURE_NAME_TO_PRICE_CALCULATION[feature.name] : null;

    if (customCalc) {
        return customCalc(packageData, feature);
    }

    return feature.price * (feature.amount ?? 0);
};
