import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import CustomOrder from '../../shared/CustomOrder';
import { CUSTOM_ORDER_BQ_SOURCE_NAME } from './constants';

import classes from './style.module.scss';

const CustomOrderRequestBtn = () => {
    const { general } = useGigPageContext();
    const buttonColor = general.isPro ? Button.COLORS.BLACK : Button.COLORS.GREY;

    return (
        <CustomOrder bqSourceName={CUSTOM_ORDER_BQ_SOURCE_NAME}>
            <Button className={classes.btnCustomOrderRequest} color={buttonColor} fill={Button.FILLS.GHOST}>
                <I18n k="gig_page_perseus.custom_order.get_a_quote" />
            </Button>
        </CustomOrder>
    );
};

export default CustomOrderRequestBtn;
