export const GENERAL_SCHEMA = 'http://schema.org/';
export const TYPE_BREADCRUMBS = 'BreadcrumbList';
export const TYPE_LIST_ITEM = 'ListItem';
export const TYPE_FAQ = 'FAQPage';
export const TYPE_QUESTION = 'Question';
export const TYPE_ANSWER = 'Answer';
export const IN_STOCK = 'InStock';
export const OUT_OF_STOCK = 'OutOfStock';
export const BRAND = 'Brand';
export const FIVERR = 'Fiverr';
