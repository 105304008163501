import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { object, string, number } from 'prop-types';
import CheckoutCta from '../../../../shared/CheckoutCta';
import { shouldShowRequestToOrderBtn } from '../RequestToOrderBtn/utils/shouldShowRequestToOrderBtn';
import { getSelectedPackageId } from '../../../../../reducers/packages';
import { RequestToOrderBtn } from '../RequestToOrderBtn';
import { reportCtaType } from '../../PaymentDrawerFooter/utils/events';
import LimitedEdition from './LimitedEdition';

const CtaBtn = ({ className, price, packageId }, { general, rollouts, gig: { instantOrderSettings } }) => {
    const { isLimitedEdition } = general;
    const showRequestToOrder = shouldShowRequestToOrderBtn({ instantOrderSettings, packageId, rollouts });

    useEffect(() => {
        if (!showRequestToOrder && !isLimitedEdition) {
            reportCtaType('other');
        }
    }, [showRequestToOrder, isLimitedEdition]);

    if (showRequestToOrder) {
        return <RequestToOrderBtn packageId={packageId} />;
    }

    if (isLimitedEdition) {
        return <LimitedEdition />;
    }

    return <CheckoutCta className={className} price={price} />;
};

CtaBtn.propTypes = {
    className: string,
    price: string,
    packageId: number,
};

CtaBtn.contextTypes = {
    general: object,
    rollouts: object,
    gig: object,
};

const mapStateToProps = (state, ownProps = {}) => ({
    packageId: getSelectedPackageId(state),
    ...ownProps,
});

export { CtaBtn };
export default connect(mapStateToProps)(CtaBtn);
