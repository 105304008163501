import { arrayOf, number, shape, string } from 'prop-types';

export const MP_SOURCE_COMPONENT = 'Gig page contact seller button';
export const BQ_SOURCE_NAME = 'contact_seller';
export const ARROW_SIZE = 12;
export const CONTACT_OPTIONS_TYPE = {
    DROPDOWN: 'both_get_a_quote_contact_seller',
    GET_A_QUOTE_ONLY: 'get_a_quote_only',
    CONTACT_SELLER_ONLY: 'contact_seller_only',
    NONE: 'none',
};
export const AI_BRIEF_CONTACT_OPTIONS_TYPE = {
    DROPDOWN: 'both_ai_brief_and_consultation',
};

export const ConsultationDataPropType = shape({
    description: string,
    pricing: arrayOf(
        shape({
            session_minutes: number,
            price: number,
            status: string,
            package_id: number,
        })
    ),
    gig_id: number,
    duration: number,
    account_id: string,
});
