import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { BQ_CLICKED_REVIEWS_RATINGS } from '../utils/bigQueryEvents';
import { REVIEWS } from '../utils/pageSections';

export const useOnReviewsClick = () => {
    const { biEvents, sectionScroller } = useGigPageContext();

    const onReviewsClick = () => {
        biEvents.sendBigQueryEvent({ eventName: BQ_CLICKED_REVIEWS_RATINGS });
        biEvents.sendMixPanelEvent({ eventName: 'Gig Page - Gig Title - Reviews clicked' });
        sectionScroller.scroll({ sectionKey: REVIEWS, forceScroll: true });
    };

    return onReviewsClick;
};
