import React from 'react';
import { Avatar, AvatarImage } from '@fiverr-private/avatar';
import { ProBadge } from './ProBadge';

export interface SellerAvatarProps {
  username: string;
  profilePhoto: string;
  isPro?: boolean;
  shape: 'square' | 'circle';
  size: 'md' | 'lg';
}

export function SellerAvatar({ username, profilePhoto, isPro, shape, size }: SellerAvatarProps) {
  return (
    <Avatar size={size} shape={shape} username={username} showRing={false}>
      <AvatarImage src={profilePhoto} alt={username} />
      {isPro && <ProBadge />}
    </Avatar>
  );
}
