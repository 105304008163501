import { localStorage } from '@fiverr-private/futile';
import Logger from '../../logger';
import { CHOICE_VIEWS_STORAGE_KEY, CHOICE_VIEWS_STORAGE_TTL, CHOICE_GIGS_STORAGE_LIMIT } from './constants';

const getChoiceViews = () => {
    const gigsObject = localStorage.get(CHOICE_VIEWS_STORAGE_KEY);
    if (!gigsObject) {
        return [];
    }

    const { gigs, ttl } = gigsObject;
    if (Date.now() - ttl > CHOICE_VIEWS_STORAGE_TTL) {
        localStorage.remove(CHOICE_VIEWS_STORAGE_KEY);
        return [];
    }

    return gigs;
};

export const addChoiceView = (gigId) => {
    try {
        const gigs = getChoiceViews();
        if (!gigs.find((id) => id === gigId)) {
            gigs.push(gigId);
        }
        if (gigs.length > CHOICE_GIGS_STORAGE_LIMIT) {
            gigs.shift();
        }

        const gigsObject = { gigs, ttl: Date.now() };
        localStorage.set(CHOICE_VIEWS_STORAGE_KEY, gigsObject);
    } catch (error) {
        const logger = Logger.getInstance();
        logger.error(error);
    }
};

export const removeChoiceView = (gigId) => {
    try {
        const gigs = getChoiceViews();
        if (!gigs) {
            return;
        }
        const gigIndex = gigs.indexOf(gigId);
        if (gigIndex === -1) {
            return;
        }

        gigs.splice(gigIndex, 1);
        const gigsObject = { gigs, ttl: Date.now() };
        localStorage.set(CHOICE_VIEWS_STORAGE_KEY, gigsObject);
    } catch (error) {
        const logger = Logger.getInstance();
        logger.error(error);
    }
};
