import { events as gtmEvents } from '@fiverr-private/gtm';
import { BiEvents } from '@fiverr-private/gig_page_context';
import { MP_DRAWER_CLOSE, MP_DRAWER_OPEN } from '../../../utils/mixPanelEvents';
import {
    BQ_CHECKOUT_GIG_ELIGIBLE,
    BQ_CLICKED_SHOW_MORE_EXTRAS,
    BQ_PAYMENT_DRAWER_IS_SHOWN,
} from '../../../utils/bigQueryEvents';
import { CHECKOUT_PLANS } from '../../../utils/checkoutPlan/constants';
import { isSingleOrder } from '../../../utils/checkoutPlan';
import { metric } from '../../../utils/metric';

interface ReportDrawerIsShownEventParams {
    paymentOption: string;
    pricingFactorsCount: number;
    packageBasePrice: number;
    packageId: number;
    gigPackagesType: string;
    currency: any;
    biEvents: BiEvents;
}

/**
 * Report Drawer Is Shown Event
 * */
export const reportDrawerIsShownEvent = ({
    paymentOption,
    pricingFactorsCount,
    packageBasePrice,
    packageId,
    biEvents,
    currency,
    gigPackagesType,
}: ReportDrawerIsShownEventParams) => {
    biEvents.sendMixPanelEvent({
        eventName: MP_DRAWER_OPEN,
        params: {
            paymentOption,
            pricingFactorsCount: paymentOption === CHECKOUT_PLANS.SINGLE_ORDER ? pricingFactorsCount : undefined,
        },
    });

    biEvents.sendBigQueryEvent({
        eventName: BQ_PAYMENT_DRAWER_IS_SHOWN,
        params: {
            paymentOption,
            page: {
                component: {
                    num_of_elements: pricingFactorsCount,
                },
            },
            packageData: {
                id: packageId,
                price: packageBasePrice,
            },
            user: {
                display_currency: currency.name,
            },
            localization: {
                display_currency: currency.name,
            },
            payment: {
                option: {
                    amount: {
                        charged: {
                            currency: {
                                exchange_rate: currency.rate,
                            },
                        },
                    },
                },
            },
            gig: {
                packages_type: gigPackagesType,
            },
        },
    });

    metric.count(`checkout_drawer.shown.${paymentOption}`);

    /**
     * GTM event used by team Meerkats
     */
    gtmEvents.gig.orderOptionsOpened();
};

/**
 * Report Drawer is Closed
 * @param {string} {CHECKOUT_PLANS} paymentOption
 * @param {number} pricingFactorsCount
 * @param {number} selectedPricingFactors
 * @param {number} gigQuantity
 * @param {Object} biEvents - injected from the component `contextTypes`
 **/
export const reportDrawerClosedEvent = ({
    paymentOption,
    pricingFactorsCount,
    selectedPricingFactors,
    gigQuantity,
    biEvents,
}) => {
    biEvents.sendMixPanelEvent({
        eventName: MP_DRAWER_CLOSE,
        params: {
            paymentOption,
            pricingFactorsCount: isSingleOrder(paymentOption) ? pricingFactorsCount : undefined,
            selectedPricingFactors: isSingleOrder(paymentOption) ? selectedPricingFactors : undefined,
            gigQuantity,
        },
    });
};

/**
 * Report Gig Is Eligible for Checkout Drawer
 * @param {Object} biEvents - injected from the component `contextTypes`
 */
export const reportEligibleGigForDrawerExperiment = ({ biEvents }) => {
    biEvents.sendBigQueryEvent({ eventName: BQ_CHECKOUT_GIG_ELIGIBLE });
};

/**
 * Report `Show More Extras` was clicked when the extras are shown in a collapsible
 * @param {Object} biEvents - injected from the component `contextTypes`
 * @param {Object} type - state of the Collapsible - Open / Closed
 */
export const reportClickedShowMoreExtras = ({ biEvents }, { type }) => {
    biEvents.sendBigQueryEvent({
        eventName: BQ_CLICKED_SHOW_MORE_EXTRAS,
        params: {
            page: {
                element: {
                    type,
                },
            },
        },
    });
};
