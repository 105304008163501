import React, { useCallback, useEffect, useState, useRef } from 'react';
import { object, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isEmpty, get } from 'lodash';
import { LazyPersonalizedSectionFetcher, Placeholder } from '@fiverr-private/personalized_content';
import { useIntersectionObserver } from '@fiverr-private/hooks';
import { getContext } from '@fiverr-private/fiverr_context';
import { CURRENCY_CODES } from '@fiverr-private/localization';
import {
    getUgcData,
    putUgcData,
} from '@fiverr-private/personalized_content/src/PersonalizedContent/externalUtils/ugcData';
import { isTranslated } from '../../utils/ugc/isTranslated';
import { buildSourceInfo } from '../../utils/ugc/buildSourceInfo';
import { translateUGC } from '../../utils/ugc/translate';
import { updateCollections } from '../../actions/collections';
import EXPERIMENTS from '../../utils/experiments/experimentsEnum';
import { inClientExperiment , allocateToClientExperiment } from '../../utils/experiments/clientExperiments';
import { ROLLOUTS } from '../../types/rollouts';
import SafePureComponent from '../shared/SafePureComponent';
import { getConfig, SECTION_NAMES } from './constants';

import './style.scss';

const LazyPersonalizationWrapper = (
    { sectionName = SECTION_NAMES.COMPARE, initialPersonalizedContent, showTranslatedUGC, className, ...rest },
    { general, rollouts }
) => {
    const observableRef = useRef(null);
    const entry = useIntersectionObserver(observableRef, {
        rootMargin: '800px',
        threshold: 0,
        once: true,
    });
    const didIntersect = entry?.isIntersecting;
    const isInRollout = rollouts?.[ROLLOUTS.CHAM_GIG_PAGE_LAZY_LOAD_PCP];
    const isInTest = isInRollout && inClientExperiment(EXPERIMENTS.CHAM_GIG_PAGE_LAZY_LOAD_PCP);
    const shouldRenderRecommendations = didIntersect || !isInTest;

    useEffect(() => {
        if (isInRollout) {
            allocateToClientExperiment(EXPERIMENTS.CHAM_GIG_PAGE_LAZY_LOAD_PCP);
        }
    }, [isInRollout]);

    const { gigId } = general;
    const config = getConfig(sectionName, gigId);

    const [personalizedContent, setPersonalizedContent] = useState(initialPersonalizedContent);
    const [translated, setTranslated] = useState(false);

    const containPersonalizedData = useCallback(
        () => !isEmpty(get(personalizedContent, `sections.${sectionName}`)),
        [personalizedContent, sectionName]
    );

    useEffect(() => {
        (async () => {
            /*
             * return if translated is true is needed here or else the component
             * will get into an infinite loop since we're changing the state during useEffect
             * */
            if (translated) {
                return;
            }

            if (containPersonalizedData() && showTranslatedUGC) {
                const { locale } = getContext();
                const ugcData = getUgcData(personalizedContent, locale);
                const sourceInfo = buildSourceInfo({ componentName: `Personalization.${sectionName}`, gigId });
                try {
                    const translatedUgc = await translateUGC({
                        content: ugcData,
                        sourceInfo,
                    });
                    setTranslated(true);
                    const ugcPersonalizedContent = putUgcData(translatedUgc, personalizedContent);
                    setPersonalizedContent(ugcPersonalizedContent);
                    // eslint-disable-next-line no-empty
                } catch (ignore) {}
            }
        })();
    }, [showTranslatedUGC, sectionName, gigId, containPersonalizedData, personalizedContent, translated]);

    const placeholder = (
        <Placeholder.GigsSectionPlaceholder
            slidesToShow={config.sliderBaseNum}
            sliderBreakpoints={config.sliderBreakpoints}
        />
    );

    const { currency } = getContext();

    const features = {
        useForcePriceRounding: currency !== CURRENCY_CODES.USD,
    };

    return (
        <div ref={observableRef} className={className}>
            {shouldRenderRecommendations && (
                <LazyPersonalizedSectionFetcher
                    placeholder={placeholder}
                    showTranslatedUGC={showTranslatedUGC}
                    personalizedContent={personalizedContent}
                    onFetchSucceeded={setPersonalizedContent}
                    useV5Routes={true}
                    features={features}
                    rollouts={rollouts}
                    {...config}
                    {...rest}
                />
            )}
        </div>
    );
};

LazyPersonalizationWrapper.propTypes = {
    sectionName: string,
    initialPersonalizedContent: object,
    showTranslatedUGC: bool,
    className: string,
};

LazyPersonalizationWrapper.contextTypes = {
    general: object,
    rollouts: object,
};

const mapStateToProps = ({ ugc, collections }, ownProps) => ({
    ...ownProps,
    showTranslatedUGC: isTranslated(ugc),
    collections,
});

const mapDispatchToProps = {
    updateCollections,
};

const mergeProps = ({ collections, ...restMapStateToProps }, { updateCollections, ...restMapDispatchToProps }) => ({
    ...restMapStateToProps,
    ...restMapDispatchToProps,
    collectProps: {
        collections,
        onUpdateCollections: updateCollections,
    },
});

const PersonalizationWrapper = compose(
    SafePureComponent,
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(LazyPersonalizationWrapper);

PersonalizationWrapper.SECTION_NAMES = SECTION_NAMES;

export { LazyPersonalizationWrapper };
export default PersonalizationWrapper;
