import React, { useState } from 'react';
import { bool, string, object, func } from 'prop-types';
import { connect } from 'react-redux';
import { MachineTranslationButton, setTranslateOnLoad, raiseErrorToast } from '@fiverr-private/machine_translation';
import { toggleUGC } from '../../actions/ugc';
import { buildTranslationLayerBQProps } from '../../utils/ugc/buildTranslationLayerBQProps';
import { buildSourceInfo } from '../../utils/ugc/buildSourceInfo';
import SafePureComponentWrapper from '../shared/SafePureComponent';
import {
    BQ_LOCAL_CATALOG_GIG_IN_LOCAL_LANGUAGE_CLICKED,
    BQ_LOCAL_CATALOG_GIG_IN_ORIGINAL_LANGUAGE_CLICKED,
} from '../../utils/bigQueryEvents';

const UGCTranslateButton = (
    { showOriginal, ugcMap, componentName, toggleUGC, showDisclaimerText = true },
    { general, biEvents }
) => {
    const [loading, setLoading] = useState(false);

    const { gigId } = general;
    const sourceInfo = buildSourceInfo({ componentName, gigId });

    const { LEAN_WITH_DISCLAIMER } = MachineTranslationButton.STYLES;

    const handleButtonClick = async () => {
        setLoading(true);
        setTranslateOnLoad(showOriginal);
        try {
            await toggleUGC(sourceInfo);
            if (ugcMap.translatedBySeller?.enabled) {
                reportGigSellerTranslationFeatureEnabledBIEvent(showOriginal, ugcMap);
            }
        } catch (ignore) {
            if (showOriginal) {
                raiseErrorToast();
            }
        } finally {
            setLoading(false);
        }
    };

    const reportGigSellerTranslationFeatureEnabledBIEvent = (showOriginal, ugcMap) => {
        biEvents.sendBigQueryEvent({
            eventName: showOriginal
                ? BQ_LOCAL_CATALOG_GIG_IN_LOCAL_LANGUAGE_CLICKED
                : BQ_LOCAL_CATALOG_GIG_IN_ORIGINAL_LANGUAGE_CLICKED,
            params: buildTranslationLayerBQProps(ugcMap.translatedBySeller),
        });
    };

    return (
        <section className="ugc-translation-button-wrapper">
            <MachineTranslationButton
                loading={loading}
                toggled={!showOriginal}
                onClick={handleButtonClick}
                style={LEAN_WITH_DISCLAIMER}
                sourceInfo={sourceInfo}
                showDisclaimerText={showDisclaimerText}
            />
        </section>
    );
};

UGCTranslateButton.propTypes = {
    showOriginal: bool,
    showDisclaimerText: bool,
    ugcMap: object,
    componentName: string,
    toggleUGC: func,
};

UGCTranslateButton.contextTypes = {
    general: object,
    biEvents: object,
};

const mapStateToProps = ({ ugc }) => ({
    showOriginal: ugc.showOriginal,
    ugcMap: ugc.ugcMap,
});
const mapDispatchToProps = {
    toggleUGC,
};

const ConnectedUGCTranslateButton = connect(mapStateToProps, mapDispatchToProps)(UGCTranslateButton);

export { UGCTranslateButton };

export default SafePureComponentWrapper(ConnectedUGCTranslateButton);
