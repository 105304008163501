import { merge } from 'lodash';
import { bigQuery, mixpanel } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import {
    MP_CLICKED_COMPARE_PACKAGES,
    MP_CLICKED_CONTACT_ME,
    MP_CLICKED_NAVBAR,
    MP_CLICKED_PACKAGE_SELECT,
    MP_CLICKED_SELLER_NAME_ANCHOR,
    MP_SHOW_PAGE_NAME,
    MP_VIDEO_THUMBNAIL_SIZE,
    MP_CLICKED_GALLERY_FULL_SCREEN,
    MP_CLICKED_TAB,
    MP_CHANGED_RECURRING_OPTION,
    MP_CHANGED_CHECKOUT_PLAN,
    MP_DRAWER_CHANGE_PAYMENT_PLAN,
    MP_DRAWER_CHANGE_SUBSCRIPTION_LENGTH,
    MP_DRAWER_OPEN,
    MP_DRAWER_CLOSE,
    MP_DRAWER_CLICKED_ADD_EXTRA,
    MP_DRAWER_CHANGED_EXTRA_QUANTITY,
    MP_DRAWER_CHANGED_GIG_QUANTITY,
    MP_DRAWER_CONTINUE_TO_CHECKOUT,
} from '../mixPanelEvents';
import {
    createMpComparePackagesParams,
    createMpPackageSelectParams,
    createMpSellerAnchorClickParams,
    createMpShowPageEventParams,
    createMpTopNavClickParams,
    createMpClickedTab,
    createChangedRecurringOption,
    createChangedCheckoutPlan,
    createMpDrawerChangePaymentPlanParams,
    createMpDrawerChangeSubscriptionLengthParams,
    createMpDrawerOpenParams,
    createMpDrawerCloseParams,
    createMpDrawerDrawerClickedAddExtraParams,
    createMpDrawerDrawerChangedExtraQuantityParams,
    createMpChangedGigQuantityParams,
    createMpContinueToCheckoutParams,
} from '../mixPanelEventsData';
import {
    BQ_CLICKED_CUSTOM_ORDER,
    BQ_CLICKED_PACKAGE_SELECT,
    BQ_CLICKED_REPORT_GIG,
    BQ_GIG_DESCRIPTION_SEE_MORE,
    BQ_CLICKED_CONTACT_SELLER,
    BQ_CLICKED_PACKAGES_TAB,
    BQ_CLICKED_REVIEWS_SNIPPET_MORE,
    BQ_OPEN_COUPON_ARTICLE,
    BQ_SEEN_BOMBA_COUPON,
    BQ_SELLER_BUYER_SPEAK_SAME_LANGUAGE,
    BQ_CLICKED_SELLER_LINK,
    BQ_CLICKED_GALLERY_VIDEO,
    BQ_CLICKED_SELLER_NAME_ANCHOR,
    BQ_CLICKED_REVIEWS_RATINGS,
    BQ_CLICKED_TOPNAV,
    BQ_CLICKED_ON_CONTACT_SELLER_OPTIONS,
    BQ_SIGNUP_CLICK,
    BQ_NOTABLE_CLIENTS_ABOVE_THE_FOLD_SHOW,
    BQ_NOTABLE_CLIENTS_BELOW_THE_FOLD_SHOW,
    BQ_NOTABLE_CLIENT_GIG_ELIGIBLE,
    BQ_CLICKED_ON_CONTINUE_IN_WORK_PROCESS_DRAWER,
    BQ_VOICE_OVER_AI_AUDITION_SUCCESS,
    BQ_VOICE_OVER_AI_AUDITION_CLICKED_ON_PLAY,
    BQ_VOICE_OVER_AI_AUDITION_ERROR_MESSAGE_IS_SHOWN,
    BQ_PAYMENT_DRAWER_IS_SHOWN,
    BQ_CLICK_ON_SUBSCRIBE_OPTION,
    BQ_CLICKED_PROMOTE_YOUR_GIG,
    BQ_VIEWED_PROMOTE_YOUR_GIG,
    BQ_RETURNING_BUYER_SUB_MODAL_SHOW,
    BQ_RETURNING_BUYER_SUB_MODAL_CLICK_CTA,
    BQ_CLICKED_ON_STICKY_CHAT_OPTION,
    BQ_REQUEST_TO_ORDER_ED_TOOLTIP_IS_SHOWN,
    BQ_REQUEST_TO_ORDER_BUTTON_IS_SHOWN,
    BQ_REQUEST_TO_ORDER_BUTTON_CLICKED,
    BQ_CHECKOUT_DRAWER_VIEWED,
    BQ_CHECKOUT_GIG_ELIGIBLE,
    BQ_SELLER_DETAILS_SEE_MORE,
    BQ_FAQ_EXPANSION,
    BQ_REVIEWS_TOGGLE,
    BQ_CLICKED_SHOW_MORE_EXTRAS,
    BQ_CLICKED_ON_CONTINUE_TO_CHECKOUT,
    BQ_CLICKED_ON_GIG_REVIEWS_SECTION,
    BQ_CLICKED_ON_SELLER_REVIEWS_SECTION,
    BQ_LOCAL_CATALOG_MACHINE_TRANSLATION_PREVIEW_VIEWED,
    BQ_LOCAL_CATALOG_HUMAN_TRANSLATION_PREVIEW_VIEWED,
    BQ_LOCAL_CATALOG_GIG_TRANSLATION_LAYER_VIEWED,
    BQ_LOCAL_CATALOG_GIG_IN_ORIGINAL_LANGUAGE_CLICKED,
    BQ_LOCAL_CATALOG_GIG_IN_LOCAL_LANGUAGE_CLICKED,
} from '../bigQueryEvents';
import {
    PAGE_NAME,
    createBqCustomOrderClickParams,
    createBqDescriptionSeeMoreParams,
    createBqPackageSelectParams,
    createBqReportGigClickParams,
    createBqContactSellerClickParams,
    createBqClickedPackagesTab,
    createBqReviewsSnippetMoreClickParams,
    createGigGalleryReportParams,
    createOpenCouponArticleParams,
    createSeenCouponParams,
    createBqSellerAndBuyerSpeakSameLanguageParams,
    createBqClickedSellerLinkParams,
    createBqClickedReviewsRatingsParams,
    createBqClickedSellerNameAnchor,
    createBqClickedGalleryVideoParams,
    createBqTopNavClickParams,
    createBqContactSellerOptionsClickParams,
    createBqSignupClickParams,
    createBqNotableClientsAboveFoldShowParams,
    createBqNotableClientsBelowFoldShowParams,
    createBqNotableClientsListHoverdParams,
    createBqContinueInWorkProcessPaymentClickedParams,
    createVoiceOverAiAuditionSuccessParams,
    createVoiceOverAiAuditionClickedOnPlayParams,
    createVoiceOverAiAuditionErrorMessageIsShownParams,
    createSeenPaymentDrawerParams,
    createClickPromoteEventParams,
    createClickedOnSubscriptionPaymentOptionParams,
    createImpPromoteEventParams,
    createBqReturningBuyerSubModalShow,
    createBqReturningBuyerSubModalClickCta,
    createBqStickyChatClickParams,
    createBqRequestToOrderEducationTooltipIsShow,
    createBqRequestToOrderBtnIsShown,
    createBqRequestToOrderBtnClick,
    createCheckoutDrawerViewed,
    createGigEligibleForCheckoutDrawerExperiment,
    createBqSellerDetailsSeeMoreParams,
    createBqFAQExpansionParams,
    createBqReviewsToggleParams,
    createCheckoutDrawerClickedShowMoreExtras,
    createClickedOnContinueToCheckout,
    createBqClickedOnGigReviewsSection,
    createBqClickedOnSellerReviewsSection,
    createBqLocalCatalogMTBannerShown,
    createBqLocalCatalogHTBannerShown,
    createBqLocalCatalogGigTranslationLayerViewed,
    createBqLocalCatalogGigInOriginalLanguageClicked,
    createBqLocalCatalogGigInLocalLanguageClicked,
} from '../bigQueryEventsData';
import { createImpressionEvent } from '../events/impression/base';
import { SINGLE_PACKAGE, TRIPLE_PACKAGE, GIG_GALLERY } from '../events';
import { MIXPANEL_EVENT_TYPES } from '../../types/mixpanelEventTypes';
import { PLATFORMS } from '../../types/platforms';

class BiEvents {
    constructor({ general, packagesCount, paymentType }) {
        const { userId, userGuid, pageCtxId, pathParameters, isTouch } = getContext();
        this.reportingData = {
            ...general,
            userId,
            userGuid,
            pageCtxId,
            isPromotedGig: !!pathParameters.ad_key,
            packagesCount,
            paymentType,
            platform: isTouch ? PLATFORMS.MOBILE : PLATFORMS.WEB,
        };
    }

    sendRawBigQueryEvent(params = {}) {
        bigQuery.send(params);
    }

    getBigQueryEnrichmentData() {
        return {
            gigId: this.reportingData.gigId,
            sellerId: this.reportingData.sellerId,
            userId: this.reportingData.userId,
            userGuid: this.reportingData.userGuid,
            pageCtxId: this.reportingData.pageCtxId,
            platform: this.reportingData.platform,
            pageName: PAGE_NAME,
        };
    }

    /**
     * @deprecated
     * Please avoid using this one. Use sendRawBigQueryEvent instead. any enrichment can be retrieved from getBigQueryEnrichmentData
     */
    sendBigQueryEvent({ eventName, params = {} }) {
        if (!this.reportingData) {
            return;
        }

        const { packagesCount, pageCtxId, gigId } = this.reportingData;

        /** These attributes are reported by default for all BQ events. */
        const defaultEventData = {
            gig: {
                id: gigId,
                packages_type: packagesCount === 3 ? TRIPLE_PACKAGE : SINGLE_PACKAGE,
            },
            page: {
                name: PAGE_NAME,
                ctx_id: pageCtxId,
            },
        };

        const eventParams = { ...this.reportingData, ...params };
        const specificEventData = this.createBigQueryEventData(eventName, eventParams);
        const eventAttributes = merge(defaultEventData, specificEventData);

        bigQuery.send(eventAttributes);
    }

    createBigQueryEventData(eventName, eventParams) {
        switch (eventName) {
            case BQ_CLICKED_PACKAGE_SELECT:
                return createBqPackageSelectParams(eventParams);
            case BQ_CLICKED_CUSTOM_ORDER:
                return createBqCustomOrderClickParams(eventParams);
            case BQ_CLICKED_REPORT_GIG:
                return createBqReportGigClickParams(eventParams);
            case BQ_GIG_DESCRIPTION_SEE_MORE:
                return createBqDescriptionSeeMoreParams(eventParams);
            case BQ_SELLER_DETAILS_SEE_MORE:
                return createBqSellerDetailsSeeMoreParams(eventParams);
            case BQ_FAQ_EXPANSION:
                return createBqFAQExpansionParams(eventParams);
            case BQ_REVIEWS_TOGGLE:
                return createBqReviewsToggleParams(eventParams);
            case BQ_CLICKED_CONTACT_SELLER:
                return createBqContactSellerClickParams(eventParams);
            case BQ_CLICKED_PACKAGES_TAB:
                return createBqClickedPackagesTab(eventParams);
            case BQ_CLICKED_REVIEWS_SNIPPET_MORE:
                return createBqReviewsSnippetMoreClickParams(eventParams);
            case GIG_GALLERY:
                return createGigGalleryReportParams(eventParams);
            case BQ_OPEN_COUPON_ARTICLE:
                return createOpenCouponArticleParams(eventParams);
            case BQ_SEEN_BOMBA_COUPON:
                return createSeenCouponParams(eventParams);
            case BQ_SELLER_BUYER_SPEAK_SAME_LANGUAGE:
                return createBqSellerAndBuyerSpeakSameLanguageParams(eventParams);
            case BQ_CLICKED_SELLER_LINK:
                return createBqClickedSellerLinkParams(eventParams);
            case BQ_CLICKED_REVIEWS_RATINGS:
                return createBqClickedReviewsRatingsParams(eventParams);
            case BQ_CLICKED_SELLER_NAME_ANCHOR:
                return createBqClickedSellerNameAnchor(eventParams);
            case BQ_CLICKED_GALLERY_VIDEO:
                return createBqClickedGalleryVideoParams(eventParams);
            case BQ_CLICKED_TOPNAV:
                return createBqTopNavClickParams(eventParams);
            case BQ_CLICKED_ON_CONTACT_SELLER_OPTIONS:
                return createBqContactSellerOptionsClickParams(eventParams);
            case BQ_CLICKED_ON_STICKY_CHAT_OPTION:
                return createBqStickyChatClickParams(eventParams);
            case BQ_SIGNUP_CLICK:
                return createBqSignupClickParams(eventParams);
            case BQ_NOTABLE_CLIENTS_ABOVE_THE_FOLD_SHOW:
                return createBqNotableClientsAboveFoldShowParams(eventParams);
            case BQ_NOTABLE_CLIENTS_BELOW_THE_FOLD_SHOW:
                return createBqNotableClientsBelowFoldShowParams(eventParams);
            case BQ_NOTABLE_CLIENT_GIG_ELIGIBLE:
                return createBqNotableClientsListHoverdParams(eventParams);
            case BQ_CLICKED_ON_CONTINUE_IN_WORK_PROCESS_DRAWER:
                return createBqContinueInWorkProcessPaymentClickedParams(eventParams);
            case BQ_VOICE_OVER_AI_AUDITION_SUCCESS:
                return createVoiceOverAiAuditionSuccessParams(eventParams);
            case BQ_VOICE_OVER_AI_AUDITION_CLICKED_ON_PLAY:
                return createVoiceOverAiAuditionClickedOnPlayParams(eventParams);
            case BQ_VOICE_OVER_AI_AUDITION_ERROR_MESSAGE_IS_SHOWN:
                return createVoiceOverAiAuditionErrorMessageIsShownParams(eventParams);
            case BQ_PAYMENT_DRAWER_IS_SHOWN:
                return createSeenPaymentDrawerParams(eventParams);
            case BQ_CLICK_ON_SUBSCRIBE_OPTION:
                return createClickedOnSubscriptionPaymentOptionParams(eventParams);
            case BQ_CLICKED_PROMOTE_YOUR_GIG:
                return createClickPromoteEventParams(eventParams);
            case BQ_VIEWED_PROMOTE_YOUR_GIG:
                return createImpPromoteEventParams(eventParams);
            case BQ_RETURNING_BUYER_SUB_MODAL_SHOW:
                return createBqReturningBuyerSubModalShow(eventParams);
            case BQ_RETURNING_BUYER_SUB_MODAL_CLICK_CTA:
                return createBqReturningBuyerSubModalClickCta(eventParams);
            case BQ_REQUEST_TO_ORDER_ED_TOOLTIP_IS_SHOWN:
                return createBqRequestToOrderEducationTooltipIsShow(eventParams);
            case BQ_REQUEST_TO_ORDER_BUTTON_IS_SHOWN:
                return createBqRequestToOrderBtnIsShown(eventParams);
            case BQ_REQUEST_TO_ORDER_BUTTON_CLICKED:
                return createBqRequestToOrderBtnClick(eventParams);
            case BQ_CHECKOUT_DRAWER_VIEWED:
                return createCheckoutDrawerViewed(eventParams);
            case BQ_CHECKOUT_GIG_ELIGIBLE:
                return createGigEligibleForCheckoutDrawerExperiment(eventParams);
            case BQ_CLICKED_SHOW_MORE_EXTRAS:
                return createCheckoutDrawerClickedShowMoreExtras(eventParams);
            case BQ_CLICKED_ON_CONTINUE_TO_CHECKOUT:
                return createClickedOnContinueToCheckout(eventParams);
            case BQ_CLICKED_ON_GIG_REVIEWS_SECTION:
                return createBqClickedOnGigReviewsSection(eventParams);
            case BQ_CLICKED_ON_SELLER_REVIEWS_SECTION:
                return createBqClickedOnSellerReviewsSection(eventParams);
            case BQ_LOCAL_CATALOG_MACHINE_TRANSLATION_PREVIEW_VIEWED:
                return createBqLocalCatalogMTBannerShown(eventParams);
            case BQ_LOCAL_CATALOG_HUMAN_TRANSLATION_PREVIEW_VIEWED:
                return createBqLocalCatalogHTBannerShown(eventParams);
            case BQ_LOCAL_CATALOG_GIG_TRANSLATION_LAYER_VIEWED:
                return createBqLocalCatalogGigTranslationLayerViewed(eventParams);
            case BQ_LOCAL_CATALOG_GIG_IN_ORIGINAL_LANGUAGE_CLICKED:
                return createBqLocalCatalogGigInOriginalLanguageClicked(eventParams);
            case BQ_LOCAL_CATALOG_GIG_IN_LOCAL_LANGUAGE_CLICKED:
                return createBqLocalCatalogGigInLocalLanguageClicked(eventParams);
            default:
                return {};
        }
    }

    sendBigQueryImpression({ eventName, params = {} }) {
        bigQuery.send(createImpressionEvent(eventName)(params));
    }

    sendMixPanelEvent({ mpEventType = MIXPANEL_EVENT_TYPES.STANDARD, eventName, params = {} }) {
        if (!this.reportingData) {
            return;
        }

        const eventParams = { ...this.reportingData, ...params };
        const eventData = this.createMixPanelEventData(eventName, eventParams);

        const enrichedEventData = {
            ...this.getEnrichmentData(),
            ...eventData,
        };

        if (mpEventType === MIXPANEL_EVENT_TYPES.PAGE_VIEW) {
            mixpanel.trackPageView({
                'Page Name': eventName,
                ...enrichedEventData,
            });
        } else {
            mixpanel.track(eventName, enrichedEventData);
        }
    }

    createMixPanelEventData(eventName, eventParams = {}) {
        switch (eventName) {
            case MP_SHOW_PAGE_NAME:
                return createMpShowPageEventParams(eventParams);
            case MP_CLICKED_NAVBAR:
                return createMpTopNavClickParams(eventParams);
            case MP_CLICKED_PACKAGE_SELECT:
                return createMpPackageSelectParams(eventParams);
            case MP_CLICKED_COMPARE_PACKAGES:
                return createMpComparePackagesParams(eventParams);
            case MP_CLICKED_SELLER_NAME_ANCHOR:
                return createMpSellerAnchorClickParams(eventParams);
            case MP_CLICKED_TAB:
                return createMpClickedTab(eventParams);
            case MP_CHANGED_RECURRING_OPTION:
                return createChangedRecurringOption(eventParams);
            case MP_CHANGED_CHECKOUT_PLAN:
                return createChangedCheckoutPlan(eventParams);
            case MP_DRAWER_CHANGE_PAYMENT_PLAN:
                return createMpDrawerChangePaymentPlanParams(eventParams);
            case MP_DRAWER_CHANGE_SUBSCRIPTION_LENGTH:
                return createMpDrawerChangeSubscriptionLengthParams(eventParams);
            case MP_DRAWER_OPEN:
                return createMpDrawerOpenParams(eventParams);
            case MP_DRAWER_CLOSE:
                return createMpDrawerCloseParams(eventParams);
            case MP_DRAWER_CHANGED_GIG_QUANTITY:
                return createMpChangedGigQuantityParams(eventParams);
            case MP_DRAWER_CLICKED_ADD_EXTRA:
                return createMpDrawerDrawerClickedAddExtraParams(eventParams);
            case MP_DRAWER_CHANGED_EXTRA_QUANTITY:
                return createMpDrawerDrawerChangedExtraQuantityParams(eventParams);
            case MP_DRAWER_CONTINUE_TO_CHECKOUT:
                return createMpContinueToCheckoutParams(eventParams);
            case MP_CLICKED_CONTACT_ME:
            case MP_VIDEO_THUMBNAIL_SIZE:
            case MP_CLICKED_GALLERY_FULL_SCREEN:
            default:
                return eventParams;
        }
    }

    getEnrichmentData() {
        return {
            'Category Id': this.reportingData.categoryId,
            'Sub Category Id': this.reportingData.subCategoryId,
            'Nested Sub Category Id': this.reportingData.nestedSubCategoryId,
            'Is pro': this.reportingData.isPro,
            'Payment Type': this.reportingData.paymentType,
            pageCtxId: this.reportingData.pageCtxId,
            userId: this.reportingData.userId,
        };
    }
}

export default BiEvents;
