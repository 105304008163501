import React from 'react';
import { useImpressionManager } from '@fiverr-private/impressionable';
import GigPage from '../../../apps/gig_page/components/GigPage';
import { ExperientialGigPage } from '../../../apps/gig_page/components/GigPage/Layouts/ExperientialGigPage';

const GigPageClient = (props: Record<string, unknown>) => {
  useImpressionManager();

  return (
    <GigPage {...props}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <ExperientialGigPage />
    </GigPage>
  );
};

export default GigPageClient;
