import { getContext } from '@fiverr-private/fiverr_context';
import { metric } from '../metric';
import BrowserDetector from '../browserDetector';
import { PLATFORMS } from '../../types/platforms';

export const sendGraphiteEvent = (event) => {
    const platform = getPlatform();
    const browser = BrowserDetector.getBrowser();
    const label = `${event}.${platform}.${browser}`;

    metric.count(label);
};

const getPlatform = () => {
    const { isMobile } = getContext();

    return isMobile ? PLATFORMS.MOBILE : PLATFORMS.WEB;
};
