import { createLoyaltyizationImpressionEvent } from './loyalty';
import { createGigDescriptionImpressionEvent } from './gigDescription';
import {
    createNotableClientAboveTheFoldComponentIsShown,
    createNotableClientBelowTheFoldComponentIsShown,
} from './notableClients';
import { createWorkProcessComponentIsShown } from './workProcess';
import { createVoiceOverAiAuditionComponentIsShown } from './voiceOverAiAudition';
import { createPortfolioImpressionEvent } from './portfolio';
import { createMachineTranslatePromotion } from './machineTranslatePromotion';
import { createHighlyResponsiveImpressionEvent } from './highlyResponsive';

export const ImpressionEvents = {
    loyalty_banner: createLoyaltyizationImpressionEvent('returning_buyers_banner'),
    gig_description: createGigDescriptionImpressionEvent('gig_description_is_shown'),
    notable_client_above_the_fold_component_is_shown: createNotableClientAboveTheFoldComponentIsShown(
        'notable_clients_above_fold_is_shown'
    ),
    notable_client_below_the_fold_component_is_shown: createNotableClientBelowTheFoldComponentIsShown(
        'notable_clients_below_fold_is_shown'
    ),
    gig_work_process: createWorkProcessComponentIsShown(),
    ai_audition_component_is_shown: createVoiceOverAiAuditionComponentIsShown('ai_audition_component_is_shown'),
    portfolio_impression: createPortfolioImpressionEvent('seller_portfolio_component_is_shown'),
    localization_machine_translation_promotion_component_is_shown: createMachineTranslatePromotion(
        'localization_machine_translation_promotion_component_is_shown'
    ),
    highly_responsive_impression: createHighlyResponsiveImpressionEvent('highly_responsive_badge_is_shown'),
};
