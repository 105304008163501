import { isRecommendedPackage } from '../packagesUtils';
import { BASIC_PACKAGE_ID } from '../packagesUtils/constants';
import { extractQueryParams } from '../queryParams/extract/extractQueryParams';

const calcDefaultSelectedPackage = (packageList) => {
    const { packageId: selectedPackageIdFromUrl } = extractQueryParams();
    if (selectedPackageIdFromUrl) {
        return selectedPackageIdFromUrl;
    }

    let defaultCheckedId = BASIC_PACKAGE_ID;
    packageList.forEach((packageData) => {
        if (isRecommendedPackage(packageData)) {
            defaultCheckedId = packageData.id;
        }
    });

    return defaultCheckedId;
};

export default calcDefaultSelectedPackage;
