const { SUB_CATEGORIES } = require('../../types/categories');

/**
 * Which SC are forbidden for displaying the returning buyer modal
 * @type {number[]}
 */
const FORBIDDEN_SC_IDS = [SUB_CATEGORIES.SEARCH_ENGINE_OPTIMIZATION, SUB_CATEGORIES.SOCIAL_MARKETING];

module.exports = {
    FORBIDDEN_SC_IDS,
};
