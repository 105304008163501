import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { Title } from './Title';
import { BenefitBullets } from './BenefitBullets/BenefitBullets';
import { Sparkle } from './Sparkle';

export const Content = () => (
  <Stack gap="2" hidden={{ default: true, sm: false }}>
    <Stack direction="column" paddingY="6">
      <Title />
      <BenefitBullets />
    </Stack>
    <Sparkle />
  </Stack>
);
