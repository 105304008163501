import { isLoggedInUser } from '../../../../../../../utils/isLoggedInUser';
import { getCurrentDateUnix } from '../../../../../../../utils/timeUtil';
import { REQUEST_TO_ORDER_AUDIENCE, PACKAGE_ID_TO_TYPE } from '../../constants';
import { ROLLOUTS } from '../../../../../../../types/rollouts';

export const shouldShowRequestToOrderBtn = ({ instantOrderSettings, packageId, rollouts = {} }) => {
    const settings = instantOrderSettings && instantOrderSettings[0];
    const packageType = PACKAGE_ID_TO_TYPE[packageId];
    const inRollout = rollouts[ROLLOUTS.ANACONDAS_REQUEST_TO_ORDER];

    if (!settings || !packageType || !inRollout) {
        return false;
    }

    const { audience, packages, unavailabilityHours, ftbOfSeller } = settings;

    const availableForTheFirstTimeBuyersOnly = audience === REQUEST_TO_ORDER_AUDIENCE.AUDIENCE_FIRST_TIME_BUYERS;

    if (!isLoggedInUser() && availableForTheFirstTimeBuyersOnly) {
        return false;
    }

    const currentDateUnix = getCurrentDateUnix();

    const dateMatches = unavailabilityHours.some(
        (dateInterval) => currentDateUnix > dateInterval.from && currentDateUnix < dateInterval.to
    );

    if (!dateMatches) {
        return false;
    }

    const packageMatches = packages.includes(packageType);

    if (availableForTheFirstTimeBuyersOnly) {
        return ftbOfSeller && packageMatches;
    }

    return packageMatches;
};
