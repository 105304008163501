import React, { useState } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { translate } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { LazyComponent } from '@fiverr-private/ui_utils';
import { ShareIcon } from '@fiverr-private/visuals';
import { Tooltip } from '@fiverr-private/tooltip';
import { useSocialSharingModal } from '../../hooks/useSocialSharingModal';
import { ActionCtaBase } from './ActionCtaBase';

const lazyImport = () => import(/* webpackChunkName: 'LazySharingModal' */ '@fiverr-private/sharing_link');

export const ShareCta = () => {
  const [modalLoaded, setModalLoaded] = useState(false);
  const [isOpeningModal, setIsOpeningModal] = useState(false);
  const { locale } = getContext();
  const { logger } = useGigPageContext();
  const { openModal } = useSocialSharingModal();

  const onClick = () => {
    setIsOpeningModal(true);

    if (modalLoaded) {
      openModal();
    } else {
      setModalLoaded(true);
    }
  };

  const onLoadError = (error) => {
    setModalLoaded(false);
    setIsOpeningModal(false);

    logger.error(error, {
      description: 'failed to load sharing link modal',
    });
  };

  const onLoadSuccess = () => {
    requestAnimationFrame(openModal); // need to send the open modal to next event tick so it will open modal after the load event loop
    setIsOpeningModal(false);
  };

  return (
    <>
      <Tooltip content={translate('gig_page_nav.action_ctas.share_gig.cta')}>
        <ActionCtaBase onClick={onClick} disabled={isOpeningModal} dataTestId="share_gig_test_id">
          <ShareIcon />
        </ActionCtaBase>
      </Tooltip>
      <LazyComponent
        lazyImport={lazyImport}
        shouldImport={modalLoaded}
        afterLoad={onLoadSuccess}
        onError={onLoadError}
        componentProps={{ locale }}
      />
    </>
  );
};
