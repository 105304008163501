import _ from 'lodash';
import { sanitizeSeo } from '../utils';
import { GENERAL_SCHEMA, TYPE_FAQ, TYPE_QUESTION, TYPE_ANSWER } from '../constants';

export const shouldFormatFaq = (faq) => !!faq && !_.isEmpty(faq.questionsAndAnswers);

export const formatFAQ = (faq) => {
    const formattedFaq = {
        '@context': GENERAL_SCHEMA,
        '@type': TYPE_FAQ,
        mainEntity: _.map(faq.questionsAndAnswers, formatQA),
    };

    return sanitizeSeo(formattedFaq);
};

const formatQA = (qa) => ({
    '@type': TYPE_QUESTION,
    name: qa.question,
    acceptedAnswer: {
        '@type': TYPE_ANSWER,
        text: qa.answer,
    },
});
