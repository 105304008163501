import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { func, arrayOf, object, bool } from 'prop-types';
import { Icon, Tooltip } from '@fiverr-private/fit';
import { s_question_circle } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { NUMBER_STYLES, numberFormat } from '@fiverr-private/localization';
import { selectCheckoutPlan, setCheckoutDrawerOpen } from '../../../../../actions/checkoutPlan';
import { getRecurringOptions } from '../../../../../reducers/packages';
import { CHECKOUT_PLANS } from '../../../../../utils/checkoutPlan/constants';
import { getMaxDiscount } from '../../../../../utils/recurring';
import { getSubscriptionTitleKeySuffix } from '../../../../../utils/recurring/translation';
import SubscriptionInfo from './SubscriptionInfo';

import classes from './styles.module.scss';

const SubscriptionDrawerLabel = ({ recurringOptions, selectCheckoutPlan, setCheckoutDrawerOpen }) => {
    const recurringOptionsWithDiscount = recurringOptions.filter(({ discountPercentage }) => discountPercentage);
    const maxDiscountPercentage = getMaxDiscount(recurringOptionsWithDiscount);
    const percentage = numberFormat({
        value: maxDiscountPercentage / 100,
        options: {
            style: NUMBER_STYLES.PERCENT,
        },
    });

    const subscriptionLabelSuffix = getSubscriptionTitleKeySuffix(maxDiscountPercentage > 0);
    const subscriptionLabelKey = `gig_page_perseus.recurring.subscription_label.title.${subscriptionLabelSuffix}`;

    const className = classNames('flex', 'flex-items-center', 'text-normal', 'tbody-5', classes.subscriptionLabel);

    const onClick = () => {
        selectCheckoutPlan({
            selectedCheckoutPlan: CHECKOUT_PLANS.RECURRING_ORDER,
        });

        setCheckoutDrawerOpen(true);
    };

    const templates = {
        link: (text) => (
            <span className={classes.linkText} onClick={onClick}>
                {text}
            </span>
        ),
    };

    return (
        <div className={className}>
            <span>
                <I18n k={subscriptionLabelKey} templates={templates} params={{ percentage }} />
            </span>
            <Tooltip
                className={classes.tooltipWrapper}
                containerClassName={classes.infoTooltip}
                content={<SubscriptionInfo />}
            >
                <Icon size={12}>{s_question_circle}</Icon>
            </Tooltip>
        </div>
    );
};

SubscriptionDrawerLabel.propTypes = {
    recurringOptions: arrayOf(object),
    selectCheckoutPlan: func,
    setCheckoutDrawerOpen: bool,
};

const mapStateToProps = (state) => ({
    recurringOptions: getRecurringOptions(state.packages),
});

const mapDispatchToProps = { selectCheckoutPlan, setCheckoutDrawerOpen };

export { SubscriptionDrawerLabel };
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDrawerLabel);
