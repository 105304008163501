import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { Icon } from '@fiverr-private/fit';
import { s_pause_circle } from '@fiverr-private/fit/icons';
import TopPanel from '../shared/TopPanel';
import SafePureComponent from '../shared/SafePureComponent';

import './style.scss';

const PausedGig = () => (
    <TopPanel customClass="paused-gig">
        <header>
            <h2>
                <Icon size={30} className="paused-gig-icon">
                    {s_pause_circle}
                </Icon>
                <I18n k={'gig_page_perseus.paused_gig.on_hold'} />
            </h2>
        </header>
    </TopPanel>
);

export { PausedGig };
export default SafePureComponent(PausedGig);
