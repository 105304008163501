import { translate } from '@fiverr-private/i18n-react';
import { Attributes } from '../../attributes';
import { getAttributeKeys } from '..';

/**
 *  Get calculator tooltip of a single calculator. In case the label attribute
 *  will not be found under the calculator key, it will fallback to the pricing factor key.
 */
export const getCalculatorTooltip = ({ name, subCategoryId, replacements = {} }) =>
    getBuyerTooltip({
        name,
        subCategoryId,
        replacements,
        options: {
            withCalculator: true,
        },
    });

/**
 *  Get buyer tooltip of a single pricing factor. In case we have a specific configuration
 *  for the gig sub category, we should get that one, otherwise it will fallback to
 *  the basic tooltip.
 *
 *  For example, for the following configuration and the sub category [353]:
 *
 *  "word_count": {
      "buyer_description": "Word count included in package (additional words may be available for purchase separately)",
 *    "sub_category_353": {
        "buyer_description": "Page count included in package (additional pages may be available for purchase separately)"
 *    }
 *  }
 *
 *  We should get "Page count included in package (additional pages may be available for purchase separately)".
 */
export const getBuyerTooltip = ({ name, subCategoryId, replacements = {}, options = {} }) => {
    const attributes = [Attributes.tooltip, Attributes.buyerDescription];
    const attributeKeys = getAttributeKeys({ name, subCategoryId, attributes, options });

    return translate(attributeKeys, {
        params: {
            ...replacements,
            currencySymbol: '',
        },
    });
};
