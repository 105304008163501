import React, { PureComponent } from 'react';
import { bool, func, object } from 'prop-types';
import classNames from 'classnames';
import { throttle } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import Image from '../../components/Image';
import CloudinaryPdf from '../../components/CloudinaryPdf';
import PinchToZoom from '../../components/PinchToZoom';
import GigReview from '../../GigReview';
import SlideHOC from '../slideHOC';
import { metric } from '../../../../../utils/metric';

class PdfSlide extends PureComponent {
    constructor(props) {
        super(props);

        // Bindings
        this.loadPages = this.loadPages.bind(this);
        this.throttledUpdateScrollStatus = throttle(this.updateScrollStatus.bind(this), 250);
        this.handlePageLoaded = this.handlePageLoaded.bind(this);
        this.renderReview = this.renderReview.bind(this);
        this.renderThumbnail = this.renderThumbnail.bind(this);
        this.renderPages = this.renderPages.bind(this);

        // Refs
        this.wrapper = null;

        // Initial state
        this.state = {
            isLoadingMorePages: true,
            isSinglePage: false,
            pageUrls: null,
            isScrollable: false,
            hasBeenScrolled: false,
        };
    }

    componentDidMount() {
        const { flashReview } = this.props;
        const { isActive, expanded, preload } = this.props,
            { pageUrls } = this.state;

        if ((isActive || preload) && expanded && !pageUrls) {
            this.loadPages();
        }

        if (isActive) {
            flashReview();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isActive, expanded, flashReview, hideReview, isShowingReview } = this.props,
            { pageUrls } = this.state;

        this.updateScrollStatus();

        if (!nextProps.isShowingReview && isShowingReview) {
            hideReview();
        }
        if (nextProps.isActive && !isActive) {
            flashReview();
        }
        if ((nextProps.isActive || nextProps.preload) && expanded && !pageUrls) {
            this.loadPages();
        }
    }

    updateScrollStatus() {
        if (!this.wrapper) {
            return;
        }
        this.setState((prevState) => {
            if (!prevState.hasBeenScrolled) {
                return {
                    isScrollable: this.wrapper.scrollHeight > this.wrapper.offsetHeight,
                    hasBeenScrolled: this.wrapper.scrollTop > 5,
                };
            }
        });
    }

    loadPages() {
        const {
            slide: { media },
        } = this.props;
        const { logger } = this.context;
        const { res, path, assetId } = CloudinaryPdf.getPartsFromUrl(media.pages[0]);
        if (!assetId) {
            // There was an error getting the asset id. Fallback to the default media
            this.setState({
                pageUrls: media.pages || [media.medium],
                isSinglePage: true,
            });
            return;
        }
        this.setState({ isLoadingMorePages: true });

        const cloudinary = new CloudinaryPdf(res);
        cloudinary
            .getPages({
                path,
                assetId,
                width: 1260,
                batchSize: 6,
                onPageLoaded: this.handlePageLoaded,
            })
            .then((pages) => {
                this.setState({
                    isLoadingMorePages: false,
                    isSinglePage: pages.length === 1,
                });
            })
            .catch((e) => {
                logger.warn(e, 'Failed to load first pdf batch');
                metric.count('warnings.gallery.pdf_slide.load_pages.failed');
            });
    }

    handlePageLoaded(page) {
        this.setState((prevState) => {
            const pageUrls = prevState.pageUrls ? [...prevState.pageUrls] : [];
            pageUrls[page.index] = page.src;
            return {
                pageUrls,
                isLoadingMorePages: true,
            };
        });
        this.throttledUpdateScrollStatus();
    }

    renderReview() {
        const { expanded, review } = this.props;

        return review ? <GigReview {...review} compact={!expanded} /> : null;
    }

    renderPages() {
        // Render a list of images that represent the pages in the pdf or fallback to the medium thumbanil.
        const { isActive, onZoomActive, onZoomIdle, preload, toggleReview } = this.props,
            { pageUrls, isLoadingMorePages, isSinglePage } = this.state,
            lazyLoad = !isActive && !preload,
            pages = pageUrls
                ? pageUrls.map((page, i) => (
                      <li key={`${i}--${page}`}>
                          <Image lazyLoad={lazyLoad} isActive={isActive} alt="" src={page} onClick={toggleReview} />
                      </li>
                  ))
                : null,
            loadingMore = isLoadingMorePages ? <li key="loading-more" className="loading-more" /> : null,
            classes = classNames('slide-pages', { 'single-page': isSinglePage, 'loading-more': isLoadingMorePages });

        return pages || loadingMore ? (
            <div className={classes}>
                <PinchToZoom
                    rotate={false}
                    minScale={1.5}
                    onActive={onZoomActive}
                    onIdle={onZoomIdle}
                    preventSingleTouchEvents={false}
                >
                    <ul ref={(node) => (this.wrapper = node)} onScroll={this.throttledUpdateScrollStatus}>
                        {pages}
                        {loadingMore}
                    </ul>
                </PinchToZoom>
                {this.renderReview()}
            </div>
        ) : null;
    }

    renderThumbnail() {
        const {
                slide: { name, media },
                isActive,
                preload,
            } = this.props,
            lazyLoad = !isActive && !preload;
        return (
            <figure className="thumbnail" tabIndex="0">
                <Image lazyLoad={lazyLoad} isActive={isActive} alt={name} src={media.medium} />
            </figure>
        );
    }

    render() {
        const { onClick, expanded, isActive, isShowingReview } = this.props;
        const { isScrollable, hasBeenScrolled } = this.state;
        const slideClasses = classNames('slide', 'slide-pdf', {
            'active-review': isShowingReview && isActive,
            'scroll-hint': isScrollable && !hasBeenScrolled && !isShowingReview,
        });

        return (
            <div className={slideClasses} onClick={onClick}>
                {expanded ? this.renderPages() : this.renderThumbnail()}
                {this.renderReview()}
                {!expanded && (
                    <div className="slide-fullscreen">
                        <I18n k={'gig_page_perseus.gallery.view_presentation'} />
                    </div>
                )}
            </div>
        );
    }
}

PdfSlide.propTypes = {
    isActive: bool,
    expanded: bool,
    preload: bool,
    slide: object,
    onZoomActive: func,
    onZoomIdle: func,
    onClick: func,
    flashReview: func,
    toggleReview: func,
    hideReview: func,
    isShowingReview: bool,
    review: object,
};

PdfSlide.contextTypes = {
    logger: object,
};

export { PdfSlide };
export default SlideHOC(PdfSlide);
