import { CONTACT_OPTIONS_TYPE } from '../constants';

/**
 * determine which contact option type to render upon the user test:
 * available options are:
 * 1 "get a quote" button
 * 2 "contact seller" button
 * 3 "get a quote" button and "contact seller" button
 * 4 none of the above
 *
 * Consultation button also can be added to the dropdown upon consultation=true query param.
 *
 * - When there is contact seller and get a quote (customOrderEligible = true):
 *      group A and b: we show both (option 3)
 *      group C: keep only "get a quote" button (option 1)
 * - When there is only contact seller (customOrderEligible = false):
 *      group A and b: keep the "contact seller" button (option 2)
 *      group C: render nothing (option 4)
 *
 * @param {boolean} customOrderEligible
 * @param {boolean} consultationEligible
 * @param {boolean} inAIBriefTest
 * @returns {string}
 */
export const getContactOptionsType = (customOrderEligible, consultationEligible, inAIBriefTest) =>
    (!inAIBriefTest && customOrderEligible) || consultationEligible
        ? CONTACT_OPTIONS_TYPE.DROPDOWN
        : CONTACT_OPTIONS_TYPE.CONTACT_SELLER_ONLY;
