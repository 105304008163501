import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Button } from '@fiverr-private/fit';

import './style.scss';

const TextButton = ({ text, onClick = () => null, disabled = false, className: customClassName }) => {
    const className = classNames('text-button', customClassName);

    return (
        <Button className={className} onClick={onClick} disabled={disabled}>
            <I18n k={text} />
        </Button>
    );
};

TextButton.propTypes = {
    text: string.isRequired,
    className: string,
    onClick: func,
    disabled: bool,
};

export default TextButton;
