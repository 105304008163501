import { clientAllocate } from '@fiverr-private/experiments_package';
import { ExperimentsMap } from '../types';
import { DEFAULT_NUM_OF_GROUPS } from '../constants';
import { getCalculatedExperiments } from '../calculation';
import Logger, { AppLogger } from '../../../logger';

/** contains Experiment Allocations - used to prevent double allocations (due to rate limits etc) */
const allocatedExperiments: ExperimentsMap = {}; // results: {[experimentId]: Group }

/**
 * Allocates to Experiment based on Experiment ID.
 * @note make sure the Experiment ID is added to the `client_allocation_allowed_experiments` rollouts in production & dev
 * @ref https://docs.fiverr-gw.com/platform/experiments/technical/experiments_package#clientallocate
 * @param experimentId
 */
export const allocateToClientExperiment = (experimentId: number): number | undefined | void => {
    const calculatedExperiments = getCalculatedExperiments();

    try {
        const previousClientAllocationResult = allocatedExperiments[experimentId];
        if (previousClientAllocationResult) {
            return previousClientAllocationResult;
        }

        const {
            experimentType,
            numOfGroups = DEFAULT_NUM_OF_GROUPS,
            calculatedGroup,
            rolloutResult,
        } = calculatedExperiments[experimentId];

        // assuming the Group isn't calculated if the User / Gig isn't Eligible for the Experiment
        if (!calculatedGroup) {
            return;
        }

        const variation = clientAllocate({ experimentId, experimentType, numOfGroups, rolloutResult });
        allocatedExperiments[experimentId] = variation;
        return variation;
    } catch (error) {
        const logger = Logger.getInstance() as AppLogger;

        logger.error(error, {
            description: 'Failed to Allocate to Client Experiment',
            experimentId,
            calculatedExperiments,
            allocatedExperiments,
        });
    }
};
