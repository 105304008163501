export const validateOverview = ({ overview }) => !!overview;

export const validateDescription = ({ description }) => !!description;

export const validateFaq = ({ faq }) => !!(faq && faq.questionsAndAnswers && faq.questionsAndAnswers.length);

export const validateReviews = ({ reviews }) => reviews?.reviews?.length || reviews?.seller_reviews?.reviews?.length;

export const validateOrderDetails = ({ isSinglePackage }) => !!isSinglePackage;

export const validatePackagesTable = ({ isTriplePackage }) => !!isTriplePackage;

export const validateSellerCard = ({ sellerCard }) => !!sellerCard;
