import { isString } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import { numberFormat } from '@fiverr-private/localization';
import { logger } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { isUnlimited } from '../../../packagesUtils';
import { MULTIPLE_VALUE, SINGLE_VALUE } from '../../../pricingFactors/constants';
import { Attributes } from '../../attributes';
import { getAttributeKeys } from '..';

/**
 *  Get label of a single pricing factor. In case we have a specific configuration
 *  for the gig sub category, we should get that one, otherwise it will fallback to
 *  the basic label.
 *
 *  For example, for the following configuration and the sub category [353]:
 *
 *  "word_count": {
 *    "label": "Words Included",
 *    "sub_category_353": {
 *      "label": "Pages Included"
 *    }
 *  }
 *
 *  We should get "Pages Included".
 */
export const getLabel = ({ name, subCategoryId, options = {} }, attributes = [Attributes.label]) => {
    const attributeKeys = getAttributeKeys({ name, subCategoryId, attributes, options });

    try {
        return translate(attributeKeys);
    } catch (e) {
        const { url } = getContext();
        const fallbackAttributeKeys = getAttributeKeys({
            name,
            subCategoryId,
            attributes: [Attributes.label],
            options,
        });

        logger.error(e, {
            name,
            description: 'Translation with "buyer_title" attribute failed',
            subCategoryId,
            attributes,
            options,
            failingAttributeKeys: attributeKeys,
            fallbackAttributeKeys,
            url,
        });

        return translate(fallbackAttributeKeys);
    }
};

/**
 *  Get calculator formatted label of a single calculator.
 */
export const getCalculatorFormattedLabel = ({ name, value, subCategoryId }) =>
    getFormattedLabel({
        name,
        value,
        subCategoryId,
        options: {
            withCalculator: true,
        },
    });

/**
 *  Get formatted label of a single pricing factor. The formatted label is designed to handle
 *  pricing factors of type "Number" (Pricing factors representing a single numeric value).
 *
 *  For example, for the following configuration, sub category [353] and the values 1, 5, -1:
 *
 *  "word_count": {
 *    "buyer_title": {
 *      "one": "Up to %{count} Word",
 *      "other": "Up to %{count} Words"
 *    }
 *    "sub_category_353": {
 *      "buyer_title": {
 *        "one": "Just one page"
 *      }
 *    }
 *  }
 *
 *  We should get "Just one page", "Up to 5 Words", "Up to Unlimited Words" respectively.
 */
export const getFormattedLabel = ({ name, value, subCategoryId, options = {} }) => {
    const attributes = buildFormattedLabelAttributes(value);
    const attributeKeys = getAttributeKeys({ name, subCategoryId, attributes, options });
    const formattedLabel = translate(attributeKeys, { params: { count: getPresentedCount(value) } });

    return isString(formattedLabel) ? formattedLabel : name;
};

const buildFormattedLabelAttributes = (value) => {
    const multipleValueSuffix = isSingleValue(value) ? SINGLE_VALUE : MULTIPLE_VALUE;
    const multipleValueAttribute = `${Attributes.buyerTitle}.${multipleValueSuffix}`;

    return [Attributes.title, multipleValueAttribute, Attributes.label];
};

const isSingleValue = (value) => Number(value) === 1;

export const getPresentedCount = (value) => {
    if (isUnlimited(value)) {
        return translate('gig_page_perseus.packages.unlimited');
    }
    return numberFormat({ value, options: { fallbackValue: value } });
};
