import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bool, func, number, object } from 'prop-types';
import { noop } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import { updateExtraFast } from '../../../actions/packages';
import { getPackageById } from '../../../reducers/packages';
import { formatDaysKey } from '../../../utils/packagesUtils';

import styles from './style.module.scss';

class FakeRadio extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: props.currentValue,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentValue !== this.props.currentValue) {
            this.setState({ checked: this.props.currentValue });
        }
    }

    onChange(e) {
        const numValue = Number(e.target.value);
        const { onChange } = this.props;

        this.setState({
            checked: numValue,
        });

        onChange(numValue);
    }

    getAdditionalRadioImageClassName() {
        const {
            general: { isPro },
            isBusinessLayout,
            isProRebrandingLayout,
        } = this.context;

        if (isBusinessLayout || isProRebrandingLayout) {
            return styles.newProLayout;
        } else if (isPro) {
            return styles.marketplaceProGig;
        } else {
            return '';
        }
    }

    // TODO: migrate to proper radio image SVG
    render() {
        const { packageId, baseDays, fasterDays, fasterPrice } = this.props;
        const { currencyConverter } = this.context;
        const { checked } = this.state;

        const radioImageClassName = classNames(styles.radioImg, this.getAdditionalRadioImageClassName());

        return (
            <div className={styles.fakeRadioWrapper}>
                <label className={styles.fakeRadio}>
                    <input type="radio" value={0} checked={checked === 0} name={packageId} onChange={this.onChange} />
                    <span className={radioImageClassName} />
                    <span>
                        {baseDays} <I18n k={formatDaysKey(baseDays)} />
                    </span>
                </label>
                <label className={styles.fakeRadio}>
                    <input type="radio" value={1} checked={checked === 1} name={packageId} onChange={this.onChange} />
                    <span className={radioImageClassName} />
                    <span>
                        {fasterDays} <I18n k={formatDaysKey(fasterDays)} />
                    </span>
                    <p className={styles.fasterPrice}>(+{currencyConverter.convert(fasterPrice)})</p>
                </label>
            </div>
        );
    }
}

const mapStateToProps = ({ packages }, ownProps) => {
    const packageData = getPackageById(packages, ownProps.packageId),
        { extraFast, duration } = packageData;

    return {
        packageId: ownProps.packageId,
        id: extraFast.id,
        baseDays: duration,
        fasterDays: extraFast.duration,
        fasterPrice: extraFast.price,
        currentValue: extraFast.currentValue,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onChange: (numValue) => {
        dispatch(updateExtraFast({ value: numValue, packageId: ownProps.packageId }));
    },
});

FakeRadio.propTypes = {
    baseDays: number,
    fasterDays: number,
    fasterPrice: number,
    onChange: func,
    currentValue: number,
}.isRequired;

FakeRadio.defaultProps = {
    onChange: noop,
    currentValue: 0,
};

FakeRadio.contextTypes = {
    general: {
        isPro: bool,
    },
    currencyConverter: object,
    isBusinessLayout: bool,
    isProRebrandingLayout: bool,
};

export { FakeRadio };
export default connect(mapStateToProps, mapDispatchToProps)(FakeRadio);
