import React from 'react';
import { string, number, object } from 'prop-types';
import { isEmpty } from 'lodash';
import { dateTimeFormat, DATETIME_ELEMENT_STYLES } from '@fiverr-private/localization';
import { howLongAgo } from '@fiverr-private/futile';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { translate } from '../../../../utils/translation';
import Languages from '../Languages';
import './style.scss';

const renderHoursAsDays = (hours) =>
    hours < 24 ? (
        <I18n k={'gig_page_perseus.seller_card.response_time_hours'} params={{ count: hours }} />
    ) : (
        <I18n k={'gig_page_perseus.seller_card.response_time_days'} params={{ count: Math.round(hours / 24) }} />
    );

const formatMemberSince = (memberSince) => {
    const memberSinceDate = new Date(memberSince * 1000);
    const monthKey = `months.abbrv.${memberSinceDate.getMonth()}`;
    const fallbackValue = `${translate(monthKey)} ${memberSinceDate.getFullYear()}`;

    const formattedDate = dateTimeFormat({
        value: memberSinceDate,
        options: {
            fallbackValue,
            year: DATETIME_ELEMENT_STYLES.NUMERIC,
            month: DATETIME_ELEMENT_STYLES.SHORT,
        },
    });

    return formattedDate;
};

const formatRecentDelivery = (recentDelivery, locale) =>
    howLongAgo(recentDelivery, { prefix: 'gig_page_perseus.general.how_long_ago.', locale });

const Stats = ({ countryCode, memberSince, responseTime, recentDelivery }, { sellerLanguageParams }) => {
    const { locale } = getContext();
    const sellerLanguages = sellerLanguageParams?.sellerLanguages;
    const shouldShowSellerLanguages = !isEmpty(sellerLanguages);

    if (!countryCode && !memberSince && !responseTime && !recentDelivery) {
        return null;
    }

    return (
        <ul className="user-stats">
            {countryCode && (
                <li>
                    <I18n k={'gig_page_perseus.seller_card.from'} />
                    <strong>
                        <I18n k={`countries.${countryCode}`} />
                    </strong>
                </li>
            )}
            {memberSince > 0 && (
                <li>
                    <I18n k={'gig_page_perseus.seller_card.member_since'} />
                    <strong>{formatMemberSince(memberSince)}</strong>
                </li>
            )}
            {responseTime > 0 && (
                <li>
                    <I18n k={'gig_page_perseus.seller_card.response_time'} />
                    <strong>{renderHoursAsDays(responseTime)}</strong>
                </li>
            )}
            {recentDelivery > 0 && (
                <li>
                    <I18n k={'gig_page_perseus.seller_card.last_delivery'} />
                    <strong>{formatRecentDelivery(recentDelivery, locale)}</strong>
                </li>
            )}
            {shouldShowSellerLanguages && (
                <li>
                    <Languages />
                </li>
            )}
        </ul>
    );
};

Stats.propTypes = {
    countryCode: string,
    memberSince: number,
    responseTime: number,
    recentDelivery: number,
};

Stats.contextTypes = {
    sellerLanguageParams: object,
};

export default Stats;
