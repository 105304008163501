import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { validateAttributes, sortAttributes } from './utils';
import MetadataAttribute from './MetadataAttribute';

import './style.scss';

const Metadata = ({ metadataAttributes }) => {
    const validAttributes = validateAttributes(metadataAttributes);
    const sortedAttributes = sortAttributes(validAttributes);

    if (!(sortedAttributes.length > 0)) {
        return null;
    }

    return (
        <ul className="metadata">
            {sortedAttributes.map(({ name, options, metadata }) => (
                <MetadataAttribute key={name} name={name} options={options} metadata={metadata} />
            ))}
        </ul>
    );
};

Metadata.propTypes = {
    metadataAttributes: arrayOf(
        shape({
            name: string,
            options: arrayOf(
                shape({
                    label: string,
                    value: string,
                })
            ),
        })
    ).isRequired,
};

export default Metadata;
