import { snakeCase } from 'lodash';
import { metric } from '../metric';
import { EXCLUDED_FUNCTIONS } from './constants';

/**
 * Extract the status code from the error
 * @param error
 * @returns {string} status code
 */
export const getStatusCode = (error = {}) => {
    const errorCode = error.code?.toString()?.toLowerCase();
    const statusCode = error.response?.status;

    return statusCode || errorCode;
};

/**
 * Get the source function in which maxios method was called (server requests)
 * @returns {string} function's name
 */
export const getCallerFromStack = () => {
    const oldPrepareStackTrace = Error.prepareStackTrace;
    Error.prepareStackTrace = (error, stack) => stack;
    const { stack } = new Error();
    Error.prepareStackTrace = oldPrepareStackTrace;

    const callers = stack.map((x) => x.getFunctionName());

    const filteredCallers = callers?.filter((caller) => !EXCLUDED_FUNCTIONS.includes(caller));
    const callerFunction = filteredCallers?.[2];

    return snakeCase(callerFunction);
};

export const measureOnSuccess = (routeId, startTime, status) => {
    metric.time(`http.${routeId}.execution_time.success`, Date.now() - startTime);
    metric.count(`http.${routeId}.success`);
    metric.count(`http.${routeId}.result.status.${status}`);
};

export const measureOnFailure = (routeId, startTime, status) => {
    metric.time(`http.${routeId}.execution_time.failure`, Date.now() - startTime);
    metric.count(`http.${routeId}.failure`);

    if (status) {
        metric.count(`http.${routeId}.result.status.${status}`);
    }
};
