import { merge } from 'lodash';
import { BigQueryEnrichmentData, GigPageContextType } from '@fiverr-private/gig_page_context';
import { getBaseActionsParams, getBaseImpressionParams } from '../../events/base';

export interface BQEventData extends BigQueryEnrichmentData {
    gigTheme: GigPageContextType['gigTheme'];
    organizationId?: string;
}

const BQ_CUSTOMER_PROTECTION_BANNER_IS_SHOWN = 'customer_protection_banner_is_shown';

export const bqCustomerProtectionBannerIsShown = ({ gigTheme, organizationId, ...enrichmentData }: BQEventData) =>
    merge({}, getBaseImpressionParams(BQ_CUSTOMER_PROTECTION_BANNER_IS_SHOWN, enrichmentData), {
        organization: { id: organizationId },
        page: {
            element: { position: gigTheme },
        },
    });

export const bqClickedOnCustomerProtection = ({ gigTheme, organizationId, ...enrichmentData }: BQEventData) =>
    merge({}, getBaseActionsParams('clicked_on_customer_protection', enrichmentData), {
        organization: { id: organizationId },
        page: {
            element: { position: gigTheme },
            component: { name: 'customer_protection_banner' },
        },
    });
