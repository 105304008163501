import { BiEvents, General } from '@fiverr-private/gig_page_context';
import { PROMOTED_GIGS_CAROUSEL } from '../../shared/GigCardCarouselHOC';
import { PROMOTED_GIGS_SOURCE } from '../constants';

const BQ_SEE_MORE_PROMOTED_CAROUSEL = 'clicked_on_see_more';

interface SeeMoreClickEventParams {
    biEvents: BiEvents;
    params: {
        promotedAuctionId?: string;
        general: General;
    };
}

export const reportSeeMoreClick = ({
    biEvents,
    params: {
        promotedAuctionId,
        general: { categoryId, nestedSubCategoryId, subCategoryId, gigId, sellerId },
    },
}: SeeMoreClickEventParams) => {
    const { userId, pageCtxId, pageName } = biEvents.getBigQueryEnrichmentData();

    biEvents.sendRawBigQueryEvent({
        type: BQ_SEE_MORE_PROMOTED_CAROUSEL,
        group: 'user_actions',
        feature: {
            name: PROMOTED_GIGS_CAROUSEL,
        },
        item_context: {
            source_component: PROMOTED_GIGS_SOURCE,
        },
        page: {
            name: pageName,
            component: {
                name: 'see_more',
            },
            element: {
                name: 'gig_page_link',
            },
            ctx_id: pageCtxId,
        },
        action: {
            type: 'click',
        },
        ...(promotedAuctionId && { auction: { id: promotedAuctionId } }),
        category: { id: categoryId },
        nested_sub_category: { id: nestedSubCategoryId },
        sub_category: { id: subCategoryId },
        assets_sharing: {
            id: gigId,
        },
        user: {
            id: userId,
        },
        seller: {
            id: sellerId,
        },
        gig: {
            id: gigId,
        },
    });
};
