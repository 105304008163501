import React from 'react';
import { I18n, translate } from '@fiverr-private/i18n-react';
import SafePureComponent from '../../shared/SafePureComponent';
import './style.scss';

const troyMobileSrc =
    'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/6ba543a6b4d64aa6b6371c2e0ae35314-1633331946671/Troy-mobileX1.png';
const troyDesktopSrc =
    'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/6ba543a6b4d64aa6b6371c2e0ae35314-1633331948394/Troy-DesktopX1.png';
const logoSrc =
    'https://fiverr-res.cloudinary.com/image/upload/f_png,q_auto/v1/attachments/generic_asset/asset/89eec6960a614498f9cee611c2044997-1632657127024/limited-edition-troy-logo.svg';

const LimitedEditionBanner = () => (
    <div className="limited-edition-banner">
        <div className="text-wrapper">
            <p>
                <h4>
                    <I18n k="gig_page_perseus.limited_edition.banner.title" />
                </h4>
                <span className="submit-entry">
                    <I18n k="gig_page_perseus.limited_edition.banner.submit_your_entry" />
                </span>
            </p>
            <p className="info-container">
                <span className="info">
                    <I18n k="gig_page_perseus.limited_edition.banner.info" />
                    &nbsp;
                </span>
                <i className="apply-now">
                    <I18n k="gig_page_perseus.limited_edition.banner.apply_now" />
                </i>
            </p>
        </div>
        <div className="image-container">
            <picture className="troy">
                <source media="(max-width: 599px)" srcSet={troyMobileSrc} />
                <source srcSet={troyDesktopSrc} />
                <img src={troyMobileSrc} alt={translate('gig_page_perseus.limited_edition.banner.image_alt')} />
            </picture>
            <div className="logo">
                <img alt={translate('gig_page_perseus.limited_edition.banner.logo_alt')} src={logoSrc} />
            </div>
        </div>
    </div>
);

export { LimitedEditionBanner };
export default SafePureComponent(LimitedEditionBanner);
