import { useEffect } from 'react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { BQ_PROMOTION_CROSSED_PRICE_IS_SHOWN } from '../../../../utils/bigQueryEvents';
import { SELLER_COUPON_TYPES } from '../../../GigPage/constants';

export const useShowPromotionDiscountedPriceBIEvent = (isDiscountedPriceShown: boolean) => {
    const { biEvents, coupon } = useGigPageContext();

    useEffect(() => {
        const canReport = isDiscountedPriceShown && coupon && coupon.type === SELLER_COUPON_TYPES.PROMOTION;

        if (!canReport) {
            return;
        }

        const { gigId, sellerId, userId } = biEvents.getBigQueryEnrichmentData();
        const { id, discountValue, minOrderAmount, expiresAt } = coupon;

        biEvents.sendRawBigQueryEvent({
            type: BQ_PROMOTION_CROSSED_PRICE_IS_SHOWN,
            group: 'user_impressions',
            sub_group: 'seller_plus_events',
            user: { id: userId },
            seller: { id: sellerId },
            gig: { id: gigId },
            promotion: {
                id,
                discount_percent: discountValue / 100,
                end_date: expiresAt,
                minimum_amount: minOrderAmount,
            },
        });
    }, [biEvents, coupon, isDiscountedPriceShown]);
};
