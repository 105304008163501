import { MediaUrlData, PreviewUrl } from '../../sellerTranslationTypes';
import { ATTACHMENT_SIZES } from './constants';

export const mapToMediaUrls = (previewUrls: PreviewUrl[] = []): MediaUrlData => {
    const mediaObject: MediaUrlData = {
        small: '',
        medium: '',
        original: '',
    };

    previewUrls.forEach(({ url, transformation }) => {
        const currentMediaType = ATTACHMENT_SIZES[transformation];
        mediaObject[currentMediaType] = url;
    });

    return mediaObject;
};

export const buildSrcUrl = (previewUrls: PreviewUrl[] = [], targetType: string): string => {
    const preview = previewUrls.find(({ transformation }) => ATTACHMENT_SIZES[transformation] === targetType);

    return preview?.url || '';
};
