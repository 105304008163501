import { getShortLanguageCode, SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';

export const LANGUAGE_PROFICIENCY_LEVEL_MAPPING = {
    unspecified: 0,
    basic: 1,
    conversational: 2,
    fluent: 3,
    native_or_bilingual: 4,
};

export const SUPPORTED_LOCALE_CODES = {
    DE: getShortLanguageCode(SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE),
    FR: getShortLanguageCode(SUPPORTED_FIVERR_LOCALES_KEYS.FR_FR),
};
