import { Component } from 'react';
import PropTypes from 'prop-types';

class ErrorBoundary extends Component {
    static getDerivedStateFromError() {
        return {
            hasError: true,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error) {
        const { logger } = this.context;

        logger.error(error);
    }

    render() {
        const { hasError } = this.state;

        if (hasError) {
            return null;
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
};

ErrorBoundary.contextTypes = {
    logger: PropTypes.object,
};

export default ErrorBoundary;
