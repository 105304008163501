import React from 'react';
import MediaQuery from 'react-responsive';
import { number, object } from 'prop-types';
import { translate } from '@fiverr-private/i18n-react';
import { MEDIA_GRID_UNDER_SM } from '../../../utils/constants';
import { REVIEWS_COPIES_PREFIX } from '../constants';

const SellersTabTitle = ({ ref, reviewsCount = 0 }) => {
    const params = { params: { count: reviewsCount } };

    return (
        <span ref={ref}>
            <MediaQuery query={`(max-width: ${MEDIA_GRID_UNDER_SM})`}>
                {(matches) =>
                    matches
                        ? translate(`${REVIEWS_COPIES_PREFIX}.tabs.mobile.for_this_seller`, params)
                        : translate(`${REVIEWS_COPIES_PREFIX}.tabs.for_this_seller`, params)
                }
            </MediaQuery>
        </span>
    );
};

SellersTabTitle.propTypes = {
    ref: object,
    reviewsCount: number,
};

export default SellersTabTitle;
