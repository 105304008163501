import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, object } from 'prop-types';
import { formatPricingFactorValue } from '../../../utils/packagesUtils';
import PricingFactorLabel from '../PricingFactorLabel';
import NumberedPricingFactor from '../NumberedPricingFactor';

const isValidRevisions = (revisions) => revisions.every(Boolean);

const Revisions = ({ revisions }) => {
    if (!isValidRevisions(revisions)) {
        return null;
    }
    const { name } = revisions[0];

    return (
        <tr>
            <td className="package-row-label">
                <PricingFactorLabel name={name} />
            </td>
            {revisions.map(({ id, value }, index) => (
                <NumberedPricingFactor key={`${id}_${index}`} value={formatPricingFactorValue(value)} />
            ))}
        </tr>
    );
};

const mapStateToProps = ({ packages }) => ({
    revisions: packages.packageList.map((packageData) => packageData.revisions),
});

Revisions.propTypes = {
    revisions: arrayOf(object),
};

export { Revisions };
export default connect(mapStateToProps)(Revisions);
