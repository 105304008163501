import React, { createRef, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { get, merge, noop } from 'lodash';
import { Impressionable } from '@fiverr-private/impressionable';
import { ReportingChannelType } from '../../../types/reportingChannelType';
import eventsReporter from '../../../utils/reporter';

const withImpressions = (WrappedComponent, { DOMSelector, eventKey, buildImpressionData = noop }) => {
    class ImpressionsWrapper extends PureComponent {
        constructor(props) {
            super(props);

            this.impressionRef = createRef();

            this.collect = this.collect.bind(this);
            this.enrich = this.enrich.bind(this);
        }

        componentDidMount() {
            this.init();
        }

        componentDidUpdate() {
            this.impressionable && this.impressionable.die();
            this.init();
        }

        collect() {
            const hasImpressions = get(this.impressionable, 'unimpressed', []).length > 0;

            if (hasImpressions) {
                this.impressionable.collect();
            }
        }

        init() {
            if (!this.impressionRef.current) {
                return;
            }

            this.impressionable = new Impressionable({
                DOMSelector,
                container: this.getImpressionContainer(),
                enrich: this.enrich,
            });
        }

        getImpressionContainer() {
            // eslint-disable-next-line react/no-find-dom-node
            return ReactDOM.findDOMNode(this.impressionRef.current);
        }

        enrich(data, child) {
            const eventData = buildImpressionData(this.props, child);
            const event = ReportingChannelType.IMPRESSION.events[eventKey];
            const eventParams = merge({}, data, eventData);

            return eventsReporter.build(event, eventParams);
        }

        render() {
            const enhancedProps = {
                impressionRef: this.impressionRef,
                collectImpressions: this.collect,
            };

            return <WrappedComponent {...this.props} {...enhancedProps} />;
        }
    }

    return ImpressionsWrapper;
};

export default withImpressions;
