import React from 'react';
import { array, func } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { numberFormat } from '@fiverr-private/localization';
import Collect from '@fiverr-private/collect';
import { getContext } from '@fiverr-private/fiverr_context';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { PAGE_NAME } from '../../../utils/events';
import { updateCollections } from '../../../actions/collections';

import './style.scss';

const CollectWrapper = ({ collections, updateCollections }) => {
    const { userId, isTouch } = getContext();
    const {
        general,
        gig: { gigCollectedCount },
    } = useGigPageContext();
    const { gigId } = general;

    const resource = {
        id: gigId.toString(),
        type: Collect.RESOURCE_TYPES.GIG,
    };

    const sourceInfo = {
        name: PAGE_NAME,
        userId,
        isTouch,
    };

    // The additional wrapper class is due to an issue with how webpack bundles css rules.
    // Should be removed when issue is resolved
    const collectBodyClass = 'gig-page-nav-collect';

    const formattedGigCollectedCount = numberFormat({
        value: gigCollectedCount,
        options: {
            fallbackValue: gigCollectedCount,
        },
    });

    return (
        <div className="collect-wrapper">
            <Collect
                collections={collections}
                resource={resource}
                onUpdateCollections={updateCollections}
                menuPosition={Collect.MENU_POSITIONS.BOTTOM_LEFT}
                allowMenuOverflow
                sourceInfo={sourceInfo}
                className="nav-collect"
                tooltipClassName={classNames(collectBodyClass, 'tooltip')}
                triggerData={{
                    triggerId: 'Save a Gig',
                    triggerCopy: 'Heart icon',
                    triggerPlacement: 'Save Gig to list'
                }}
            />
            {gigCollectedCount > 0 && <span className="collect-count">{formattedGigCollectedCount}</span>}
        </div>
    );
};

CollectWrapper.propTypes = {
    collections: array,
    updateCollections: func,
};

const mapStateToProps = ({ collections }) => ({
    collections,
});

export { CollectWrapper };
export default connect(mapStateToProps, { updateCollections })(CollectWrapper);
