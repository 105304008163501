import React from 'react';
import { array } from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { s_check_circle } from '@fiverr-private/fit/icons';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import { buildSubCategoryNameKey } from '../../../../utils/translation/categories';
import { PRO_BADGE_LOCALE_CLASS_MAPPER } from './constants';

import './style.scss';

const Pro = ({ proSubCategories }) => {
    const { locale } = getContext();

    const localeClass = PRO_BADGE_LOCALE_CLASS_MAPPER[locale] || '';
    const proVerifiedLabel = classNames('pro-verified', localeClass);

    return (
        <div className="pro-wrapper">
            <label className={proVerifiedLabel}>
                <I18n k={'gig_page_perseus.seller_card.pro.verified_by'} />
            </label>
            <ul>
                {proSubCategories.map((subcategory) => (
                    <li key={subcategory.id}>
                        <Icon size={14} className="pro-check-icon">
                            {s_check_circle()}
                        </Icon>
                        <I18n k={buildSubCategoryNameKey(subcategory.id)} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

Pro.propTypes = {
    proSubCategories: array,
};

export default Pro;
