import { getContext } from '@fiverr-private/fiverr_context';
import { localStorage } from '@fiverr-private/futile';
import { FORBIDDEN_SC_IDS } from '../../constants/returningBuyerModal';
import { inExperiment } from '../../experiments';
import { COBRAS_GIG_RETURNING_BUYER_MODAL } from '../../experiments/experimentsEnum';
import { getMinDiscount } from '..';
import {
    RETURNING_BUYER_MODAL_TTL,
    SCREEN_WIDTH_THRESHOLD,
    SEEN_RETURNING_BUYER_SUBSCRIPTION_MODAL_LOCAL_STORAGE_KEY,
} from './constants';

/**
 * Get the local storage key for if seen the returning buyer subscription suggestion modal
 * @return {boolean} true iff the user has seen the returning buyer subscription suggestion modal
 * in the past `RETURNING_BUYER_MODAL_TTL` days
 */
export const hasSeenReturningBuyerSubscriptionModal = () => {
    const lastSeen = localStorage.get(SEEN_RETURNING_BUYER_SUBSCRIPTION_MODAL_LOCAL_STORAGE_KEY);

    return !!lastSeen && Date.now() - lastSeen < RETURNING_BUYER_MODAL_TTL;
};

/**
 * Set the local storage key for seen the returning buyer subscription suggestion modal
 */
export const markSeenReturningBuyerSubscriptionModal = () =>
    localStorage.set(SEEN_RETURNING_BUYER_SUBSCRIPTION_MODAL_LOCAL_STORAGE_KEY, Date.now());

/**
 * Checks whether the current user is eligible for seeing the returning buyer subscription modal
 * @param packages the packages context data
 * @param currentUser the current user data
 * @param general the general context data
 * @returns {boolean} a flag whether the user should see the returning buyer subscription modal
 */
export const eligibleForReturningBuyerSubscriptionModal = ({ packages = {}, currentUser = {}, general = {} }) => {
    const { abTests } = getContext();
    const gigHasSubscriptionDiscount = getMinDiscount(packages.recurringOptions) > 0;
    const { isReturningBuyer = false } = currentUser;
    const suitableScreenWidth = typeof window !== 'undefined' && window.innerWidth >= SCREEN_WIDTH_THRESHOLD;
    const isForbiddenCategory = FORBIDDEN_SC_IDS.includes(general.subCategoryId);

    return (
        inExperiment(COBRAS_GIG_RETURNING_BUYER_MODAL, abTests) &&
        !hasSeenReturningBuyerSubscriptionModal() &&
        gigHasSubscriptionDiscount &&
        isReturningBuyer &&
        !!suitableScreenWidth &&
        !isForbiddenCategory
    );
};
