import React from 'react';
import { array, bool, func, number, object } from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import { alignPackagesFeatures, getPackageTypeById, hasRecommendedPackage } from '../../../utils/packagesUtils';
import TabContent from '../TabContent';
import { selectPackage } from '../../../actions/packages';
import { reportTabClickEvents } from './utils';

import './style.scss';

const TriplePackage = (
    { packageList, selectedPackageId, selectPackage },
    { biEvents, isBusinessLayout, isProRebrandingLayout }
) => {
    const onPackageChange = (id) => {
        selectPackage({ packageId: id });
        reportTabClickEvents(biEvents, id);
    };

    const recommendedGig = hasRecommendedPackage(packageList);
    const selectedPackage = packageList.find((packageData) => packageData.id === selectedPackageId);
    const tabContentProps = {
        id: selectedPackage.id,
        features: selectedPackage.features,
        recommendedGig,
    };
    const packageListItemClasses = classnames({
        'new-pro-layout': isBusinessLayout || isProRebrandingLayout,
    });

    return (
        <div className={classnames('packages-tabs', 'triple')}>
            {packageList.map(({ id }) => (
                <input
                    key={id}
                    id={`package-tab-${id}`}
                    name="package-tab-group"
                    type="radio"
                    checked={selectedPackageId === id}
                />
            ))}

            <div className="nav-container">
                {packageList.map(({ id }) => (
                    <label
                        key={id}
                        htmlFor={`package-tab-${id}`}
                        className={packageListItemClasses}
                        tabIndex="0"
                        role="tab"
                        aria-selected={selectedPackageId === id}
                        onClick={() => onPackageChange(id)}
                    >
                        {translate(getPackageTypeById(id))}
                    </label>
                ))}
            </div>
            <TabContent {...tabContentProps} />
        </div>
    );
};

TriplePackage.propTypes = {
    packageList: array,
    selectedPackageId: number,
    selectPackage: func,
};

TriplePackage.contextTypes = {
    biEvents: object,
    isBusinessLayout: bool,
    isProRebrandingLayout: bool,
};

const mapStateToProps = (state) => {
    const { packages } = state;

    return {
        packageList: alignPackagesFeatures(packages.packageList),
        selectedPackageId: packages.selectedPackageId,
    };
};

export { TriplePackage };
export default connect(mapStateToProps, { selectPackage })(TriplePackage);
