import React, { useState } from 'react';
import classNames from 'classnames';
import { string, func, object } from 'prop-types';
import { Textarea } from '@fiverr-private/fit';
import { translate } from '@fiverr-private/i18n-react';
import { ERRORS, MAX_SENTENCE_LENGTH } from '../../../../utils/voiceOverAiAudition/constants';
import { sendErrorBiEvent } from '../biEvents';
import { generateAuditionId } from '../utils';

import './style.scss';

const SampleInput = (
    { inputSentence, onSampleInputUpdate, onSampleInputSubmit, error, setError, clearError },
    { biEvents }
) => {
    const [shouldExpendInput, setShouldExpendInput] = useState(false);

    const PLACE_HOLDER_KEY = 'gig_page_perseus.voice_over_ai_audition.input_placeholder';
    const ENTER_KEY = 13;
    const ALLOWED_CHARS_REGEX = /^[A-Za-z0-9 !#$%&()*+',-./:;?@]*$/;
    const ERROR_KEY_PREFIX = 'gig_page_perseus.voice_over_ai_audition.errors';

    const onChange = (e) => {
        // disable text area line breaks
        const inputValue = e.target.value.replace(/[\r\n\v]+/g, '');

        const inputHandlerMethod = isLegalSentence(inputValue) ? handleValidInput : handleInputError;

        inputHandlerMethod(inputValue);
    };

    const isLegalSentence = (sentence) => ALLOWED_CHARS_REGEX.test(sentence);

    const handleValidInput = (inputValue) => {
        clearError();
        onSampleInputUpdate(inputValue);
    };

    const handleInputError = (inputValue) => {
        const errorAuditionId = generateAuditionId();

        setError(ERRORS.ILLEGAL_CHAR);
        sendErrorBiEvent({
            biEvents,
            auditionId: errorAuditionId,
            sentence: inputValue,
            error: ERRORS.ILLEGAL_CHAR,
        });
    };

    const onKeyDown = (e) => {
        if (e.keyCode === ENTER_KEY) {
            onSampleInputSubmit(inputSentence);
        }
    };

    const onFocus = () => setShouldExpendInput(true);

    const getErrorMessage = () =>
        translate([`${ERROR_KEY_PREFIX}.${error}`, `${ERROR_KEY_PREFIX}.${ERRORS.GENERAL_ERROR}`]);

    const hasError = !!error;

    return (
        <div className="sample-input">
            <Textarea
                className={classNames('sample-text', { expended: shouldExpendInput, error: hasError })}
                value={inputSentence}
                placeholder={translate(PLACE_HOLDER_KEY)}
                onChange={onChange}
                maxLength={MAX_SENTENCE_LENGTH}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                showCounter={shouldExpendInput}
            />
            {hasError && <p className="error-message">{getErrorMessage()}</p>}
        </div>
    );
};

SampleInput.propTypes = {
    inputSentence: string,
    onSampleInputUpdate: func,
    onSampleInputSubmit: func,
    error: string,
    setError: func,
    clearError: func,
};

SampleInput.contextTypes = {
    biEvents: object,
};

export default SampleInput;
