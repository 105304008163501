export const UPDATE_SHOW_FIRST_MESSAGE_POPUP = 'UPDATE_SHOW_FIRST_MESSAGE_POPUP';

/**
 * Returns an action for toggling the first message popup.
 *
 * @param {boolean} showFirstMessagePopup whether to show first message popup.
 * @returns {object} the dispatch object
 */
export const updateShowFirstMessagePopup = (showFirstMessagePopup) => ({
    type: UPDATE_SHOW_FIRST_MESSAGE_POPUP,
    data: { showFirstMessagePopup },
});
