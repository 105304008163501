import { getContext } from '@fiverr-private/fiverr_context';
import { StatusToast } from '@fiverr-private/orca';
import { CHECKOUT_PLANS } from '../../../../../utils/checkoutPlan/constants';
import { BQ_CHECKOUT_DRAWER_VIEWED, BQ_CLICKED_ON_CONTINUE_TO_CHECKOUT } from '../../../../../utils/bigQueryEvents';
import { translate } from '../../../../../utils/translation';
import { metric } from '../../../../../utils/metric';

/**
 * @param paymentOption
 */
export const shouldSendCheckoutDrawerViewed = (paymentOption) => paymentOption === CHECKOUT_PLANS.SINGLE_ORDER;

/**
 * @param gigId
 * @param gigTitle
 * @param sellerId
 * @param biEvents
 * @param username
 */
export const sendCheckoutDrawerViewedEvent = ({ gigTitle, sellerId }, biEvents, username) => {
    const { pathParameters } = getContext();
    const { username: sellerUsername } = pathParameters;

    biEvents.sendBigQueryEvent({
        eventName: BQ_CHECKOUT_DRAWER_VIEWED,
        params: {
            buyer: {
                username,
            },
            gig: {
                title: gigTitle,
            },
            seller: {
                id: sellerId,
                username: sellerUsername,
            },
        },
    });
};

/**
 * Clicked on Continue to Checkout Event
 */
export const sendClickedOnContinueToCheckoutEvent = (biEvents) => {
    biEvents.sendBigQueryEvent({ eventName: BQ_CLICKED_ON_CONTINUE_TO_CHECKOUT });
};

/** Lazy Import Error Toast & Log Error
 * @param logger
 * @param error
 * @param paymentOption
 */
export const logAndToastLazyImportError = ({ logger, error, paymentOption }) => {
    StatusToast.raise({
        message: translate('gig_page_perseus.limited_edition.form.errors.submit'),
        theme: (StatusToast as any).THEMES.ERROR,
    });

    logger.error(error, {
        description: 'Failed to lazy load payment drawer',
        paymentOption,
    });

    metric.count('checkout_drawer.lazy_load.error');
};

/**
 * @param ctaType
 */
export const reportCtaType = (ctaType) => {
    metric.count(`checkout_drawer.cta_type.${ctaType}`);
};
