import React from 'react';
import { connect } from 'react-redux';
import { isRecurringOrderSelected } from '../../reducers/checkoutPlan';
import { usePaypalPaylaterTokenHook } from './usePaypalPaylaterToken.hook';
import { PayLaterGigPageMessage } from './PayLaterGigPageMessage';

const PayLaterMessage: React.FC = () => {
  const paypalClientToken = usePaypalPaylaterTokenHook();

  if (!paypalClientToken || paypalClientToken === 'paypal_client_token_forbidden') {
    return null;
  }

  return <PayLaterGigPageMessage paypalClientToken={paypalClientToken} />;
};

const mapStateToProps = (state) => ({
  isRecurringOrderSelected: isRecurringOrderSelected(state),
});
export default connect(mapStateToProps)(PayLaterMessage);
