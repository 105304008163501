import React from 'react';
import { CHECKOUT_PLANS } from '../../../../utils/checkoutPlan/constants';
import MilestoneFooterInfo from './MilestoneFooterInfo';
import ComparePackagesFooterInfo from './ComparePackagesFooterInfo';

export const FOOTER_CONFIG = {
    [CHECKOUT_PLANS.SINGLE_ORDER]: {
        FooterInfo: () => <ComparePackagesFooterInfo />,
    },
    [CHECKOUT_PLANS.MILESTONES_ORDER]: {
        FooterInfo: () => <MilestoneFooterInfo />,
    },
    [CHECKOUT_PLANS.RECURRING_ORDER]: {
        FooterInfo: () => <ComparePackagesFooterInfo />,
    },
};
