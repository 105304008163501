import { successModalState } from '@fiverr-private/business_success_sign_modal';

export enum FibTriggers {
    Contact = 'gig-page-contact-trigger',
    GetQuote = 'gig-page-get-quote',
}

export const fibMigrationFlow = {
    isTriggerIdPersisted: (callbackId: FibTriggers) => {
        const state = successModalState.getSuccessSignModalState();
        return state.shouldShow && state.ctaData?.source === callbackId;
    },
};
