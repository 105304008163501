import React, { useCallback } from 'react';
import classnames from 'classnames';
import { translate } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';

import classes from './DisplayName.module.scss';

interface DisplayNameProps {
  username: string;
  displayName?: string;
  sellerUrl?: string;
  classname?: string;
  onClick: VoidFunction;
}

const buildDisplayName = ({ displayName, username }) => displayName || username;

export const DisplayName = ({
  classname = 'tbody-4 ',
  username,
  displayName,
  sellerUrl,
  onClick,
}: DisplayNameProps) => {
  const { seller } = useGigPageContext();
  const { isAgency } = seller;
  const nameToDisplay = buildDisplayName({ displayName, username });

  const onNameClick = useCallback(() => {
    if (sellerUrl) {
      window.location.assign(sellerUrl);
    }
    if (onClick) {
      onClick();
    }
  }, [sellerUrl, onClick]);

  const commonProps: React.HTMLAttributes<HTMLDivElement> = {
    tabIndex: 0,
    role: 'button',
    'aria-label': translate<string>('gig_page_perseus.overview.scroll_to_seller'),
  };

  const displayNameClasses = classnames(classes.name, classname, 'text-bold', { [classes.grey1200]: isAgency });

  return (
    <div className="flex flex-items-baseline">
      <div {...commonProps} onClick={onNameClick} className={displayNameClasses}>
        {nameToDisplay}
      </div>
    </div>
  );
};
