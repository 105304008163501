import { getContext } from '@fiverr-private/fiverr_context';
import { PAGE_NAME } from '../../events';

/**
 * Builds source info object for machine translation BI reporting.
 *
 * @param {string} componentName - source component name initiating the translation
 * @param {number} gigId - gig id
 * @param {Object=} user - user object
 */
export const buildSourceInfo = ({ componentName, gigId }) => {
    const { userId, userGuid, pageCtxId } = getContext();

    return {
        name: PAGE_NAME,
        component: {
            name: componentName,
        },
        gig: {
            id: gigId,
        },
        user: {
            id: userId,
        },
        page: {
            ctxId: pageCtxId,
        },
        userGuid,
    };
};
