import React from 'react';
import { bool, func } from 'prop-types';
import { noop } from 'lodash';
import { I18n } from '@fiverr-private/i18n-react';
import { Button, Icon } from '@fiverr-private/fit';
import { l_arrow_right } from '@fiverr-private/icons';
import { getCheckoutCtaCopyKey } from './getCheckoutCtaCopy';

const OpenDrawerButton = ({ onClick = noop, loading = false }) => {
    const continueCopyKey = getCheckoutCtaCopyKey();

    return (
        <Button type="button" onClick={onClick} loading={loading} color={Button.COLORS.BLACK}>
            <I18n k={continueCopyKey} />
            <Icon className="rf" color={Button.COLORS.WHITE}>
                {l_arrow_right}
            </Icon>
        </Button>
    );
};

OpenDrawerButton.propTypes = {
    onClick: func,
    loading: bool,
};

export default OpenDrawerButton;
