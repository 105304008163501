import React from 'react';
import { bool, node, string } from 'prop-types';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { SELLER_COUPON_TYPES } from '../../../GigPage/constants';
import { useShowPromotionDiscountedPriceBIEvent } from '../hooks/useShowPromotionDiscountedPriceBIEvent';
import PriceTooltipIcon from '../PriceTooltipIcon';
import SubscriptionDrawerLabel from './SubscriptionDrawerLabel';
import SubscriptionPackageDescription from './SubscriptionPackageDescription';

import './style.scss';

const HeaderRecurring = ({
    title,
    showPrice,
    formattedPrice,
    description,
    showPriceTooltip,
    discountedPriceSection,
}) => {
    const { coupon } = useGigPageContext();

    useShowPromotionDiscountedPriceBIEvent(!!discountedPriceSection);

    {
        /* When the promotion is present, the SubscriptionDrawerLabel component
        is moved under the "Continue" CTA. */
    }
    const isSubscriptionLabelHidden =
        coupon &&
        coupon.type === SELLER_COUPON_TYPES.PROMOTION;

    return (
        <header className="header-recurring">
            <h3>
                {showPrice && (
                    <div className="price-wrapper">
                        {!showPriceTooltip && <span className="price">{formattedPrice}</span>}
                        {showPriceTooltip && (
                            <span className="price-with-tooltip">
                                <span className="price">{formattedPrice}</span>
                                <PriceTooltipIcon />
                            </span>
                        )}
                        {!isSubscriptionLabelHidden && <SubscriptionDrawerLabel />}
                    </div>
                )}
            </h3>
            {discountedPriceSection && <div className="m-t-4">{discountedPriceSection}</div>}
            <SubscriptionPackageDescription title={title} description={description} />
        </header>
    );
};

HeaderRecurring.propTypes = {
    title: string,
    showPrice: bool,
    showPriceTooltip: bool,
    formattedPrice: string,
    description: string,
    discountedPriceSection: node,
};

export default HeaderRecurring;
