export const VO_TYPE = 'VO';
export const WORD_COUNT = 'word_count';

export const isValidCalculator = (calculator) => {
    if (!calculator) {
        return false;
    }

    const { price, additionalValue } = calculator;

    return !!(price && additionalValue);
};

export const getVoCalculator = (calculators) => calculators.find(isWordCountCalculator);

export const isWordCountCalculator = (calculator) => calculator && calculator.name === WORD_COUNT;

export const isVoiceOverCalculator = (calculator) => calculator && calculator.type === VO_TYPE;

export const flattenCalculators = (packageList) => {
    const flatCalculators = [];

    packageList.forEach(({ id, calculators }) =>
        calculators.forEach((calculator) => flatCalculators.push({ ...calculator, packageId: id }))
    );

    return flatCalculators;
};

export const findCalculator = (calculators, calculatorId) =>
    calculators.find((calculator) => calculator.id === calculatorId);

/**
 * Selects matching calculator for a given package id from a calculators collection
 * For example for packageId = PREMIUM_PACKAGE_ID returns
 * the calculator of the premium package
 *
 * @param calculators
 * @param packageId
 */
export const findCalculatorByPackageId = (calculators, packageId) =>
    calculators && calculators.find((calculator) => calculator.packageId === packageId);
