const { omit } = require('lodash');

const XSS_WHITELIST = {
    b: [],
    br: [],
    code: [],
    div: [],
    h4: [],
    h5: [],
    h6: [],
    i: [],
    li: [],
    ol: [],
    p: [],
    small: [],
    span: [],
    sub: [],
    sup: [],
    strong: [],
    ul: [],
    u: [],
    em: [],
};

const NON_EXP_XSS_WHITELIST = omit(XSS_WHITELIST, ['u']);

const XSS_STRIP = ['script', 'a'];

module.exports = {
    XSS_WHITELIST,
    NON_EXP_XSS_WHITELIST,
    XSS_STRIP,
};
