import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { Button, Select, Option } from '@fiverr-private/fit';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { getSellerLanguageNativeName } from '@fiverr-private/localization';
import { getContext } from '@fiverr-private/fiverr_context';
import pathfinder from '@fiverr-private/pathfinder';
import getTranslationLayerEligibleLanguages from '../../../utils/getTranslationLayerEligibleLanguages';
import { BQ_LOCAL_CATALOG_MACHINE_TRANSLATION_PREVIEW_VIEWED } from '../../../utils/bigQueryEvents';
import { LOCAL_CATALOG_PREVIEW_STATES } from '../../GigPage/constants';
import Illustration from './assets/illustration.svg';

import './style.scss';

const MachineTranslationPreviewBanner = (_, { seller, general, biEvents, gigSellerTranslation }) => {
    const { pathParameters, queryParameters } = getContext();
    const { username, slug } = pathParameters;
    const { forced_locale, preview_state } = queryParameters;
    const { sellerId } = general;
    const { gigTitle, description } = gigSellerTranslation;
    useEffect(() => {
        biEvents.sendBigQueryEvent({
            eventName: BQ_LOCAL_CATALOG_MACHINE_TRANSLATION_PREVIEW_VIEWED,
            params: {
                sellerId,
                forcedLocale: forced_locale,
                status: preview_state,
                title: gigTitle,
                description,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { proficientLanguages } = seller;
    const previewLanguages = getTranslationLayerEligibleLanguages(proficientLanguages);
    const currentLanguage = previewLanguages?.find(({ name }) => name === forced_locale);

    if (!previewLanguages.length || !currentLanguage) {
        return null;
    }

    const setCurrentLanguage = (languageName) => {
        const path = pathfinder(
            'short_v4_user_gig',
            { username, slug },
            {
                query: {
                    forced_locale: languageName,
                    preview_state: LOCAL_CATALOG_PREVIEW_STATES.MACHINE_TRANSLATION_PREVIEW,
                },
            }
        );

        window.location = path;
    };

    const startTranslationLink = pathfinder(
        'edit_user_manage_gig',
        { user_id: username, id: slug },
        { query: { tab: 'additional_languages', add_tl: currentLanguage?.name } }
    );

    return (
        <div className="gig-mt-preview flex flex-between">
            <div className="flex">
                <img
                    src={Illustration}
                    alt={translate('gig_page_perseus.translation_layer_preview.machine_translation_preview.alt_text')}
                />
                <div className="preview-info">
                    <div className="m-b-8 text-bold tbody-2 co-text-grey-1200">
                        <I18n k="gig_page_perseus.translation_layer_preview.machine_translation_preview.title" />
                    </div>
                    <div className="m-b-8 co-text-grey-1000">
                        <I18n k="gig_page_perseus.translation_layer_preview.machine_translation_preview.description" />
                    </div>
                    <Select
                        defaultValue={currentLanguage.name}
                        className="text-semi-bold"
                        onChange={(e) => setCurrentLanguage(e.target.value)}
                    >
                        {previewLanguages.map(({ name }) => (
                            <Option value={name} key={name}>
                                {getSellerLanguageNativeName(name)}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="flex flex-items-center">
                <Button className="p-t-8 p-r-24 p-b-8 p-l-24" href={startTranslationLink} target="_blank">
                    <I18n k="gig_page_perseus.translation_layer_preview.machine_translation_preview.cta" />
                </Button>
            </div>
        </div>
    );
};

MachineTranslationPreviewBanner.contextTypes = {
    seller: object,
    general: object,
    biEvents: object,
    gigSellerTranslation: object,
};

export default MachineTranslationPreviewBanner;
