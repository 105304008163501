import { UPDATE_COLLECTIONS } from '../../actions/collections';

const collectionsReducer = () => {
    const initialState = [];

    return (state = initialState, action) => {
        const { type, data } = action;

        switch (type) {
            case UPDATE_COLLECTIONS:
                return data.collections;

            default:
                return state;
        }
    };
};

export default collectionsReducer;
