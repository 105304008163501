import React, { useState } from 'react';
import { noop } from 'lodash';
import { Container, Stack } from '@fiverr-private/layout_components';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { getContext } from '@fiverr-private/fiverr_context';
import { CloseButton } from '@fiverr-private/button';
import { setWasClosed } from './utils/cookies';
import { bqClickedOnProVettedBannerClose } from './utils/biEvents';
import { Content } from './Content';
import { CompactContent } from './CompactContent';

export interface ProBenefitsBannerProps {
  onClose?: () => void;
  className?: string;
}

export const ProBenefitsBanner: React.FC<ProBenefitsBannerProps> = ({ className, onClose = noop }) => {
  const [isOpen, setIsOpen] = useState(true);

  const { biEvents } = useGigPageContext();
  const bqEnrichments = biEvents.getBigQueryEnrichmentData();
  const { organizationId } = getContext();

  const onCloseClick = () => {
    setWasClosed();
    biEvents.sendRawBigQueryEvent(bqClickedOnProVettedBannerClose({ ...bqEnrichments, organizationId }));
    setIsOpen(false);
    onClose();
  };

  return isOpen ? (
    <Stack
      className={className}
      borderWidth="sm"
      borderRadius="lg"
      paddingLeft="6"
      gap="0"
      alignItems="stretch"
      justifyContent="spaceBetween"
    >
      <Content />
      <CompactContent />
      <Container width="fit-content">
        <CloseButton onClick={onCloseClick} />
      </Container>
    </Stack>
  ) : null;
};
