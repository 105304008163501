import React from 'react';
import classnames from 'classnames';
import { Stack } from '@fiverr-private/layout_components';
import HighlyResponsive from '../../HighlyResponsive/HighlyResponsive';
import { OffersHourlyRatesHighlight } from '../../OffersHourlyRates/OffersHourlyRatesHighlight';
import { Seller } from '../../../types/seller';
import { ConsultationIndicationWrapper } from '../../ContactSeller/ContactConsultationIndication/ConsultationIndicationWrapper';
import { containerClass } from './SidebarHighlights.ve.css';

interface SidebarHighlightsProps {
  shouldUseStickyLayout?: boolean;
  seller: Seller;
}

export const SidebarHighlights: React.FC<SidebarHighlightsProps> = ({ shouldUseStickyLayout, seller }) => {
  if (!shouldUseStickyLayout) {
    return null;
  }

  return (
    <Stack
      className={classnames('sidebar-highlights', containerClass)}
      width="auto"
      direction="column"
      gap="3"
      alignItems={{ default: 'center', sm: 'flexStart' }}
      marginX={{ default: '0', sm: '6' }}
    >
      <ConsultationIndicationWrapper />
      <OffersHourlyRatesHighlight seller={seller} />
      <HighlyResponsive />
    </Stack>
  );
};
