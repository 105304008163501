import { isEmpty } from 'lodash';
import { mapAttachmentFields } from './attachmentsMappers';
import { Attachment, PreparedGigSellerTranslation } from './sellerTranslationTypes';
import { mapToLocalizedPackageList } from './packagesAndCustomExtrasMappers/mapToLocalizedPackageList';

interface extractSellerTranslationUGCProps {
    gigSellerTranslation: PreparedGigSellerTranslation;
}

export const extractSellerTranslationUGC = ({ gigSellerTranslation = {} }: extractSellerTranslationUGCProps) => {
    const {
        localizedTitle,
        localizedDescription,
        localizedAttachments = [],
        localizedFaq = [],
        localizedPackages = [],
        localizedCustomExtras,
    } = gigSellerTranslation;
    const questionsAndAnswers = localizedFaq.map(({ answer, question }) => ({
        answer,
        question,
    }));
    const mappedLocalizedPackages = mapToLocalizedPackageList({
        packageList: localizedPackages,
        customExtras: localizedCustomExtras,
    });

    const mappedLocalizedAttachments = localizedAttachments
        .filter((attachment) => !isEmpty(attachment))
        .map((attachment) => ({
            slide: mapAttachmentFields(attachment as Attachment),
        }));

    return {
        attachments: mappedLocalizedAttachments,
        ...(localizedTitle && { gigTitle: localizedTitle }),
        ...(localizedDescription && { description: { content: localizedDescription } }),
        ...(!isEmpty(questionsAndAnswers) && { faq: { questionsAndAnswers } }),
        ...(!isEmpty(mappedLocalizedPackages) && { packages: mappedLocalizedPackages }),
        enabled: !!localizedTitle,
    };
};
