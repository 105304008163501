import { getContext } from '@fiverr-private/fiverr_context';
import { Carousel } from '@fiverr-private/orca';
import localeStorage from '../../utils/recentlyViewedPages/localStorage';

/**
 * @constant
 * @type {object}
 * @param {string} appName personalized section application name
 * @param {string} perseusName perseus name
 * */
export const BASE_PARAMS = {
    appName: 'gig_page',
    perseusName: 'gig_page',
};

/**
 * @constant
 * @type {object}
 * @param {string} COMPARE personalized section compare name
 * @param {string} EXPLORE personalized section explore name
 * */
export const SECTION_NAMES = {
    COMPARE: 'compare',
    EXPLORE: 'explore',
};

/**
 * Get configuration for personalized section
 * @param {string} sectionName personalized section name
 * @param {number} gigId
 * @return {object} personalized section configuration
 * */
export const getConfig = (sectionName, gigId) => {
    const { queryParameters: { ref = '' } = {} } = getContext();

    if (sectionName === SECTION_NAMES.COMPARE) {
        return {
            sectionName,
            appName: 'gig_page',
            perseusName: 'gig_page',
            recommendationsParams: {
                pageName: 'gig_page',
                funnelName: 'gig_page',
                gig: {
                    id: gigId,
                },
                context_filters: ref,
            },
            sliderBaseNum: 2,
            sliderBreakpoints: {
                [Carousel.BREAKPOINTS.BP_600]: 1,
                [Carousel.BREAKPOINTS.BP_900]: 2,
                [Carousel.BREAKPOINTS.BP_1000]: 1,
                [Carousel.BREAKPOINTS.BP_1300]: 2,
            },
        };
    }
    if (sectionName === SECTION_NAMES.EXPLORE) {
        return {
            sectionName,
            appName: 'gig_page',
            perseusName: 'gig_page',
            recommendationsParams: {
                pageName: 'gig_page',
                funnelName: 'gig_page',
                gig: {
                    id: gigId,
                },
                context_filters: ref,
                browse_history: localeStorage.getGigIds(gigId),
            },
            sliderBaseNum: 5,
            sliderBreakpoints: {
                [Carousel.BREAKPOINTS.BP_600]: 1,
                [Carousel.BREAKPOINTS.BP_750]: 2,
                [Carousel.BREAKPOINTS.BP_900]: 2,
                [Carousel.BREAKPOINTS.BP_1000]: 3,
                [Carousel.BREAKPOINTS.BP_1160]: 3,
                [Carousel.BREAKPOINTS.BP_1300]: 4,
            },
        };
    }

    return {
        appName: 'gig_page',
        perseusName: 'gig_page',
    };
};
