import React, { Component } from 'react';
import { func, number, object, string } from 'prop-types';
import { connect } from 'react-redux';
import { noop } from 'lodash';
import classNames from 'classnames';
import { getLabel } from '../../../../utils/translation/pricingFactors/label';
import { getBuyerTooltip } from '../../../../utils/translation/pricingFactors/buyerTooltip';
import { FEATURE_TYPE } from '../../../../utils/packagesUtils/constants';
import { getCurrentPackage } from '../../../../reducers/packages';
import { PackageExtraCheckbox } from '../PackageExtraCheckbox';
import Quantity from '../../Quantity';
import { calcCurrentExtraPrice } from '../../../../utils/packagesUtils/calcCurrentExtraPrice';
import { hideQuantityForExtra } from './utils';
import { dispatchOnExtraChange } from './actions';

import './style.scss';

class PackageExtra extends Component {
    constructor(props) {
        super(props);

        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onCheckboxChange({ target }) {
        this.onChange(target.checked ? 1 : 0);
    }

    onChange(amount) {
        const { type, id, packageId, onExtraChange } = this.props;

        onExtraChange({
            type,
            id,
            packageId,
            amount,
        });
    }

    isChecked() {
        const { amount } = this.props;

        return amount >= 1;
    }

    calculateLabel() {
        const { name, label } = this.props;
        const { subCategoryId } = this.context.general;

        return this.shouldTranslateExtra() ? getLabel({ name, subCategoryId }) : label;
    }

    calculateBuyerTooltip() {
        const { name, buyerTooltip, value } = this.props;
        const { subCategoryId } = this.context.general;

        return this.shouldTranslateExtra()
            ? getBuyerTooltip({ name, subCategoryId, replacements: { count: value } })
            : buyerTooltip;
    }

    shouldTranslateExtra() {
        const { type } = this.props;
        return type === FEATURE_TYPE;
    }

    render() {
        const { label, name, amount, type, currentDuration, packageData } = this.props;

        const currentPrice = calcCurrentExtraPrice(packageData, this.props);

        const { currencyConverter } = this.context;

        if (!currentPrice || !label) {
            return null;
        }

        const checked = this.isChecked();
        const hasQuantity = !hideQuantityForExtra(name);

        return (
            <li className={classNames({ checked })}>
                {
                    // This div is needed because in the single package component we have different
                    // padding for it and since we're using a transition on max-height we don't want
                    // the padding to be part of the transition
                }
                <div className="package-extra">
                    <PackageExtraCheckbox
                        checked={checked}
                        label={this.calculateLabel()}
                        type={type}
                        tooltipContent={this.calculateBuyerTooltip()}
                        onChange={this.onCheckboxChange}
                        currentDuration={currentDuration}
                    />
                    {hasQuantity ? (
                        <Quantity quantity={amount || 1} price={currentPrice} onQuantityChange={this.onChange} />
                    ) : (
                        <p>{currencyConverter.convert(currentPrice)}</p>
                    )}
                </div>
            </li>
        );
    }
}

PackageExtra.propTypes = {
    id: number,
    name: string,
    label: string,
    buyerTooltip: string,
    value: number,
    amount: number,
    onExtraChange: func,
    currentDuration: number,
    type: string,
    packageId: number,
    packageData: object,
};

PackageExtra.defaultProps = {
    onExtraChange: noop,
};

PackageExtra.contextTypes = {
    currencyConverter: object,
    general: object,
};

export { PackageExtra };

const mapStateToProps = (state) => ({
    packageData: getCurrentPackage(state),
});

export default connect(mapStateToProps, {
    onExtraChange: dispatchOnExtraChange,
})(PackageExtra);
