import { openFipMigrationModal, FLOW_CONTEXT_TYPES, ModalType } from '@fiverr-private/pro_migration';
import { BUSINESS_TRIGGER_CTA, CtaData } from '@fiverr-private/business_success_sign_modal';
import { getContext } from '@fiverr-private/fiverr_context';
import { PAGE_NAME } from '../events';

const MAP_FLOW_CONTEXT: Partial<Record<FLOW_CONTEXT_TYPES, BUSINESS_TRIGGER_CTA>> = {
    purchase: BUSINESS_TRIGGER_CTA.PURCHASE,
    contact: BUSINESS_TRIGGER_CTA.CONTACT,
    request_a_quote: BUSINESS_TRIGGER_CTA.REQUEST_QUOTE,
};

const prepareRedirectCtaData = (trigger: FLOW_CONTEXT_TYPES, triggerSource?: string): CtaData | undefined => {
    const cta = MAP_FLOW_CONTEXT[trigger];
    return cta ? { type: cta, source: triggerSource } : undefined;
};

export const triggerFibMigrationPopup = ({
    trigger,
    triggerSource,
    flowCallback,
    sellerDisplayName,
}: {
    trigger: FLOW_CONTEXT_TYPES;
    triggerSource?: string;
    sellerDisplayName: string;
    flowCallback: () => void;
}) => {
    const { pathParameters } = getContext();
    const sellerName = sellerDisplayName || pathParameters.username;

    openFipMigrationModal({
        variant: ModalType.FullMigration,
        payload: {
            source: `${PAGE_NAME}.${trigger}`,
            biEnrichment: {
                pageElementName: PAGE_NAME,
                trigger,
            },
            flowContext: {
                type: trigger,
                sellerName,
            },
            redirectCtaData: prepareRedirectCtaData(trigger, triggerSource),
            onSuccess: flowCallback,
        },
        callbackWhenNotMounted: flowCallback,
    });
};
