import { get } from 'lodash';
import uaParser from 'ua-parser-js';
import { getContext } from '@fiverr-private/fiverr_context';
import { UNKNOWN } from './constants';

class BrowserDetector {
    init() {
        const { userAgent } = getContext();

        this.userAgent = uaParser(userAgent);
    }

    getBrowser() {
        if (!this.userAgent) {
            return UNKNOWN;
        }

        const browserName = get(this.userAgent, 'browser.name', UNKNOWN);

        return browserName.toLowerCase();
    }
}

export default new BrowserDetector();
