import React from 'react';
import { workProcessStepsType } from '../../propTypes';

const MilestoneCollapsedSteps = ({ steps }) => {
    const stepsTitles = steps.map(({ title }) => title);
    return <div className="tbody-7 step-container">{stepsTitles.join(' · ')}</div>;
};

MilestoneCollapsedSteps.propTypes = {
    steps: workProcessStepsType.isRequired,
};

export default MilestoneCollapsedSteps;
