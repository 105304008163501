import React, { useEffect, useState } from 'react';
import MediaQuery from 'react-responsive';
import { object, number } from 'prop-types';
import { EducationTooltip } from '@fiverr-private/orca';
import { I18n } from '@fiverr-private/i18n-react';
import { Tooltip } from '@fiverr-private/fit';
import { onGuestClick } from '../../../../../utils/contactSeller';
import { isLoggedInUser } from '../../../../../utils/isLoggedInUser';
import { MEDIA_GRID_MD } from '../../../../../utils/constants';
import { metric } from '../../../../../utils/metric';
import {
    BQ_REQUEST_TO_ORDER_BUTTON_CLICKED,
    BQ_REQUEST_TO_ORDER_BUTTON_IS_SHOWN,
    BQ_REQUEST_TO_ORDER_ED_TOOLTIP_IS_SHOWN,
} from '../../../../../utils/bigQueryEvents';
import {
    MP_REQUEST_TO_ORDER_BUTTON_CLICKED,
    MP_REQUEST_TO_ORDER_BUTTON_IS_SHOWN,
} from '../../../../../utils/mixPanelEvents';
import { TRANSLATION_PREFIX, EDUCATIONAL_TOOLTIP_STORAGE_KEY } from './constants';
import { RequestToOrderCtaButton } from './CtaButton';
import RequestToOrderPopup from './RequestToOrderPopup';

import classes from './styles.module.scss';

export const RequestToOrderBtn = ({ packageId }, { biEvents, pathfinderWrapper }) => {
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [isEduTooltipActive, setIsEduTooltipActive] = useState(true);

    useEffect(() => {
        metric.count('reuqest_to_order_button.shown');

        biEvents.sendBigQueryEvent({
            eventName: BQ_REQUEST_TO_ORDER_BUTTON_IS_SHOWN,
        });
        biEvents.sendMixPanelEvent({
            eventName: MP_REQUEST_TO_ORDER_BUTTON_IS_SHOWN,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const triggerEducationTooltip = (isActive) => {
        setIsEduTooltipActive(isActive);

        if (!isActive) {
            return;
        }

        biEvents.sendBigQueryEvent({
            eventName: BQ_REQUEST_TO_ORDER_ED_TOOLTIP_IS_SHOWN,
        });
    };

    const handleCtaClick = () => {
        metric.count('reuqest_to_order_button.clicked');

        if (!isLoggedInUser()) {
            onGuestClick({ biEvents, pathfinderWrapper, modalOptions: { source: 'gig_page_request' } });

            return;
        }

        setIsModalOpened(true);

        biEvents.sendBigQueryEvent({
            eventName: BQ_REQUEST_TO_ORDER_BUTTON_CLICKED,
        });
        biEvents.sendMixPanelEvent({
            eventName: MP_REQUEST_TO_ORDER_BUTTON_CLICKED,
        });
    };

    return (
        <>
            <RequestToOrderPopup
                shouldRender={isModalOpened}
                bqSourceName="request_to_order_modal"
                modalType="requestToOrder"
                packageId={packageId}
                onClose={() => setIsModalOpened(false)}
            />
            <MediaQuery query={`(min-width: ${MEDIA_GRID_MD})`}>
                {(matches) => {
                    const tooltipPosition = matches ? EducationTooltip.POSITIONS.LEFT : EducationTooltip.POSITIONS.TOP;
                    return isEduTooltipActive ? (
                        <EducationTooltip
                            title={<I18n k={`${TRANSLATION_PREFIX}.education.title`} />}
                            onActiveStateChanged={triggerEducationTooltip}
                            body={<I18n k={`${TRANSLATION_PREFIX}.education.body`} />}
                            closeTitle={<I18n k={`${TRANSLATION_PREFIX}.education.cta`} />}
                            appendToBody={false}
                            tooltipStorageKey={EDUCATIONAL_TOOLTIP_STORAGE_KEY}
                            className={classes.ctaEducationTooltip}
                            contentClassName={classes.ctaEducationTooltipContent}
                            boxContentClassName={classes.ctaEducationTooltipBoxContent}
                            position={tooltipPosition}
                        >
                            <RequestToOrderCtaButton onClick={handleCtaClick} />
                        </EducationTooltip>
                    ) : (
                        <Tooltip
                            content={<I18n k={`${TRANSLATION_PREFIX}.education.body`} />}
                            className={classes.ctaTooltip}
                            position={tooltipPosition}
                            contentClassName={classes.ctaTooltipContent}
                            boxContentClassName={classes.ctaTooltipBoxContent}
                        >
                            <RequestToOrderCtaButton onClick={handleCtaClick} />
                        </Tooltip>
                    );
                }}
            </MediaQuery>
        </>
    );
};

RequestToOrderBtn.propTypes = {
    packageId: number,
};

RequestToOrderBtn.contextTypes = {
    biEvents: object,
};

export default RequestToOrderBtn;
