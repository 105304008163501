import { merge, snakeCase } from 'lodash';
import { BASE_PARAMS } from '../constants';

export const createImpressionEvent =
    (type, { page, ...params } = {}) =>
    ({ userId, pageCtxId, pageName, elementType, elementName, ...enrichmentParams } = {}) =>
        merge({}, BASE_PARAMS, params, enrichmentParams, {
            type,
            event_source: snakeCase(pageName),
            user: { id: userId },
            page: merge({}, page, {
                ctx_id: pageCtxId,
                name: pageName,
                ...(elementType && {
                    element: {
                        type: elementType,
                        name: elementName,
                    },
                }),
            }),
        });
