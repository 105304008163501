import React from 'react';
import { bool, node, object } from 'prop-types';
import { connect } from 'react-redux';

import { getContext } from '@fiverr-private/fiverr_context';
import { isTranslated } from '../../../utils/ugc/isTranslated';
import { isFieldTranslatedBySeller } from '../../../utils/ugc/isFieldTranslatedBySeller';
import SellerTranslationBadge from '../SellerTranslationBadge';

const SellerTranslationBadgeWrapper = (
    { children, isTranslationApproved, isFieldFromSellerTranslations },
    { gigSellerTranslation, general = {} }
) => {
    const { sellerId } = general;
    const { userId } = getContext();
    const isPageOwner = sellerId === userId;
    const isSellerTranslationEnabled = gigSellerTranslation?.isEnabled && isTranslationApproved;
    const isFieldMissingFromSellerTranslation =
        isPageOwner && isSellerTranslationEnabled && !isFieldFromSellerTranslations;

    return (
        <>
            {children}
            {isFieldMissingFromSellerTranslation && <SellerTranslationBadge />}
        </>
    );
};

SellerTranslationBadgeWrapper.propTypes = {
    isTranslationApproved: bool.isRequired,
    isFieldFromSellerTranslations: bool.isRequired,
    children: node.isRequired,
};

SellerTranslationBadgeWrapper.contextTypes = {
    gigSellerTranslation: object,
    general: object,
};

const mapStateToProps = ({ ugc }, { translationKey }) => {
    const isFieldFromSellerTranslations = translationKey ? isFieldTranslatedBySeller(ugc, translationKey) : false;

    return {
        isTranslationApproved: isTranslated(ugc),
        isFieldFromSellerTranslations,
    };
};

export default connect(mapStateToProps)(SellerTranslationBadgeWrapper);
