import { GIG_PACKAGE_TYPE } from '../../../../../utils/requestToOrder';
import {
    BASIC_PACKAGE_ID,
    STANDARD_PACKAGE_ID,
    PREMIUM_PACKAGE_ID,
} from '../../../../../utils/packagesUtils/constants';

export const TRANSLATION_PREFIX = 'gig_page_perseus.request_to_order';
export const EDUCATIONAL_TOOLTIP_STORAGE_KEY = 'request_to_order_education_tooltip';
export const REQUEST_TO_ORDER_AUDIENCE = {
    AUDIENCE_FIRST_TIME_BUYERS: 'AUDIENCE_FIRST_TIME_BUYERS',
    AUDIENCE_ALL: 'AUDIENCE_ALL',
};

export const PACKAGE_ID_TO_TYPE = {
    [BASIC_PACKAGE_ID]: GIG_PACKAGE_TYPE.BASIC,
    [STANDARD_PACKAGE_ID]: GIG_PACKAGE_TYPE.STANDARD,
    [PREMIUM_PACKAGE_ID]: GIG_PACKAGE_TYPE.PREMIUM,
};
