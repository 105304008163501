import React from 'react';
import { string, bool, number, func } from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import { l_chevron_down, l_chevron_up } from '@fiverr-private/icons';
import { Icon } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { translate } from '../../../../utils/translation';
import { getRevisionsCount } from '../../../../utils/workProcess';
import { CHEVRON_ICON_SIZE } from '../../constants';
import MilestonePrice from '../MilestonePrice';

import './index.scss';

const MilestoneHeader = ({
    title,
    duration,
    revisions,
    expandedSteps,
    priceInUsd,
    milestoneNumber,
    hasSteps,
    onStepsToggle = noop,
}) => {
    const onClick = hasSteps ? onStepsToggle : noop;
    const showMilestoneSubtitle = !!priceInUsd;
    const chevronIcon = expandedSteps ? l_chevron_up : l_chevron_down;

    const milestoneTitleKey = `gig_page_perseus.workProcess.milestone.title.${milestoneNumber}`;
    const milestoneTitle = priceInUsd ? `${translate(milestoneTitleKey)}: ${title}` : title;
    const count = getRevisionsCount(revisions);

    const wrapperClassName = classNames('flex', 'flex-justify-between', 'milestone-header');
    const milestoneTitleClassName = classNames('tbody-5', 'flex', 'flex-items-center', 'milestone-title', {
        clickable: hasSteps,
    });

    return (
        <div className={wrapperClassName}>
            <div className="m-b-8">
                <strong className={milestoneTitleClassName} onClick={onClick}>
                    <span>{milestoneTitle}</span>
                    {hasSteps && (
                        <Icon className="expand-steps-icon" size={CHEVRON_ICON_SIZE}>
                            {chevronIcon}
                        </Icon>
                    )}
                </strong>
                {showMilestoneSubtitle && (
                    <div className="milestone-subtitle">
                        <I18n k={'gig_page_perseus.workProcess.milestone.subtitle.delivery'} params={{ duration }} />
                        &nbsp;·&nbsp;
                        <I18n k={'gig_page_perseus.workProcess.milestone.subtitle.revisions'} params={{ count }} />
                    </div>
                )}
            </div>
            <MilestonePrice priceInUsd={priceInUsd} milestoneNumber={milestoneNumber} />
        </div>
    );
};

MilestoneHeader.propTypes = {
    title: string.isRequired,
    duration: number.isRequired,
    revisions: number.isRequired,
    expandedSteps: bool,
    priceInUsd: number,
    milestoneNumber: number.isRequired,
    hasSteps: bool,
    onStepsToggle: func,
};

export default MilestoneHeader;
