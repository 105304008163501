import React from 'react';
import { number } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { r_milestones_flag } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { WORK_PROCESS } from '../../../../../utils/pageSections';
import { selectFirstMilestonePrice } from '../../../../../reducers/workProcess';

import classes from './styles.module.scss';

const MilestoneFooterInfo = ({ firstMilestonePrice }) => {
    const { currencyConverter, sectionScroller } = useGigPageContext();
    const price = currencyConverter.convert(firstMilestonePrice);
    const onTitleClick = () => sectionScroller.scroll({ sectionKey: WORK_PROCESS });

    return (
        <div className={classNames('m-t-20', 'flex', 'flex-items-center')}>
            <Icon size={32} className={classes.milestoneFlagLogo}>
                {r_milestones_flag}
            </Icon>
            <div className="flex-col m-l-16">
                <div className={classNames('flex', 'flex-items-center')}>
                    <span onClick={onTitleClick} className={classNames('m-r-8', classes.milestonePaymentTitle)}>
                        <I18n k={'gig_page_perseus.workProcess.sidebar.payment_available'} />
                    </span>
                </div>
                <span className="milestone-payment-subtitle">
                    <I18n k={'gig_page_perseus.workProcess.sidebar.pay_per_milestone'} params={{ price }} />
                </span>
            </div>
        </div>
    );
};

MilestoneFooterInfo.propTypes = {
    firstMilestonePrice: number,
};

export { MilestoneFooterInfo };

const mapStateToProps = (state) => ({
    firstMilestonePrice: selectFirstMilestonePrice(state),
});

export default connect(mapStateToProps)(MilestoneFooterInfo);
