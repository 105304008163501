export const GIG_PAGE = 'gig_page';
export const GIG_PAGE_REVIEWS_CLASS = 'gig-page-reviews';
export const REVIEWS_COPIES_PREFIX = 'gig_page_perseus.reviews';

export const REVIEWS_TYPES = {
    ONLY_GIG_REVIEWS: 'only_gig_reviews',
    ONLY_SELLER_REVIEWS: 'only_seller_reviews',
    GIG_REVIEWS_SECTION: 'gig_reviews_section',
    SELLER_REVIEWS_SECTION: 'seller_reviews_section',
};
