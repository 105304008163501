import { getContext } from '@fiverr-private/fiverr_context';
import { General } from '../interfaces/general';

/**
 * Checks if the current user is the owner of the gig
 */
export const isPageOwner = (general: General): boolean => {
    const { sellerId } = general;
    const { userId } = getContext();

    return sellerId === userId;
};
