import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Alert } from '@fiverr-private/orca';
import SafePureComponent from '../shared/SafePureComponent';

import './style.scss';

const DelayWarning = () => (
    <>
        <div className="delay-warning">
            <Alert text={translate('gig_page_perseus.delayed_gig.disclaimer')} theme="warning" />
        </div>
    </>
);

export { DelayWarning };
export default SafePureComponent(DelayWarning);
