import React, { useState } from 'react';
import { bool, object, string, node, array } from 'prop-types';
import classNames from 'classnames';
import { LazyComponent } from '@fiverr-private/orca';
import { LoadingGigCardCarousel } from '@fiverr-private/gig_listings';
import { getContext } from '@fiverr-private/fiverr_context';
import { CURRENCY_CODES } from '@fiverr-private/localization';
import { GigCardCarouselPropTypes } from '@fiverr-private/gig_listings/src/GigCardListings/types/types';
import { metric } from '../../../utils/metric';
import GigCardCarouselHOC from '../GigCardCarouselHOC';
import { LAZY_LOAD_ERROR_PREFIX } from '../../../utils/constants';

const GigCardCarouselWrapper = (
    {
        boxClass,
        showTranslatedUGC = false,
        gigs = [],
        isLoading = true,
        contextEnrichment = {},
        sliderBaseNum,
        sliderBreakpoints,
        skeletonBreakpoints,
        children,
    },
    { logger }
) => {
    const [isLazyImportError, setLazyImportError] = useState(false);
    const boxClasses = classNames('gig-card-carousel-wrapper', boxClass);
    const { currency } = getContext();

    const lazyImport = () => import(/* webpackChunkName: 'GigCardCarousel' */ '../GigCardCarousel');

    const onLazyImportError = (error) => {
        metric.count(`${LAZY_LOAD_ERROR_PREFIX}.gig_card_carousel.failure`);
        logger.warn(error, { description: 'Failed to lazy load GigCardCarousel.' });
        setLazyImportError(true);
    };

    const componentProps = {
        gigs,
        sliderBaseNum,
        sliderBreakpoints,
        isLoading,
        showTranslatedUGC,
        features: {
            priceFormatting: true,
            useForcePriceRounding: currency !== CURRENCY_CODES.USD,
        },
        lazyLoad: true,
        isExperiential: true,
        ...contextEnrichment,
    };

    if (isLazyImportError) {
        return null;
    }

    return (
        <div className={boxClasses} data-testid="gig-card-carousel-wrapper">
            {children}
            <LazyComponent
                componentProps={componentProps}
                lazyImport={lazyImport}
                placeholder={
                    <LoadingGigCardCarousel
                        slidesToShow={sliderBaseNum}
                        sliderBreakpoints={skeletonBreakpoints || sliderBreakpoints}
                    />
                }
                onError={onLazyImportError}
            />
        </div>
    );
};

GigCardCarouselWrapper.propTypes = {
    boxClass: string,
    sliderBaseNum: GigCardCarouselPropTypes.sliderBaseNum,
    sliderBreakpoints: GigCardCarouselPropTypes.sliderBreakpoints,
    children: node,
    showTranslatedUGC: bool,
    gigs: array,
    isLoading: bool,
    contextEnrichment: object,

    // REMOVE ONCE GigCardCarousel is updated from Slider to Carousel component
    // Currently LoadingGigCardCarousel is using newer version that has different breakpoints structure.
    skeletonBreakpoints: object,
};

GigCardCarouselWrapper.contextTypes = {
    logger: object,
};

export { GigCardCarouselWrapper };
export default GigCardCarouselHOC(GigCardCarouselWrapper);
