import { isFeatureFiltered } from '../../../../../utils/packagesUtils';
import { isVoiceOverCalculator } from '../../../../../utils/calculator';

export const SCRIPT_PROOFREADING = 'script_proofreading';
export const ADDITIONAL_MODIFICATIONS = 'additional_modifications';

/**
 * Extract pricing factor that are available as an upgrade at checkout.
 * Available pricing factor is a pricing factor with non-zero price
 * that is not already included in the package.
 *
 * When the package is Customized -
 * the user searched for a feature that is not included in the package -
 * and chose the gig -
 * the Filtered Factors are Included in the package.
 * And there will be a [Customized for You] banner.
 *
 * Since they are not actually part of the package - we want to give the user the ability to un-select them.
 * This is the current behavior in the Checkout Page - where the Feature is pre-selected,
 * and the user can un-select it to reduce the package price.
 *
 * @param {Feature[]} features - Package's features (pricing factors)
 * @return {Feature[]}
 */
const getNotIncludedFeaturesWithPrice = (features = []) =>
    features.filter((feature) => {
        const { included, price } = feature;

        return price > 0 && (!included || isFeatureFiltered(feature));
    });

/**
 * Get all the custom extras that are not included in the package.
 *
 * @param {CustomExtra[]} customExtras
 * @param {Feature[]} features
 * @return {CustomExtra[]}
 */
const getCustomExtras = ({ customExtras = [], features = [] }) => {
    const featureIds = features.map((feature) => feature.id);

    return customExtras.filter(
        ({ id, label, price, buyerTooltip }) => !!label && !!price && !!buyerTooltip && !featureIds.includes(id)
    );
};

/**
 * Get Extra Fast for the Package.
 *
 * @param {ExtraFast} extraFast
 * @return {ExtraFast | undefined}
 */
const getExtraFast = ({ extraFast = {}, calculators }) => {
    if (!extraFast?.price || !extraFast?.duration) {
        return null;
    }

    const voCalculator = calculators.find(isVoiceOverCalculator);
    const scriptLength = voCalculator?.currentValue;

    if (!scriptLength) {
        return extraFast;
    }

    const currentDuration = Math.ceil(scriptLength / extraFast.value);
    return {
        ...extraFast,
        price: extraFast.price * currentDuration,
        currentDuration,
    };
};

/**
 * Get list of upgrade items.
 *
 * @param {GigPackage} gigPackage
 * @return {{notIncludedFeatures: Feature[], customExtras: CustomExtra[], extraFast: (ExtraFast|{})}}
 */
export const getExtras = (gigPackage) => {
    const unsortedNotIncludedFeatures = getNotIncludedFeaturesWithPrice(gigPackage.features);
    const additionalModificationsFeature = unsortedNotIncludedFeatures.filter(
        (feature) => feature.name === ADDITIONAL_MODIFICATIONS
    );
    const scriptProofreadingFeature = unsortedNotIncludedFeatures.filter(
        (feature) => feature.name === SCRIPT_PROOFREADING
    );
    const filteredNotIncludedFeatures = unsortedNotIncludedFeatures
        .filter((feature) => ![ADDITIONAL_MODIFICATIONS, SCRIPT_PROOFREADING].includes(feature.name))
        .sort((prev, next) => prev.id - next.id);
    const notIncludedFeatures = [
        ...scriptProofreadingFeature,
        ...additionalModificationsFeature,
        ...filteredNotIncludedFeatures,
    ];
    const customExtras = getCustomExtras(gigPackage);
    const extraFast = getExtraFast(gigPackage);

    return { notIncludedFeatures, customExtras, extraFast };
};

/**
 * Count Available PricingFactors.
 * @param {GigPackage} gigPackage
 * @return {number} count
 */
export const countAvailablePricingFactors = (gigPackage) => {
    const { notIncludedFeatures = [], customExtras = [], extraFast } = getExtras(gigPackage);
    const extraFastCount = extraFast ? 1 : 0;

    return notIncludedFeatures.length + customExtras.length + extraFastCount;
};

/**
 * Count Selected PricingFactors.
 * @param {GigPackage} gigPackage
 * @return {number} count
 */
export const countSelectedPricingFactors = (gigPackage) => {
    const { notIncludedFeatures = [], customExtras = [], extraFast } = getExtras(gigPackage);
    const extraFastCount = extraFast?.currentValue > 0;
    const notIncludedFeaturesCount = notIncludedFeatures.filter((feat) => feat?.amount > 0).length;
    const customExtrasCount = customExtras.filter((feat) => feat?.amount > 0).length;

    return notIncludedFeaturesCount + customExtrasCount + extraFastCount;
};
