import React, { useState } from 'react';
import { object, bool } from 'prop-types';
import { connect } from 'react-redux';
import { delay, isEmpty } from 'lodash';
import fetchReviewAsync from '../../../../../utils/fetchReviewAsync';
import { translateUGC } from '../../../../../utils/ugc/translate';
import { isTranslated } from '../../../../../utils/ugc/isTranslated';
import { buildSourceInfo } from '../../../../../utils/ugc/buildSourceInfo';

const GALLERY_REVIEW_SOURCE = 'gallery_review';

const SlideHOCWrapper = (WrappedComponent) => {
    const SlideHOC = (props, context) => {
        const { slide, showTranslatedUGC } = props;
        const { pathfinderWrapper, general } = context;
        const { gigId } = general;

        const [review, setReview] = useState(null);
        const [isShowingReview, setIsShowingReview] = useState(false);
        const [timerId, setTimerId] = useState(null);

        const showReview = async (hide = false) => {
            const { isWorkSample } = slide;
            if (!isWorkSample) {
                return;
            }

            const review = await getReview();
            if (isEmpty(review)) {
                return;
            }

            clearTimeout(timerId);
            if (hide) {
                setTimerId(delay(hideReview, 4000));
            }
            setIsShowingReview(true);
        };

        const getReview = async () => {
            const { orderId } = slide;
            let orderReview = review;

            if (!orderReview) {
                orderReview = await fetchReviewAsync({ orderId, pathfinderWrapper });
                if (showTranslatedUGC) {
                    const sourceInfo = buildSourceInfo({ componentName: GALLERY_REVIEW_SOURCE, gigId });
                    try {
                        orderReview.translatedReview = await translateUGC({
                            content: orderReview.text,
                            sourceInfo,
                        });
                        // eslint-disable-next-line no-empty
                    } catch (ignore) {}
                }
                setReview(orderReview);
            }

            return orderReview;
        };

        const hideReview = () => {
            clearTimeout(timerId);
            setIsShowingReview(false);
        };

        const flashReview = () => {
            hideReview();
            setTimerId(delay(showReview, 200, true));
        };

        const toggleReview = async () => {
            if (isShowingReview) {
                hideReview();
            } else {
                await showReview();
            }
        };

        const formattedReview = showTranslatedUGC && review ? { ...review, text: review.translatedReview } : review;

        return (
            <WrappedComponent
                {...props}
                flashReview={flashReview}
                toggleReview={toggleReview}
                hideReview={hideReview}
                review={formattedReview}
                isShowingReview={isShowingReview}
            />
        );
    };

    SlideHOC.propTypes = {
        slide: object,
        showTranslatedUGC: bool,
    };

    SlideHOC.defaultProps = {
        slide: {},
    };

    SlideHOC.contextTypes = {
        pathfinderWrapper: object,
        general: object,
    };

    const mapStateToProps = ({ ugc }) => {
        const showTranslatedUGC = isTranslated(ugc);

        return {
            showTranslatedUGC,
        };
    };

    return connect(mapStateToProps)(SlideHOC);
};

export default SlideHOCWrapper;
