export const TOGGLE_LIST = 'TOGGLE_LIST';
export const toggleList = (id) => ({
    type: TOGGLE_LIST,
    data: {
        id,
    },
});

export const TOGGLE_ALL = 'TOGGLE_ALL';
export const toggleAll = () => ({
    type: TOGGLE_ALL,
    data: {},
});
