import React from 'react';
import { Stack } from '@fiverr-private/layout_components';
import { AgencyPeopleIcon } from '@fiverr-private/icons';
import { Text } from '@fiverr-private/typography';
import { theme } from '@fiverr-private/theme';
import { translate } from '@fiverr-private/i18n-react';

interface AgencyLogoParams {
  labelSize?: any;
}

export const AgencyLogo = ({ labelSize = 'b_md' }: AgencyLogoParams) => {
  const agencyLabel = translate('gig_page_perseus.general.agency');

  return (
    <Stack width="auto" alignItems="center" gap="2">
      <AgencyPeopleIcon size={20} color={theme.colors.grey_1200} />
      <Text size={labelSize} fontWeight="semibold">
        {agencyLabel}
      </Text>
    </Stack>
  );
};
