import { translate } from '@fiverr-private/i18n-react';
import { getShortLanguageCode } from '@fiverr-private/localization';

/**
 * @desc make a string of comma separated languages names
 * @param {Array<object>} languages - array of languages data object consisting of names, codes etc
 * @returns {string} - the language string
 */

export const stringifyToLanguageCodes = (languages = []) =>
    languages.map((language) => translate(`languages.codes.${getShortLanguageCode(language.name?.toLowerCase())}`)).join(', ');
