import { UPDATE_SHOW_FIRST_MESSAGE_POPUP } from '../../actions/widgets';

const initialState = {
    showFirstMessagePopup: false,
};

const widgetsReducer =
    () =>
    (state = initialState, action) => {
        const { type, data } = action;

        switch (type) {
            case UPDATE_SHOW_FIRST_MESSAGE_POPUP:
                return { showFirstMessagePopup: data.showFirstMessagePopup };

            default:
                return state;
        }
    };

// Selectors

export const selectShowFirstMessagePopup = ({ widgets }) => widgets.showFirstMessagePopup;

export default widgetsReducer;
