import React from 'react';
import { bool } from 'prop-types';
import { connect } from 'react-redux';
import SafePureComponent from '../shared/SafePureComponent';
import { isTriplePackage } from '../../reducers/packages';
import SinglePackage from './SinglePackage';
import TriplePackage from './TriplePackage';

import './style.scss';

const PackagesTabs = ({ shouldRender = true, isTriplePackage }) => {
    if (!shouldRender) {
        return null;
    }

    return isTriplePackage ? <TriplePackage /> : <SinglePackage />;
};

PackagesTabs.propTypes = {
    shouldRender: bool,
    isTriplePackage: bool,
};

const mapStateToProps = ({ packages }) => ({
    shouldRender: packages.packageList.length > 0,
    isTriplePackage: isTriplePackage(packages),
});

export { PackagesTabs };
export default SafePureComponent(connect(mapStateToProps)(PackagesTabs));
