import React from 'react';
import { string, number, func } from 'prop-types';
import { noop } from 'lodash';
import classNames from 'classnames';
import { Rating } from '@fiverr-private/orca';
import { useGigPageContext } from '@fiverr-private/gig_page_context';

import classes from './styles.module.scss';
import { calcRatingColor } from './utils';

const RatingWrapper = ({ className, rating = 0, ratingsCount, onClick = noop, theme = Rating.THEMES.FIVE_STARS, themeOverride }) => {
    const {
        general: { isPro },
        isBusinessLayout,
        isProRebrandingLayout,
    } = useGigPageContext();

    const ratingTheme = getTheme({ isBusinessLayout, isProRebrandingLayout, theme, themeOverride });

    const containerClasses = classNames(classes.container, className);
    const ratingsCountClasses = classNames({
        [classes.ratingsCount]: isProRebrandingLayout,
    });

    return (
        <Rating
            className={containerClasses}
            ratingsCountClassName={ratingsCountClasses}
            theme={ratingTheme}
            score={rating}
            color={calcRatingColor({ isBusinessLayout, isProRebrandingLayout, isPro })}
            ratingsCount={ratingsCount}
            onClick={onClick}
            scoreMinimumFractionDigits={1}
            scoreMaximumFractionDigits={1}
        />
    );
};

RatingWrapper.propTypes = {
    className: string,
    rating: number,
    ratingsCount: number,
    theme: string,
    /**
     * This is a temporary solution to allow overriding the theme of the rating component until it's properly refactored.
     */
    themeOverride: string,
    onClick: func,
};

const getTheme = ({ isBusinessLayout, isProRebrandingLayout, theme, themeOverride }) => {
    if (themeOverride) return themeOverride;
    if (isBusinessLayout || isProRebrandingLayout) return Rating.THEMES.ONE_STAR;
    return theme;
};

export default RatingWrapper;
