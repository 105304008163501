import { createImpressionEvent } from '../base';
import { MACHINE_TRANSLATE_PROMOTION_PARAMS } from '../constants';

/**
 * BI impression event for the Machine translate promotion component.
 * @param {string} type The event's type.
 * @returns {object} parameters for the event.
 */
export const createMachineTranslatePromotion = (type) =>
    createImpressionEvent(type, MACHINE_TRANSLATE_PROMOTION_PARAMS);
