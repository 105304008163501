import React from 'react';
import { connect } from 'react-redux';
import { bool } from 'prop-types';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { Icon } from '@fiverr-private/fit';
import { r_circle } from '@fiverr-private/icons';
import { useGigPageContext } from '@fiverr-private/gig_page_context';

import './style.scss';

const ONLINE_INDICATION_SIZE = 4;

export const Online = () => (
    <div className={classNames('status', 'online-indicator')}>
        <Icon className="circle-icon" size={ONLINE_INDICATION_SIZE}>
            {r_circle}
        </Icon>
        <I18n k={'gig_page_perseus.general.online'} />
    </div>
);

export const Vacation = () => (
    <div className={classNames('status', 'ooo-indicator')}>
        <I18n k={'gig_page_perseus.seller_card.unavailable'} />
    </div>
);

const Status = ({ isOnline = false }) => {
    const {
        general: { isOnVacation },
    } = useGigPageContext();

    if (isOnVacation) {
        return <Vacation />;
    } else if (isOnline) {
        return <Online />;
    } else {
        return null;
    }
};

Status.propTypes = {
    isOnline: bool,
};

const mapStateToProps = ({ seller: { isOnline } = {} }) => ({
    isOnline,
});

export { Status };
export default connect(mapStateToProps)(Status);
