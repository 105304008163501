import React, { Component } from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import SafePureComponentWrapper from '../shared/SafePureComponent';

import './style.scss';

const PRO_VIDEO_LINK =
    'https://fiverr-res.cloudinary.com/video/upload/t_direct_hd/v1566729480/static_videos/Fiverr_PRO_2019_promo.mp4.mov';

class ProVideo extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();

        this.fullScreenEventHandler = this.fullScreenEventHandler.bind(this);
    }

    componentDidMount() {
        this.videoRef.current.addEventListener('webkitfullscreenchange', this.fullScreenEventHandler, false);
    }

    componentWillUnmount() {
        this.videoRef.current.removeEventListener('webkitfullscreenchange', this.fullScreenEventHandler, false);
    }

    fullScreenEventHandler() {
        this.videoRef.current.muted = false;
    }

    render() {
        return (
            <div className="pro-video">
                <h2 className="section-title">
                    <I18n k={'gig_page_perseus.pro_video.title'} />
                </h2>
                <div className="video-wrapper">
                    <video controls autoPlay muted loading="lazy" ref={this.videoRef}>
                        <source src={PRO_VIDEO_LINK} type="video/mp4" />
                    </video>
                </div>
            </div>
        );
    }
}
export { ProVideo };

export default SafePureComponentWrapper(ProVideo);
