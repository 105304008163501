import React from 'react';
import { connect } from 'react-redux';
import { arrayOf, object, string, bool } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { getRecurringOptions } from '../../../reducers/packages';
import { getRecurringDiscountInfo } from '../../../utils/recurring';
import { getSubscriptionSubtitleKeySuffix, getSubscriptionTitleKeySuffix } from '../../../utils/recurring/translation';
import BulletList from '../BulletList';

const SubscriptionInfoList = ({
    listClassName,
    titleClassName,
    recurringOptions,
    templates,
    showFullList = false,
    withTitle = true,
}) => {
    const { singleDiscount, hasDiscount, percentageMax, percentageMin } = getRecurringDiscountInfo(recurringOptions);
    const subscriptionTitleSuffix = getSubscriptionTitleKeySuffix(hasDiscount);
    const subscriptionSubtitleSuffix = getSubscriptionSubtitleKeySuffix({ hasDiscount, singleDiscount });
    const subscriptionSubtitle = `gig_page_perseus.recurring.sidebar.subscription_payment.subtitle.${subscriptionSubtitleSuffix}`;
    const subtitleParams = {
        percentageMax,
        percentageMin,
    };

    const getI18nWithTemplate = (key) => <I18n k={key} templates={templates} />;

    const listItems = [
        getI18nWithTemplate(
            `gig_page_perseus.recurring.sidebar.subscription_payment.info_list.1.${subscriptionTitleSuffix}`
        ),
        getI18nWithTemplate('gig_page_perseus.recurring.sidebar.subscription_payment.info_list.2'),
        getI18nWithTemplate('gig_page_perseus.recurring.sidebar.subscription_payment.info_list.3'),
    ];
    if (showFullList) {
        listItems.push(getI18nWithTemplate('gig_page_perseus.recurring.sidebar.subscription_payment.info_list.4'));
    }

    return (
        <>
            {withTitle && (
                <span className={titleClassName}>
                    <I18n k={subscriptionSubtitle} params={subtitleParams} />
                </span>
            )}
            <BulletList className={listClassName} listItems={listItems} />
        </>
    );
};

SubscriptionInfoList.propTypes = {
    listClassName: string,
    titleClassName: string,
    recurringOptions: arrayOf(object),
    templates: object,
    showFullList: bool,
    withTitle: bool,
};

const mapStateToProps = (state) => ({
    recurringOptions: getRecurringOptions(state.packages),
});

export { SubscriptionInfoList };
export default connect(mapStateToProps)(SubscriptionInfoList);
