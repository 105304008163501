import { getContext } from '@fiverr-private/fiverr_context';
import { calculateGroup, CalculateGroupParams } from '@fiverr-private/experiments_package';
import {
    ClientExperimentConsumerConfig,
    ConsumerExperimentsConfigs,
    ExperimentsConfigMap,
    RolloutResultsMap,
} from '../../types';
import { DEFAULT_NUM_OF_GROUPS } from '../../constants';
import logger from '../../../../logger';

/**
 * Creates the Experiments Map
 * @param {ConsumerExperimentsConfigs} experimentConfigs
 * @param {object} rollouts
 * @param {object} propsForEligibility
 * @return {ExperimentsConfigMap}
 */
export const buildExperimentsMap = (
    experimentConfigs: ConsumerExperimentsConfigs,
    rollouts: RolloutResultsMap,
    propsForEligibility?: any
): ExperimentsConfigMap => {
    try {
        return experimentConfigs.reduce((configsMap, experimentConfig: ClientExperimentConsumerConfig) => {
            const { experimentId, rolloutName } = experimentConfig;
            try {
                const rolloutResult = rollouts[rolloutName];
                const calculatedGroup = calculateExperienceGroup(experimentConfig, rolloutResult, propsForEligibility);
                const experiment: ExperimentsConfigMap = {
                    [experimentId]: {
                        ...experimentConfig,
                        calculatedGroup,
                        rolloutResult,
                    },
                };
                return {
                    ...configsMap,
                    ...experiment,
                };
            } catch (error) {
                logger.error(error, {
                    experimentConfigs,
                    rollouts,
                    experimentId,
                    rolloutName,
                    description: 'Calculating a Client Experiment failed',
                });
            }

            return configsMap;
        }, {});
    } catch (error) {
        logger.error(error, {
            experimentConfigs,
            description: 'Building the Calculated Client Experiments failed',
        });
    }
    return {};
};

export const calculateExperienceGroup = (
    experimentConfig: ClientExperimentConsumerConfig,
    rolloutResult: boolean,
    propsForEligibility: any
): number | undefined => {
    const { experimentId, experimentType, numOfGroups = DEFAULT_NUM_OF_GROUPS, isEligible } = experimentConfig;
    const context = getContext();
    const { abTests = {} } = context;

    const isEligibleForExperiment = !isEligible(propsForEligibility);
    if (isEligibleForExperiment) {
        return undefined;
    }

    const actualPreviousAllocation = abTests?.[experimentId];
    if (actualPreviousAllocation) {
        return Number(actualPreviousAllocation);
    }

    if (!rolloutResult) {
        return undefined;
    }

    const config: CalculateGroupParams = { experimentId, experimentType, numOfGroups, context };

    return calculateGroup(config);
};
