import { startCase } from 'lodash';
import { RECURRING_TIME_UNITS } from '../../types/recurringTimeUnits';
import { TIME_UNITS } from '../../types/timeUnits';
import { MIXPANEL_DRAWER_SOURCE } from './constants';

export const createMpShowPageEventParams = (data) => ({
    'Category Name': data.categoryName,
    'Sub Category Name': data.subCategoryName,
    'Is Pro': data.isPro,
    'Source Component': data.sourceComponent,
    'Gig Card Position': data.pos,
    'Context Page Number': data.contextPageNumber,
    'Number of Packages': data.packagesCount,
    'Is Promoted Gig': data.isPromotedGig,
    'Buyer Assumed Language': data.buyerAssumedLanguage,
    'Min package price': data.minPackagePrice,
    'Seller Languages': data.sellerValidatedLanguages,
    'Seller Country': data.sellerCountryCode,
    'Is nex': data.isNex,
    'Number of highlights': data.highlightsCount,
    'Have review snippet': data.withReviewsSnippet,
    'Have portfolio': data.withPortfolio,
    'Number of notable clients': data.notableClientsCount,
    'Number of reviews': data.reviewsCount,
    ...data.shutterStockEventData,
});

export const createMpComparePackagesParams = (data) => ({
    'Category Name': data.categoryName,
    'Sub Category Name': data.subCategoryName,
});

export const createMpPackageSelectParams = (data) => ({
    'Package Position': startCase(data.source),
    'Package Selected': data.id,
    'Is subscription': !!data.selectedRecurringOption,
    ...getRecurringParams(data),
});

const getRecurringParams = ({ selectedRecurringOption }) =>
    selectedRecurringOption ? buildRecurringOptionParams(selectedRecurringOption) : {};

const buildRecurringOptionParams = ({ duration, durationTimeUnit, discountPercentage }) => ({
    'Subscription Length': duration,
    'Subscription Time Unit': startCase(transformTimeUnit(durationTimeUnit)),
    'Discount Percentage': discountPercentage,
});

const transformTimeUnit = (durationTimeUnit) => {
    switch (durationTimeUnit) {
        case RECURRING_TIME_UNITS.WEEK:
            return TIME_UNITS.WEEK;
        case RECURRING_TIME_UNITS.MONTH:
            return TIME_UNITS.MONTH;
        default:
            return TIME_UNITS.MONTH;
    }
};

export const createMpTopNavClickParams = (data) => ({
    'Category Name': data.categoryName,
    'Sub Category Name': data.subCategoryName,
    'Displayed Sections': data.displayedSections,
    'Clicked Section': data.clickedSection,
});

export const createMpSellerAnchorClickParams = (data) => ({
    'Category Name': data.categoryName,
    'Sub Category Name': data.subCategoryName,
});

export const createChangedRecurringOption = (data) => ({
    'Package Position': startCase(data.source),
    'Subscription Length': data.duration,
});

export const createChangedCheckoutPlan = (data) => ({
    'Package Position': startCase(data.source),
    'Checkout Plan': startCase(data.selectedCheckoutPlan),
});

export const createMpClickedTab = (data) => ({
    'Tab Name': data['Tab Name'],
    'Category Name': data.categoryName,
    'Sub Category Name': data.subCategoryName,
    'Category Id': data.categoryId,
    'Sub Category Id': data.subCategoryId,
    'Nested Sub Category Id': data.nestedSubCategoryId,
    'Is pro': data.isPro,
});

export const createMpDrawerChangePaymentPlanParams = (data) => ({
    'Checkout plan': startCase(data.checkoutPlan),
    'Max subscription discount': data.maxDiscount,
});

export const createMpDrawerChangeSubscriptionLengthParams = (data) => ({
    ...getRecurringParams(data),
});

export const createMpDrawerOpenParams = (data) => ({
    'Extras Count': data.pricingFactorsCount,
    'Payment Option': data.paymentOption,
});

export const createMpDrawerCloseParams = (data) => ({
    'Extras Count': data.pricingFactorsCount,
    'Extras selected count': data.selectedPricingFactors,
    'Payment Option': data.paymentOption,
    'Gig Quantity': data.gigQuantity,
});

export const createMpChangedGigQuantityParams = (data) => ({
    'Gig Quantity Source': MIXPANEL_DRAWER_SOURCE,
    'Payment Option': data.paymentOption,
    'Gig Quantity': data.quantity,
});

export const createMpContinueToCheckoutParams = (data) => ({
    'Payment Option': data.paymentOption,
    'Extras Count': data.pricingFactorsCount,
    'Extras selected count': data.selectedPricingFactors,
    'Gig Quantity': data.quantity,
});

export const createMpDrawerDrawerClickedAddExtraParams = (data) => ({
    'Extra Source': MIXPANEL_DRAWER_SOURCE,
    'Extras Count': data.pricingFactorsCount,
    'Is Checked': data.isChecked,
});

export const createMpDrawerDrawerChangedExtraQuantityParams = (data) => ({
    'Extra Source': MIXPANEL_DRAWER_SOURCE,
    'Extras Count': data.pricingFactorsCount,
    Quantity: data.quantity,
});
