import React, { useEffect } from 'react';
import { arrayOf, string, object, func } from 'prop-types';
import { NotableClientsAbout } from '@fiverr-private/seller_presence';
import { useGigPageContext } from "@fiverr-private/gig_page_context";
import { translate } from "@fiverr-private/i18n-react";
import { atoms } from '@fiverr-private/theme';
import WithImpressions from '../../../shared/withImpressions';
import { NOTABLE_CLIENTS_ORIGIN } from '../../../shared/NotableClients/constants';

const titleCss = atoms({
    fontSize: 'b_md',
    lineHeight: 'b_md',
});

import './style.scss';

const CLASS_NAME = 'notable-clients-about-local';

const NotableClientsAboutLocal = ({ notableClients, sellerName, profilePhoto, collectImpressions, impressionRef }) => {
    const { seller: { isAgency } } = useGigPageContext();

    useEffect(() => {
        collectImpressions();
    }, [collectImpressions]);

    const aboutProps = {
        notableClients,
        seller: {
            name: sellerName,
            profilePhoto,
        },
        origin: NOTABLE_CLIENTS_ORIGIN,
        titleProps: {
            ...(isAgency && { title: translate('gig_page_perseus.general.among_our_clients') }),
            educate: false,
            newBadge: true,
            infoIcon: true,
            classNames: { titleCss }
        },
        ...(isAgency && { detailsPopoverProps: { title: translate('gig_page_perseus.general.about_our_work_with') } }),
    };

    return (
        <div className="notable-clients-about-local m-t-16 p-t-16" ref={impressionRef}>
            <NotableClientsAbout {...aboutProps} />
        </div>
    );
};

const impressionConfig = {
    DOMSelector: `.${CLASS_NAME} > *`,
    eventKey: 'notable_client_below_the_fold_component_is_shown',
};

NotableClientsAboutLocal.propTypes = {
    notableClients: arrayOf(object),
    sellerName: string,
    profilePhoto: string,
    collectImpressions: func,
    impressionRef: object,
};

export { NotableClientsAboutLocal };
export default WithImpressions(NotableClientsAboutLocal, impressionConfig);
