import { BQ_PAYPAL_CREDIT_LEARN_IS_SHOWN } from '../../utils/bigQueryEvents';

export const buildPaypalPaylaterMessageIsShownEvent = ({
    eventSource,
    sellerUsername,
    general,
    gigId,
    sellerId,
    userId,
    pageCtxId,
    pageName,
    platform,
    userGuid,
    organizationId,
    contextId,
}) => ({
    uid: userGuid,
    user: { id: userId },
    entity: 'client',
    group: 'user_impressions',
    type: BQ_PAYPAL_CREDIT_LEARN_IS_SHOWN,
    organization: { id: organizationId },
    page: {
        ctx_id: pageCtxId,
        name: pageName,
    },
    event_source: eventSource,
    platform,
    context_id: contextId,
    seller: {
        id: sellerId,
        username: sellerUsername,
    },
    gig: {
        id: gigId,
        category_id: general.categoryId,
        sub_category_id: general.subCategoryId,
        category_name: general.categoryName,
        sub_category_name: general.subCategoryName,
        nested_sub_category_id: general.nestedSubCategoryId,
        pro: general.isPro,
        title: general.gigTitle,
        handpicked: general.isHandpicked,
        status: general.gigStatus,
    },
});
