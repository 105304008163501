import React from 'react';
import { object, string } from 'prop-types';
import { Tag, Tooltip } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { RECOMMENDED_TYPES } from '../../../utils/packagesUtils/types/recommendedTypes';

import './style.scss';

const RecommendedBadge = ({ recommendedType, tooltipPosition = RecommendedBadge.POSITIONS.LEFT }, { general }) => {
    const getRecommendedText = (recommendedType) => {
        switch (recommendedType) {
            case RECOMMENDED_TYPES.RECOMMENDED:
                return {
                    label: 'gig_page_perseus.packages.recommended',
                    tooltipContent: 'gig_page_perseus.packages.recommended_hint',
                };
            case RECOMMENDED_TYPES.CUSTOMIZED:
                return {
                    label: 'gig_page_perseus.packages.customized',
                    tooltipContent: 'gig_page_perseus.packages.customized_hint',
                };
            default:
                return null;
        }
    };

    const { isPro } = general;
    const color = isPro ? Tag.COLORS.PRO : Tag.COLORS.GREEN;
    const recommendedText = getRecommendedText(recommendedType);

    if (!recommendedText) {
        return null;
    }

    return (
        <div className="recommended-badge">
            <Tooltip
                className="recommended-badge-tooltip"
                boxClassName="recommended-badge-tooltip-box"
                position={tooltipPosition}
                appendToBody={false}
                content={<I18n k={recommendedText.tooltipContent} />}
            >
                <Tag color={color}>
                    <I18n k={recommendedText.label} />
                </Tag>
            </Tooltip>
        </div>
    );
};

RecommendedBadge.propTypes = {
    recommendedType: string,
    tooltipPosition: string,
};

RecommendedBadge.contextTypes = {
    general: object,
};

Object.assign(RecommendedBadge, {
    POSITIONS: Tooltip.POSITIONS,
});

export default RecommendedBadge;
