import React from 'react';
import { noop } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';

interface FoldButtonProps {
  isFolded?: boolean;
  onClick?: (isFolded: boolean) => void;
}

export const FoldButton: React.FC<FoldButtonProps> = ({ isFolded = true, onClick = noop }) => {
  const copyKey = isFolded
    ? 'gig_page_perseus.expert_list.fold_cta.show_more'
    : 'gig_page_perseus.expert_list.fold_cta.show_less';

  const onFoldedToggle = () => {
    onClick(!isFolded);
  };

  return (
    <Stack width="auto" marginTop="3">
      <Typography
        decoration={{ default: 'underline', hover: 'none' }}
        onClick={onFoldedToggle}
        cursor="pointer"
        fontWeight="semibold"
        size="b_sm"
        color="bodyPrimary"
      >
        {translate(copyKey)}
      </Typography>
    </Stack>
  );
};
