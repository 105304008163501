import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { Button } from '@fiverr-private/fit';
import { TRANSLATION_PREFIX } from '../constants';

import classes from '../styles.module.scss';

export const RequestToOrderCtaButton = ({ onClick }, { general, isBusinessLayout, isProRebrandingLayout }) => {
    const { sellerId, isPro } = general;
    const { userId } = getContext();

    const isPageOwner = sellerId === userId;
    const btnColor = isPro || isBusinessLayout || isProRebrandingLayout ? Button.COLORS.BLACK : Button.COLORS.GREEN;

    return (
        <Button onClick={onClick} disabled={isPageOwner} className={classes.ctaButton} type="button" color={btnColor}>
            <I18n k={`${TRANSLATION_PREFIX}.cta_title`} />
        </Button>
    );
};

RequestToOrderCtaButton.propTypes = {
    onClick: PropTypes.func,
};

RequestToOrderCtaButton.contextTypes = {
    general: PropTypes.object,
    isBusinessLayout: PropTypes.bool,
    isProRebrandingLayout: PropTypes.bool,
};
