import { metric } from '../../../../utils/metric';
import { sendGraphiteEvent } from '../../../../utils/sendGraphiteEvent';
import { CUSTOM_ORDER_SUBMIT } from '../../../../utils/sendGraphiteEvent/events';
import { BQ_CLICKED_CUSTOM_ORDER } from '../../../../utils/bigQueryEvents';
import {
    MP_CONTACT_SELLER_OPTIONS_CUSTOM_ORDER_SUBMIT,
    MP_CONTACT_SELLER_OPTIONS_CUSTOM_ORDER_CLOSE,
    MP_CONTACT_SELLER_OPTIONS_CUSTOM_ORDER_CLICK,
} from '../../../../utils/mixPanelEvents';

const eventSource = 'contact_seller_options';

export const reportCustomOrderSubmitSuccess = (biEvents) => {
    sendGraphiteEvent(CUSTOM_ORDER_SUBMIT);

    biEvents.sendMixPanelEvent({
        eventName: MP_CONTACT_SELLER_OPTIONS_CUSTOM_ORDER_SUBMIT,
    });
};

export const reportCustomOrderClick = (biEvents, bqSourceName) => {
    metric.count('custom_order.click');

    biEvents.sendMixPanelEvent({
        eventName: MP_CONTACT_SELLER_OPTIONS_CUSTOM_ORDER_CLICK,
    });

    biEvents.sendBigQueryEvent({
        eventName: BQ_CLICKED_CUSTOM_ORDER,
        params: {
            eventSource,
            name: bqSourceName,
        },
    });
};

export const reportCustomOrderClose = (biEvents) => {
    biEvents.sendMixPanelEvent({
        eventName: MP_CONTACT_SELLER_OPTIONS_CUSTOM_ORDER_CLOSE,
    });
};
