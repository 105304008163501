import {
    BQ_VOICE_OVER_AI_AUDITION_SUCCESS,
    BQ_VOICE_OVER_AI_AUDITION_CLICKED_ON_PLAY,
    BQ_VOICE_OVER_AI_AUDITION_ERROR_MESSAGE_IS_SHOWN,
} from '../../../utils/bigQueryEvents';

/**
 * BI event that will be sent whenever a new Voice Over AI sample was generated succesfully.
 * @param {object} data The event's data.
 * @param {object} data.biEvents The class in charge of sending BI events.
 * @param {number} data.auditionId A UUID representing a single sample.
 *                                 Will be generated for every playing of a new sentence (or in case of sentence errors).
 * @param {string} data.sentence The sentence sampled.
 * @returns {object} parameters for the event.
 */
export const sendSampleGenerationSuccessBiEvent = ({ biEvents, auditionId, sentence }) =>
    biEvents.sendBigQueryEvent({
        eventName: BQ_VOICE_OVER_AI_AUDITION_SUCCESS,
        params: {
            auditionId,
            sentence,
        },
    });

/**
 * BI event that will be sent whenever the user clicked the play button, or pressed enter in order to play.
 * @param {object} data The event's data.
 * @param {object} data.biEvents The class in charge of sending BI events.
 * @param {number} data.auditionId A UUID representing a single sample.
 *                                 Will be generated for every playing of a new sentence (or in case of sentence errors).
 * @param {string} data.sentence The sentence sampled.
 * @returns {object} parameters for the event.
 */
export const sendPlayClickedBiEvent = ({ biEvents, auditionId, sentence }) =>
    biEvents.sendBigQueryEvent({
        eventName: BQ_VOICE_OVER_AI_AUDITION_CLICKED_ON_PLAY,
        params: {
            auditionId,
            sentence,
        },
    });

/**
 * BI event that will be sent whenever an error has occured which prevents sample generations.
 * @param {object} data The event's data.
 * @param {object} data.biEvents The class in charge of sending BI events.
 * @param {number} data.auditionId A UUID representing a single sample.
 *                                 Will be generated for every playing of a new sentence (or in case of sentence errors).
 * @param {string} data.sentence The sentence sampled.
 * @param {string} data.error The error, can be any of the errors in './errors' ERRORS object.
 */
export const sendErrorBiEvent = ({ biEvents, auditionId, sentence, error }) =>
    biEvents.sendBigQueryEvent({
        eventName: BQ_VOICE_OVER_AI_AUDITION_ERROR_MESSAGE_IS_SHOWN,
        params: {
            auditionId,
            sentence,
            error,
        },
    });
