import { EXPERIMENT_TYPE } from '@fiverr-private/experiments_package';
import { ROLLOUTS } from '../../types/rollouts';
import EXPERIMENTS from './experimentsEnum';
import { ConsumerExperimentsConfigs } from './clientExperiments';

export const clientExperimentsConfig: ConsumerExperimentsConfigs = [
    /* Please don't erase - Example config
    {
        experimentId: FOXES_CHECKOUT_DRAWER_VARIATIONS,
        rolloutName: ROLLOUTS.FOXES_DRAWER_VARIATIONS_GRADUAL,
        experimentType: EXPERIMENT_TYPE.USER,
        numOfGroups: 3,
        isEligible: (propsForEligibility: Record<string, any>) =>
            isLoggedInUser() && isPackageValidForCheckoutDrawer(propsForEligibility.store)
    }, */
    // Checkout Test (Milestones + Subscriptions + Calculators) w/ Extras

    {
        experimentId: EXPERIMENTS.CHAM_GIG_PAGE_LAZY_LOAD_PCP,
        rolloutName: ROLLOUTS.CHAM_GIG_PAGE_LAZY_LOAD_PCP,
        experimentType: EXPERIMENT_TYPE.GUEST,
        numOfGroups: 2,
        isEligible: () => true,
    },
];
