import { numberFormat } from '@fiverr-private/localization';
import { separateNumber } from '@fiverr-private/futile';
import { DEFAULT_REVIEWS_SORT, LOCAL_REVIEW_SORT } from '../../server/utils/reviews/fetchReviews/constants';
import { PAGE_NAME } from '../../utils/events';
import { GIG_PAGE } from './constants';

export const buildGigReviewsConfiguration = ({ pageCtxId, userId, gigId, isMobile, showLocalReview }) => ({
    pathFinderConfig: {
        path: 'gig_page_fetch_reviews_api',
        params: { gig_id: gigId },
    },
    pageCtxId,
    pageName: PAGE_NAME,
    type: showLocalReview ? LOCAL_REVIEW_SORT : DEFAULT_REVIEWS_SORT,
    displayRelevant: true,
    displayLocation: showLocalReview,
    biEventGroup: GIG_PAGE,
    displayBreakdown: !isMobile,
    currentUserId: userId,
});

export const buildSellerReviewsConfiguration = (pageCtxId, userId, sellerId, isMobile) => ({
    pathFinderConfig: {
        path: 'gig_page_fetch_user_reviews_api',
        params: {
            perseus_name: GIG_PAGE,
            user_id: sellerId,
        },
    },
    pageCtxId,
    pageName: PAGE_NAME,
    type: DEFAULT_REVIEWS_SORT,
    displayRelevant: true,
    biEventGroup: GIG_PAGE,
    displayBreakdown: !isMobile,
    currentUserId: userId,
});

/**
 * formatting number to fit current locale
 * @param  {number} number
 * @return {number} formatted number
 * @example formatSimpleNumber(1000) -> 1,000
 */
export const formatSimpleNumber = (number) =>
    numberFormat({ value: number, options: { fallbackValue: separateNumber(number) } });
