import { isFunction, merge } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_REPORTED_CHANNELS } from './constants';

class EventsReporter {
    enrich(baseEnrichmentParams) {
        const { userId, pageCtxId: ctx_id, userGuid: uid, isMobile } = getContext();
        const platform = isMobile ? 'mobile_web' : 'web';

        this.baseEnrichmentParams = {
            user: { id: userId },
            uid,
            page: { ctx_id },
            platform,
            ...baseEnrichmentParams,
        };
    }

    /**
     * Reports event to all reporting channels (grafana, bigQuery, mixpanel).
     *
     * In case no specific channel is supplied, the event is reported to all channels.
     *
     * @param {String} eventKey
     * @param {Object} eventParams
     * @param {Array} reportingChannels
     */
    report(eventKey, eventParams = {}, reportingChannels = DEFAULT_REPORTED_CHANNELS) {
        if (!eventKey) {
            return;
        }

        reportingChannels.forEach(({ events, reporter }) => this.reportEvent(events[eventKey], reporter, eventParams));
    }

    reportEvent(event, reporter, eventParams) {
        if (!event) {
            return;
        }

        const enrichedEvent = this.build(event, eventParams);

        reporter(enrichedEvent);
    }

    /**
     * Build an enriched event using the base enrichment parameters
     *
     * @param {Object | Function} event
     * @param {Object} eventParams
     */
    build(event, eventParams = {}) {
        const enrichmentParams = merge({}, this.baseEnrichmentParams, eventParams);

        return isFunction(event) ? event(enrichmentParams) : event;
    }
}

export default new EventsReporter();
