import Cookies from 'universal-cookie';
import { MONTH_IN_MS, WEEK_IN_MS } from '@fiverr-private/futile';
import { FIVERR_DOMAIN } from '../../../common/uri/constants';
import { COOKIE_DELIMITER, DEFAULT_OPTIONS, LAST_CONTENT_PAGES_COOKIE, LAST_VIEWED_GIG_COOKIE } from './constants';

class CookieManager {
    constructor() {
        this.cookies = new Cookies();
    }

    updateLastContentPages(pageId) {
        const lastContentPages = (this.cookies.get(LAST_CONTENT_PAGES_COOKIE) || '').split(COOKIE_DELIMITER);

        // Update cookie if current page is different than the last viewed page.
        if (pageId !== lastContentPages[0]) {
            this.setCookie(
                LAST_CONTENT_PAGES_COOKIE,
                `${pageId}${COOKIE_DELIMITER}${lastContentPages[0]}`,
                MONTH_IN_MS
            );
        }
    }

    updateLastViewedGig(gigId) {
        this.setCookie(LAST_VIEWED_GIG_COOKIE, gigId, 2 * WEEK_IN_MS);
    }

    removeLastViewedGig() {
        let options = {};

        if (document.location.href.includes(FIVERR_DOMAIN)) {
            options = DEFAULT_OPTIONS;
        }

        this.cookies.remove(LAST_VIEWED_GIG_COOKIE, options);
    }

    setCookie(key, value, ttl) {
        let options = {
            expires: new Date(Date.now() + ttl),
        };

        if (document.location.href.includes(FIVERR_DOMAIN)) {
            options = { ...options, ...DEFAULT_OPTIONS };
        }

        this.cookies.set(key, value, options);
    }
}

export default new CookieManager();
