import { isEmpty, pick } from 'lodash';

export const shouldKeepField = (field) => !isEmpty(field);

export const shouldShowOriginal = ({ machineTranslationFromServer }) => !machineTranslationFromServer?.isEnabled;

export const extractMachineTranslationUGC = ({
    machineTranslationFromServer: {
        gigTitle,
        description,
        workflow,
        workProcess,
        sellerCard,
        faq,
        packages = {},
        studio = {},
    } = {},
} = {}) => ({
    ...(gigTitle && { gigTitle }),
    ...(shouldKeepField(description) && { description: pick(description, 'content') }),
    ...(shouldKeepField(workflow) && { workflow }),
    ...(shouldKeepField(workProcess) && { workProcess }),
    ...(shouldKeepField(sellerCard) && { sellerCard }),
    ...(shouldKeepField(studio) && { studio }),
    ...(shouldKeepField(faq) && { faq }),
    ...(shouldKeepField(packages) && { packages }),
});
