import { PackageData } from '../../types/gig/interfaces';
import { calcFeaturePrice } from './calcFeaturePrice';
import { calcExtraFastPrice } from './calcExtraFastPrice';
import { isExtraFastIncluded, isPricingFactorIncluded } from '.';

export const calcExtrasPrice = (packageData: PackageData): number => {
    const { features = [], customExtras = [] } = packageData;
    const extraFastPrice = isExtraFastIncluded(packageData.extraFast) ? calcExtraFastPrice(packageData) : 0;

    let featuresPrice = 0;
    features.filter(isPricingFactorIncluded).forEach((feature) => {
        featuresPrice += calcFeaturePrice(packageData, feature);
    });

    let customExtrasPrice = 0;
    customExtras
        .filter(isPricingFactorIncluded)
        .forEach((customExtra) => (customExtrasPrice += calcFeaturePrice(packageData, customExtra)));

    return extraFastPrice + featuresPrice + customExtrasPrice;
};
