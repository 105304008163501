import { isVoiceOverCalculator } from '../calculator';
import { PackageData } from '../../types/gig/interfaces';

export const calcExtraFastPrice = (packageData: PackageData) => {
    const { extraFast } = packageData;

    const voCalculator = packageData.calculators.find(isVoiceOverCalculator);
    const scriptLength = voCalculator?.currentValue;

    if (!scriptLength) {
        return extraFast.price;
    }

    const amount = Math.ceil(scriptLength / extraFast.value);
    return extraFast.price * amount;
};
