import React from 'react';
import PropTypes, { bool } from 'prop-types';
import classNames from 'classnames';

import './style.scss';

export const TopPanel = ({ children, customClass }, { isBusinessLayout, isProRebrandingLayout }) => (
    <section className={classNames('top-panel-wrap', customClass)}>
        <div
            className={classNames('top-panel', {
                'new-pro-layout': isBusinessLayout || isProRebrandingLayout,
            })}
        >
            {children}
        </div>
    </section>
);

TopPanel.propTypes = {
    customClass: PropTypes.string,
    children: PropTypes.node.isRequired,
};

TopPanel.contextTypes = {
    isBusinessLayout: bool,
    isProRebrandingLayout: bool,
};

export default TopPanel;
