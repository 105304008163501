import React from 'react';
import { arrayOf, object } from 'prop-types';
import { connect } from 'react-redux';

import './style.scss';

const Description = ({ packageList }) => (
    <tr className="description">
        <td className="package-row-label" />
        {packageList.map((packageData) => (
            <td key={packageData.id}>{packageData.description}</td>
        ))}
    </tr>
);

const mapStateToProps = ({ packages }) => ({
    packageList: packages.packageList,
});

Description.propTypes = {
    packageList: arrayOf(object),
};

export { Description };
export default connect(mapStateToProps)(Description);
