/* eslint-disable no-console */
import { getContext } from '@fiverr-private/fiverr_context';
import { logger } from '@fiverr-private/obs';
import { unInitializedErrorString } from './constants';

export const CLIENT_TIMEOUT_STATUS = 499;

export class AppLogger {
    constructor() {
        this.requestContext = getContext();
    }

    contextualise(data = {}) {
        return Object.assign(data, { requestContext: JSON.stringify(this.requestContext) });
    }

    error(error, data = {}) {
        if (data.responseStatus === CLIENT_TIMEOUT_STATUS) {
            return;
        }
        logger.error(error, this.contextualise(data));
    }

    warn(message, data = {}) {
        logger.warn(message, this.contextualise(data));
    }
}

const Logger = (() => {
    let instance = {
        error: () => console.error(`${unInitializedErrorString} logger.error`),
        warn: () => console.error(`${unInitializedErrorString} logger.warn`),
    };

    const init = () => {
        instance = new AppLogger();
        return instance;
    };

    return {
        init,
        getInstance: () => instance,
    };
})();

export default Logger;
