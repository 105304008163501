import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const OneLiner = ({ oneLiner }) => <p className="one-liner">{oneLiner}</p>;

OneLiner.propTypes = {
    oneLiner: PropTypes.string,
};

export default OneLiner;
