import React from 'react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { arrayOf, string, node } from 'prop-types';

import classes from './styles.module.scss';

const BulletList = ({ className, listItems }) => {
    if (isEmpty(listItems)) {
        return null;
    }

    const wrapperClassNames = classNames(classes.bulletList, className);

    return (
        <ul className={wrapperClassNames}>
            {listItems.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
};

BulletList.propTypes = {
    className: string,
    listItems: arrayOf(node),
};

export default BulletList;
