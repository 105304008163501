import { isEmpty } from 'lodash';
import { AMOUNT_SEPARATOR } from '../constants';

/**
 * Extracts a Map of Pre Selected Pricing Factors {name:amount}
 * @param {string | undefined} queryParamFromUrl
 * @return {Map<string,number> | object}
 */
export const buildMapFromQueryParamList = (queryParamFromUrl: string): Map<string, number> | object => {
    if (isEmpty(queryParamFromUrl)) {
        return {};
    }

    const list = queryParamFromUrl.split(',');

    const entries = list.map((nameAndAmount) => {
        const [name, amount] = nameAndAmount.split(AMOUNT_SEPARATOR);

        return [name, Number(amount)];
    });

    return Object.fromEntries(entries);
};
