import React from 'react';
import { func, number, object } from 'prop-types';
import { range } from 'lodash';
import { Option, Select } from '@fiverr-private/fit';
import { MAX_AMOUNT } from '../../PaymentDrawer/constants';

import './style.scss';

const quantitySelectorRange = range(1, MAX_AMOUNT + 1);

const Quantity = ({ quantity, price, onQuantityChange }, { currencyConverter }) => {
    const onSelectChange = ({ target }) => {
        const { value } = target;
        const numberedValue = Number(value);

        onQuantityChange(numberedValue);
    };

    return (
        <Select contentClassName="quantity" value={quantity} onChange={onSelectChange}>
            {quantitySelectorRange.map((val) => (
                <Option key={val} value={val}>{`${val} (${currencyConverter.convert(val * price)})`}</Option>
            ))}
        </Select>
    );
};

Quantity.propTypes = {
    quantity: number,
    price: number,
    onQuantityChange: func,
};

Quantity.contextTypes = {
    currencyConverter: object,
};

export default Quantity;
