import React from 'react';
import { Text } from '@fiverr-private/typography';

interface SellerProfessionTitleProps {
  children: string;
}

export function SellerProfessionTitle({ children }: SellerProfessionTitleProps) {
  return <Text fontWeight="bold">{children}</Text>;
}
