const statuses = require('../constants/gigStatuses');

const isGigPaused = (gigStatus, traffiqed = false) =>
    gigStatus === statuses.SUSPENDED || gigStatus === statuses.ONHOLD || traffiqed;

const isGigApproved = (gigStatus) => gigStatus === statuses.APPROVED;

module.exports = {
    isGigPaused,
    isGigApproved,
};
