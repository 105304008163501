import React from 'react';
import { arrayOf, node, object } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { Tooltip } from '@fiverr-private/fit';
import { getShortLanguageCode } from '@fiverr-private/localization';

const LanguagesTooltip = ({ children, languages = [] }) => (
    <Tooltip
        position={Tooltip.POSITIONS.TOP}
        className="languages-tooltip"
        content={
            <>
                <p className="text-semi-bold m-b-8">
                    <I18n k={'gig_page_perseus.seller_card.seller_languages.seller_speaks'} />
                </p>
                <ul>
                    {languages.map(({ name, level }) => (
                        <li key={name}>
                            <I18n k={`languages.codes.${getShortLanguageCode(name?.toLowerCase())}`} />
                            {!!level && (
                                <>
                                    <span>&nbsp; (</span>
                                    <I18n k={`languages.proficiencies.level_${level}`} />
                                    <span>)</span>
                                </>
                            )}
                        </li>
                    ))}
                </ul>
            </>
        }
    >
        {children}
    </Tooltip>
);

LanguagesTooltip.propTypes = {
    languages: arrayOf(object),
    children: node,
};

export default LanguagesTooltip;
