import React from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';
import { Button } from '@fiverr-private/fit';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { reportContactSellerClick } from '../../../utils/contactSeller';
import ContactTrigger from '../../shared/ContactTrigger';

import './style.scss';

const ContactSellerBtn = ({ asContactSellerOption, mixpanelSourceComponent, bqSourceName, triggerId, triggerCopy, triggerPlacement, children }) => {
    const { biEvents } = useGigPageContext();
    const onClick = () =>
        reportContactSellerClick({
            biEvents,
            mixpanelSourceComponent,
            bqSourceName,
            metricKey: 'contact_seller.click',
        });

    return (
        <div className={classNames('contact-seller-wrapper', { 'list-item': asContactSellerOption })}>
            <ContactTrigger
                className="btn-contact-seller"
                source="contact-btn"
                onClick={onClick}
                bqSourceName={bqSourceName}
                triggerId={triggerId}
                triggerCopy={triggerCopy}
                triggerPlacement={triggerPlacement}
            >
                {(triggerProps) => <Button {...triggerProps}>{children}</Button>}
            </ContactTrigger>
        </div>
    );
};

ContactSellerBtn.propTypes = {
    asContactSellerOption: bool,
    mixpanelSourceComponent: string,
    bqSourceName: string,
    children: node,
    triggerId: string,
    triggerCopy: string,
    triggerPlacement: string
};

export default ContactSellerBtn;
