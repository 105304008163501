const { capitalize } = require('lodash');
const { TOP_RATED_SELLER } = require('./constants');

const buildTitleSeo = (initialSeoTitle, locale) => {
    if (initialSeoTitle && initialSeoTitle.gigTitle && initialSeoTitle.sellerUsername) {
        const gigTitle = capitalize(initialSeoTitle.gigTitle);
        const sellerUsername = capitalize(initialSeoTitle.sellerUsername);

        return i18n.t('gig_page_perseus.metadata.seo.title', {
            $scope: locale,
            gig_title: gigTitle,
            seller: sellerUsername,
        });
    }
};

const buildDescriptionSeo = (seoDescription, locale, subCategoryId) => {
    if (seoDescription) {
        const subCategoryName = i18n.t(`sub_categories.sub_category_${subCategoryId}.name`, { $scope: locale });

        const sellerType = getSellerType(seoDescription, locale);
        const props = {
            $scope: locale,
            seller_type: sellerType,
            sub_category: subCategoryName,
            gig_title: seoDescription.gigTitle,
            service_include: seoDescription.serviceInclude,
            delivery_time: seoDescription.deliveryTime,
        };

        if (seoDescription.serviceInclude && seoDescription.deliveryTime) {
            return i18n.t('gig_page_perseus.metadata.seo.full_description', props);
        }

        if (seoDescription.serviceInclude) {
            return i18n.t('gig_page_perseus.metadata.seo.description_w_include', props);
        }

        if (seoDescription.deliveryTime) {
            return i18n.t('gig_page_perseus.metadata.seo.description_w_delivery_time', props);
        }

        return i18n.t('gig_page_perseus.metadata.seo.basic_description', props);
    }
};

const getSellerType = (seoDescription, locale) => {
    const type = seoDescription.sellerType === TOP_RATED_SELLER ? 'top_rated' : 'other';

    return i18n.t(`gig_page_perseus.metadata.seo.seller_type.${type}`, { $scope: locale });
};

module.exports = {
    buildTitleSeo,
    buildDescriptionSeo,
};
