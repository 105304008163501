import React from 'react';
import WaypointBase from 'react-waypoint';
import { noop } from 'lodash';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { WAYPOINT_OFFSET } from '../../utils/constants';

interface WaypointProps {
  source?: string;
  onEnter?: () => void;
  onLeave?: () => void;
}

export const Waypoint: React.FC<WaypointProps> = ({ source, onEnter: _onEnter = noop, onLeave: _onLeave = noop }) => {
  const { sectionScroller } = useGigPageContext();
  const { onEnterWaypoint, onLeaveWaypoint, refsObj } = sectionScroller;
  const onEnter = (data) => {
    onEnterWaypoint({ section: source, data });
    _onEnter();
  };

  const onLeave = (data) => {
    onLeaveWaypoint({ section: source, data });
    _onLeave();
  };

  const updateRefsObject = (element) => {
    if (source) {
      refsObj[source] = element;
    }
  };

  return <WaypointBase ref={updateRefsObject} onEnter={onEnter} onLeave={onLeave} topOffset={WAYPOINT_OFFSET} />;
};
