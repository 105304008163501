import React, { useEffect } from 'react';
import { shape, string, number, object } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import { TagIcon } from '@fiverr-private/icons';
import { colorBlue1100 } from '@fiverr-private/sass/helpers';
import {
    currencyFormat,
    CURRENCY_CODES,
    numberFormat,
    NUMBER_STYLES,
    dateTimeFormat,
    DATETIME_ELEMENT_STYLES,
} from '@fiverr-private/localization';
import { SafeComponent } from '@fiverr-private/orca';
import { BQ_SEEN_PROMOTION_BANNER } from '../../utils/bigQueryEvents';
import { LEARN_MORE_LINK } from './constants';
import classes from './styles.module.scss';

export const PromotionBanner = ({ coupon }, { biEvents }) => {
    const { discountValue, expiresAt, minOrderAmount, id } = coupon;
    const convertedMinOrder = currencyFormat({ value: minOrderAmount / 100, currencyCode: CURRENCY_CODES.USD });
    const formattedDiscountValue = numberFormat({
        value: discountValue / 100,
        options: {
            style: NUMBER_STYLES.PERCENT,
        },
    });

    const translateParams = {
        discount: formattedDiscountValue,
        minOrder: convertedMinOrder,
        date: dateTimeFormat({
            value: new Date(expiresAt),
            options: {
                year: DATETIME_ELEMENT_STYLES.NUMERIC,
                month: DATETIME_ELEMENT_STYLES.SHORT,
                day: DATETIME_ELEMENT_STYLES.TWO_DIGIT,
            },
        }),
    };

    useEffect(() => {
        const enrichData = biEvents.getBigQueryEnrichmentData();
        biEvents.sendRawBigQueryEvent({
            type: BQ_SEEN_PROMOTION_BANNER,
            group: 'user_impressions',
            sub_group: 'seller_plus_events',
            user: { id: enrichData.userId },
            seller: { id: enrichData.sellerId },
            gig: { id: enrichData.gigId },
            promotion: {
                id,
                discount_percent: discountValue / 100,
                end_date: expiresAt,
                minimum_amount: minOrderAmount,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`top-panel-wrap p-b-16 ${classes.promotion}`}>
            <div className={`${classes.bannerMessage} co-blue-1000`}>
                <span className={classes.icon}>
                    <TagIcon size={16} color={colorBlue1100} />
                </span>
                <span data-testid="promotion-content">
                    <I18n k={'gig_page_perseus.promotion_banner.content'} params={translateParams} />
                </span>{' '}
                <a href={LEARN_MORE_LINK} target="_blank" rel="noreferrer">
                    <I18n k={'gig_page_perseus.promotion_banner.learn_more'} />
                </a>
            </div>
        </div>
    );
};

PromotionBanner.contextTypes = {
    biEvents: object,
};

PromotionBanner.propTypes = {
    coupon: shape({
        code: string.isRequired,
        id: string.isRequired,
        type: string.isRequired,
        discountValue: number.isRequired,
        discountType: string.isRequired,
        providerName: string.isRequired,
        expiresAt: string.isRequired,
        minOrderAmount: number.isRequired,
        deliveryPackId: string,
    }),
};

export default SafeComponent(PromotionBanner);
