import React from 'react';
import { Icon, Tooltip } from '@fiverr-private/fit';
import { l_translation } from '@fiverr-private/icons';
import { I18n } from '@fiverr-private/i18n-react';

import './style.scss';

const SellerTranslationBadge = () => (
    <Tooltip
        boxClassName="seller-translation-tooltip"
        className="seller-translation-tooltip-wrapper"
        position={Tooltip.POSITIONS.TOP}
        content={<I18n k="gig_page_perseus.ugc.translated_by_mt_tooltip" />}
    >
        <Icon className="seller-translation-badge" size={20}>
            {l_translation}
        </Icon>
    </Tooltip>
);

export default SellerTranslationBadge;
