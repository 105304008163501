/**
 * The storage key for keeping a flag of whether the user has seen
 * the returning buyer subscription suggestion modal
 * @type {String}
 */
export const SEEN_RETURNING_BUYER_SUBSCRIPTION_MODAL_LOCAL_STORAGE_KEY = 'seen_returning_buyer_subscription';

/**
 * TTL of how many days to mute the returning buyer modal
 * @type {number}
 */
export const RETURNING_BUYER_MODAL_TTL = 30 * 24 * 60 * 60 * 1000; // 30 days

/**
 * The screen width threshold that below that shouldn't display the modal
 * @type {number}
 */
export const SCREEN_WIDTH_THRESHOLD = 680;
