import React from 'react';
import { object, number } from 'prop-types';
import { isEmpty } from 'lodash';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import SafePureComponentWrapper from '../shared/SafePureComponent';
import {
    extractTranslatedDescriptionContentForSeo,
    extractTranslatedTitleContentForSeo,
} from '../../server/middlewares/addMachineTranslation/extractUGCForMachineTranslation';
import SeoScript from './SeoScript';
import { shouldFormatFaq, formatFAQ } from './formatFaq';
import { shouldFormatSeo, formatSeo } from './formatSeo';
import { formatBreadcrumbs } from './formatBreadcrumbs';

const Seo = (
    { seo, faq: originalFaq, packagesCount },
    { general, pathfinderWrapper, machineTranslationFromServer, gigSellerTranslation }
) => {
    const {
        faq: faqFromMT,
        rawTitle: machineTranslatedRawTitle,
        description: machineTranslatedDescription,
    } = machineTranslationFromServer;
    const { gig } = useGigPageContext();
    const { gigTitle: sellerTranslatedTitle, description: sellerTranslatedDescription } = gigSellerTranslation;
    const isFaqFromMTValid = !isEmpty(faqFromMT?.questionsAndAnswers);
    const faq = isFaqFromMTValid ? faqFromMT : originalFaq;
    const originalGigTitleInEnglish = general.gigTitle;
    const gigTitle =
        extractTranslatedTitleContentForSeo({
            machineTranslatedTitle: machineTranslatedRawTitle,
            sellerTranslatedTitle,
        }) || originalGigTitleInEnglish;
    const localizedDescription = extractTranslatedDescriptionContentForSeo({
        machineTranslatedDescription,
        sellerTranslatedDescription,
    });
    const localizedGigTitle = sellerTranslatedTitle || machineTranslatedRawTitle;

    return (
        <>
            <SeoScript scriptContent={formatBreadcrumbs({ general, gigTitle, pathfinderWrapper })} />
            {shouldFormatSeo(seo) && (
                <SeoScript
                    scriptContent={formatSeo({
                        seo,
                        gigRating: gig.rating,
                        pathfinderWrapper,
                        packagesCount,
                        general,
                        localizedGigTitle,
                        localizedDescription,
                    })}
                />
            )}
            {shouldFormatFaq(faq) && <SeoScript scriptContent={formatFAQ(faq)} />}
        </>
    );
};

Seo.propTypes = {
    faq: object,
    seo: object,
    packagesCount: number,
};

Seo.contextTypes = {
    general: object,
    pathfinderWrapper: object,
    machineTranslationFromServer: object,
    gigSellerTranslation: object,
};

export { Seo };
export default SafePureComponentWrapper(Seo);
