import React from 'react';
import SubscriptionInfoList from '../../../../SubscriptionInfoList';

const SubscriptionInfo = () => {
    const templates = {
        b: (text) => text,
    };

    return <SubscriptionInfoList showFullList listClassName="m-t-8" titleClassName="text-bold" templates={templates} />;
};

export default SubscriptionInfo;
