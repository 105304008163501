import React from 'react';
import { SellerProfessionTitle } from '@fiverr-private/gig_seller_info';
import type { CatalogTextualEntity } from '../../../../../../../gql-operations/gql-generated';
import { translateTextualEntity } from '../../../../../utils/dataFormatters/translateTextualEntity';

interface ProfessionTitleProps {
  title: CatalogTextualEntity;
}

export function ProfessionTitle({ title }: ProfessionTitleProps) {
  const text = translateTextualEntity(title);
  return <SellerProfessionTitle>{text}</SellerProfessionTitle>;
}
