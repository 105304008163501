export const UPDATE_ONLINE_STATUS = 'UPDATE_ONLINE_STATUS';

/**
 * Returns the dispatch object for the seller's isOnline status.
 * @param {boolean} isOnline whether or not the seller is online.
 * @returns {object} the dispatch object
 */
export const updateOnlineStatus = (isOnline) => ({
    type: UPDATE_ONLINE_STATUS,
    data: {
        isOnline,
    },
});
