import React from 'react';
import { array, bool, number, object } from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { getPackageById, getSelectedPackageId, isTriplePackage } from '../../../reducers/packages';
import { isVoiceOver } from '../../../utils/packagesUtils';
import PackageContent from '../../shared/PackageContent';
import Logger from '../../../utils/logger';
import { shouldShowSelectPackage } from '../../../utils/shouldShowSelectPackage';
import TabFooter from './TabFooter';
import PaymentDrawerFooter from './PaymentDrawerFooter';
import { shouldShowRequestToOrderBtn } from './TabFooter/RequestToOrderBtn/utils/shouldShowRequestToOrderBtn';

import classes from './style.module.scss';

const TabContent = (
    { id, currentPrice, recommendedGig, features, packageId },
    { general, rollouts, gig: { instantOrderSettings } }
) => {
    const { isLimitedEdition } = general;
    const isGigActive = shouldShowSelectPackage(general);
    const showRequestToOrder = shouldShowRequestToOrderBtn({ instantOrderSettings, packageId, rollouts });

    // TODO: kill TabFooter
    const showDrawer = isGigActive && !showRequestToOrder;

    // TODO: remove when VO package has a title
    const title = isVoiceOver(general) ? 'gig_page_perseus.packages.order_details' : '';

    const wrapperClass = classNames(classes.tabContent, { [classes.recommended]: recommendedGig });

    return (
        <div className={wrapperClass}>
            <PackageContent
                id={id}
                className={classes.packageContent}
                showPrice={!isLimitedEdition}
                title={title ? <I18n k={title} /> : ''}
                features={features}
            />
            {showDrawer ? <PaymentDrawerFooter /> : <TabFooter currentPrice={currentPrice} />}
        </div>
    );
};

TabContent.propTypes = {
    id: number,
    currentPrice: number,
    recommendedGig: bool,
    features: array,
    packageId: number,
};

TabContent.contextTypes = {
    general: object,
    rollouts: object,
    gig: object,
};

const mapStateToProps = (state, ownProps) => {
    const { packages } = state;
    const packageData = getPackageById(packages, ownProps.id);
    const triplePackage = isTriplePackage(packages);

    if (!packageData.title) {
        const logger = Logger.getInstance();
        logger.warn({
            message: 'missing package title',
            is_triple_package: triplePackage,
        });
    }

    return {
        ...packageData,
        packageId: getSelectedPackageId(state),
        features: ownProps.features,
    };
};

export { TabContent };
export default connect(mapStateToProps)(TabContent);
