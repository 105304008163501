import axios from 'axios';
import { GIG_PAGE_CLIENT } from '../../../../../configuration/constants';
import { isBrowser } from '../isBrowser';
import { getCallerFromStack, getStatusCode, measureOnFailure, measureOnSuccess } from './utils';

/**
 * Intercept all axios requests and responses for monitoring successes and failures
 * @param axiosInstance
 */
export const monitoringInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.request.use(
        (config = {}) => {
            const { service, routeKey, ...axiosConfig } = config;
            const NO_CONFIG = {};

            const serviceConfig = isBrowser() ? GIG_PAGE_CLIENT : service || NO_CONFIG;
            const routeIdentifier = isBrowser() ? routeKey : getCallerFromStack();

            return {
                ...axiosConfig,
                startedAt: Date.now(),
                identifier: `${serviceConfig.name}.${routeIdentifier}`,
            };
        },
        (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
        (response = {}) => {
            const { identifier, startedAt } = response.config || {};
            measureOnSuccess(identifier, startedAt, response?.status);

            return response;
        },
        (error = {}) => {
            const { identifier, startedAt } = error.config || {};
            const status = getStatusCode(error);
            measureOnFailure(identifier, startedAt, status);

            throw error;
        }
    );
};

const axiosInstance = axios.create();
monitoringInterceptor(axiosInstance);

/**
 * maxios is an axios instance which supports extended axios config. It allows us to make axios requests
 * and get metrics that can help us investigate the behavior of each request without sending
 * the metrics manually.
 *
 * routeKey - path unique id (pathfinder value) - for AJAX routes
 * service - external service configuration object - for external requests on the server
 * @type {AxiosInstance}
 */
export const maxios = axiosInstance;
