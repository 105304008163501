export const defaultAssets = {
    small: 'https://fiverr-res.cloudinary.com/q_auto,f_auto,dpr_2.0/general_assets/gig_gallery_package/assets/audio-delivery-bg.png',
    medium: 'https://fiverr-res.cloudinary.com/q_auto,f_auto,dpr_2.0/general_assets/gig_gallery_package/assets/audio-delivery-bg.png',
};

export const enrichMedia = (slide) => {
    const { media, typeAudio } = slide;

    if (!typeAudio) {
        return media;
    }

    return {
        small: media.small || defaultAssets.small,
        medium: media.medium || defaultAssets.medium,
        original: media.original || slide.media.medium || defaultAssets.medium,
    };
};
