import { Attachment, MappedAttachmentData } from '../sellerTranslationTypes';
import { buildSrcUrl, mapToMediaUrls } from './mapPreviewUrls';
import { ATTACHMENT_SIZES } from './mapPreviewUrls/constants';
import { mapAttachmentTypes } from './mapAttachmentTypes';

export const mapAttachmentFields = ({ id, mediaUrl, previewUrls, type }: Attachment): MappedAttachmentData => ({
    attachmentId: id,
    src: buildSrcUrl(previewUrls, ATTACHMENT_SIZES.GIG_ATTACHMENT_ORIGINAL_PREVIEW),
    videoSrc: mediaUrl,
    media: mapToMediaUrls(previewUrls),
    ...mapAttachmentTypes(type),
});
