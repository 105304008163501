import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bool, func, object, string, number, array } from 'prop-types';
import _ from 'lodash';
import { getUgcData } from '@fiverr-private/gig_listings/src/GigCardListings/externalUtils/getUgcData';
import { getContext } from '@fiverr-private/fiverr_context';
import { metric } from '../../../utils/metric';
import { buildSourceInfo } from '../../../utils/ugc/buildSourceInfo';
import { isTranslated } from '../../../utils/ugc/isTranslated';
import { translateUGC } from '../../../utils/ugc/translate';
import { updateCollections } from '../../../actions/collections';
import { getGigs } from '../../../utils/gigCardCarouselService';
import { PAGE_NAME } from '../../../utils/events';

export const OTHER_GIGS_BY = 'otherGigsBy';
export const PROMOTED_GIGS_CAROUSEL = 'promoted_gigs';
export const PROMOTED_ORGANIC_GIGS = 'promoted_organic_gigs';
export const PROMOTED_MIXED_GIGS = 'promoted_mixed_gigs';

const AUCTION_ALGORITHM_TYPE = 'recommended|t2g_dfm';

const GigCardCarouselHOCWrapper = (WrappedComponent) => {
    const GigCardCarouselHOC = (props, context) => {
        const {
            type,
            url,
            collections,
            updateCollections,
            carouselPosition,
            carouselContext,
            source,
            isTouch,
            showTranslatedUGC,
            viewportType,
        } = props;

        const { general, currencyConverter } = context;

        const { locale, userId } = getContext();
        const { gigId, subCategorySlug } = general;

        const [gigs, setGigs] = useState([]);
        const [isLoading, setIsLoading] = useState(true);
        const [gigsTranslated, setGigsTranslated] = useState(false);

        useEffect(() => {
            (async () => {
                const getStartTime = Date.now();
                try {
                    const gigs = await getGigs({ url, type });
                    reportFetchGigsData(false, Date.now() - getStartTime);

                    const enrichedGigs = enrichGigsByCarouselType(type, gigs);

                    setGigs(enrichedGigs);
                    setIsLoading(false);
                } catch (ex) {
                    setIsLoading(false);
                    reportFetchGigsData(true, Date.now() - getStartTime);
                }
            })();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            (async () => {
                /*
                 * return if gigsTranslated is true is needed here or else the component
                 * will get into an infinite loop since we're changing the state during useEffect
                 * */

                if (gigsTranslated) {
                    return;
                }

                if (gigs.length && showTranslatedUGC) {
                    const ugcData = getUgcData(gigs, locale);
                    const sourceInfo = buildSourceInfo({ componentName: source, gigId });
                    try {
                        const translatedUGC = await translateUGC({
                            content: ugcData,
                            sourceInfo,
                        });
                        const enrichedGigs = gigs.map((gig) => ({
                            ...gig,
                            ...(translatedUGC[gig.gig_id] || {}),
                        }));
                        setGigsTranslated(true);
                        setGigs(enrichedGigs);
                        // eslint-disable-next-line no-empty
                    } catch (ignore) {}
                }
            })();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [gigs, showTranslatedUGC]);

        const reportFetchGigsData = (emptyGigs, responseTime) => {
            metric.count(`fetch_gigs_data.carousel.${type}.empty_gigs.${emptyGigs}`);
            metric.time(`fetch_gigs_data.carousel.${type}.time`, responseTime);
        };

        const getContextEnrichment = (gigs) => {
            const { pageCtxId, queryParameters } = getContext();
            const { ref } = queryParameters;
            const  auctionData = buildAuctionData(type, gigs)

            return {
                collectProps: {
                    collections,
                    onUpdateCollections: updateCollections,
                },
                impressionEnrichment: {
                    listings: {
                        carousel_position: carouselPosition,
                        page_ctx_id: pageCtxId,
                    },
                    gigData: {
                        ...enrichGigImpressionByCarouselType(type, gigs),
                    },
                    auctionData: {
                        ...auctionData,
                    }
                },
                context: {
                    queryParameters: {
                        context: carouselContext,
                        ref,
                    },
                    sourcePage: PAGE_NAME,
                    sourceComponent: source,
                    currency: currencyConverter.getCurrency(),
                    pageCtxId,
                    userId,
                    subCategory: subCategorySlug,
                    viewportType,
                    currentPage: 'Gig page',
                    controllerAction: 'gigs#show',
                },
                isTouch,
            };
        };

        if (!gigs.length && !isLoading) {
            return null;
        }

        const contextEnrichment = getContextEnrichment(gigs);

        return (
            <WrappedComponent
                {...props}
                gigs={gigs}
                isLoading={isLoading}
                contextEnrichment={contextEnrichment}
            />
        );
    };

    GigCardCarouselHOC.propTypes = {
        showTranslatedUGC: bool,
        type: string.isRequired,
        url: string.isRequired,
        carouselPosition: number,
        collections: array,
        isTouch: bool,
        updateCollections: func,
        source: string,
        carouselContext: string,
        viewportType: string,
    };

    GigCardCarouselHOC.defaultProps = {
        showTranslatedUGC: false,
        collections: [],
        isTouch: false,
        updateCollections: _.noop,
        viewportType: 'gig_page',
    };

    GigCardCarouselHOC.contextTypes = {
        general: object,
        currencyConverter: object,
    };

    const mapStateToProps = ({ ugc, collections }) => {
        const showTranslatedUGC = isTranslated(ugc);

        return {
            showTranslatedUGC,
            collections,
        };
    };

    return connect(mapStateToProps, {
        updateCollections,
    })(GigCardCarouselHOC);
};

export const enrichGigsByCarouselType = (type, gigs) => {
    switch (type) {
        case PROMOTED_MIXED_GIGS:
            return gigs.map((gig) => {
                if (gig.auction?.id) {
                    return {
                    ...gig,
                    type: PROMOTED_GIGS_CAROUSEL,
                    };
                } else {
                    return gig;
                }
            });
        case PROMOTED_GIGS_CAROUSEL:
            return gigs.map((gig) => ({
                ...gig,
                type: PROMOTED_GIGS_CAROUSEL,
            }));
        case OTHER_GIGS_BY:
        case PROMOTED_ORGANIC_GIGS:
        default:
            return gigs;
    }
};

export const enrichGigImpressionByCarouselType = (type, gigs) => {
    switch (type) {
        case PROMOTED_MIXED_GIGS:
            if (!gigs || !gigs.length) {
                metric.count('promoted_ads.promoted_gigs_type.no_expected_gigs');
                return {};
            }

            return {
                type: AUCTION_ALGORITHM_TYPE,
            };
        case PROMOTED_GIGS_CAROUSEL:
            const auction = gigs[0]?.auction;

            if (!gigs || !gigs.length || !auction) {
                metric.count('promoted_ads.promoted_gigs_type.no_expected_gigs_or_auctions');
                return {};
            }

            return {
                type: AUCTION_ALGORITHM_TYPE,
                auction,
            };
        case PROMOTED_ORGANIC_GIGS:
            if (!gigs || !gigs.length) {
                metric.count('promoted_ads.promoted_organic_gigs_type.no_expected_gigs');
                return {};
            }

            return {
                type: AUCTION_ALGORITHM_TYPE,
            };
        case OTHER_GIGS_BY:
        default:
            return {};
    }
};

const buildAuctionData = (type, gigs) => {
    if (type !== PROMOTED_MIXED_GIGS) {
        return {};
    }

    const auctionGig = gigs.find((gig) => !!gig.auction?.id)
    if (!auctionGig) {
        return {};
    }

    const auctionId = auctionGig.auction.id
    const auctionGigsIds = gigs.filter((gig) => !!gig.auction?.id).map((gig) => gig.gig_id);

    return {
        auctionGigsIds,
        auctionId
    };
};

export default GigCardCarouselHOCWrapper;
