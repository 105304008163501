import { getContext } from '@fiverr-private/fiverr_context';

export const translate = (key, additionalParams = {}) => {
    const { locale } = getContext();

    const params = {
        $scope: locale,
        ...additionalParams,
    };

    return i18n.t(key, params);
};
