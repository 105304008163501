import React from 'react';
import { object } from 'prop-types';
import { Container } from '@fiverr-private/layout_components';
import { HighlyResponsiveHighlight } from '@fiverr-private/highlights';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { isHighlyResponsive } from '../../utils/checkHighlyResponsive';
import { isNonExperientialGigTheme } from '../../utils/theme/isNonExperientialGigTheme';
import WithImpressions from '../shared/withImpressions';
import SafePureComponent from '../shared/SafePureComponent';

export const CLASS_NAME = 'highly-responsive';
const IMPRESSIONS_WRAPPER_ID = 'impressions-wrapper';

const HighlyResponsive = ({ impressionRef }) => {
  const { seller, general, gigTheme } = useGigPageContext();
  const shouldRenderHighlyResponsive = !isNonExperientialGigTheme(gigTheme) && isHighlyResponsive({ seller, general });

  if (!shouldRenderHighlyResponsive) {
    return null;
  }

  return (
    <Container className={CLASS_NAME} id={IMPRESSIONS_WRAPPER_ID} ref={impressionRef}>
      <HighlyResponsiveHighlight />
    </Container>
  );
};

HighlyResponsive.propTypes = {
  impressionRef: object,
};

const impressionConfig = {
  DOMSelector: `.${CLASS_NAME} #${IMPRESSIONS_WRAPPER_ID}`,
  eventKey: 'highly_responsive_impression',
};

export { HighlyResponsive };
export default SafePureComponent(WithImpressions(HighlyResponsive, impressionConfig));
