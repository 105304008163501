import React from 'react';
import { Breadcrumb, BreadcrumbsLink, BreadcrumbsHomeIcon } from '@fiverr-private/navigation';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { buildCategoryUrl, buildNestedSubCategoryUrl, buildSubCategoryUrl } from './utils/categoryRoutes';
import {
  translateCategoryName,
  translateNestedSubCategoryName,
  translateSubCategoryName,
} from './utils/categoryTranslation';

export const Breadcrumbs = () => {
  const { pathfinderWrapper, general } = useGigPageContext();
  const { categoryId, categorySlug, subCategoryId, subCategorySlug, nestedSubCategoryId, nestedSubCategorySlug } =
    general;
  const showCategory = !!(categoryId && categorySlug);
  const showSubCategory = !!(showCategory && subCategoryId && subCategorySlug);
  const showNestedSubCategory = !!(showSubCategory && nestedSubCategoryId && nestedSubCategorySlug);

  return (
    <Breadcrumb>
      <BreadcrumbsLink href={pathfinderWrapper.pathfinder('homepage')}>
        <BreadcrumbsHomeIcon />
      </BreadcrumbsLink>
      {showCategory && (
        <BreadcrumbsLink href={buildCategoryUrl({ categorySlug, pathfinderWrapper })}>
          {translateCategoryName(categoryId)}
        </BreadcrumbsLink>
      )}
      {showSubCategory && (
        <BreadcrumbsLink href={buildSubCategoryUrl({ categorySlug, subCategorySlug, pathfinderWrapper })}>
          {translateSubCategoryName(subCategoryId)}
        </BreadcrumbsLink>
      )}
      {showNestedSubCategory && (
        <BreadcrumbsLink
          href={buildNestedSubCategoryUrl({ categorySlug, subCategorySlug, nestedSubCategorySlug, pathfinderWrapper })}
        >
          {translateNestedSubCategoryName(subCategoryId, nestedSubCategoryId)}
        </BreadcrumbsLink>
      )}
    </Breadcrumb>
  );
};
