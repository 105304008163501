import { SELLER_COUPON_TYPES } from '../../components/GigPage/constants';

/*
 * In order for a promotion discount to be applied,
 * the order price should be at least 15$
 */
export const PROMOTIONS_MIN_ORDER_PRICE = 1500;

export const getPromotionDiscountedPrice = (
    originalPrice: number,
    coupon: { type: string; discountValue: number } | null
) => {
    if (!coupon || coupon.type !== SELLER_COUPON_TYPES.PROMOTION || originalPrice < PROMOTIONS_MIN_ORDER_PRICE) {
        return null;
    }

    const discountPercentage = (100 - coupon.discountValue) / 100;

    return originalPrice * discountPercentage;
};
