import React, { useEffect } from 'react';
import { arrayOf, object, string, bool, func } from 'prop-types';
import classnames from 'classnames';
import { NotableClientsBar } from '@fiverr-private/seller_presence';
import { useGigPageContext } from "@fiverr-private/gig_page_context";
import { translate } from "@fiverr-private/i18n-react";
import { MP_VIEWED_NOTABLE_CLIENTS } from '../../../utils/mixPanelEvents';
import { NOTABLE_CLIENTS_ORIGIN } from '../../shared/NotableClients/constants';
import WithImpressions from '../../shared/withImpressions';

import './style.scss';

const CLASS_NAME = 'notable-clients-bar-local';

const NotableClientsBarLocal = (
    { notableClients, sellerName, profilePhoto, wrap, impressionRef, collectImpressions },
) => {
    const { seller: { isAgency }, biEvents } = useGigPageContext();

    const notableClientMixPanelParams = {
        'source component': 'Gig page',
        'seller Name': sellerName,
        'has sbss': !!wrap,
        'number of clients': notableClients.length,
    };
    const barProps = {
        notableClients,
        seller: {
            name: sellerName,
            profilePhoto,
        },
        titleProps: {
            educate: false,
            ...(isAgency && { title: translate('gig_page_perseus.general.among_our_clients') }),
        },
        ...(isAgency && { detailsPopoverProps: { title: translate('gig_page_perseus.general.about_our_work_with') } }),
        wrap,
        origin: NOTABLE_CLIENTS_ORIGIN,
    };

    useEffect(() => {
        collectImpressions();
        biEvents.sendMixPanelEvent({
            eventName: MP_VIEWED_NOTABLE_CLIENTS,
            params: {
                ...notableClientMixPanelParams,
                'clients name': notableClients.map((client) => client.name),
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={classnames(CLASS_NAME, { 'wrap-elements': wrap })} ref={impressionRef}>
            <NotableClientsBar {...barProps} />
        </div>
    );
};

NotableClientsBarLocal.propTypes = {
    notableClients: arrayOf(object),
    sellerName: string,
    profilePhoto: string,
    wrap: bool,
    impressionRef: object,
    collectImpressions: func,
};

const impressionConfig = {
    DOMSelector: `.${CLASS_NAME} > *`,
    eventKey: 'notable_client_above_the_fold_component_is_shown',
};

export { NotableClientsBarLocal };
export default WithImpressions(NotableClientsBarLocal, impressionConfig);
