import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { number, bool, func, object } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Tag } from '@fiverr-private/fit';
import { LazyComponent } from '@fiverr-private/orca';
import { getPackageTypeById } from '../../utils/packagesUtils';
import { isLoggedInUser } from '../../utils/isLoggedInUser';
import { getPackageListCount, getSelectedPackageId } from '../../reducers/packages';
import { isActiveMilestonePay, selectWorkProcessMilestonesForCurrentPackage } from '../../reducers/workProcess';
import WithImpressions from '../shared/withImpressions';
import SafePureComponent from '../shared/SafePureComponent';
import { Workflow } from '../Workflow';
import { WORK_PROCESS } from '../../utils/pageSections';
import { Waypoint } from '../Waypoint/Waypoint';
import { hasSeenWorkProcessEducationModal } from './utils';
import { workProcessMilestonesType } from './propTypes';
import WorkProcessItem from './WorkProcessItem';

import './index.scss';

const WorkProcess = (
    { workProcessMilestones, selectedPackageId, packageListCount, activePay, impressionRef, collectImpressions },
    { logger }
) => {
    const lazyImportEducationModal = () =>
        import(/* webpackChunkName: 'WorkProcessEducationModal' */ './WorkProcessEducationModal');

    const onLazyImportError = (error) => {
        logger.error(error, {
            description: 'Failed to lazy load WorkProcessEducationModal',
        });
    };

    if (isEmpty(workProcessMilestones)) {
        return null;
    }

    // In case of 1 milestone, it's a converted workflow, so we want to display the old workflow view
    if (workProcessMilestones.length === 1) {
        const [{ steps }] = workProcessMilestones;

        return (
            <Workflow selectedPackageId={selectedPackageId} packageListCount={packageListCount} workflowSteps={steps} />
        );
    }

    const milestoneElements = workProcessMilestones.map(
        ({ order, title, description, currentPackage, steps }, index) => {
            const { price, duration, revisions } = currentPackage;

            return (
                <WorkProcessItem
                    key={order}
                    milestoneNumber={index + 1}
                    title={title}
                    description={description}
                    price={activePay && price}
                    duration={duration}
                    revisions={revisions}
                    selectedPackageId={selectedPackageId}
                    steps={steps}
                />
            );
        }
    );

    if (isEmpty(milestoneElements)) {
        return null;
    }

    const { isMobile } = getContext();
    const packageType = translate(getPackageTypeById(selectedPackageId));
    const activePayKeySuffix = activePay ? 'active_pay' : 'no_active_pay';
    const titleKey = `gig_page_perseus.workProcess.title.${activePayKeySuffix}`;
    const subTitleKey = `gig_page_perseus.workProcess.subtitle.${activePayKeySuffix}`;
    const displayModal = activePay && !isMobile && isLoggedInUser() && !hasSeenWorkProcessEducationModal();

    return (
        <div className="gig-work-process" ref={impressionRef}>
            <LazyComponent
                lazyImport={lazyImportEducationModal}
                onError={onLazyImportError}
                shouldImport={displayModal}
            />
            <Waypoint source={WORK_PROCESS} onEnter={collectImpressions} />
            <header className="m-t-36 milestones-header">
                <h2 className="flex m-b-16 work-process-title">
                    <I18n k={titleKey} />
                    {activePay && (
                        <Tag className="new-tag" size={Tag.SIZES.TINY} color={Tag.COLORS.PINK}>
                            <I18n k="gig_page_perseus.workProcess.new" />
                        </Tag>
                    )}
                </h2>
                <p className="work-process-subtitle">
                    <I18n k={subTitleKey} />
                </p>
            </header>
            <ul className="flex flex-col tbody-6 milestones-container">
                {!isMobile && (
                    <span className="m-b-24">
                        <I18n k="gig_page_perseus.workProcess.milestone.intro" params={{ packageType }} />
                    </span>
                )}
                {milestoneElements}
            </ul>
        </div>
    );
};

WorkProcess.propTypes = {
    workProcessMilestones: workProcessMilestonesType,
    activePay: bool,
    selectedPackageId: number,
    packageListCount: number,
    impressionRef: object,
    collectImpressions: func,
};

WorkProcess.contextTypes = {
    logger: object,
};

const mapStateToProps = (state) => ({
    packageListCount: getPackageListCount(state),
    selectedPackageId: getSelectedPackageId(state),
    workProcessMilestones: selectWorkProcessMilestonesForCurrentPackage(state),
    activePay: isActiveMilestonePay(state),
});

export { WorkProcess };

const ConnectedWorkProcess = connect(mapStateToProps)(WorkProcess);

const buildImpressionData = ({ paymentType }) => ({ paymentType });

const impressionConfig = {
    DOMSelector: '.gig-work-process .milestones-header',
    eventKey: 'gig_work_process',
    buildImpressionData,
};

export default SafePureComponent(WithImpressions(ConnectedWorkProcess, impressionConfig));
