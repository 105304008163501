import React from 'react';
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { NUMERIC_TYPE } from '../../../utils/packagesUtils/constants';
import { formatPricingFactorValue, isFeatureFiltered } from '../../../utils/packagesUtils';
import BooleanPricingFactor from '../BooleanPricingFactor';
import NumberedPricingFactor from '../NumberedPricingFactor';
import PricingFactorLabel from '../PricingFactorLabel';

const PricingFactorRow = ({ name, rowData = [] }) => {
    if (!rowData.length) {
        return null;
    }

    return (
        <tr>
            <td className="package-row-label">
                <PricingFactorLabel name={name} />
            </td>
            {rowData.map((feature) => {
                const { value, included, packageId, type, name } = feature;
                const isCustomizedForYouFeature = isFeatureFiltered({ name });

                return type === NUMERIC_TYPE && included ? (
                    <NumberedPricingFactor key={packageId} value={formatPricingFactorValue(value)} />
                ) : (
                    <BooleanPricingFactor key={packageId} included={included || isCustomizedForYouFeature} />
                );
            })}
        </tr>
    );
};

PricingFactorRow.propTypes = {
    name: string.isRequired,
    rowData: arrayOf(
        shape({
            id: number,
            value: number,
            included: bool,
            packageId: number,
            type: string,
        })
    ),
};

export default PricingFactorRow;
