import React from 'react';
import classnames from 'classnames';
import { Center, Container, Stack } from '@fiverr-private/layout_components';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { getContext } from '@fiverr-private/fiverr_context';
import { DisplayName } from '../../../Overview/SellerProfile/DisplayName';
import { ProBadge } from '../../../shared/ProBadge/ProBadge';
import Status from '../Status';
import { Badges } from '../../../Overview/Badges/Badges';
import { ROLLOUTS } from '../../../../types/rollouts';

interface SellerDetailsProps {
  sellerUrl: any;
  onLinkClick: () => void;
}

export const SellerDetails: React.FC<SellerDetailsProps> = ({ sellerUrl, onLinkClick }) => {
  const {
    seller: { isPro, isAgency, displayName },
    rollouts,
  } = useGigPageContext();

  const { pathParameters } = getContext();
  const { username } = pathParameters;
  const displayClassname = classnames({ 'text-display-4': isAgency });
  const inNewSellerLevelBadgesRollout = rollouts[ROLLOUTS.QUALAS_NEW_LEVEL_SYSTEM_CONSUMERS_MIGRATION];
  const showOnlyProBadge = !inNewSellerLevelBadgesRollout && isPro;

  return (
    <Stack gap={{ sm: '3', default: '2' }} justifyContent="center" alignItems="center" width="fit-content" wrap="wrap">
      <DisplayName
        classname={displayClassname}
        displayName={displayName}
        username={username}
        sellerUrl={sellerUrl}
        onClick={onLinkClick}
      />
      {showOnlyProBadge && (
        <Center height="100%" width="fit-content">
          <ProBadge isAgency={isAgency} />
        </Center>
      )}
      {inNewSellerLevelBadgesRollout && <Badges />}
      <Container height="fit-content" paddingTop="0.5" width="fit-content" paddingLeft={{ default: '0', sm: '7' }}>
        <Status />
      </Container>
    </Stack>
  );
};
