import { merge, snakeCase } from 'lodash';
import { PAGE_NAME } from '../../../bigQueryEventsData';
import { BASE_PARAMS } from '../constants';

export const activePaymentWorkProcessEventType = 'gig_page_milestone_payment_is_shown';
export const noActivePaymentWorkProcessEventType = 'gig_page_work_process_is_shown';

/**
 * Return a creator method for returning data for work process impression event for BQ
 * @returns {({userId: number, pageCtxId: number, paymentType: string, enrichmentParams: object}) => object}
 */
export const createWorkProcessComponentIsShown =
    () =>
    /**
     * The function used to return enriched data for work process component show
     * @param {number} userId The current user id
     * @param {number} pageCtxId The current page context id
     * @param {string} paymentType The gig's payment type
     * @param {object} enrichmentParams Any params needed to add to the data
     * @returns {object} enrichment object
     */
    ({ userId, pageCtxId, paymentType, ...enrichmentParams } = {}) =>
        merge({}, BASE_PARAMS, enrichmentParams, {
            type: paymentType,
            event_source: snakeCase(PAGE_NAME),
            user: { id: userId },
            page: merge(
                {},
                {
                    ctx_id: pageCtxId,
                    name: PAGE_NAME,
                }
            ),
        });
