import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Center } from '@fiverr-private/layout_components';

import { sparkleImageClass } from './ProBenefitsBanner.style.ve.css';

const SVG_TITLE_KEY = 'gig_page_banners.pro_benefits_banner.sparkle';

export const Sparkle = () => (
  <Center width="fit-content">
    <svg
      className={sparkleImageClass}
      role="img"
      aria-label={translate<string>(SVG_TITLE_KEY)}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      viewBox="0 0 102.7 110.9"
    >
      <path
        fill="#b1abff"
        stroke="#b1abff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M75.5 76.8c.2-1.5-1.7-4.3-3-5.9-3.5 1.6-6.4 4.1-8.6 7.3-2.1 3.2-3.3 6.9-3.4 10.7-.1 3.8.8 7.6 2.7 10.9 1.9 3.3 4.7 6 8.1 7.8 1.5-10.7 4-29.2 4.2-30.8zM95.8 86.2c-.5 4.2-3.2 15.9-3.9 20.9 1.4-.8 2.6-1.8 3.8-3 7.6-7.6 7.9-19.7 1.5-27.5-.6 3-1.3 8.8-1.4 9.6z"
      />
      <path
        fill="none"
        stroke="#222325"
        strokeLinejoin="bevel"
        strokeWidth="1.5"
        d="M19.9 33.1 2.4 27.8M80.3 62.1 32.8 39.8M11.4 6.4l12.8 20.2M30 23l-2.5-10.1M37.1 24.8 47.6 1M63.5 19.2l3.5-1.9M43.7 30.1l16.9-9.3M54 31.9l7.9-2.9M46.8 37.5l25.3-.5M52.6 62l4.2 5M41.2 48.6l7.3 8.6M34.1 50.5 38 72.2M27.3 49.3l-8 22.8M20.2 42.2 12 45.6M9.1 47 1 50.4M13.8 23.6l4.4 1.5M16.3 22.1l-.6 4.6M53.8 44l4.9.5M56 41.8l.6 4.9M14.6 54.1l5.5-.5M17.2 51.1l.5 5.5"
      />
      <path
        fill="#b1abff"
        stroke="#b1abff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M8 39.1c.7-.7.7-1.8 0-2.5s-1.8-.7-2.5 0-.7 1.8 0 2.5 1.8.7 2.5 0z"
      />
      <path
        fill="#fff"
        stroke="#b1abff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M36.9 40.4c2.5-2.5 2.5-6.5 0-9s-6.5-2.5-9 0-2.5 6.5 0 9 6.5 2.5 9 0z"
      />
      <path
        fill="#b1abff"
        stroke="#b1abff"
        strokeMiterlimit="10"
        strokeWidth="1.5"
        d="M50.9 18c.7-.7.7-1.8 0-2.5s-1.8-.7-2.5 0-.7 1.8 0 2.5 1.8.7 2.5 0zM45.7 64.4c.7-.7.7-1.8 0-2.5s-1.8-.7-2.5 0-.7 1.8 0 2.5c.6.7 1.8.7 2.5 0z"
      />
      <path
        fill="none"
        stroke="#222325"
        strokeLinejoin="bevel"
        strokeWidth="1.5"
        d="M83.1 51.7c-2.8-7.9-5.8-13-3.5-14.9.1-.1.2-.2.4-.3.2-.1.3-.1.5-.1s.3 0 .5.1.3.1.4.3c.1 0 .1.1.2.2 1.9 1.9 4 7.1 5.1 9.4 1.4 3.1 4.7 11.1 4.7 11.1"
      />
      <path
        fill="none"
        stroke="#222325"
        strokeWidth="1.5"
        d="M87 47.1c-1.1-3.9-2.9-8.8-1.1-10.7 1.9-1.9 3.7 4 4.8 7 1.1 3 4.2 12.9 4.2 12.9s-.3-10-.3-12.7 1.2-3.6 1.9-3.3c1.2.3 2 2.2 2.6 8.7.6 6.4-.8 21.1-1.7 25.8-.9 4.8-1.7 9.8-2.1 13.3-.4 3.4-3.9 19.4-3.9 19.4-11.7 5.2-20.1.3-20.1.3l-.2-.1 4.2-30.8c.1-2.1-2.1-4.6-4.7-8.2-1.7-2.2-3.3-4.6-4.7-7.1-1.3-2.5-2.3-4.7-.9-5.8 1.4-1.1 4.9 2 7.4 5.2s6.2 5 8.2 1.4-2.8-7.3-5.1-7.4-7.4 1.4-9 0c-1.7-1.4-2-3.4 4.1-4.5 6-1.1 10.3-.5 11.9.6s4.5 8.3 4.5 8.3"
      />
      <path fill="#222325" d="m73.4 91.9 20.8 2.8-2.5 12.7s-7.1 2.9-11.5 2.4c-3-.4-5.9-1.1-8.8-2l2-15.9z" />
      <path
        fill="#2e25ad"
        fillRule="evenodd"
        d="M34 27.1c-1-.8-2.4-.8-3.4 0L29 28.5c-.3.3-.7.5-1.1.5l-2 .5c-1.3.3-2.1 1.4-2.1 2.7v2.1c0 .4-.1.8-.3 1.2l-.9 1.9c-.6 1.2-.2 2.6.8 3.4l1.6 1.3c.3.3.6.6.8 1l.9 1.9c.6 1.2 1.8 1.8 3.1 1.5l2-.5c.4-.1.8-.1 1.2 0l2 .5c1.3.3 2.5-.3 3.1-1.5l.9-1.9c.2-.4.4-.7.8-1l1.6-1.3c1-.8 1.3-2.2.8-3.4l-.9-1.9c-.2-.4-.3-.8-.3-1.2v-2.1c0-1.3-.9-2.5-2.1-2.7l-2-.5c-.4-.1-.8-.3-1.1-.5L34 27.1zm3.2 8.5c.4-.4.4-1.1 0-1.5s-1.1-.4-1.5 0l-4 4.1-2.1-2c-.4-.4-1.1-.4-1.5 0s-.4 1.1 0 1.5l2.8 2.7c.4.4 1 .4 1.4 0l4.9-4.8z"
        clipRule="evenodd"
      />
    </svg>
  </Center>
);
