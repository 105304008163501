import { useEffect, useState } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { localStorage } from '@fiverr-private/futile';
import { logger } from '@fiverr-private/obs';
import pathfinder from '@fiverr-private/pathfinder';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { maxios } from '../../utils/maxios';
import { ROLLOUTS } from '../../types/rollouts';

interface StoredTokenData {
    token: string;
    expirationTime: number;
}

const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
const isTokenDataValid = (storedTokenData: StoredTokenData) =>
    storedTokenData.token && Date.now() < storedTokenData.expirationTime;

const shouldRenderPayLaterMessage = (rollouts: Record<string, boolean>) => {
    const { countryCode, isPro } = getContext();
    if (isPro || countryCode !== 'US') {
        return false;
    }

    return rollouts[ROLLOUTS.PIGLETS_GIG_PAGE_PAYPAL_PAYLATER_MESSAGE];
};

export const usePaypalPaylaterTokenHook = () => {
    const { rollouts } = useGigPageContext();
    const { userId } = getContext();
    const [paypalClientToken, setPaypalClientToken] = useState<null | string>(null);

    useEffect(() => {
        try {
            if (!shouldRenderPayLaterMessage(rollouts)) {
                setPaypalClientToken(null);
                return;
            }

            const tokenKey = `paypal_client_token_${userId}`;
            const storedTokenData = JSON.parse(localStorage.get(tokenKey)) as StoredTokenData | null;

            if (storedTokenData && isTokenDataValid(storedTokenData)) {
                setPaypalClientToken(storedTokenData.token);
            } else {
                maxios
                    .post(pathfinder('gig_page_generate_paypal_client_token'), {
                        routeKey: 'gig_page_generate_paypal_client_token',
                    })
                    .then((response) => {
                        const paypalClientToken = response?.data?.paypal_client_token;
                        if (paypalClientToken) {
                            localStorage.set(
                                tokenKey,
                                JSON.stringify({ token: paypalClientToken, expirationTime: Date.now() + DAY_IN_MILLIS })
                            );
                            setPaypalClientToken(paypalClientToken);
                        }
                    })
                    .catch((error) => {
                        throw error;
                    });
            }
        } catch (error) {
            logger.error(error as Error, {
                description: 'Failed to get paypal client token',
                userId,
            });
            setPaypalClientToken(null);
        }
    }, [rollouts, userId]);

    return paypalClientToken;
};
