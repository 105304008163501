import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const ServiceNameRow = ({ name }) => (
    <tr className="service-name-row">
        <td colSpan="4">{name}</td>
    </tr>
);

ServiceNameRow.propTypes = {
    name: PropTypes.string.isRequired,
};

export default ServiceNameRow;
