import React from 'react';
import { noop } from 'lodash';
import { ArrowRightIcon } from '@fiverr-private/icons';
import { Button, IconButton } from '@fiverr-private/button';
import { Stack, Wrap } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { Box } from '@fiverr-private/theme';

interface ContactSellerOptionProps {
  title: string;
  IconComponent: React.ReactNode;
  loading?: boolean;
  onClick?: () => void;
}

export const ContactSellerOption: React.FC<ContactSellerOptionProps> = ({
  title,
  IconComponent,
  loading = false,
  onClick = noop,
  ...props
}) => (
  <Button variant="ghost" loading={loading} onClick={onClick} {...props} fullWidth>
    <Stack gap="4" justifyContent="spaceBetween" flex="1" alignItems="center">
      <Box flexShrink="0">
        <IconButton aria-label="Video Icon" shape="circle" disabled={true}>
          <Box display="flex">{IconComponent}</Box>
        </IconButton>
      </Box>

      <Wrap justifyContent="flexStart">
        <Text fontWeight="semibold">{title}</Text>
      </Wrap>

      <Box flexShrink="0" display="inlineFlex">
        <ArrowRightIcon />
      </Box>
    </Stack>
  </Button>
);
