import { TOGGLE_LIST, TOGGLE_ALL } from '../../actions/packagesTabs';

const DEFAULT_SHOW_ALL = false;

const packagesTabsReducer = (tabItems = []) => {
    const initialState = {
        showAll: DEFAULT_SHOW_ALL,
        tabItems: tabItems.map((tabItem) => ({
            ...tabItem,
            collapsed: !DEFAULT_SHOW_ALL,
        })),
    };

    return (state = initialState, action) => {
        const { type, data } = action;
        let newTabItems;

        switch (type) {
            case TOGGLE_LIST:
                newTabItems = state.tabItems.map((tabItem) => {
                    if (tabItem.id === data.id) {
                        return { ...tabItem, collapsed: !tabItem.collapsed };
                    } else {
                        return tabItem;
                    }
                });

                return {
                    ...state,
                    tabItems: newTabItems,
                };
            case TOGGLE_ALL:
                const newShowAll = !state.showAll;
                newTabItems = state.tabItems.map((tabItem) => ({ ...tabItem, collapsed: !newShowAll }));

                return {
                    tabItems: newTabItems,
                    showAll: newShowAll,
                };
            default:
                return state;
        }
    };
};

export default packagesTabsReducer;
