import pathfinder from '@fiverr-private/pathfinder';

class PathfinderWrapper {
    constructor(logger) {
        this.logger = logger;

        this.errorCallback = this.errorCallback.bind(this);
    }

    errorCallback(errorMsg) {
        this.logger.warn({ message: errorMsg });
    }

    pathfinder(namedRoute, userParams = null, options = {}) {
        return pathfinder(namedRoute, userParams, { ...options, errorCallback: this.errorCallback });
    }
}

export default PathfinderWrapper;
