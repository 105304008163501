export const TOP_PAGE = 'topPage';
export const OVERVIEW = 'overview';
export const THE_SERVICE = 'theService';
export const DESCRIPTION = 'description';
export const ABOUT_THIS_GIG = 'aboutThisGig';
export const ABOUT_EXPERT = 'aboutExpert';
export const FAQ = 'faq';
export const REVIEWS = 'reviews';
export const ORDER_DETAILS = 'orderDetails';
export const RECOMMENDATIONS = 'recommendations';
export const ABOUT_SELLER = 'aboutSeller';
export const PACKAGES_TABLE = 'packagesTable';
export const WORK_PROCESS = 'workProcess';
