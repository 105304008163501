import { isEmpty } from 'lodash';

export const calcAgencyData = ({ seller }) => {
    const hasAgencyData = !isEmpty(seller?.agency);
    const isAgency = hasAgencyData && seller.agency.status === 'ACTIVE';

    const agency = {
        ...(isAgency ? seller.agency : {}),
    };

    return { isAgency, agency };
};
