import React from 'react';
import { string } from 'prop-types';

const SeoScript = ({ scriptContent }) => (
    <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: scriptContent }} />
);

SeoScript.propTypes = {
    scriptContent: string,
};

export default SeoScript;
