import React from 'react';
import { isEmpty } from 'lodash';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { ProSubCategory } from '../../../types/seller';
import { NewLayoutSubcategoriesList } from './NewLayoutSubcategoriesList/NewLayoutSubcategoriesList';
import DefaultSubcategoriesList from './DefaultSubcategoriesList';

interface ProSubcategoriesProps {
  proSubcategories?: ProSubCategory[];
  isPro?: boolean;
}

// TODO: for the test-out we need to move the proSubcategories to the Gig Page Context, to reduce prop drilling
export const ProSubcategories: React.FC<ProSubcategoriesProps> = ({ proSubcategories = [], isPro = false }) => {
  const { isBusinessLayout, isProRebrandingLayout } = useGigPageContext();

  if (!isPro || isEmpty(proSubcategories)) {
    return null;
  }

  const showRebrandedSubcategoriesList = isProRebrandingLayout || isBusinessLayout;

  return (
    <>
      {showRebrandedSubcategoriesList ? (
        <NewLayoutSubcategoriesList proSubcategories={proSubcategories} />
      ) : (
        <DefaultSubcategoriesList proSubCategories={proSubcategories} />
      )}
    </>
  );
};
