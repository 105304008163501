import { Rating } from '@fiverr-private/orca';

export const calcRatingColor = ({ isBusinessLayout, isPro, isProRebrandingLayout }) => {
    let color;

    if (isBusinessLayout || isProRebrandingLayout) {
        color = Rating.COLORS.BLACK;
    } else if (isPro) {
        color = Rating.COLORS.PRO;
    } else {
        color = Rating.COLORS.YELLOW;
    }

    return color;
};
