import React from 'react';
import { bool } from 'prop-types';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import { r_check } from '@fiverr-private/fit/icons';

import styles from './style.module.scss';

const BooleanPricingFactor = ({ included }, { general, isBusinessLayout, isProRebrandingLayout }) => {
    const { isPro } = general;

    const getAdditionalIconClass = () => {
        if (isBusinessLayout || isProRebrandingLayout) {
            return styles.newProLayout;
        } else if (isPro) {
            return styles.marketplaceProGig;
        } else {
            return '';
        }
    };

    const iconClassName = classNames(styles.pricingFactorCheckIcon, getAdditionalIconClass(), {
        [styles.included]: included,
    });

    return (
        <td>
            <Icon className={iconClassName}>{r_check()}</Icon>
        </td>
    );
};

BooleanPricingFactor.propTypes = {
    included: bool,
};

BooleanPricingFactor.contextTypes = {
    general: {
        isPro: bool,
    },
    isBusinessLayout: bool,
    isProRebrandingLayout: bool,
};

export default BooleanPricingFactor;
