import { RECURRING_TIME_UNITS } from '../../../types/recurringTimeUnits';
import { SUB_CATEGORIES } from '../../../types/categories';
import { translate } from '../../translation';
import { RECURRING_NONE_OPTION } from '../constants';

const ROOT = 'gig_page_perseus';
const GENERAL = `${ROOT}.general`;
const RECURRING = `${ROOT}.recurring`;
const HOW_LONG_AGO = `${GENERAL}.how_long_ago`;

export const translateTimeUnit = (duration, durationTimeUnit) => {
    const key = getTimeUnitKey(durationTimeUnit);

    return translateDuration(duration, key);
};

export const translateHowLongAgo = (duration, durationTimeUnit) => {
    const key = getHowLongAgoKey(durationTimeUnit);
    const isNoneOption = duration === RECURRING_NONE_OPTION.duration;

    return isNoneOption ? getNoneOptionTitle() : translateDuration(duration, key);
};

const getTimeUnitKey = (durationTimeUnit) => {
    switch (durationTimeUnit) {
        case RECURRING_TIME_UNITS.WEEK:
            return `${GENERAL}.week`;
        case RECURRING_TIME_UNITS.MONTH:
            return `${GENERAL}.month`;
        default:
            return `${GENERAL}.month`;
    }
};

const getHowLongAgoKey = (durationTimeUnit) => {
    switch (durationTimeUnit) {
        case RECURRING_TIME_UNITS.WEEK:
            return `${HOW_LONG_AGO}.weeks_ago`;
        case RECURRING_TIME_UNITS.MONTH:
            return `${HOW_LONG_AGO}.months_ago`;
        default:
            return `${HOW_LONG_AGO}.months_ago`;
    }
};

const getNoneOptionTitle = () => translate(`${GENERAL}.none`);

const translateDuration = (duration, key) =>
    translate(key, {
        count: duration,
    });

export const translatePricingSummary = ({ formattedPrice, duration, durationTimeUnit }) => {
    const timeUnit = translateTimeUnit(duration, durationTimeUnit);

    const params = {
        price: formattedPrice,
        short_time_unit: timeUnit.substr(0, 2),
        time_unit: timeUnit,
    };

    return translate(`${RECURRING}.pricing_summary`, params);
};

/**
 * Return subscription discount key suffix for translation
 * @param {boolean} singleDiscount does the recurring option has only one discount option
 * @returns {string} the discount key suffix
 */
export const getDiscountKeySuffix = (singleDiscount) => (singleDiscount ? 'single' : 'multiple');

/**
 * Return subscription title key suffix for translation
 * @param {boolean} hasDiscount does the recurring option has any discount option
 * @returns {string} the title key suffix
 */
export const getSubscriptionTitleKeySuffix = (hasDiscount) => (hasDiscount ? 'with_discount' : 'regular');

/**
 * Return subscription subtitle key suffix for translation
 * @param {boolean} hasDiscount does the recurring option has any discount option
 * @param {boolean} singleDiscount does the recurring option has only one discount option
 * @returns {string} the subtitle key suffix
 */
export const getSubscriptionSubtitleKeySuffix = ({ hasDiscount, singleDiscount }) => {
    const keySuffix = getSubscriptionTitleKeySuffix(hasDiscount);

    return hasDiscount ? `${keySuffix}.${getDiscountKeySuffix(singleDiscount)}` : keySuffix;
};

/**
 * Return subscription subtitle key suffix for translation
 * @param {number} subCategoryId
 * @returns {string|null} the subtitle key suffix
 */
export const getSubscriptionRecommendedForSubtitleKeySuffix = (subCategoryId) => {
    switch (subCategoryId) {
        case SUB_CATEGORIES.SEARCH_ENGINE_OPTIMIZATION:
            return 'seo';
        case SUB_CATEGORIES.SOCIAL_MARKETING:
            return 'social_media_marketing';
        default:
            return null;
    }
};
