import { combineReducers } from 'redux';
import { DEFAULT_TAB_ITEM_ID } from '../utils/packagesUtils/constants';
import checkoutPlanReducer from './checkoutPlan';
import packagesReducer from './packages';
import packagesTabsReducer from './packagesTabs';
import ugcReducer from './ugc';
import collectionsReducer from './collections';
import sellerReducer from './seller';
import workProcessReducer from './workProcess';
import widgetsReducer from './widgets';

export default ({ packages, studio, general, ugcMap, showOriginal, workProcess, rollouts }) => {
    const { isStudio } = general;
    const ugc = { ugcMap, showOriginal };
    let tabItems = [{ id: DEFAULT_TAB_ITEM_ID, name: 'gig_page_perseus.general.whats_included' }];

    if (isStudio) {
        tabItems = studio.services;
    }

    return combineReducers({
        packages: packagesReducer({ packages, general, ugc }),
        packagesTabs: packagesTabsReducer(tabItems),
        ugc: ugcReducer({ ugcMap, showOriginal }),
        collections: collectionsReducer(),
        seller: sellerReducer(),
        workProcess: workProcessReducer(workProcess),
        checkoutPlan: checkoutPlanReducer({ workProcess, general, rollouts, packages }),
        widgets: widgetsReducer(),
    });
};
