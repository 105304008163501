import React from 'react';
import { LazyComponent } from '@fiverr-private/orca';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { getContext } from '@fiverr-private/fiverr_context';
import { metric } from '../../../utils/metric';
import SafePureComponent from '../../shared/SafePureComponent';
import { LAZY_LOAD_ERROR_PREFIX } from '../../../utils/constants';
import { useContactAvailability } from '../../../hooks';

const FloatingContactSeller = (props) => {
    const { isMobile } = getContext();
    const { logger } = useGigPageContext();
    const { isContactAvailable } = useContactAvailability();

    const lazyImport = () => import(/* webpackChunkName: 'FirstMessagePopover' */ './FirstMessagePopover');

    const onError = (error) => {
        metric.count(`${LAZY_LOAD_ERROR_PREFIX}.fmp.failure`);
        logger.warn(error, {
            description: 'Failed to lazy load FirstMessagePopover',
        });
    };

    const shouldImport = isContactAvailable && !isMobile;

    return (
        <LazyComponent
            lazyImport={lazyImport}
            fallback={null}
            shouldImport={shouldImport}
            componentProps={props}
            onError={onError}
        />
    );
};

export { FloatingContactSeller };
export default SafePureComponent(FloatingContactSeller);
