import React, { Component } from 'react';
import { func, number, object } from 'prop-types';
import { connect } from 'react-redux';
import { Input } from '@fiverr-private/fit';
import { updateCalculator } from '../../../../actions/packages';
import { CALC_MAX } from '../../../../utils/packagesUtils/constants';

const onKeyUp = (e) => {
    const inputEl = e.target;

    // In case the user clears input we want it to stay empty
    if (inputEl.value === '') {
        return true;
    }

    let newValue = Number(inputEl.value); // Number is also for removal of a leading zero if exists

    if (newValue > CALC_MAX) {
        newValue = CALC_MAX;
    }
    inputEl.value = newValue;
};

class CalculatorInput extends Component {
    constructor() {
        super();

        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onChange(e) {
        const value = e.target.value;

        if (Number(value) > CALC_MAX) {
            return;
        }

        const newValue = value !== '' ? Number(value) : value;

        this.recalculatePrice(newValue);
    }

    onBlur(e) {
        const { initValue } = this.props;
        const { value } = e.target;

        // When the input is empty, change it and the price to default
        if (value === '' || Number(value) < initValue) {
            this.recalculatePrice(initValue);
        }
    }

    recalculatePrice(newValue) {
        const { updateCalculator, packageId, id } = this.props;

        updateCalculator({ id, value: newValue, packageId });
    }

    render() {
        const { currentValue } = this.props;

        return (
            <Input
                className="calculator-input"
                type="number"
                max={CALC_MAX}
                value={currentValue}
                onChange={this.onChange}
                onKeyUp={onKeyUp}
                onBlur={this.onBlur}
            />
        );
    }
}

CalculatorInput.propTypes = {
    packageId: number,
    id: number,
    currentValue: number,
    initValue: number,
    updateCalculator: func,
};

CalculatorInput.defaultProps = {
    updateCalculator: () => true,
};

CalculatorInput.contextTypes = {
    general: object,
};

const dispatchUpdateCalculator =
    ({ id, value, packageId }) =>
    (dispatch) => {
        dispatch(updateCalculator({ calculatorId: id, value, packageId }));
    };

export { CalculatorInput };

export default connect(null, {
    updateCalculator: dispatchUpdateCalculator,
})(CalculatorInput);
