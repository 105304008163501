import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import { Stack } from '@fiverr-private/layout_components';
import { useGigPageContext } from '@fiverr-private/gig_page_context';
import { theme, Types } from '@fiverr-private/theme';
import { BenefitBullet } from './BenefitBullet';

const handpickedKey = 'gig_page_banners.pro_benefits_banner.bullets.hand_picked_freelancer';
const highQualityKey = 'gig_page_banners.pro_benefits_banner.bullets.high_quality_work';
const experienceKey = 'gig_page_banners.pro_benefits_banner.bullets.experience';
const satisfactionKey = 'gig_page_banners.pro_benefits_banner.bullets.satisfaction';

export const BenefitBullets = () => {
  const { isBusinessLayout } = useGigPageContext();

  if (isBusinessLayout) {
    const minWidth: Types.ResponsiveWidthType = `calc(50% - ${theme.spacing['1']})`;
    return (
      <Stack direction="row" gap="2" wrap="wrap">
        <Stack gap="2" direction="column" minWidth={minWidth} width="fit-content">
          <BenefitBullet key={handpickedKey}>{translate(handpickedKey)}</BenefitBullet>
          <BenefitBullet key={experienceKey}>{translate(experienceKey)}</BenefitBullet>
        </Stack>
        <Stack gap="2" direction="column" minWidth={minWidth} width="fit-content">
          <BenefitBullet key={highQualityKey}>{translate(highQualityKey)}</BenefitBullet>
          <BenefitBullet key={satisfactionKey}>{translate(satisfactionKey)}</BenefitBullet>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="column" gap="2">
      <BenefitBullet key={handpickedKey}>{translate(handpickedKey)}</BenefitBullet>
      <BenefitBullet key={highQualityKey}>{translate(highQualityKey)}</BenefitBullet>
      <BenefitBullet key={experienceKey}>{translate(experienceKey)}</BenefitBullet>
    </Stack>
  );
};
