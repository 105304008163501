import React, { useEffect } from 'react';
import { string, object, bool, func } from 'prop-types';
import classnames from 'classnames';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { MP_VIEWED_LOYALTY_SELLER_BANNER } from '../../../utils/mixPanelEvents';
import WithImpressions from '../../shared/withImpressions';
import { TROPHY_GIF_PATH } from './assets';

import './style.scss';

const CLASS_NAME = 'seller-loyalty-banner';

export const titleKey = 'gig_page_perseus.loyalty_seller.title';
export const titleKeyAlt = 'gig_page_perseus.loyalty_seller.title_alt';

const LoyaltySellerBanner = (
    { sellerName, wrap = false, displayAltTitle = false, impressionRef, collectImpressions },
    { biEvents }
) => {
    useEffect(() => {
        collectImpressions();
        biEvents.sendMixPanelEvent({
            eventName: MP_VIEWED_LOYALTY_SELLER_BANNER,
            params: { 'Source component': 'Gig page' },
        });
    }, [biEvents, collectImpressions]);

    return (
        <div className={classnames(CLASS_NAME, { wrap: !!wrap })} ref={impressionRef}>
            <img src={TROPHY_GIF_PATH} alt={translate('gig_page_perseus.loyalty_seller.alt')} />
            <I18n k={displayAltTitle ? titleKeyAlt : titleKey} params={{ sellerName }} />
        </div>
    );
};

LoyaltySellerBanner.propTypes = {
    sellerName: string.isRequired,
    displayAltTitle: bool,
    impressionRef: object,
    collectImpressions: func,
    wrap: bool,
};

LoyaltySellerBanner.contextTypes = {
    biEvents: object,
};

const impressionConfig = {
    DOMSelector: `.${CLASS_NAME} > img`,
    eventKey: 'loyalty_banner',
};

export { LoyaltySellerBanner };
export default WithImpressions(LoyaltySellerBanner, impressionConfig);
