const { DELETED, DENIED } = require('../../utils/constants/gigStatuses');
const { ACTIVE, PENDING } = require('../../utils/constants/userStatuses');

const { CATEGORIES, SUB_CATEGORIES } = require('../../types/categories');

/*
 * Allowed categories for custom order
 * */
const ALLOWED_CATEGORIES = [CATEGORIES.PROGRAMMING_TECH];

/*
 * Allowed subCategories for custom order
 * */
const ALLOWED_SUB_CATEGORIES = [
    SUB_CATEGORIES.VOICE_OVERS,
    SUB_CATEGORIES.SOCIAL_MARKETING,
    SUB_CATEGORIES.RESUMES_COVER_LETTERS,
    SUB_CATEGORIES.PROOFREADING,
    SUB_CATEGORIES.WEB_MOBILE_DESIGN,
    SUB_CATEGORIES.WHITEBOARD_EXPLAINER_VIDEOS,
];

module.exports = {
    ALLOWED_CATEGORIES,
    ALLOWED_SUB_CATEGORIES,
    ALLOWED_CURRENT_USER_STATUSES: [ACTIVE, PENDING],
    RESTRICTED_GIG_STATUS: [DELETED, DENIED],
};
