import { localStorage } from '@fiverr-private/futile';

class LocaleStorage {
    constructor() {
        this.LOCAL_STORAGE_ITEMS_LIMIT = 50;
        this.LOCAL_STORAGE_KEY = 'browse-history';
        this.GIGS_REGEX = /gigs\|\|\|show\|\|\|([\d]+)/;
        this.localStorage = localStorage;
    }

    get storageValue() {
        return this.localStorage.get(this.LOCAL_STORAGE_KEY);
    }

    set storageValue(val) {
        if (val && val.length > this.LOCAL_STORAGE_ITEMS_LIMIT) {
            val = val.slice(-this.LOCAL_STORAGE_ITEMS_LIMIT);
        }

        this.localStorage.set(this.LOCAL_STORAGE_KEY, val);
    }

    removeStorage() {
        this.localStorage.remove(this.LOCAL_STORAGE_KEY);
    }

    getGigIds(gigId) {
        // this method returns ls gig ids excluding current gig id
        const gigIdStr = gigId.toString();

        if (this.storageValue) {
            return this.storageValue.reduce((accumulator, data) => {
                const match = data.match(this.GIGS_REGEX);

                if (match && match.length === 2 && match[1] !== gigIdStr) {
                    accumulator.push(match[1]);
                }

                return accumulator;
            }, []);
        } else {
            return [];
        }
    }
}

export default new LocaleStorage();
