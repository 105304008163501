import { PAGE_NAME } from '../../bigQueryEventsData';

export const BASE_PARAMS = {
    group: 'user_impressions',
    sub_group: 'content_impression',
    entity: 'client',
    out_of_session: false,
    action: {
        type: 'impression',
    },
    domain: {
        name: 'foxes',
    },
    publisher: {
        domain: 'buyers',
        sub_domain: 'foxes',
    },
};

export const LOYALTY_PARAMS = {
    domain: { name: 'quality' },
    publisher: {
        domain: 'algos',
        sub_domain: 'quality',
    },
    feature: {
        name: 'loyalty_program',
    },
    page: {
        name: PAGE_NAME,
        element: {
            name: 'loyalty_banner',
            type: 'text',
            text: 'buyers keep returning',
        },
    },
};

export const GIG_DESCRIPTION_PARAMS = {
    page: {
        component: {
            name: 'gig_page_gig_description',
        },
    },
};

export const NOTABLE_CLIENTS_ABOVE_FOLD_PARAMS = {
    page: {
        name: PAGE_NAME,
        component: {
            name: 'notable_clients_above_the_fold',
        },
    },
};

export const NOTABLE_CLIENTS_BELOW_FOLD_PARAMS = {
    page: {
        name: PAGE_NAME,
        component: {
            name: 'notable_clients_below_the_fold',
        },
    },
};

export const VOICE_OVER_AI_AUDITION_PARAMS = {
    page: {
        name: PAGE_NAME,
        component: {
            name: 'ai_audition_component_is_shown',
        },
    },
};

export const PORTFOLIO_EVENT_PARAMS = {
    page: {
        name: PAGE_NAME,
    },
};

export const MACHINE_TRANSLATE_PROMOTION_PARAMS = {
    domain: { name: 'localization' },
    page: {
        name: PAGE_NAME,
        component: {
            name: 'localization_machine_translation_promotion_component_is_shown',
        },
    },
};

export const HIGHLY_RESPONSIVE_PARAMS = {
    domain: { name: 'quality' },
    publisher: {
        domain: 'algos',
        sub_domain: 'quality',
    },
    page: {
        name: PAGE_NAME,
        component: {
            name: 'highly_responsive_badge',
        },
    },
};

export const RISING_TALENT_BANNER_EVENT_PARAMS = {
    page: {
        name: PAGE_NAME,
    },
};

export const GIG_TITLE_EVENT_PARAMS = {
    page: {
        name: PAGE_NAME,
    },
};
